// @flow

import React, { type Element } from 'react';
import Option from '../Option';

type Props = {
  /** extra className */
  className?: string,

  /** if input is disabled */
  disabled?: boolean,

  children: Element<typeof Option> | Iterable<Element<typeof Option>>,

  /** input value */
  value: string,

  /** onChange event handler */
  onChange: (value: string) => void,
};

/**
 * Controlled select component
 */
const Select = ({
  className = '',
  disabled = false,
  children,
  value,
  onChange,
  ...otherProps
}: Props) => {
  const classNames = ['c-selectbox'];

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    <select
      className={classNames.join(' ')}
      disabled={disabled}
      value={value}
      onChange={(event: SyntheticInputEvent<HTMLSelectElement>) => {
        onChange(event.target.value);
      }}
      {...otherProps}
    >
      {children}
    </select>
  );
};

export default Select;
