// @flow

import api from '../utils/api';

import { type TaxOffice } from '../types/tax';
import { type Country, type CountryDetection } from '../types/country';
import { type Location } from '../types/location';
import { CountryMapper, CountryDetectionMapper } from '../mappers/country';

// eslint-disable-next-line import/prefer-default-export
export const taxOffices = async (query: string): Promise<Array<TaxOffice>> => {
  const response = await api.get(`/tax-offices?q=${query}`);

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload.taxOffices;
};

// eslint-disable-next-line import/prefer-default-export
export const getCountries = async (): Promise<Array<Country>> => {
  const response = await api.get('/countries');

  if (!response.ok) {
    throw response.data;
  }

  return CountryMapper.fromAPIResponseMultiple(response.data.payload.countries);
};

export async function countryDetection(
  location: Location | null,
  deviceCountryCode: string,
): Promise<CountryDetection> {
  const initialBaseUrlWithoutApiPostfix = api
    .getBaseURL()
    .slice(0, api.getBaseURL().lastIndexOf('/'));
  api.setBaseURL(`${initialBaseUrlWithoutApiPostfix}/api`);
  api.setHeader('X-App-Country', deviceCountryCode);
  const response = await api.post<any>('/country/check', {
    ...location,
  });
  api.setBaseURL(`${initialBaseUrlWithoutApiPostfix}/web_api`);

  if (!response.ok) {
    throw response.data;
  }
  return CountryDetectionMapper.fromAPIResponse(response.data.payload);
}
