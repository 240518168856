// @flow
export default {
  packages: {
    packageListItem: {
      viewDetails: 'View Details',
      salesAgreement: 'Terms and Conditions',
      readAndAgree: 'I have read and agree to the terms and conditions.',
      buy: 'Buy',
      creditCardDisclaimer:
        'We Do Not Keep Your Credit Card Information. This Is A Single Purchase Only',
      exclusiveOfferForNewAccounts: 'Exclusive Offer for New Accounts',
      timeRemaining: 'Time remaining for this special offer: ',
    },
    error: {
      agreementNotAccepted: 'You must accept the terms and conditions.',
      anErrorOccurred: 'An error has occurred. Please try again later.'
    },
    listPage: {
      disclaimer: 'You can call us at 0850 840 57 48 for package details and our services.',
    },
  },
};
