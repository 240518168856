// @flow
export default {
  branches: {
    dialogRemoveMessage: 'Bu şubeyi silmek istediğinize emin misiniz?',
    editBranch: 'ŞUBE DÜZENLE',
    addBranch: 'ŞUBE EKLE',
    branchNotExist: 'Hiç şubeniz bulunmamaktadır.',
    noBranch: 'Şube bulunmuyor',
    companyName: {
      label: 'Şube Adı',
      required: 'Lütfen şube adını giriniz.',
    },
    firstName: {
      label: 'İsim',
      required: 'Lütfen isminizi giriniz.',
    },
    lastName: {
      label: 'Soyad',
      required: 'Lütfen soyadınızı giriniz.',
    },
    email: {
      label: 'Yetkili E-posta Adresi',
      required: 'Lütfen yetkili e-posta adresini giriniz.',
      information:
        '* Kullanıcının e-posta adresine parolasını oluşturabilmesi için link gönderilecektir.',
    },
    taxOffice: {
      label: 'Vergi Dairesi',
      placeholder: 'Lütfen Seçiniz',
    },
    taxOfficeNo: {
      label: 'Vergi Dairesi No',
    },
    commercialTitle: {
      label: 'Ticari Ünvan',
    },
    website: {
      label: 'Web Sitesi',
    },
    companyAddress: {
      label: 'Açık Adres',
    },
    locationSearch: {
      label: 'Lokasyon Ara',
    },
    locationPosition: {
      label: 'Lokasyon Seçiniz',
      required: 'Lütfen lokasyon seçiniz.',
    },
    inbox: {
      totalMessages: 'Toplam Mesajlaşma Sayısı',
      totalUnreadMessages: 'Okunmamış Mesaj Sayısı',
    },
  },
};
