// @flow

import apiNew from '../utils/apiNew';
import { BasketMapper } from '../mappers/basket';
import { AddBasketItemRequest, Basket } from '../types/basket';

// eslint-disable-next-line import/prefer-default-export
export const createBasket = async (): Promise<Basket> => {
  const response = await apiNew.post<any>('/basket');

  if (!response.ok || !response) {
    throw response.data;
  }

  return BasketMapper.fromApiResponse(response.data.payload);
};

export const getBasket = async (): Promise<Basket | null> => {
  apiNew.setHeader('X-App-Info', 'v5.17.0 web 16.2');
  const response = await apiNew.get<any>('/basket');
  apiNew.deleteHeader('X-App-Info');

  if (!response.ok) {
    throw response.data;
  }

  if (!response.data.payload) {
    return null;
  }

  return BasketMapper.fromApiResponse(response.data.payload);
};

export const addItem = async (basketId: string, data: AddBasketItemRequest): Promise<Basket> => {
  apiNew.setHeader('X-App-Info', 'v5.17.0 web 16.2');
  const response = await apiNew.post<any>(`/basket/${basketId}/item`, data);
  apiNew.deleteHeader('X-App-Info');

  if (!response.ok || !response) {
    throw response.data;
  }

  return BasketMapper.fromApiResponse(response.data.payload);
};

export const updateItem = async (
  basketId: string,
  itemId: string,
  quantity: number,
): Promise<Basket> => {
  apiNew.setHeader('X-App-Info', 'v5.17.0 web 16.2');
  const response = await apiNew.put<any>(`/basket/${basketId}/item/${itemId}`, { quantity });
  apiNew.deleteHeader('X-App-Info');

  if (!response.ok || !response) {
    throw response.data;
  }

  return BasketMapper.fromApiResponse(response.data.payload);
};

export const deleteItem = async (basketId: string, itemId: string): Promise<Basket> => {
  apiNew.setHeader('X-App-Info', 'v5.17.0 web 16.2');
  const response = await apiNew.delete<any>(`/basket/${basketId}/item/${itemId}`);
  apiNew.deleteHeader('X-App-Info');

  if (!response.ok || !response) {
    throw response.data;
  }

  return BasketMapper.fromApiResponse(response.data.payload);
};
