// @flow
import React from 'react';
import cx from 'classnames';

const COMPONENT_CSS_CLASS = 'c-match-score';

type Props = {
  className?: string,
  percent: number, // Between 0-1
};

const classNameByScore = (score: number) => {
  switch (true) {
    case score >= 60:
      return `${COMPONENT_CSS_CLASS}--high`;
    default:
      return '';
  }
};

const ApplicantMatchScore = ({ className, percent }: Props) => {
  const score = Math.floor(percent * 100);

  const scoreClass = classNameByScore(score);

  return <span className={cx(COMPONENT_CSS_CLASS, scoreClass, className)}>%{score}</span>;
};

export default ApplicantMatchScore;
