// @flow

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import { logout } from '../../redux/modules/currentUser';
import { type ReduxDispatch } from '../../types/redux';

type Props = {
  logout: () => void,
  push: Function,
};

class Logout extends PureComponent<Props> {
  props: Props;

  componentWillMount() {
    this.props.logout();
    this.props.push('/login');
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ logout, push }, dispatch);

// $FlowFixMe
export default connect(
  null,
  mapDispatchToProps,
)(Logout);
