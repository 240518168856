/* eslint-disable import/prefer-default-export */
// @flow

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

const Events = {
  FB_WEB_SIGNUP: 'web_signup',
  FB_WEB_LOGIN: 'web_login',
  FB_WEB_PURCHASE: 'web_purchase',
  FB_WEB_JOB_CREATED: 'web_job_created',
  FB_WEB_ADDED_TO_CART: 'web_add_to_cart',
};

const logSignUp = (country: number, id: number) => {
  const params = {
    country,
    id,
  };

  window.FB.AppEvents.logEvent(Events.FB_WEB_SIGNUP, null, params);
};

const logLogin = (country: number) => {
  const params = {
    country,
  };

  window.FB.AppEvents.logEvent(Events.FB_WEB_LOGIN, null, params);
};

/**
 * TODO parametres will be added
 * @param {*} packageId
 * @param {*} currency
 * @param {*} price
 */
const logPurchase = () => {
  // const params = {
  //   packageId,
  //   currency,
  //   price,
  // };

  // window.FB.AppEvents.logEvent(Events.FB_WEB_PURCHASE, params);
  window.FB.AppEvents.logEvent(Events.FB_WEB_PURCHASE);
};

const logJobCreated = ({ country, jobId, jobTitle }) => {
  const params = {
    country,
    jobId,
    jobTitle,
  };

  window.FB.AppEvents.logEvent(Events.FB_WEB_JOB_CREATED, null, params);
};

async function initFacebookSdk() {
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: facebookAppId,
      cookie: true,
      xfbml: true,
      version: 'v8.0',
    });
  };

  // load facebook sdk script
  ((d, s, id) => {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}

const logPurchasePackagePress = (packageId: string, price: string) => {
  const content = [{ id: packageId, quantity: '1', item_price: price }];
  const params: Params = {
    fb_content_type: 'package',
    fb_content_id: packageId,
    fb_content: JSON.stringify(content),
    fb_currency: 'TRY',
  };

  window.FB.AppEvents.logEvent(Events.FB_WEB_ADDED_TO_CART, params);
};

export default {
  Events,
  logSignUp,
  logLogin,
  logPurchase,
  logJobCreated,
  initFacebookSdk,
  logPurchasePackagePress,
};
