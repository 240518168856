// @flow
export default {
  common: {
    errors: {
      errorOccured: 'Hubo un error.',
      anErrorOccurred:
        'Lo Sentimos, Ha ocurrido un error. Favor de Intentarlo Más Tarde. Código de error: {{errorCode}}',
      unexpected: 'Se ha producido un error',
    },
    validations: {
      phone: '5555555555.',
      email: 'Escribe un e-mail válido.',
      integer: 'Escribe carácter válido.',
      maxLength: 'Demasiados caracteres.',
      maxNumber: 'Menos números.',
      minLength: 'Nececitas más caracteres.',
      minNumber: 'Más números.',
      required: 'Obligatorio',
    },
    glossary: {
      nonExistent: 'No',
      month: 'Mes',
      inexperienced: 'Sin experiencia',
      jobApplied: 'Solicitó empleo',
      writeMessage: 'Mensaje',
      yes: 'Sí',
      no: 'No',
      edit: 'Editar',
      delete: 'Borrar',
      select: 'Seleccionar',
      unknown: 'Desconocido',
      save: 'Guardar',
      cancel: 'Cancelar',
      continue: 'Continuar',
      send: 'Enviar',
      editPhoto: 'Editar foto',
      ok: 'OK',
      warning: 'Advertencia',
      noResult: 'Sin resultado',
      searchInChoices: 'Buscar',
      loading: 'Cargando',
      detail: 'Detalles',
      removeChoice: 'Borrar',
      backToHomePage: 'Volver a la página de inicio',
      unAuthorizedView: 'No está autorizado para ver esta página.',
      tryAgain: 'Inténtalo de nuevo',
    },
    confirms: {
      isSureToContinue: '¿Estás seguro de continuar?',
    },
    alerts: {
      dashboardAccess:
        'Un representante de ventas se pondrá en contacto contigo para ayunderte a usar la página. ',
      jobCreation: 'Puedes comparar un paquete',
    },
    apiValidations: {
      user: {
        phone: {
          verify: {
            unique: 'Este número ha sido usado.',
            dailyLimit: 'Has alcanzado el limite. Intenta en 24 horas',
            invalidCode: 'El codigo que usaste es inválido.',
            notFoundHttpException:
              'Numero de telefono invalido. Por favor, introduzca un número de teléfono móvil válido (Error Code: 1030)',
          },
        },
      },
    },
  },
};
