// @flow
export default {
  payment: {
    error: {
      paymentErrorMessage: 'Error has occurred while completing the payment.',
      cardDeclined: 'Card Declined TR',
      expiredCard: 'The card has expired. Check the expiration date or use a different card.',
      incorrectCvc:
        'The card’s security code is incorrect. Check the card’s security code or use a different card.',
      incorrectNumber:
        'The card number is incorrect. Check the card’s number or use a different card.',
      invalidExpiryMonth:
        'The card’s expiration month is incorrect. Check the expiration date or use a different card.',
      invalidExpiryYear:
        'The card’s expiration year is incorrect. Check the expiration date or use a different card.',
      invalidNumber: 'The card number is invalid. Check the card details or use a different card.',
      insufficientFunds: 'The customer’s account has insufficient funds to cover this payment.',
      invalidExpiryDate:
        'The card’s expiration date is incorrect. Check the expiration date or use a different card.',
    },
    success: {
      paymentSuccessMessage: 'Your payment has been processed successfully',
    },
    paymentResult: {
      success: 'Success',
      failure: 'Failure',
    },
    stripeForm: {
      pay: 'Pay',
      payWithAmount: 'PAY {{amount}}',
    },
    input: {
      cardHolderName: {
        required: 'Card Holder Name is required',
        enterValid: 'Enter valid card holder name',
        placeholder: 'Card Holder Name'
      },
      cardNumber: {
        required: 'Card Number is required',
        enterValid: 'Enter valid card number',
        placeholder: 'Card Number'
      },
      expiryDate: {
        required: 'Expiry Date is required',
        enterValid: 'Enter valid expiry date',
        placeholder: 'MM/YY'
      },
      cvc: {
        required: 'CVC is required',
        enterValid: 'Enter valid CVC',
        placeholder: 'CVC'
      },
    },
    general: {
      reachedPurchaseLimit: 'Reached purchase limit',
      anErrorOccuredTryAgain: 'An error occured. Please try again',
      noAddonForSelectedPackage: 'No addon for selected package',
      anErrorOccuredWhileLoadingAddons: 'An error occurred while loading addons. Please refresh the page.',
      backToPreviousPage: '< Back To Previous Page',
      yourCart: 'Your Cart',
      additionalProducts: 'Additional Products',
      proceedToCheckout : 'Proceed to checkout',
      pay: 'PAY',
      cardDetails: 'Card Details',
      completePayment: 'Complete Payment'
    }
  },
};
