// @flow

import Container from '../components/abstract/notification/Container';
import { type Notification } from '../types/notification';

// eslint-disable-next-line
let _container: Container | null = null;

function setContainer(container: Container) {
  _container = container;
}

function present(notification: Notification) {
  if (_container) {
    _container.add(notification);
  }
}

export default {
  setContainer,
  present,
};
