// @flow

import React, { type Node } from 'react';
import BaseModal from 'react-modal';
import cx from 'classnames';

import Box from '../Box';
import Icon from '../Icon';

type Props = {
  title?: string,
  closeable?: boolean,
  size?: 'large',
  children: Node,
  contentLabel: string,
  isOpen: boolean,
  onModalCloseRequested?: () => void,
};

const Modal = ({
  title,
  children,
  contentLabel,
  isOpen,
  size,
  closeable = true,
  onModalCloseRequested,
  ...otherProps
}: Props) => (
  <BaseModal
    isOpen={isOpen}
    contentLabel={contentLabel}
    {...otherProps}
    className="c-modal__body"
    overlayClassName={cx('c-modal', { 'c-modal--large': size === 'large' })}
  >
    <div className="c-modal__body-inner">
      <Box className="u-pad-ends u-pad-sides-medium@md-up u-pad-sides@sm-down">
        {closeable && (
          <button type="button" className="c-modal__close-button" onClick={onModalCloseRequested}>
            <Icon name="close" className="c-modal__close-icon" />
          </button>
        )}
        {title && <h2 className="u-font-weight-bold u-text-center u-clear-gap-top">{title}</h2>}
        {children}
      </Box>
    </div>
  </BaseModal>
);

export default Modal;
