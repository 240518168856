// @flow

import React, { PureComponent } from 'react';


import { withTranslation } from 'react-i18next';
import FileSaver from "file-saver"

import { UserTypeMap } from '../../../../enums/userType';

import { type AuthenticatedUser } from '../../../../types/user';
import { type OtherApplication, type Application } from '../../../../types/application';

import { downloadApplicationAsPDF, exportApplicationAsPdf, type CVUser } from '../../../../api/job';

import WorkerComment from '../../../../containers/WorkerComment';
import ProfileHeader from '../ProfileHeader';

import Row from '../../../../components/layout/Row';
import Col from '../../../../components/layout/Col';
import Button from '../../../../components/ui/Button';

import PdfModal from './PdfModal';
import { themePalette } from '../../../../theme/index';
import formInputList from '../../../../api/formInputList';
import Loading from '../../../../components/elements/Loading';
import Alert from '../../../../components/elements/Alert';
import moment from 'moment';
import DownloadIcon from '../../../../components/elements/Icon/downloadIcon';

const MILITARY_STATUS_MAP = {
  '1': 'waiting',
  '2': 'delayed',
  '3': 'done',
}

const ENGLISH_LEVEL_MAP = {
  '1': 'zero',
  '2': 'low',
  '3': 'normal',
  '4': 'high',
}

const EDUCATION_MAP = {
  elementary_school: 'elementarySchool',
  middle_school: 'middleSchool',
  high_school: 'highSchool',
  university: 'university',
}
const WORKTIME_PREFERENCE_MAP = {
  full_time: 'workTimePreference.fullTime',
  part_time: 'workTimePreference.partTime',
  seasonal: 'workTimePreference.seasonal',
}

type Props = {
  application: Application,
  otherApplications: Array<OtherApplication>,
  currentUser: AuthenticatedUser,
  workerRating: number,
  t: Function,
};

type State = {
  showPdfModal: boolean,
  formViewData: any,
  alertMessage: string,
  showAlert: boolean,
  loading: Boolean,
  alertData: any,
  pdfDownloading: boolean
};

class Profile extends PureComponent<Props, State> {
  props: Props;

  state: State = {
    showPdfModal: false,
    formViewData: null,
    alertMessage: "",
    showAlert: false,
    alertData: null,
    loading: true,
    pdfDownloading: false
  };

  constructor(props) {
    super(props);

    this.handleAlertClose = this.handleAlertClose.bind(this);
  }

  componentWillMount() {
    formInputList("WorkerProfileForm")
      .then((res) => {
        this.setState({
          formViewData: res
        });
      })
      .catch((e) => {
        this.setState({
          alertMessage: "common.errors.anErrorOccurred",
          alertData: { errorCode: 1014 },
          showAlert: true
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  handleAlertClose = () => {
    this.setState({
      showAlert: false
    });
  }

  handleExport = () => {
    this.setState({ showPdfModal: true });
  };

  handlePdfModalClose = () => {
    this.setState({ showPdfModal: false });
  };

  handlePdfModalSubmit = async (values: { email: string }) => {
    const applicationId = +this.props.application.id;
    const { email } = values;
    const { t } = this.props;

    try {
      await exportApplicationAsPdf(applicationId, email);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(t('common.errors.errorOccurred'));
    }

    this.setState({ showPdfModal: false });
  };

  handleExportCvAsPDF = async () => {
    this.setState({ pdfDownloading: true })
    const { application, t, currentUser } = this.props
    const { user } = application

    const cvUser: CVUser = {
      profilePictureUrl: user.imageUrl || "",
      fullname: `${user.firstName} ${user.lastName}`,
      geoLocation: user.location.name,
      email: user.email,
      phone: user.phone,
      experienceMonth: user.experienceMonth,
      gender: user.gender || "-",
      localizedBirthDate: user.birthDate ? moment(user.birthDate).format("YYYY-MM-DD") : "-",
      disabledStatus: user.disabledStatus || "-",
      worktimePreferences: user.workTimePreferences ? user.workTimePreferences.map((v) => WORKTIME_PREFERENCE_MAP[v.value]) : ["-"],
      educationStatus: user.education ? user.education : "-",
      university: user.university ? {
        name: user.university.name,
        status: user.universityStatus,
      }
        : null,
      about: user.about || "-",
      expectedSalary: user.expectedSalary,
      militaryStatus: user.military ? MILITARY_STATUS_MAP[user.military] : "-",
      englishLevel: user.english ? ENGLISH_LEVEL_MAP[user.english] : "-",
      experiences: user.experiences ?
        user.experiences.map((e) => ({
          companyName: e.companyName || "-",
          position: e.position || "-",
          duration: e.duration.key || 0
        }))
        : ["-"]
    }

    try {
      const { fileName, result } = await downloadApplicationAsPDF({ userInformation: cvUser, languageCode: currentUser.user.language.languageCode })

      const blob = new Blob([result], { type: "application/pdf" })

      FileSaver.saveAs(blob, `${fileName}.pdf`)
    } catch (error) {
      console.error("PersonelInfo - handleExportCvAsPDF - ", error)
      alert(t('common.errors.errorOccurred'));
    } finally {
      this.setState({ pdfDownloading: false })
    }
  }

  render() {
    const { application, otherApplications, currentUser, workerRating, t } = this.props;
    const profile = application.user;

    if (this.state.loading) return <Loading />

    return (
      <div>
        <PdfModal
          isOpen={this.state.showPdfModal}
          onSubmit={this.handlePdfModalSubmit}
          onClose={this.handlePdfModalClose}
        />

        <ProfileHeader profile={profile} workerRating={workerRating}>
          <div className="u-gap-bottom-small" style={{ display: "flex", flexDirection: "row" }}>
            <Button
              block
              outline
              small
              onClick={this.handleExport}
              style={{
                color: themePalette.primary_color,
                boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
              }}
            >
              {t('jobDetail.profile.sendAsPDF')}
            </Button>
            <div style={{ width: "8px" }} />
            <Button
              block
              outline
              small
              disabled={this.state.pdfDownloading}
              onClick={this.handleExportCvAsPDF}
              style={{
                color: themePalette.primary_color,
                boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
              }}
            >
              {
                this.state.pdfDownloading ?
                  <div className='c-loading' style={{ minHeight: "10px" }} />
                  :
                  <DownloadIcon height={18} width={18} />
              }
            </Button>
          </div>
        </ProfileHeader>

        <hr className="u-hr-line" />
        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.personalInformation')}
          </h6>
          <Row>
            <Col md={3}>
              <p className="u-font-size-sm u-color-gull-gray">{t('jobDetail.profile.gender')}</p>
              <span>
                {profile.gender
                  ? t('constants.genderMap')[profile.gender]
                  : t('jobDetail.profile.unknown')}
              </span>
            </Col>
            <Col md={3}>
              <p className="u-font-size-sm u-color-gull-gray">{t('jobDetail.profile.birthDate')}</p>
              <span>
                {profile.birthDate ? profile.birthDate.format('L') : t('jobDetail.profile.unknown')}
              </span>
            </Col>
            <Col md={3}>
              <p className="u-font-size-sm u-color-gull-gray">
                {t('jobDetail.profile.disabledStatus')}
              </p>
              <span>
                {profile.disabledStatus
                  ? t('constants.disabledStatusMap')[profile.disabledStatus]
                  : t('jobDetail.profile.unknown')}
              </span>
            </Col>
            {profile.gender === 'male' && (this.state.formViewData && this.state.formViewData.military_status && this.state.formViewData.military_status.isActive) && (
              <Col md={3}>
                <p className="u-font-size-sm u-color-gull-gray">
                  {t('jobDetail.profile.militaryStatus')}
                </p>
                <span>
                  {profile.military
                    ? t('constants.militaryStatusMap')[profile.military]
                    : t('jobDetail.profile.unknown')}
                </span>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={3}>
              <p className="u-font-size-sm u-color-gull-gray">
                {t('jobDetail.profile.identityNumber')}
              </p>
              <span>{profile.identityNumber || t('jobDetail.profile.unknown')}</span>
            </Col>
            <Col md={3}>
              <p className="u-font-size-sm u-color-gull-gray">
                {t('jobDetail.profile.certificate')}
              </p>
              <span>{profile.certificates || t('jobDetail.profile.unknown')}</span>
            </Col>
            <Col md={3}>
              <p className="u-font-size-sm u-color-gull-gray">
                {t('jobDetail.profile.drivingLicenses')}
              </p>
              <span>
                {profile.drivingLicenses && profile.drivingLicenses.length > 0
                  ? profile.drivingLicenses.join(', ')
                  : t('jobDetail.profile.unknown')}
              </span>
            </Col>
          </Row>
        </div>
        <hr className="u-hr-line" />
        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.about')}
          </h6>
          <p>{profile.about || t('jobDetail.profile.noAboutArticle')}</p>
        </div>
        <hr className="u-hr-line" />
        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.educationInformation')}
          </h6>
          <Row>
            <Col md={3}>
              <p className="u-font-size-sm u-color-gull-gray">
                {t('jobDetail.profile.educationStatus')}
              </p>
              <span>
                {profile.education
                  ? t('constants.educationMap')[profile.education]
                  : t('jobDetail.profile.unknown')}
              </span>
            </Col>
            {profile.university && (
              <Col md={6}>
                <p className="u-font-size-sm u-color-gull-gray">
                  {t('jobDetail.profile.university')}
                </p>
                <span>
                  {profile.university ? profile.university.name : t('jobDetail.profile.unknown')} (
                  {t('constants.universityStatusMap')[profile.universityStatus]})
                </span>
              </Col>
            )}
            {(this.state.formViewData && this.state.formViewData.english_level && this.state.formViewData.english_level.isActive) ? <Col md={3}>
              <p className="u-font-size-sm u-color-gull-gray">
                {t('jobDetail.profile.englishStatus')}
              </p>
              <span>
                {profile.english
                  ? t('constants.englishLevelMap')[profile.english]
                  : t('jobDetail.profile.unknown')}
              </span>
            </Col> : null}
          </Row>
        </div>
        <hr className="u-hr-line" />
        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.experience')}
          </h6>
          {profile.experiences.length === 0 ? (
            <p>{t('jobDetail.profile.noExperience')}</p>
          ) : (
            profile.experiences.map(experience => (
              <div key={experience.id}>
                <p>
                  {experience.companyName}{' '}
                  <span className="u-font-size-sm u-color-gull-gray">{experience.position}</span>{' '}
                  <span className="u-font-size-sm u-color-gull-gray">
                    ({experience.duration.value})
                  </span>
                </p>
              </div>
            ))
          )}
        </div>
        <hr className="u-hr-line" />
        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.workPreferences')}
          </h6>
          <p className="u-font-size-sm u-color-gull-gray">
            {' '}
            {t('jobDetail.profile.workCategoryPreferences')}
          </p>
          <span>
            {profile.workCategoryPreferences.length !== 0
              ? profile.workCategoryPreferences.map(category => category.name).join(', ')
              : t('jobDetail.profile.unknown')}
          </span>
          <Row>
            <Col md={6}>
              <p className="u-font-size-sm u-color-gull-gray">
                {t('jobDetail.profile.workTimePreferences')}
              </p>
              <span>
                {profile.workTimePreference
                  ? t('constants.workTimeMap')[profile.workTimePreference]
                  : t('jobDetail.profile.unknown')}
              </span>
            </Col>
            <Col md={6}>
              <p className="u-font-size-sm u-color-gull-gray">
                {t('jobDetail.profile.expectedSalary')}
              </p>
              <span>{profile.expectedSalary || t('jobDetail.profile.unknown')}</span>
            </Col>
          </Row>
        </div>
        <hr className="u-hr-line" />
        <div className="u-pad-ends-small u-pad-sides-small">
          <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
            {t('jobDetail.profile.otherApplications')}
          </h6>
          {otherApplications.map(item => (
            <div key={item.applicationId}>
              <p>
                {item.jobTitle}{' '}
                <span className="u-font-size-sm u-color-gull-gray">({item.companyName})</span>
              </p>
            </div>
          ))}
          {otherApplications.length === 0 && <p>{t('jobDetail.profile.noOtherApplications')}</p>}
        </div>
        {currentUser.user.userType !== UserTypeMap.COMPANY && (
          <div>
            <hr className="u-hr-line" />
            <WorkerComment worker={profile} />
          </div>
        )}
        <Alert
          isVisible={this.state.showAlert}
          onConfirmClick={this.handleAlertClose}
          message={t(this.state.alertMessage, this.state.alertData)}
        />
      </div>
    );
  }
}

export default withTranslation()(Profile);
