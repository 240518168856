// @flow

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, matchPath, type Location, type RouterHistory } from 'react-router-dom';

import { UserTypeMap } from '../../enums/userType';
import Notification from '../../services/notification';
import fcm from '../../services/fcm';
import { MessageMapper } from '../../mappers/chat';
import { fcmSupportChangeThunk } from '../../redux/modules/app';
import { type CurrentUserState } from '../../redux/modules/currentUser';
import {
  sentThunk,
  markAsReadThunk,
  type ConversationDetailState,
} from '../../redux/modules/conversationDetail';
import { newMessageThunk } from '../../redux/modules/inbox';
import { type ReduxState } from '../../redux/modules';
import { type NewMessageReceivedNotification } from '../../types/fcm';
import { type ReduxDispatch } from '../../types/redux';
import { type TextMessagePayload, type LocationMessagePayload } from '../../types/chat';

type Props = {
  history: RouterHistory,
  location: Location,
  currentUser: CurrentUserState,
  conversationDetail: ConversationDetailState,
  sentThunk: (
    threadId: number,
    message: TextMessagePayload | LocationMessagePayload,
    isReaded: boolean,
  ) => void,
  markAsReadThunk: (threadId: number, branchId: number) => void,
  updateLastMessageThunk: (
    threadId: number,
    message: TextMessagePayload | LocationMessagePayload,
    isReaded: boolean,
  ) => void,
  fcmSupportChangeThunk: (status: boolean) => void,
};

class MessageReceiver extends PureComponent<Props> {
  props: Props;

  componentWillMount() {
    fcm.onSupportStatusChange((status: boolean) => {
      this.props.fcmSupportChangeThunk(status);
    });

    fcm.onNewMessage((message: NewMessageReceivedNotification) => {
      const threadId = message.payload.thread_id;
      const branchId = message.payload.recipient_id;
      const activeThreadId = this.findActiveThreadId();

      if (!activeThreadId || threadId !== activeThreadId) {
        this.presentNewMessageNotification(message);
      } else {
        // thread is active, so message already readed
        this.props.markAsReadThunk(threadId, branchId);
      }

      if (!this.props.conversationDetail[threadId]) {
        this.props.updateLastMessageThunk(
          threadId,
          (MessageMapper.fromAPIResponse(message.payload.message): any),
          threadId === activeThreadId,
        );

        return;
      }

      // we don't need to call updateLastMessageThunk here.
      // sentThunk will call that method.

      this.props.sentThunk(
        threadId,
        (MessageMapper.fromAPIResponse(message.payload.message): any),
        threadId === activeThreadId,
      );
    });
  }

  presentNewMessageNotification(message: NewMessageReceivedNotification) {
    Notification.present({
      type: 'message',
      title: `${message.payload.message.sender.first_name} ${
        message.payload.message.sender.last_name
      }`,
      body: message.payload.notification_text || message.payload.message.message_text,
      avatar: message.payload.message.sender.image_url,
      dismissAfter: 3000,
      onClick: () => {
        const path = this.getThreadPath();

        this.props.history.push(
          path
            .replace(':threadId', `${message.payload.thread_id}`)
            .replace(':branchId', `${message.payload.recipient_id}`),
        );
      },
    });
  }

  findActiveThreadId(): null | number {
    const path = this.getThreadPath();

    const match = matchPath(this.props.location.pathname, {
      path,
    });

    if (!match) {
      return null;
    }

    return +match.params.threadId;
  }

  getThreadPath(): string {
    const currentUser = this.props.currentUser.user;

    const isBranch =
      currentUser.userType === UserTypeMap.BRANCH || currentUser.userType === UserTypeMap.COMPANY;
    const path = isBranch ? '/inbox/:threadId' : '/inbox/:branchId/:threadId';

    return path;
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  currentUser: state.currentUser,
  conversationDetail: state.conversationDetail,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    {
      sentThunk,
      markAsReadThunk,
      updateLastMessageThunk: newMessageThunk,
      fcmSupportChangeThunk,
    },
    dispatch,
  );

// $FlowFixMe
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MessageReceiver),
);
