// @flow
import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { loadThunk, type HolidaysState } from '../redux/modules/holidays';
import { isInit, isLoading, isLoaded } from '../utils/apiState';

import { type ReduxDispatch } from '../types/redux';
import { type ReduxState } from '../redux/modules';

function makeIsStopDayFn(stopDays: moment[] = []): moment => boolean {
  return (date: moment) => {
    const day = date.isoWeekday();

    // is weekend
    if (day === 6 || day === 7) {
      return true;
    }

    return stopDays.find(d => d.isSame(date, 'd')) !== undefined;
  };
}

let isStopDay = makeIsStopDayFn();

/**
 * Calculates elapsed second for an application.
 *
 * NOTE:
 * This function designed for 24 hour durations only.
 * It will not work on any other duration.
 */
function elapsed(start: moment): number {
  const now = moment();
  const nextDayAfterStart = start
    .clone()
    .add(1, 'day')
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

  let totalElapsed = now.unix() - start.unix();

  if (isStopDay(start)) {
    totalElapsed -= Math.min(nextDayAfterStart.unix() - start.unix(), totalElapsed);
  }

  const counter = nextDayAfterStart.clone();
  while (now.isAfter(counter)) {
    if (!isStopDay(counter)) {
      counter.add(1, 'day');
      continue; // eslint-disable-line
    }

    if (now.isSame(counter, 'd')) {
      totalElapsed -= now.unix() - counter.unix();

      counter.add(1, 'day');
      continue; // eslint-disable-line
    }

    totalElapsed -= 24 * 60 * 60;
    counter.add(1, 'day');
  }

  return Math.max(0, Math.min(24 * 60 * 60, totalElapsed));
}

type Props = {
  dispatch: ReduxDispatch,
  holidays: HolidaysState,
  startDate: moment,
  t: Function,
};

class RemainingTime extends PureComponent<Props> {
  props: Props;

  intervalId: any;

  componentWillMount() {
    if (isInit(this.props.holidays)) {
      this.props.dispatch(loadThunk());
    }
  }

  componentDidMount() {
    this.forceUpdate();
    this.intervalId = setInterval(() => {
      this.forceUpdate();
    }, 1000);

    if (isLoaded(this.props.holidays)) {
      isStopDay = makeIsStopDayFn(this.props.holidays.data);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!isLoaded(this.props.holidays) && isLoaded(nextProps.holidays)) {
      isStopDay = makeIsStopDayFn(nextProps.holidays.data);
    }
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  render() {
    const { t } = this.props;

    if (isLoading(this.props.holidays)) {
      return null;
    }

    const remaining = 24 * 60 * 60 - elapsed(this.props.startDate);

    if (remaining === 0) {
      return null;
    }

    return (
      <div className="u-gap-bottom-xsmall">
        <span className="u-font-size-sm">
          {`
          ${t('jobDetail.remainingTime')}: ${moment
            .duration(remaining, 'seconds')
            .format('hh:mm:ss', { trim: false })}`}
        </span>
      </div>
    );
  }
}

// $FlowFixMe
export default connect((state: ReduxState): Object => ({
  holidays: state.holidays,
}))(withTranslation()(RemainingTime));
