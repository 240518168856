// @flow

import moment from 'moment-timezone';

import { WorkerMapper, UserMapper } from './user';
import { LocationMapper } from './location';
import {
  type Conversation,
  type Thread,
  type Message,
  type BranchesInboxItem,
} from '../types/chat';

export const ThreadMapper = {
  fromAPIResponse(response: Object = {}): Thread {
    return {
      id: response.id || 0,
      user: WorkerMapper.fromAPIResponse(response.user),
      lastMessage: response.last_message || '',
      lastMessageSent: moment(response.last_message_sent),
      nbUnreadMessage: response.nb_unread_message || 0,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Thread> {
    if (!response) {
      return [];
    }

    return response.map(ThreadMapper.fromAPIResponse);
  },
};

export const MessageMapper = {
  fromAPIResponse(response: Object = {}): Message {
    const message = {
      id: response.id || 0,
      sender: UserMapper.fromAPIResponse(response.sender),
      date: moment(response.message_date),
      type: response.message_type || 'text',
      messagePayload: response.message_text,
    };

    switch (message.type) {
      case 'location':
        (message: any).body = {
          text: response.message_text.text,
          ...LocationMapper.fromAPIResponse(response.message_text.text),
        };
        break;
      case 'application':
        (message: any).body = {
          text: response.message_text.text,
          id: response.message_text.id || 0,
          jobId: response.message_text.jobId || 0,
          jobTitle: response.message_text.jobTitle || '',
          jobDescription: response.message_text.jobDescription || '',
        };
        break;
      case 'text':
      default:
        (message: any).body = response.message_text;
    }

    return (message: any);
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Message> {
    if (!response) {
      return [];
    }

    return response.map(MessageMapper.fromAPIResponse);
  },
};

export const ConversationMapper = {
  fromAPIResponse(response: Object = {}): Conversation {
    return {
      thread: ThreadMapper.fromAPIResponse(response.thread),
      messages: MessageMapper.fromAPIResponseMultiple(response.messages),
      validation_errors: response.validation_errors,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Conversation> {
    if (!response) {
      return [];
    }

    return response.map(ConversationMapper.fromAPIResponse);
  },
};

export const BranchesInboxItemMapper = {
  fromAPIResponse(response: Object = {}): BranchesInboxItem {
    return {
      id: response.id || 0,
      companyName: response.company_name || '',
      imageUrl: response.image_url || '',
      nbInbox: response.nb_inbox || 0,
      nbTotal: response.nb_total || 0,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<BranchesInboxItem> {
    if (!response) {
      return [];
    }

    return response.map(BranchesInboxItemMapper.fromAPIResponse);
  },
};
