// @flow

import React, { PureComponent } from 'react';
import { type Match } from 'react-router-dom';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { feedback as feedbackRequest } from '../../../../api/application';
import { success as successAction } from '../../../../redux/modules/applicationDetail';

import { type Application } from '../../../../types/application';

import ProfileHeader from '../ProfileHeader';

import FeedbackForm from './Form';

type Props = {
  jobId: number,
  application: Application,
  workerRating: number,
  dispatch: any,
  t: Function,
  updateAppointmentAction: (id: number, appointment?: Appointment) => void,
  updateAppointmentJobAction: (
    jobId: number,
    appId: number,
    match: Match,
    appointment?: Appointment,
  ) => void,
  jobMatch: Match,
};

class Feedback extends PureComponent<Props> {
  props: Props;

  handleSubmit = async (values: any) => {
    const {
      jobId,
      application,
      dispatch,
      t,
      updateAppointmentAction,
      updateAppointmentJobAction,
      jobMatch,
    } = this.props;

    const { appointment } = application;

    if (!appointment || !Object.keys(appointment).length) {
      alert(t('jobDetail.feedbackForm.noAppointment'));
      return;
    }

    const valuesToSubmit = {
      ...values,
      isApplicantCameToAppointment:
        appointment.isApplicantCameToAppointment !== undefined
          ? undefined
          : values.isApplicantCameToAppointment,
      isAppointmentPositive:
        appointment.isAppointmentPositive !== undefined ? undefined : values.isAppointmentPositive,
      isOfferSentToApplicant:
        appointment.isOfferSentToApplicant !== undefined
          ? undefined
          : values.isOfferSentToApplicant,
      isApplicantAcceptTheOffer:
        appointment.isApplicantAcceptTheOffer !== undefined
          ? undefined
          : values.isApplicantAcceptTheOffer,
    };

    try {
      const updatedApplication = await feedbackRequest(jobId, application.id, valuesToSubmit);

      updatedApplication.appointment = {
        ...appointment,
        ...valuesToSubmit,
      };

      dispatch(successAction(updatedApplication, updatedApplication.id));
      alert(t('constants.updateFeedback'));
      updateAppointmentAction(updatedApplication.id, updatedApplication.appointment);
      updateAppointmentJobAction(
        jobId,
        updatedApplication.id,
        jobMatch,
        updatedApplication.appointment,
      );
    } catch (error) {
      alert(error.message || t('common.errors.errorOccured'));
    }
  };

  render() {
    const { application, workerRating } = this.props;
    const { appointment } = application;

    const initialValues = {
      isApplicantCameToAppointment: appointment
        ? appointment.isApplicantCameToAppointment
        : undefined,
      isAppointmentPositive: appointment ? appointment.isAppointmentPositive : undefined,
      isOfferSentToApplicant: appointment ? appointment.isOfferSentToApplicant : undefined,
      isApplicantAcceptTheOffer: appointment ? appointment.isApplicantAcceptTheOffer : undefined,
      comment: application.user.comment,
      appointmentComment: appointment ? appointment.appointmentComment : undefined,
    };

    return (
      <div>
        <ProfileHeader profile={application.user} workerRating={workerRating} />

        <hr className="u-hr-line" />
        <FeedbackForm
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          initialValuesInComponent={initialValues}
        />
      </div>
    );
  }
}

// $FlowFixMe
export default connect()(withTranslation()(Feedback));
