// @flow
export default {
  resetPassword: {
    errors: {
      errorOccured: 'Hubo un error.',
    },
    alerts: {
      invalidToken: 'Token invalido',
    },
    form: {
      resetPassword: 'Borrar contraseña',
      passwordIsNotEqual: 'Los contraseñas debe que son diferentes.',
      invalidToken: 'Token invalido',
      success: 'Tu contraseña ha sido cambiada éxito .',
    },
  },
  forgetPassword: {
    title: '¿Olvideste tu contraseña?',
    body: 'Escribe tu e-mail y te enviaremos un link para crear nueva contraseña.',
    submit: 'Enviar',
    successSubmitText: 'Te enviamos un link para crear nueva contraseña .',
    failSubmitText: 'E-mail invalid.',
  },
};