// @flow

import React from 'react';

import noBranch from '../../img/empty-state/no-branch.svg';
import useTranslator from '../../hooks/useTranslator';

const NoData = () => {
  const { t } = useTranslator();

  return (
    <div className="u-pad-ends-large u-text-center">
      <img src={noBranch} width={270} height={150} alt={t('branches.noBranch')} />

      <h3 className="u-color-gull-gray u-gap-bottom-medium">{t('branches.branchNotExist')}</h3>
    </div>
  );
};

export default NoData;
