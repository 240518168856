/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-undef */
// @flow

import React, { useState, useEffect, useRef } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormField from '../components/elements/FormField';
import Button from '../components/ui/Button';

import required from '../validators/required';
import minLength from '../validators/minLength';
import maxLength from '../validators/maxLength';
import email from '../validators/email';
import Row from '../components/layout/Row';
import Col from '../components/layout/Col';
import GeoSuggestField from '../components/elements/GeoSuggestField';
import type { ReduxState } from '../redux/modules';
import phone from '../validators/phone';
import FormFieldCheck from '../components/elements/FormFieldCheck';
import FormFieldSelect from '../components/elements/FormFieldSelect';
import { UserAgreementsMap } from '../enums/userAgreements';
import useTranslator from '../hooks/useTranslator';
import { type Country } from '../types/country';
import { loadScriptAsync } from '../utils/window';
import { themePalette } from '../theme/index';
import FormSearchableSelect from '../components/elements/FormSearchableSelect';

type Props = {
  handleSubmit: Function,
  change: (field: string, value: any) => void,
  submitting: boolean,
  invalid: boolean,
  countries: Country[],
  isRegisterButtonDisabled: boolean,
  shouldSubmit?: boolean,
};

const RegisterForm = ({
  handleSubmit,
  submitting,
  change,
  invalid,
  countries,
  isRegisterButtonDisabled,
  shouldSubmit,
}: Props) => {
  const { t, Trans, i18n } = useTranslator();
  const [googleMapsInitialized, setGoogleMapsInitialized] = useState(false);

  useEffect(() => {
    if (!window.google) {
      const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${
        process.env.REACT_APP_GOOGLE_MAP_API_KEY
      }&libraries=places&language=${i18n.language || 'en-US'}`;

      loadScriptAsync(googleMapURL).then(() => setGoogleMapsInitialized(true));
    } else {
      setGoogleMapsInitialized(true);
    }
  }, []);

  const formRef = useRef(null);

  useEffect(() => {
    if (shouldSubmit) {
      formRef.current.dispatchEvent(new Event('submit'));
    }
  }, [shouldSubmit]);

  const mapCountryToSelectOption = country => ({
    value: country.id,
    label: country.name,
    countryCode: country.countryCode,
    countryPhoneCode: country.phoneCode,
  });

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <h1 className="u-font-weight-bold u-text-center u-gap-top-large">
        {t('register.registerForm.title')}
      </h1>
      <div className="u-pad-top-small u-pad-sides-small">
        <Row>
          <Col md={12}>
            <Field
              name="companyName"
              type="text"
              component={FormField}
              label={t('register.registerForm.companyName.label')}
              validate={[required(t('register.registerForm.companyName.required'))]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field
              name="firstName"
              type="text"
              component={FormField}
              label={t('register.registerForm.firstName.label')}
              validate={[required(t('register.registerForm.lastName.required'))]}
            />
          </Col>
          <Col md={6}>
            <Field
              name="lastName"
              type="text"
              component={FormField}
              label={t('register.registerForm.lastName.label')}
              validate={[required(t('register.registerForm.lastName.required'))]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field
              name="email"
              type="email"
              component={FormField}
              label={t('register.registerForm.email.label')}
              validate={[
                required(t('register.registerForm.email.required')),
                email(t('common.validations.email')),
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Field
              name="CountryPhoneCode"
              type="select"
              component={FormFieldSelect}
              props={{
                options: [
                  { id: '', name: '' },
                  ...countries.map((country, index) => ({
                    id: country.phoneCode,
                    name: country.phoneCode,
                    key: index,
                  })),
                ],
                disabled: true,
                noDefaultOption: true,
                float: true,
              }}
            />
          </Col>
          <Col md={8}>
            <Field
              name="phone"
              type="text"
              component={FormField}
              label={t('register.registerForm.phone.label')}
              validate={[
                required(t('register.registerForm.phone.required')),
                maxLength(10),
                phone(t('common.validations.phone')),
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Field
              name="password"
              type="password"
              component={FormField}
              label={t('register.registerForm.password.label')}
              validate={[
                required(t('register.registerForm.password.required')),
                minLength(6, t('register.registerForm.password.minLength')),
                maxLength(16, t('register.registerForm.password.maxLength')),
              ]}
            />
          </Col>
          <Col md={12}>
            <Field
              onChange={event => {
                change('country', `${event.value}`);
                change('CountryPhoneCode', event.countryPhoneCode);
                change('CountrySelect', event.value);
              }}
              name="CountrySelect"
              label={t('register.registerForm.countrySelect.label')}
              component={FormSearchableSelect}
              props={{
                defaultOptions: countries.map(mapCountryToSelectOption),
                loadOptions: (value, callback) =>
                  callback(
                    countries
                      .filter(country => country.name.toLowerCase().includes(value.toLowerCase()))
                      .map(mapCountryToSelectOption),
                  ),
              }}
              validate={[required(t('register.registerForm.countrySelect.required'))]}
            />
          </Col>
          {googleMapsInitialized ? (
            <Col md={12}>
              <Field
                name="locationSearch"
                label={t('register.registerForm.locationSearch.label')}
                component={GeoSuggestField}
                props={{
                  onSuggestSelect: suggest => {
                    change('location', `${suggest.location.location}`);
                    change('centerLatitude', suggest.location.lat);
                    change('centerLongitude', suggest.location.lng);
                    change('zoom', 12);
                    change('latitude', suggest.location.lat);
                    change('longitude', suggest.location.lng);
                  },
                  onSuggestNoResults: () => {},
                }}
                validate={[required(t('register.registerForm.locationSearch.required'))]}
              />
            </Col>
          ) : (
            <div className="c-form-group c-form-group--floating" style={{ width: '100%' }}>
              <div style={{ height: '30px', width: '100%' }}></div>
            </div>
          )}

          <Col md={12}>
            <Field
              name="userAgreements"
              component={FormFieldCheck}
              validate={[minLength(1, t('register.registerForm.userAgreements.required'))]}
              props={{
                // eslint-disable-next-line no-unused-vars
                options: Object.entries(UserAgreementsMap).map(([key, value]) => ({
                  value: key,
                  text: [
                    <div>
                      <Trans i18nKey="register.registerForm.userAgreements.label">
                        <a
                          href={`${process.env.REACT_APP_API_NEW_URL}/app-config/contents/user-agreement-company/${i18n.language}`}
                          target="_blank"
                          rel="noreferrer"
                        />
                        <a
                          href={`${process.env.REACT_APP_API_NEW_URL}/app-config/contents/personal-data/${i18n.language}`}
                          target="_blank"
                          rel="noreferrer"
                        />
                      </Trans>
                    </div>,
                  ],
                })),
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="u-text-center">
        <Button
          id="registerButton"
          type="submit"
          primary
          disabled={(invalid || submitting) && isRegisterButtonDisabled}
          loading={submitting}
          style={{
            backgroundColor: themePalette.primary_color,
            boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
          }}
        >
          {t('register.registerForm.register')}
        </Button>
        <Col md={12}>
          <div style={{ paddingTop: '20px' }}>
            {t('register.registerForm.loginRedirectText')}
            <Trans i18nKey="register.registerForm.underlinedText">
              <a
                href="/login"
                target="_blank"
                rel="noreferrer"
                style={{ textDecorationLine: 'underline' }}
              />
            </Trans>
          </div>
        </Col>
      </div>
    </form>
  );
};

const Form = reduxForm({
  form: 'register',
})(RegisterForm);
const selector = formValueSelector('register');

// $FlowFixMe
export default connect((state: ReduxState) => ({
  latitude: selector(state, 'latitude'),
  longitude: selector(state, 'longitude'),
  centerLatitude: selector(state, 'centerLatitude'),
  centerLongitude: selector(state, 'centerLongitude'),
  zoom: selector(state, 'zoom'),
}))(Form);
