// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isLoading } from '../../utils/apiState';
import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from '../../redux/modules';
import { type CurrentUserState } from '../../redux/modules/currentUser';
import { loadThunk, type BranchesInboxState } from '../../redux/modules/branchesInbox';

import Header from '../../components/shared/Header';

import Loading from './Loading';
import List from './List';

type Props = {
  branchesInbox: BranchesInboxState,
  currentUser: CurrentUserState,
  loadThunk: () => void,
};

class BranchesInbox extends PureComponent<Props> {
  props: Props;

  componentWillMount() {
    this.props.loadThunk();
  }

  render() {
    const { branchesInbox, currentUser } = this.props;

    return (
      <div>
        <Header currentUser={currentUser} />

        {isLoading(branchesInbox) ? <Loading /> : <List branchesInboxes={branchesInbox.data} />}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  currentUser: state.currentUser,
  branchesInbox: state.branchesInbox,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ loadThunk }, dispatch);

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BranchesInbox);
