// @flow

import React from 'react';

import Icon from '../../components/elements/Icon';
import Button from '../../components/ui/Button';

import noBranch from '../../img/empty-state/no-branch.svg';
import { themePalette } from '../../theme/index';
import useTranslator from '../../hooks/useTranslator';

type Props = {
  onCreateClick: () => void,
};

const NoData = ({ onCreateClick }: Props) => {
  const { t } = useTranslator();

  return (
    <div className="u-pad-ends-large u-text-center">
      <img src={noBranch} width={270} height={150} alt={t('branches.noBranch')} />
      <h3 className="u-color-gull-gray u-gap-bottom-medium">{t('branches.branchNotExist')}</h3>

      <Button
        primary
        onClick={onCreateClick}
        style={{
          backgroundColor: themePalette.primary_color,
          boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
        }}
      >
        <Icon name="add" className="u-gap-right-small" />
        <span>{t('layout.navBar.addBranch')}</span>
      </Button>
    </div>
  );
};

export default NoData;
