// @flow
import auth from './auth';
import branches from './branches';
import common from './common';
import constants from './constants';
import errors from './errors';
import inbox from './inbox';
import jobDetail from './jobDetail';
import profile from './profile';
import register from './register';
import layout from './layout';
import jobs from './jobs';
import resetPassword from './resetPassword';
import packages from './packages';
import payment from './payment';
import phoneVerify from './phoneVerify';

export default {
  ...auth,
  ...branches,
  ...errors,
  ...inbox,
  ...jobDetail,
  ...common,
  ...register,
  ...layout,
  ...jobs,
  ...profile,
  ...constants,
  ...resetPassword,
  ...packages,
  ...payment,
  ...phoneVerify,
};
