/* eslint-disable func-names */
// @flow
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import resources from '../locales';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    resources,
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true,
    react: {
      useSuspense: false,
    },
  });

i18n.languageShort = (function(t) {
  return t.language.split('-')[0];
})(i18n);

document.documentElement.lang = i18n.languageShort;

export default i18n;
export { resources };
