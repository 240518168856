// @flow
export default {
  profile: {
    detail: {
      unknown: 'Bilinmiyor',
      name: 'İSİM',
      lastName: 'SOYİSİM',
      email: 'E-POSTA ADRESİ',
      phone: 'TELEFON NUMARASI',
      taxOffice: 'VERGİ DAİRESİ',
      taxOfficeNumber: 'VERGİ DAİRESİ NO',
      commercialTitle: 'TİCARİ ÜNVAN',
      website: 'WEB SİTESİ',
      companyAddress: 'AÇIK ADRES',
      location: 'LOKASYON',
    },

    edit: {
      error: {
        submissionError: {
          phone: 'Girdiğiniz telefon numarası başka bir kullanıcı tarafından kullanılmaktadır.',
        },
      },

      form: {
        edit: 'Düzenle',
        cancel: 'Vazgeç',
        firstName: {
          label: 'İsim',
          required: 'Lütfen isminizi giriniz.',
        },
        lastName: {
          label: 'Soyisim',
          required: 'Lütfen soyadınızı giriniz.',
        },
        email: {
          label: 'E-posta Adresi',
          required: 'Lütfen e-posta adresini giriniz.',
        },
        phone: {
          label: 'Telefon Numarası',
          required: 'Lütfen telefon numarası giriniz.',
        },
        taxOffice: {
          label: 'Vergi Dairesi',
          placeholder: 'Lütfen Seçiniz',
        },
        taxOfficeNumber: {
          label: 'Vergi Dairesi No',
        },
        commercialTitle: {
          label: 'Ticari Ünvan',
        },
        website: {
          label: 'Web Sitesi',
        },
        locationSearch: {
          label: 'Lokasyon Ara',
        },
        locationPosition: {
          label: 'Lokasyon Seçiniz',
          required: 'Lütfen lokasyon seçiniz.',
        },
      },
    },
    hrList: {
      detail: {
        title: 'KULLANICILAR',
        noHR: {
          description: 'Henüz kullanıcınız bulunmamaktadır.',
        },
      },
      alerts: {
        isSureRemoveThatHR: 'Bu kullanıcıyı silmek istediğinize emin misiniz?',
      },
      subTitles: {
        addUser: 'Kullanıcı Ekle',
        editUser: 'Kullanıcı Düzenle',
      },
      hrModal: {},
      hrForm: {
        informations: {
          aboutEMail:
            'Kullanıcı e-posta adresine parolasını oluşturabilmesi için link gönderilecektir.',
        },
      },
    },
  },
};
