// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';

import Box from '../../components/elements/Box';
import Button from '../../components/ui/Button';
import CircleImage from '../../components/elements/CircleImage';
import Icon from '../../components/elements/Icon';

import { type Company } from '../../types/user';
import { themePalette } from '../../theme/index';
import useTranslator from '../../hooks/useTranslator';

type Props = {
  branch: Company,
  onEditClick: (Company) => void,
  onRemoveClick: (Company) => void,
};

const ListItem = ({ branch, onEditClick, onRemoveClick }: Props) => {
  const { t } = useTranslator();

  return (
    <Box key={branch.id} className="u-pad-sides-small u-pad-ends-small u-gap-bottom">
      <Row className="u-flex-align-middle u-flex-align-justify">
        <Col lg={6} md={12} className="u-flex u-flex-align-middle u-gap-bottom@md-down">
          <CircleImage small src={branch.imageUrl} />
          <div className="u-flex-grow-full u-gap-left">
            <Link to={`/jobs/${branch.id}`} className="u-block u-font-size-md u-font-weight-medium">
              {branch.companyName}
            </Link>
            <span className="u-block">
              {branch.firstName} {branch.lastName}
            </span>
          </div>
        </Col>
        <Col lg={3} className="u-flex">
          <Button
            ghost
            small
            onClick={() => {
              onEditClick(branch);
            }}
            className="u-gap-right u-flex-grow-full"
          >
            <Icon name="edit" className="u-gap-right-xsmall" />
            {` ${t('common.glossary.edit')}`}
          </Button>
          <Button
            primary
            small
            className="u-flex-grow-full"
            onClick={() => {
              onRemoveClick(branch);
            }}
            style={{
              backgroundColor: themePalette.primary_color,
              color: '#fff',
              boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
            }}
          >
            <Icon name="close" className="u-gap-right-xsmall" />
            {` ${t('common.glossary.delete')}`}
          </Button>
        </Col>
      </Row>
    </Box>
  );
};

export default ListItem;
