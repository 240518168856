// @flow

export const EnglishLevelMap = {
  '1': 'Yok',
  '2': 'Az',
  '3': 'Orta',
  '4': 'İyi',
};

export const EnglishLevelMapDefault = EnglishLevelMap['1'];
