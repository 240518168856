// @flow

import moment from 'moment-timezone';

import { JobStatusDefault } from '../enums/jobStatus';
import { LocationMapper, NamedLocationMapper, GeoLocationMapper } from './location';
import { CompanyMapper } from './user';
import { CategoryMapper } from './category';
import { stringify as geoLocationStringifier } from '../utils/location';
// eslint-disable-next-line import/no-cycle
import { type JobData } from '../api/job';

import { type Job, type JobPosition } from '../types/job';
import { WorkTimeList } from '../enums/workTime';
import { pushWorkTimePreferences } from '../utils/job';

// eslint-disable-next-line import/prefer-default-export
export const JobMapper = {
  fromAPIResponse(response: Object): Job {
    const geoLocation = GeoLocationMapper.fromAPIResponse(response.geo_location);
    const workTimePreferences = [];

    pushWorkTimePreferences(response, workTimePreferences);

    return {
      id: response.id || 0,
      createdAt: moment(response.created),
      openedAt: moment(response.opened_at),
      title: response.title || '',
      position: response.position,
      description: response.description || '',
      user: CompanyMapper.fromAPIResponse(response.user || {}),
      category: CategoryMapper.fromAPIResponse(response.category || {}),
      status: response.status || JobStatusDefault,
      minExperienceDuration: response.min_experience_duration || 0,
      gender: response.gender || '',
      birthDateMin: response.birth_date_min || 0,
      birthDateMax: response.birth_date_max || 0,
      imageUrl: response.image_url || '',
      location: NamedLocationMapper.fromAPIResponse({
        ...LocationMapper.fromAPIResponse(response.location),
        name: geoLocationStringifier(geoLocation),
      }),
      geoLocation,
      counts: {
        applications: response.applications_count || 0,
        unreviewed: response.unseen_application_count || 0,
        shortLists: response.nb_short_lists || 0,
        jobViews: response.nb_view_count || 0,
      },
      expectedSalaryMax: response.expected_salary_max,
      expectedSalaryMin: response.expected_salary_min,
      militaryRequirements: response.military_requirements || [],
      minEducationStatus: response.min_education_status,
      minEnglishLevel: response.min_english_level,
      contactOption: response.contact_type,
      contactPhone: response.contact_type_phone_number,
      workTimePreferences,
      autoShortlistThreshold: response.auto_shortlist_threshold,
      fare: response.fare,
      freeMeal: response.free_meal,
      healthInsurance: response.health_insurance,
      peopleBoxProjectId: response.people_box_project && response.people_box_project.project_id,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Job> {
    if (!response) {
      return [];
    }

    return response.map(JobMapper.fromAPIResponse);
  },

  toAPIRequest(request: Job): JobData {
    return {
      category: request.category,
      user: request.user,
      title: request.title,
      position: request.position.id,
      description: request.description,
      status: request.status,
      location: `${request.latitude}, ${request.longitude}`,
      minExperienceDuration: request.minExperienceDuration || undefined,
      expectedSalaryMax: request.expectedSalaryMax,
      expectedSalaryMin: request.expectedSalaryMin,
      gender: request.gender || undefined,
      militaryRequirements: request.militaryRequirements || [],
      birthDateMin: request.birthDateMin || undefined,
      birthDateMax: request.birthDateMax || undefined,
      minEducationStatus: request.minEducationStatus || undefined,
      minEnglishLevel: request.minEnglishLevel || undefined,
      reason: request.reason || undefined,
      imageFileBase64: request.cover,
      contact_type: request.contactOption,
      contactTypePhoneNumber: request.contactPhone,
      workTimePreferenceFullTime:
        request.workTimePreferences.indexOf(WorkTimeList.FULL_TIME) !== -1,
      workTimePreferencePartTime:
        request.workTimePreferences.indexOf(WorkTimeList.PART_TIME) !== -1,
      workTimePreferenceSeasonal: request.workTimePreferences.indexOf(WorkTimeList.SEASONAL) !== -1,
      autoShortlistThreshold: request.autoShortlistThreshold,
      fare: request.fare,
      freeMeal: request.freeMeal,
      healthInsurance: request.healthInsurance,
      peopleBoxProject: +request.peopleBoxProjectId,
    };
  },
};

export const JobPositionMapper = {
  fromAPIResponse: (response: Object): JobPosition => ({
    id: response.id,
    name: response.name,
  }),
};
