// @flow
export default {
  profile: {
    detail: {
      unknown: 'Unknown',
      name: 'FIRST NAME',
      lastName: 'LAST NAME',
      email: 'E-MAIL',
      phone: 'PHONE',
      taxOffice: 'TAX OFFICE',
      taxOfficeNumber: 'TAX ID',
      commercialTitle: 'COMMERCIAL TITLE',
      website: 'WEBSITE',
      companyAddress: 'DETAILED ADDRESS',
      location: 'LOCATION',
    },

    edit: {
      error: {
        submissionError: {
          phone: 'This phone number is in use by another user.',
        },
      },

      form: {
        edit: 'Edit',
        cancel: 'Cancel',
        firstName: {
          label: 'First name',
          required: 'Please enter your first name.',
        },
        lastName: {
          label: 'Last name',
          required: 'Please enter your last name.',
        },
        email: {
          label: 'E-mail',
          required: 'Please enter your email.',
        },
        phone: {
          label: 'Phone',
          required: 'Please enter your phone number.',
        },
        taxOffice: {
          label: 'Tax office',
          placeholder: 'Please choose',
        },
        taxOfficeNumber: {
          label: 'Tax ID',
        },
        commercialTitle: {
          label: 'Commerical title',
        },
        website: {
          label: 'Web site',
        },
        locationSearch: {
          label: 'Search for a location',
        },
        locationPosition: {
          label: 'Choose a location',
          required: 'Please choose a location.',
        },
      },
    },
    hrList: {
      detail: {
        title: 'Users',
        noHR: {
          description: 'You have no users yet.',
        },
      },
      alerts: {
        isSureRemoveThatHR: 'Are you sure you want to delete this user?',
      },
      subTitles: {
        addUser: 'Add user',
        editUser: 'Edit user',
      },
      hrModal: {},
      hrForm: {
        informations: {
          aboutEMail:
            'We will send a link to this email for the user to create a password.',
        },
      },
    },
  },
};
