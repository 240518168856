// @flow

import Auth from '../auth';
import { updateFCMToken } from '../../api/auth';
import { type Token } from '../../types/fcm';

// cached token
// eslint-disable-next-line
let _token: Token = null;

async function save(token: Token) {
  _token = token;

  if (Auth.isAuthenticated()) {
    updateFCMToken(token);
  }
}

async function saveCachedToServer() {
  if (Auth.isAuthenticated()) {
    updateFCMToken(_token);
  }
}

async function clearServer() {
  updateFCMToken(null);
}

export default {
  save,
  saveCachedToServer,
  clearServer,
};
