// @flow

import React from 'react';
import cx from 'classnames';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { type Message } from '../../../types/chat';
// eslint-disable-next-line import/named
import { AppointmentStatus } from '../../../enums/appointmentStatus';
// eslint-disable-next-line import/named
import { getDate, getHour } from '../../../utils/appointment';

type Props = {
  alignToRight?: boolean,
  message: Message,
};

const AppointmentStatusBubble = ({ message, alignToRight }: Props) => {
  const { t } = useTranslation();
  if (message.type !== 'appointment_status') {
    return null;
  }

  let text;

  switch (message.messagePayload.changed_status) {
    case AppointmentStatus.ACCEPTED:
      text = t('inbox.appointmentConfirmed', {
        dateStartAt: getDate(message.messagePayload.startAt),
        hourStartAt: getHour(message.messagePayload.startAt),
        hourEndAt: getHour(message.messagePayload.endAt),
      });
      break;
    case AppointmentStatus.REJECTED:
      text = t('inbox.appointmentNotConfirmed', {
        dateStartAt: getDate(message.messagePayload.startAt),
        hourStartAt: getHour(message.messagePayload.startAt),
        hourEndAt: getHour(message.messagePayload.endAt),
      });
      break;
    case AppointmentStatus.CANCELLED:
      text = t('inbox.appointmentCanceledCompany', {
        dateStartAt: getDate(message.messagePayload.startAt),
        hourStartAt: getHour(message.messagePayload.startAt),
        hourEndAt: getHour(message.messagePayload.endAt),
      });
      break;
    case AppointmentStatus.EXPIRED:
      text = t('inbox.appointmentCanceledOutOf24Hours', {
        dateStartAt: getDate(message.messagePayload.startAt),
        hourStartAt: getHour(message.messagePayload.startAt),
        hourEndAt: getHour(message.messagePayload.endAt),
      });
      break;
    default:
      text = t('inbox.appointmentUpdated', {
        dateStartAt: getDate(message.messagePayload.startAt),
        hourStartAt: getHour(message.messagePayload.startAt),
        hourEndAt: getHour(message.messagePayload.endAt),
      });
  }

  return (
    <span
      className={cx('c-chat__bubble', {
        'c-chat__bubble--left': alignToRight,
      })}
    >
      {text}
      <time className="c-chat__time">{moment(message.date).fromNow()}</time>
    </span>
  );
};

export default AppointmentStatusBubble;
