// @flow

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import { type FormState } from '../../types/form';
import { type RouterState } from '../../types/router';
import currentUser, { type CurrentUserState, type CurrentUserActions, LOGOUT } from './currentUser';
import categories, { type CategoriesState, type CategoriesActions } from './categories';
import holidays, { type HolidaysState, type HolidaysActions } from './holidays';
import hrList, { type HRListState, type HRListActions } from './hrList';
import branches, { type BranchesState, type BranchesActions } from './branches';
import jobs, { type JobsState, type JobsActions } from './jobs';
import jobDetail, { type JobDetailState, type JobDetailActions } from './jobDetail';
import conversationDetail, {
  type ConversationDetailState,
  type ConversationDetailActions,
} from './conversationDetail';
import applicationDetail, {
  type ApplicationDetailState,
  type ApplicationDetailActions,
} from './applicationDetail';
import branchesInbox, { type BranchesInboxState, type BranchesInboxActions } from './branchesInbox';
import inbox, { type InboxState, type InboxActions } from './inbox';
import app, { type AppState, type AppActions } from './app';

export type ReduxState = {
  app: AppState,
  form: FormState,
  router: RouterState,
  currentUser: CurrentUserState,
  categories: CategoriesState,
  holidays: HolidaysState,
  hrList: HRListState,
  branches: BranchesState,
  jobs: JobsState,
  jobDetail: JobDetailState,
  conversationDetail: ConversationDetailState,
  applicationDetail: ApplicationDetailState,
  branchesInbox: BranchesInboxState,
  inbox: InboxState,
};

export type ReduxActions =
  | AppActions
  | CurrentUserActions
  | CategoriesActions
  | HolidaysActions
  | HRListActions
  | BranchesActions
  | JobsActions
  | JobDetailActions
  | ConversationDetailActions
  | ApplicationDetailActions
  | BranchesInboxActions
  | InboxActions

const rootReducer = combineReducers({
  app,
  form: formReducer,
  router: routerReducer,
  currentUser,
  categories,
  holidays,
  hrList,
  branches,
  jobs,
  jobDetail,
  conversationDetail,
  applicationDetail,
  branchesInbox,
  inbox,
});

export default function(state: ReduxState, action: ReduxActions): ReduxState {
  return rootReducer(action.type === LOGOUT ? undefined : state, action);
}
