// @flow

import { AddOn } from "../../types/product"
import { Basket } from "../../types/basket";
import React, { useState } from "react";
import BaseLoading from '../../components/elements/Loading';
import swal from 'sweetalert';
import useTranslator from "../../hooks/useTranslator";
import { addItem } from "../../api/basket";
import { themePalette } from "../../theme";
import Col from "../../components/layout/Col";
import Button from "../../components/ui/Button";
import Icon from "../../components/elements/Icon";
import { currencyMapper } from "../../mappers/currency";

type Props = {
    addons: AddOn[],
    basket: Basket,
    setBasket: React.Dispatch<React.SetStateAction<undefined>>,
    getAddonsError: String,
}

const AddonList = ({
    addons,
    basket,
    setBasket,
    getAddonsError
}: Props) => {
    const [loadingIds, setLoadingIds] = useState({});
    const { t } = useTranslator();

    const handleAddAddons = async addon => {
        try {
            setLoadingIds(prev => ({ ...prev, [addon.id]: true }));
            const selectedAddonInBasket = basket.items.find(
                basketAddon => basketAddon.product.id === addon.id,
            );

            if (selectedAddonInBasket && selectedAddonInBasket.quantity === addon.purchaseLimit) {
                setLoadingIds(prev => ({ ...prev, [addon.id]: false }));
                await swal({
                    text: t('payment.general.reachedPurchaseLimit'),
                    icon: 'warning',
                });
                return;
            }
            const basketResponse = await addItem(basket.id, { productId: addon.id });
            setBasket(basketResponse);
            setLoadingIds(prev => ({ ...prev, [addon.id]: false }));
        } catch (error) {
            setLoadingIds(prev => ({ ...prev, [addon.id]: false }));
            await swal({
                text: t('payment.general.anErrorOccuredTryAgain'),
                icon: 'error',
            });
        }
    };

    if (getAddonsError) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1 style={{ color: themePalette.color }} >{getAddonsError}</h1>
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {addons.length === 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1>{t('payment.general.noAddonForSelectedPackage')}</h1>
                </div>
            )}
            {addons.map((addon, index) => (
                <div
                    key={`addon-${index}`}
                    style={{
                        display: 'flex',
                        border: `2px solid ${themePalette.packageColors[0]}`,
                        borderRadius: '10px',
                        margin: '2%',
                        padding: '3%',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    }}
                >
                    <Col md={9}>
                        <h5 style={{ margin: '1% 0' }}>{addon.title}</h5>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ flexGrow: 3 }}>
                                <ul>
                                    {addon.features
                                        .filter(feature => feature.preview)
                                        .map((feature, index) => (
                                            <li key={`addon[${addon.id}]-feature[${index}]`}>
                                                <span>{feature.description}</span>
                                            </li>
                                        ))}
                                </ul>
                                <span style={{
                                    fontSize: "13px",
                                    fontWeight: 500,
                                }}>
                                    {addon.price} {currencyMapper.fromAPIResponse(addon.currency)}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col
                        md={3}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexGrow: 1,
                        }}
                    >
                        {loadingIds[addon.id] ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '45px',
                                    width: '45px',
                                }}
                            >
                                <BaseLoading />
                            </div>
                        ) : (
                            <Button
                                style={{ borderRadius: '10px' }}
                                type="button"
                                onClick={() => handleAddAddons(addon)}
                            >
                                <Icon name="add" className="u-font-size-xl" />
                            </Button>
                        )}
                    </Col>
                </div>
            ))}
        </div>
    );
}

export default AddonList
