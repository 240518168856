// @flow
export default {
  register: {
    registerForm: {
      title: 'REGISTRAR',
      companyName: {
        label: 'Nombre de compañia',
        required: 'Escribe nombre de compañia.',
      },
      firstName: {
        label: 'Nombre(s)',
        required: 'Escribe nombre(s).',
      },
      lastName: {
        label: 'Apellido',
        required: 'Escribe apellido.',
      },
      email: {
        label: 'E-mail',
        required: 'Escribe e-mail.',
      },
      phone: {
        label: 'Teléfono',
        required: 'Escribe teléfono.',
      },
      password: {
        label: 'Contraseña',
        required: 'Escribe contraseña.',
        minLength: 'Mínimo 6 caracteres.',
        maxLength: 'Máximo 16 caracteres.',
      },
      locationSearch: {
        label: 'Ubicación',
        required: 'Seleccionar ubicación.',
      },
      userAgreements: {
        label: 'Leí y acepto <0>los terminos y condiciones</0> y <1>política de privacidad</1>.',
        minLength: 'Obligatorio.',
      },
      register: 'REGISTRAR',
      loginRedirectText: 'Si ya es miembro, puede ',
      underlinedText: '<0>iniciar sesión</0>.',
      countrySelect: {
        label: 'Seleccionar país',
        required: 'Seleccionar tu país.',
      },
    },
    formError: {
      emailIsNotUnique:
        'Este e-mail ha sido usado para una cuenta. Regresa e intentar iniciar sesión.',
    },
    smsVerify: {
      title: 'Confirmación de número',
      infoText: 'Necesitamos confirmar tu teléfono para que las candidatos puedan contactarte.',
      sendCode: 'Enviar SMS',
      reSendCode: 'Enviar otra vez',
      confirm: 'Confirmar',
    },
  },
};
