/* eslint-disable no-shadow */
// @flow

import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import Modal from '../Modal';
import Button from '../../ui/Button';
import useTranslator from '../../../hooks/useTranslator';
import { themePalette } from '../../../theme/index';

type Props = {
  cancelLabel?: string,
  confirmLabel?: string,
};

const Confirm = ({ cancelLabel, confirmLabel }: Props, ref) => {
  const { t } = useTranslator();

  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState(t('common.confirms.isSureToContinue'));
  const callbackRef = useRef({ onConfirm: null, onCancel: null });

  const show = (message: string, options: { onConfirm: () => void, onCancel: () => void }) => {
    callbackRef.current = { onConfirm: options.onConfirm, onCancel: options.onCancel };
    setIsVisible(true);
    setMessage(message);
  };

  useImperativeHandle(ref, () => {
    return {
      show,
    };
  });

  const handleConfirm = () => {
    setIsVisible(false);
    setMessage('');
    if (typeof callbackRef.current.onConfirm === 'function') {
      callbackRef.current.onConfirm();
    }
  };

  const handleCancel = () => {
    setIsVisible(false);
    setMessage('');
    if (typeof callbackRef.current.onCancel === 'function') {
      callbackRef.current.onCancel();
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal isOpen={isVisible} closeable={false} contentLabel="Uyarı">
      <div className="u-text-center">
        <h3>{message}</h3>
        <div className="u-pad-top-small">
          <Button ghost onClick={handleCancel} className="u-gap-right">
            {cancelLabel || t('common.glossary.cancel')}
          </Button>

          <Button
            primary
            onClick={handleConfirm}
            style={{
              backgroundColor: themePalette.primary_color,
              boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
            }}
          >
            {confirmLabel || t('common.glossary.continue')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(Confirm);
