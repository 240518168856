// @flow

import React, { PureComponent } from 'react';
import AvatarEditor from 'react-avatar-editor';

import { withTranslation } from 'react-i18next';
import Modal from '../Modal';
import Button from '../../ui/Button';
import { themePalette } from '../../../theme/index';

type Props = {
  isOpen: boolean,
  title?: string,
  source: File | string,
  width: number,
  height: number,
  border: number,
  borderRadius: number,
  onModalCloseRequested: () => void,
  onImageReceive: HTMLCanvasElement => void,
  t: Function,
};

type State = {
  zoom: number,
};

class ImageCropModal extends PureComponent<Props, State> {
  props: Props;

  state: State;

  handleZoomChange: (SyntheticInputEvent<HTMLInputElement>) => void;

  handleSave: () => void;

  editor: any;

  constructor(props: Props) {
    super(props);

    this.state = { zoom: 1 };

    this.handleZoomChange = this.handleZoomChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleZoomChange(event: SyntheticInputEvent<HTMLInputElement>) {
    this.setState({ zoom: parseFloat(event.target.value) });
  }

  handleSave() {
    const croppedImage = this.editor.getImageScaledToCanvas();
    this.props.onImageReceive(croppedImage);
  }

  render() {
    const {
      source,
      width,
      height,
      border,
      borderRadius,
      isOpen,
      title = this.props.t('common.glossary.editPhoto'),
      onModalCloseRequested,
      t,
      ...otherProps
    } = this.props;

    const { zoom } = this.state;

    return (
      <Modal title={title} isOpen={isOpen} closeable={false} contentLabel={title}>
        <div className="u-text-center">
          <AvatarEditor
            ref={(ref: any) => {
              this.editor = ref;
            }}
            image={source}
            width={width}
            height={height}
            border={border}
            borderRadius={borderRadius}
            color={[255, 255, 255, 0.8]}
            rotate={0}
            scale={zoom}
            {...otherProps}
          />

          <input
            className="c-input-range"
            type="range"
            step={0.01}
            min={1}
            max={2}
            value={zoom}
            onChange={this.handleZoomChange}
          />

          <div className="u-text-center u-pad-top-small">
            <Button ghost className="u-gap-right" onClick={onModalCloseRequested}>
              {t('common.glossary.cancel')}
            </Button>
            <Button
              primary
              onClick={this.handleSave}
              style={{
                backgroundColor: themePalette.primary_color,
                boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
              }}
            >
              {t('common.glossary.save')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ImageCropModal);
