// @flow
export default {
  errors: {
    errorBoundary: {
      title: 'Hubo un error.',
      explanation: 'Intenta cargar de nuevo la pagina.',
      notFound: 'De página no encontrada',
      backToHome: 'Página de inicio',
    },
  },
};
