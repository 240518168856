// @flow
export default {
  errors: {
    errorBoundary: {
      title: 'Oops! An Error occurred.',
      explanation:
        'We could not get the expected results. Please try reloading or return to the home page.',
      notFound: 'Page not found',
      backToHome: 'Back to Home Page',
    },
  },
};
