// @flow
import { getMessaging, onMessage } from 'firebase/messaging';

import app from './app';
import parser from './parser';
import handler from './handler';

function init() {
  if ('serviceWorker' in navigator && navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('message', (event: any) => {
      // eslint-disable-next-line no-underscore-dangle
      if (!event.data.__notification) {
        return;
      }

      // eslint-disable-next-line no-underscore-dangle
      const parsedPayload = parser(event.data.__notification);

      // invalid payload.
      if (!parsedPayload) {
        return;
      }

      handler(parsedPayload);
    });
  }

  const firebaseMessaging = getMessaging(app);

  onMessage(firebaseMessaging, payload => {
    const parsedPayload = parser(payload.data);

    // invalid payload.
    if (!parsedPayload) {
      return;
    }

    handler(parsedPayload);
  });
}

export default { init };
