/* eslint-disable no-nested-ternary */
// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { withTranslation } from 'react-i18next';
import swal from 'sweetalert';
import moment from 'moment';

import { swalOkButtonTranslateKey } from '../../constants';

import { type ReduxDispatch } from '../../types/redux';
import { register } from '../../redux/modules/currentUser';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Box from '../../components/elements/Box';
import Logo from '../../components/shared/Logo';
import RegisterForm from '../../forms/RegisterForm';
import { getCountries, countryDetection } from '../../api/common';
import i18n from '../../config/i18n';
import formErrorMapper from '../../utils/formErrorMapper';

type Props = {
  location: any,
  push: Function,
  history: any,
  t: any,
  register: Function,
};

type RouteState = {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  companyName: string,
  location: string,
  country: number,
  from: any,
  phone: string,
  countryCode: string,
  phoneVerifyCode: string | undefined,
  codeError: string | undefined,
};

type State = {
  shouldSubmitForm: boolean,
};

class Register extends PureComponent<Props> {
  props: Props;

  routeState: RouteState;

  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      t: Function,
      isRegisterButtonDisabled: true,
      location: undefined,
      initialSelectedCountryValue: undefined,
      initialPhoneCode: undefined,
      shouldSubmitForm: false,
    };

    this.routeState = this.props.location.state;
  }

  async componentWillMount() {
    const result = await getCountries();
    this.setState({
      isRegisterButtonDisabled: false,
      countries: result.map(c => ({
        id: c.id,
        name: c.countryName,
        countryCode: c.countryCode,
        phoneCode: c.phoneCode,
      })),
    });
  }

  componentDidMount() {
    if (this.routeState && this.routeState.phoneVerifyCode) {
      this.setState({
        shouldSubmitForm: true,
      });
    }

    if (!(this.routeState && this.routeState.CountrySelect)) {
      this.getUserGeolocation();
      this.fetchCountryDetection();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: any) {
    if (prevState.location !== this.state.location) {
      this.fetchCountryDetection();
    }
  }

  handleSubmitInternal = async (values: any, code: string) => {
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    try {
      const {
        email,
        password,
        firstName,
        lastName,
        companyName,
        phone,
        latitude,
        longitude,
        CountrySelect,
      } = values;

      await this.props.register(
        email,
        password,
        firstName,
        lastName,
        companyName,
        phone,
        `${latitude},${longitude}`,
        CountrySelect.value,
        code,
      );
    } catch (err) {
      if (err.translate_key && err.translate_key === 'error.unknown_error') {
        swal({
          text: this.props.t('common.apiValidations.error.unknown_error'),
          icon: 'error',
          button: this.props.t(swalOkButtonTranslateKey),
          dangerMode: true,
        });

        return;
      }

      if (err.code && err.code === 400) {
        throw new SubmissionError(formErrorMapper(err.errors.children, this.props.t));
      }

      if (err.code && err.code === 500) {
        this.props.push('/error');
      }
    }

    this.props.push(from);
  };

  handleSubmit = async (values: Object): Promise<*> => {
    if (!this.routeState || (this.routeState && !this.routeState.phoneVerifyCode)) {
      this.props.push('/sms-verify', {
        pushBackTo: '/register',
        ...values,
        countryCode: values.CountrySelect.countryCode,
      });

      return;
    }

    await this.handleSubmitInternal(values, this.routeState.phoneVerifyCode);
  };

  getUserGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.setState({
            location: { latitude: position.coords.latitude, longitude: position.coords.longitude },
          });
        },
        console.error,
        {},
      );
    }
  };

  fetchCountryDetection = async () => {
    try {
      const i18ncountryCode = i18n.language.split('-')[1];
      const data = await countryDetection(this.state.location, i18ncountryCode);

      if (data.country.defaultLanguage) {
        i18n.changeLanguage(data.country.defaultLanguage.language_code).then(() => {
          const langAndCountry = data.country.defaultLanguage.language_code.split('-');
          const lang = langAndCountry[0];
          moment.locale(lang);
          document.querySelector('html').lang = lang;
        });
      }

      this.setState({
        initialSelectedCountryValue: {
          value: data.country.id,
          label: data.country.countryName,
          countryCode: data.country.countryCode,
          countryPhoneCode: data.country.countryAttributes.phone_code,
        },
        initialPhoneCode: data.country.countryAttributes.phone_code,
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <section className="u-pad-ends-large">
        <Container>
          <Row>
            <Col lg={6} className="col--offset-lg-3">
              <Box className="u-pad-ends-medium@md-up u-pad-ends@sm-down u-pad-sides-medium@md-up u-pad-sides@sm-down">
                <Logo
                  className="c-box__center-logo"
                  alt={this.state.t('layout.header.title')}
                  style={{ width: 150, height: 150, objectFit: 'scale-down' }}
                />
                <RegisterForm
                  isRegisterButtonDisabled={this.state.isRegisterButtonDisabled}
                  countries={this.state.countries}
                  onSubmit={this.handleSubmit}
                  shouldSubmit={this.state.shouldSubmitForm}
                  initialValues={
                    this.routeState && Object.keys(this.routeState).length > 1
                      ? {
                          ...this.routeState,
                          userAgreements: 'checked',
                        }
                      : this.state.initialSelectedCountryValue
                      ? {
                          CountrySelect: this.state.initialSelectedCountryValue,
                          CountryPhoneCode: this.state.initialPhoneCode,
                        }
                      : undefined
                  }
                />
              </Box>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ push, register }, dispatch);

// $FlowFixMe
export default connect(null, mapDispatchToProps)(withTranslation()(Register));
