// @flow

const DEFAULT_MESSAGE = 'Bu değer daha büyük olmalıdır.';

const minLength = (min: number, message: string = DEFAULT_MESSAGE) => (value?: string = '') => {
  if (!value) {
    return undefined;
  }

  return parseInt(value, 10) >= min ? undefined : message;
};

export default minLength;
