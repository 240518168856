/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { type AuthenticatedUser } from '../../types/user';

import { getProducts } from '../../api/product';

import Loading from './Loading';
import BaseLoading from '../../components/elements/Loading';
import Header from '../../components/shared/Header';
import Button from '../../components/ui/Button';
import Icon from '../../components/elements/Icon';
import { addItem, deleteItem, getBasket, updateItem } from '../../api/basket';
import Container from '../../components/layout/Container';
import Col from '../../components/layout/Col';
import Row from '../../components/layout/Row';
import { themePalette } from '../../theme';
import { AddOn } from '../../types/product';
import { Basket } from '../../types/basket';
import swal from 'sweetalert';
import useTranslator from '../../hooks/useTranslator';
import AddonList from './AddonList';
import BasketAddonList from './BasketAddonList';
import { currencyMapper } from '../../mappers/currency';

type Props = {
  currentUser: AuthenticatedUser,
  history: Object,
};

const ResponsiveDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 2%;
  width: 100%;

  @media (max-width: 700px) {
    flex-direction: column-reverse;
    gap: 1%;
  }
`;

const BasketDetail = ({ selectedPackage }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h3
        style={{
          marginLeft: '5%',
        }}
      >{`${selectedPackage.title} (${selectedPackage.price} ${currencyMapper.fromAPIResponse(selectedPackage.currency)})`}</h3>
      <ul style={{ maxHeight: '23em', overflow: 'auto' }}>
        {selectedPackage.features
          .filter(feature => feature.preview)
          .map((feature, index) => (
            <li key={`package[${selectedPackage.id}]-feature[${index}]`}>
              <span style={{
                fontSize: "13px",
                fontWeight: 500,
              }}>{feature.description}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};

const BasketComponent = ({ currentUser, history }: Props) => {
  if (!history || !history.location || !history.location.state || !history.location.state.basket) {
    return <Redirect to="/packages" />;
  }

  const { basket: basketFromProp } = history.location.state;
  const { t } = useTranslator();
  const [selectedPackage, setSelectedPackage] = useState();
  const [basket, setBasket] = useState();
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getAddonsLoading, setGetAddonsLoading] = useState(true);
  const [getAddonsError, setGetAddonsError] = useState('');
  const [fetchBasketLoading, setFetchBasketLoading] = useState(false);

  const getAddons = async (selectedPackageId: number) => {
    try {
      setGetAddonsLoading(true);
      const addonsResponse = await getProducts('addon', selectedPackageId);
      setAddons(addonsResponse);
      setGetAddonsLoading(false);
    } catch (error) {
      setGetAddonsLoading(false);
      setGetAddonsError(t('payment.general.anErrorOccuredWhileLoadingAddons'));
    }
  };

  const fetchBasket = async (basketId: number) => {
    try {
      setFetchBasketLoading(true);
      const basketResponse = await getBasket(basketId);
      setBasket(basketResponse);
      setFetchBasketLoading(false);
    } catch (error) {
      return <Redirect to="/packages" />;
    }
  };

  const handleCheckout = () => {
    history.push('/payment/card', {
      basket,
    });
  };

  const handlePreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    if (basket) {
      const basketPackage = basket.items.find(item => item.product.type === 'package');
      setSelectedPackage(basketPackage.product);
    }
  }, [basket]);

  useEffect(() => {
    if (selectedPackage) {
      getAddons(selectedPackage.id);
    }
  }, [selectedPackage]);

  useEffect(() => {
    fetchBasket(basketFromProp.id);
  }, [basketFromProp]);

  return (
    <div>
      <Header currentUser={currentUser} />
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '2rem' }}>
            <Row>
              <Col md={4}>
                <h4
                  onClick={handlePreviousPage}
                  style={{ marginBottom: '1rem', cursor: 'pointer', fontWeight: 'bolder' }}
                >
                  {t('payment.general.backToPreviousPage')}
                </h4>
              </Col>
            </Row>
            <Row>
              <ResponsiveDiv>
                <Col md={6} style={{ marginBottom: '2rem' }}>
                  <h2>{t('payment.general.yourCart')}</h2>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        border: `2px solid ${themePalette.packageColors[0]}`,
                        borderRadius: '10px',
                        boxShadow:
                          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      }}
                    >
                      {(fetchBasketLoading && !selectedPackage) || !selectedPackage ? (
                        <Loading />
                      ) : (
                        <BasketDetail selectedPackage={selectedPackage} />
                      )}
                    </div>
                    {basket && (
                      <div
                        style={{
                          height: '30%',
                        }}
                      >
                        <BasketAddonList
                          addons={basket.items.filter(item => item.product.type === 'addon')}
                          basketId={basket.id}
                          setBasket={setBasket}
                        />
                      </div>
                    )}
                  </div>
                  {basket && (
                    <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                      <Button
                        primary
                        style={{
                          backgroundColor: themePalette.packageColors[0],
                          borderRadius: '10px',
                          width: '100%',
                        }}
                        onClick={handleCheckout}
                        loading={getAddonsLoading || fetchBasketLoading}
                      >
                        {`${t('payment.general.proceedToCheckout')} (${basket.totalPrice} ${currencyMapper.fromAPIResponse(basket.currency)})`}
                      </Button>
                    </div>
                  )}
                </Col>
                <Col md={6}>
                  <h2>{t('payment.general.additionalProducts')}</h2>
                  <div
                    style={{
                      border: `2px solid ${themePalette.packageColors[0]}`,
                      borderRadius: '10px',
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      maxHeight: '50em',
                      overflow: 'auto',
                    }}
                  >
                    {getAddonsLoading && addons.length === 0 ? (
                      <Loading />
                    ) : (
                      <AddonList addons={addons} basket={basket} setBasket={setBasket} getAddonsError={getAddonsError} />
                    )}
                  </div>
                </Col>
              </ResponsiveDiv>
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(BasketComponent);
