// @flow

// eslint-disable-next-line no-underscore-dangle
let _isSupported = true;

// eslint-disable-next-line no-underscore-dangle
let _fn = null;

// eslint-disable-next-line no-underscore-dangle
function _callOnChange(status: boolean) {
  if (_fn) {
    _fn(status);
  }
}

function has(): boolean {
  return _isSupported;
}

function set(status: boolean) {
  _isSupported = status;
  _callOnChange(status);
}

function onChange(fn: boolean => void) {
  _fn = fn;
}

export default {
  has,
  set,
  onChange,
};
