// @flow

import React, { PureComponent } from 'react';
import { type Match } from 'react-router-dom';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Box from '../../../components/elements/Box';
import { updateAppointment } from '../../../redux/modules/applicationDetail';
import { updateAppointment as updateAppointmentJob } from '../../../redux/modules/jobDetail';

import AllApplications from './AllApplications';
import ShortListedApplications from './ShortListedApplications';
import Appointments from './Appointments';

import { type Application } from '../../../types/application';
import { type Job } from '../../../types/job';
import { type Appointment } from '../../../types/appointment';
import Icon from '../../../components/elements/Icon';
import { themePalette } from '../../../theme';
import { getAllAppointmentByApplicationIds, getAppointmentList } from '../../../api/appointment';
import { AppointmentStatusName } from '../../../enums/appointmentStatus';

type Props = {
  job: Job,
  allApplications: Array<Application>,
  shortListedApplications: Array<Application>,
  onApplicationClick: Application => void,
  onAppointmentClick: Appointment => void,
  activeApplicationId: number | null,
  t: Function,
  onReachEnd?: (activeTab: 'all' | 'shortlisted') => void,
  updateAppointment: (applicationId: number, appointment: Appointment) => Function,
  updateAppointmentInJobDetail: (applicationId: number, appointment: Appointment) => Function,
  loadingMore: boolean,
  match: Match,
};

type State = {
  activeTab: 'all' | 'shortlisted',
  appointments: Array<Appointment>,
};

class Applications extends PureComponent<Props, State> {
  props: Props;

  state: State;

  handleTabChange: string => void;

  constructor(props: Props) {
    super(props);

    this.state = { activeTab: 'all', appointments: [] };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    this.getAppointments();
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.isApplicationArraysEqual(prevProps.allApplications, this.props.allApplications)) {
      this.getAppointments();
    }
  }

  handleTabChange(activeTab: 'all' | 'shortlisted') {
    if (this.state.activeTab !== activeTab) {
      this.setState({ activeTab });
    }
  }

  getAppointments = async () => {
    const { job, match, allApplications } = this.props;

    if (match && match.params.jobId === '0') {
      const applicationIds = allApplications.map(application => application.id);
      await this.getAllAppointmentByApplicationIdList(applicationIds, match);
      return;
    }

    await this.getAppointmentsByJobId(job, match);
  };

  getAllAppointmentByApplicationIdList = async (applicationIds, match) => {
    try {
      const response = await getAllAppointmentByApplicationIds(applicationIds);
      const filteredAppointments = response.filter(
        appointment => appointment.status.name !== AppointmentStatusName.APPOINTMENT_CANCELLED,
      );

      filteredAppointments.forEach(appointment => {
        if (
          appointment.applicationId &&
          appointment.status.name !== AppointmentStatusName.APPOINTMENT_CANCELLED
        ) {
          this.props.updateAppointment(appointment.applicationId, appointment);
          this.props.updateAppointmentInJobDetail(
            appointment.jobId,
            appointment.applicationId,
            match,
            appointment,
          );
        }
      });

      this.setState({ appointments: filteredAppointments });
    } catch (error) {}
  };

  getAppointmentsByJobId = async (job, match) => {
    try {
      const response = await getAppointmentList(job.id, job.openedAt.format('YYYY-MM-DD HH:mm:ss'));
      const filteredAppointments = response.filter(
        appointment => appointment.status.name !== AppointmentStatusName.APPOINTMENT_CANCELLED,
      );

      filteredAppointments.forEach(appointment => {
        if (
          appointment.applicationId &&
          appointment.status.name !== AppointmentStatusName.APPOINTMENT_CANCELLED
        ) {
          this.props.updateAppointment(appointment.applicationId, appointment);
          this.props.updateAppointmentInJobDetail(
            job.id,
            appointment.applicationId,
            match,
            appointment,
          );
        }
      });

      this.setState({ appointments: filteredAppointments });
    } catch (error) {}
  };

  isApplicationArraysEqual = (
    prevApplications: Array<Application>,
    nextApplications: Array<Application>,
  ) => {
    let result = true;

    prevApplications.forEach(prevApplication => {
      const nextApplication = nextApplications.find(
        application => application.id === prevApplication.id,
      );

      if (nextApplication.appointment && !prevApplication.appointment) {
        result = false;
      }

      if (!nextApplication.appointment && prevApplication.appointment) {
        result = false;
      }

      if (
        nextApplication.appointment &&
        prevApplication.appointment &&
        nextApplication.appointment.status.name === AppointmentStatusName.APPLICANT_CANCELLED &&
        prevApplication.appointment.status.name !== AppointmentStatusName.APPLICANT_CANCELLED
      ) {
        result = false;
      }
    });

    return result;
  };

  render() {
    const {
      job,
      allApplications,
      shortListedApplications,
      onApplicationClick,
      onAppointmentClick,
      activeApplicationId,
      t,
      onReachEnd = () => {},
      loadingMore,
    } = this.props;

    const { activeTab } = this.state;

    const { appointments } = this.state;

    return (
      <Box>
        <ul className="c-tab-list">
          <li
            className={cx('c-tab-list__item', {
              'is-active': activeTab === 'all',
            })}
          >
            <a
              href=""
              className="c-tab-list__link"
              onClick={(event: Event) => {
                event.preventDefault();
                this.handleTabChange('all');
              }}
              style={{
                color: activeTab === 'all' ? themePalette.primary_color : null,
                borderColor: activeTab === 'all' ? themePalette.primary_color : null,
              }}
            >
              <Icon name="appointment" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.allApplications')}</span>
            </a>
          </li>

          <li
            className={cx('c-tab-list__item', {
              'is-active': activeTab === 'shortListed',
            })}
          >
            <a
              href=""
              className="c-tab-list__link"
              onClick={(event: Event) => {
                event.preventDefault();
                this.handleTabChange('shortListed');
              }}
              style={{
                color: activeTab === 'shortListed' ? themePalette.primary_color : null,
                borderColor: activeTab === 'shortListed' ? themePalette.primary_color : null,
              }}
            >
              <Icon name="recruitment" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.shortList')}</span>
            </a>
          </li>

          <li
            className={cx('c-tab-list__item', {
              'is-active': activeTab === 'appointment',
            })}
          >
            <a
              href=""
              className="c-tab-list__link"
              onClick={(event: Event) => {
                event.preventDefault();
                this.handleTabChange('appointment');
              }}
              style={{
                color: activeTab === 'appointment' ? themePalette.primary_color : null,
                borderColor: activeTab === 'appointment' ? themePalette.primary_color : null,
              }}
            >
              <Icon name="appointment" size="large" className="u-gap-bottom-small" />
              <span>{t('jobDetail.appointment')}</span>
            </a>
          </li>
        </ul>

        {activeTab === 'all' && (
          <div
            className="c-box__scroll"
            onScroll={e => {
              const element = e.target;
              if (element.scrollHeight * 0.99 - element.scrollTop <= element.clientHeight) {
                onReachEnd('all');
              }
            }}
          >
            <AllApplications
              onApplicationClick={onApplicationClick}
              activeApplicationId={activeApplicationId}
              applications={allApplications}
              job={job}
              loadingMore={loadingMore}
            />
          </div>
        )}

        {activeTab === 'shortListed' && (
          <div
            className="c-box__scroll"
            onScroll={e => {
              const element = e.target;
              if (element.scrollHeight * 0.99 - element.scrollTop <= element.clientHeight) {
                onReachEnd('shortListed');
              }
            }}
          >
            <ShortListedApplications
              onApplicationClick={onApplicationClick}
              activeApplicationId={activeApplicationId}
              applications={shortListedApplications}
              job={job}
              loadingMore={loadingMore}
            />
          </div>
        )}

        {activeTab === 'appointment' && (
          <Appointments
            appointments={appointments}
            activeApplicationId={activeApplicationId}
            onAppointmentClick={onAppointmentClick}
          />
        )}
      </Box>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateAppointment, updateAppointmentInJobDetail: updateAppointmentJob },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(withTranslation()(Applications));
