// @flow
// eslint-disable-next-line import/prefer-default-export
export const PaymentErrorMap = {
  card_decline_rate_limit_exceeded: 'cardDeclined',
  card_declined: 'cardDeclined',
  cardholder_phone_number_required: 'cardDeclined',
  debit_not_authorized: 'cardDeclined',
  invalid_card_type: 'cardDeclined',
  expired_card: 'expiredCard',
  incorrect_cvc: 'incorrectCvc',
  incorrect_number: 'incorrectNumber',
  invalid_expiry_month: 'invalidExpiryMonth',
  invalid_expiry_year: 'invalidExpiryYear',
  invalid_number: 'invalidNumber',
  insufficient_funds: 'insufficientFunds',
  'data.payment.error.init': 'cardDeclined',
  'data.payment.error.not_sufficient_funds': 'insufficientFunds',
  'data.payment.error.expired_card': 'invalidExpiryDate',
  'data.payment.error.invalid_cvc': 'incorrectCvc',
  'data.payment.error.invalid_cardnumber': 'invalidNumber'
};
