// @flow

import React from 'react';

import Container from '../components/layout/Container';
import Row from '../components/layout/Row';
import Col from '../components/layout/Col';
import Box from '../components/elements/Box';
import Logo from '../components/shared/Logo';
import useTranslator from '../hooks/useTranslator';

const UnexpectedError = () => {
  const { t } = useTranslator();

  return (
    <section className="u-pad-ends-large">
      <Container>
        <Row>
          <Col lg={6} className="col--offset-lg-3">
            <Box className="u-pad-ends-medium@md-up u-pad-ends@sm-down u-pad-sides-medium@md-up u-pad-sides@sm-down">
              <Logo
                className="c-box__center-logo"
                alt={t('layout.header.title')}
              />
              <h1 className="u-text-center u-pad-top">{t('common.glossary.unAuthorizedView')}</h1>
            </Box>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default UnexpectedError;
