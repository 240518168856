// @flow

import React from 'react';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Button from '../../components/ui/Button';
import LinkButton from '../../components/ui/LinkButton';
import Dropdown from '../../components/elements/Dropdown';

import { type JobsState } from '../../redux/modules/jobs';
import useTranslator from '../../hooks/useTranslator';
import DownloadIcon from '../../components/elements/Icon/downloadIcon';

type Props = {
  jobId: string,
  allJobs: JobsState,
  onJobChange: string => void,
  onExcelClick: () => void,
  onExcelDownloadClick: () => void,
};

const Navbar = ({ allJobs, jobId, onJobChange, onExcelClick, onExcelDownloadClick }: Props) => {
  const { t } = useTranslator();

  const allApplicationDropdownOption = {
    value: '0',
    title: t('jobDetail.allApplications'),
  };
  const dropdownOptions = [
    allApplicationDropdownOption,
    ...allJobs.data.map(job => ({
      value: job.id.toString(),
      title: job.title,
    })),
  ];

  return (
    <div className="c-navbar c-navbar--white">
      <Container>
        <div className="c-navbar__body">
          <Row className="u-flex-grow-full u-flex-align-middle">
            <Col md={6}>
              <Dropdown value={jobId} options={dropdownOptions} onChange={onJobChange} />
            </Col>
            <Col md={6} className="u-hidden@sm-down u-text-right">
              <LinkButton ghost to={`/jobs/edit/${jobId}`} className="u-gap-right">
                {t('layout.navbar.update')}
              </LinkButton>

              <Button type="button" success onClick={onExcelClick} className="u-gap-right">
                {t('layout.navbar.excelReport')}
              </Button>
              <Button type="button" success onClick={onExcelDownloadClick}>
                <DownloadIcon height={12} width={12} stroke={"white"} />
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
