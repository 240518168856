// @flow

import moment from 'moment-timezone';

import { inbox as inboxRequest } from '../../api/chat';
import { Status, type StatusType } from '../../utils/apiState';
import { type Thread, type Message } from '../../types/chat';
import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from './index';

// Actions
const REQUEST = 'inbox/REQUEST';
const MARK_AS_READ = 'inbox/MARK_AS_READ';
const SUCCESS = 'inbox/SUCCESS';
const FAILED = 'inbox/FAILED';
const NEW_MESSAGE = 'inbox/NEW_MESSAGE';

// Action Creator Types
type RequestAction = {
  type: typeof REQUEST,
  branchId: number,
};

type MarkAsReadAction = {
  type: typeof MARK_AS_READ,
  branchId: number,
  threadId: number,
};

type SuccessAction = {
  type: typeof SUCCESS,
  branchId: number,
  data: Thread[],
};

type FailedAction = {
  type: typeof FAILED,
  branchId: number,
  error: any,
};

type NewMessageAction = {
  type: typeof NEW_MESSAGE,
  threadId: number,
  message: Message,
  isReaded: boolean,
};

export type InboxActions =
  | RequestAction
  | MarkAsReadAction
  | SuccessAction
  | FailedAction
  | NewMessageAction;

// Action Creators
export const request = (branchId: number): RequestAction => ({
  type: REQUEST,
  branchId,
});

export const markAsRead = (branchId: number, threadId: number): MarkAsReadAction => ({
  type: MARK_AS_READ,
  branchId,
  threadId,
});

export const success = (data: Thread[], branchId: number): SuccessAction => ({
  type: SUCCESS,
  data,
  branchId,
});

export const failed = (error: any, branchId: number): FailedAction => ({
  type: FAILED,
  error,
  branchId,
});

export const newMessage = (
  threadId: number,
  message: Message,
  isReaded: boolean,
): NewMessageAction => ({
  type: NEW_MESSAGE,
  threadId,
  message,
  isReaded,
});

// Thunks
export const loadThunk = (
  branchId: number,
  passBranchIdToRequest: boolean = true,
  page?: number,
  limit?: number
): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  dispatch(request(branchId));

  try {
    const data = await inboxRequest(passBranchIdToRequest ? branchId : undefined, page, limit);

    dispatch(success(data, branchId));
  } catch (error) {
    dispatch(failed(error, branchId));
  }
};

export const loadHiddenThunk = (
  branchId: number,
  passBranchIdToRequest: boolean = true,
  page: number,
  limit: number
): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  try {
    const data = await inboxRequest(passBranchIdToRequest ? branchId : undefined, page, limit);

    dispatch(success(data, branchId));
  } catch (error) {
    // ...
  }
};

export const newMessageThunk = (
  threadId: number,
  message: Message,
  isReaded: boolean,
): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  dispatch(newMessage(threadId, message, isReaded));
};

export const markAsReadThunk = (branchId: number, threadId: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  dispatch(markAsRead(branchId, threadId));
};

// Reducer
export type InboxItemState = {
  +status: StatusType,
  +data: Thread[],
    +error: any,
};
export type InboxState = { [branchId: number]: InboxItemState };

const emptyItem = [];
const initialState = {};

export default function reducer(
  state: InboxState = initialState,
  action: InboxActions,
): InboxState {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        [action.branchId]: {
          status: Status.LOADING,
          data: emptyItem,
          error: null,
        },
      };
    case SUCCESS:
      return {
        ...state,
        [action.branchId]: {
          status: Status.LOADED,
          data: action.data,
          error: null,
        },
      };
    case FAILED:
      return {
        ...state,
        [action.branchId]: {
          status: Status.FAILED,
          data: emptyItem,
          error: action.error,
        },
      };
    case NEW_MESSAGE: {
      const newState: InboxState = {};
      const newMessageAction: NewMessageAction = action;

      (Object.keys(state): any).forEach((branchId: number) => {
        newState[branchId] = {
          ...state[branchId],
          data: state[branchId].data
            .map(thread => {
              const newThread = { ...thread };

              if (thread.id !== newMessageAction.threadId) {
                return newThread;
              }

              if (!newMessageAction.isReaded) {
                newThread.nbUnreadMessage += 1;
              }

              if (newMessageAction.message.type === 'text') {
                newThread.lastMessage = newMessageAction.message.body;
              }

              newThread.lastMessageSent = moment();

              return newThread;
            })
            .sort(
              (thread1: Thread, thread2: Thread) =>
                thread2.lastMessageSent - thread1.lastMessageSent,
            ),
        };
      });

      return newState;
    }

    case MARK_AS_READ: {
      const markAsReadAction: MarkAsReadAction = action;

      return {
        ...state,
        [action.branchId]: {
          ...state[action.branchId],
          data: state[action.branchId].data.map(thread => {
            if (thread.id !== markAsReadAction.threadId) {
              return { ...thread };
            }

            return { ...thread, nbUnreadMessage: 0 };
          }),
        },
      };
    }
    default:
      return state;
  }
}

// Selectors
export const getDetailById = (
  state: ReduxState,
  { branchId }: { branchId: number },
): InboxItemState => {
  const itemState = state.inbox[branchId];

  if (!itemState) {
    return {
      status: Status.INIT,
      data: emptyItem,
      error: null,
    };
  }

  return itemState;
};
