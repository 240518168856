// @flow

import React from 'react';

import { type BranchesInboxItem } from '../../types/chat';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';

import NoData from './NoData';
import ListItem from './ListItem';
import useTranslator from '../../hooks/useTranslator';

type Props = {
  branchesInboxes: BranchesInboxItem[],
};

const List = ({ branchesInboxes }: Props) => {
  const { t } = useTranslator();

  if (branchesInboxes.length === 0) {
    return <NoData />;
  }

  return (
    <div className="u-pad-ends@md-up u-pad-ends-small@sm-down">
      <Container>
        <div className="u-gap-bottom-small u-pad-sides-small u-font-size-sm u-hidden@md-down">
          <Row>
            <Col lg={5}>
              <span />
            </Col>
            <Col lg={2} className="u-text-center">
              {t('branches.inbox.totalMessages')}
            </Col>
            <Col lg={2} className="u-text-center">
              {t('branches.inbox.totalUnreadMessages')}
            </Col>
            <Col lg={3}>
              <span />
            </Col>
          </Row>
        </div>

        {branchesInboxes.map((item) => (
          <ListItem key={item.id} item={item} />
        ))}
      </Container>
    </div>
  );
};

export default List;
