// @flow

import React, { useState, useEffect } from 'react';

import { type Package } from '../../types/package';
import { type AuthenticatedUser } from '../../types/user';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';

import PackageListItem from './PackageListItem';
import useTranslator from '../../hooks/useTranslator';

type Props = {
  packages: Array<Package>,
  isPaymentEnabled: boolean,
  history: Object,
  currentUser: AuthenticatedUser,
};

const List = ({ packages, isPaymentEnabled, history, currentUser }: Props) => {
  const { t } = useTranslator();
  const [maxHeightOfPackageNameDiv, setMaxHeightOfPackageNameDiv] = useState(0);
  const [heightsOfPackageNameDivs, setHeightsOfPackageNameDivs] = useState(
    Array.from({ length: packages.length }, (v, k) => ({ packageIndex: k, height: 0 })),
  );
  const [isLargeScreen, setIsLargeScreen] = useState(
    window.matchMedia('(min-width: 1199px)').matches,
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1199px)');
    const handleMediaQueryChange = mediaQueryList => {
      setIsLargeScreen(mediaQueryList.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    const maxHeight = Math.max(...heightsOfPackageNameDivs.map(item => item.height));

    setMaxHeightOfPackageNameDiv(maxHeight);
  }, [...heightsOfPackageNameDivs]);

  const handleHeightOfPackageNameDiv = (height: number, index: number) => {
    setHeightsOfPackageNameDivs(prev =>
      prev.map(item => (item.packageIndex === index ? { ...item, height } : item)),
    );
  };

  return (
    <div
      className="u-pad-ends-small u-block-center"
      style={{
        width: '90%',
      }}
    >
      <Container>
        <Row className={`u-flex${isLargeScreen ? ' u-flex-nowrap' : ''}`}>
          {packages.map((paymentPackage, index) => (
            <PackageListItem
              key={paymentPackage.id}
              paymentPackage={paymentPackage}
              packageIndex={index}
              packageCount={packages.length}
              minHeightOfPackageNameDiv={maxHeightOfPackageNameDiv}
              handleHeightOfPackageNameDiv={handleHeightOfPackageNameDiv}
              isLargeScreen={isLargeScreen}
              isPaymentEnabled={isPaymentEnabled}
              history={history}
              currentUser={currentUser}
            />
          ))}
        </Row>
        <Row>
          <span className="u-full-width u-text-center u-color-gull-gray u-font-size-md">
            {t('packages.listPage.disclaimer')}
          </span>
        </Row>
      </Container>
    </div>
  );
};

export default List;
