// @flow
import { type Country, type CountryDetection } from '../types/country';

export const CountryMapper = {
  fromAPIResponse(response: Object): Country {
    return {
      id: response.id,
      countryName: response.countryName || response.country_name,
      latitude: response.latitude,
      longitude: response.longitude,
      countryCode: response.countryCode || response.country_code,
      phoneCode: response.phoneCode,
      languageCode: response.languageCode || response.language_code,
      countryAttributes: response.countryAttributes || response.country_attributes,
      defaultLanguage: response.defaultLanguage || response.default_language,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Country> {
    if (!response) {
      return [];
    }

    return response.map(this.fromAPIResponse);
  },
};

export interface CountryDetectionPayload {
  is_detected: boolean;
  country: CountryPayload;
}

export const CountryDetectionMapper = {
  fromAPIResponse(response: CountryDetectionPayload): CountryDetection {
    if (!response) {
      return {
        country: {
          id: -1,
          countryCode: '',
          countryName: '',
          flagImage: '',
          timeZone: '',
          countryRule: [],
          defaultLocation: '',
          latitude: '',
          longitude: '',
          defaultLanguage: null,
          countryAttributes: null,
          minimumFractionDigits: -1,
        },
        isDetected: false,
      };
    }

    return {
      isDetected: response.is_detected,
      country: CountryMapper.fromAPIResponse(response.country),
    };
  },
};
