// @flow
export default {
  jobs: {
    noData: 'Ninguna Chamba',
    addJob: 'CREAR CHAMBA',
    jobListTitle: 'Titulo de Chamba',
    jobListCount: 'Solicitude',
    unreviewed: 'Sin Revisar',
    shortList: 'Preseleccionados',
    branchName: 'Nombre de sucursal',
    active: 'ACTIVO',
    passive: 'INACTIVO',
    applicationCount: 'Número de solicitudes',
    noBranchFound: 'No hay sucursales',
    view: 'Vistas',
    jobListDate: 'Fecha de Chamba',
    status: 'Estado',
    create: {
      title: 'CREAR CHAMBA',
    },

    edit: {
      title: 'EDITAR CHAMBA',
    },

    detail: {
      noApplicationData: 'No hay solicitudes.',
      sendExcelInfoText: 'El Excel será enviado a su correo electrónico cuando esté listo',
    },

    excelModal: {
      title: 'Exportar solicitudes a Excel',
    },
    excelForm: {
      email: {
        label: 'E-mail',
        required: 'Escribe e-mail.',
      },
      send: 'Enviar',
    },

    excelDownloadModal: {
      title: 'Servicio de descarga de Excel',
    },
    excelDownloadForm: {
      date: {
        label: 'Solicitudes después de una fecha determinada',
      },
      day: {
        label: 'Día',
        required: 'Por favor seleccione un día',
      },
      month: {
        label: 'Mes',
        required: 'Por favor seleccione un mes',
      },
      day: {
        label: 'Año',
        required: 'Por favor seleccione un año',
      },
      onlyShortlisted: {
        label: 'Sólo aplicaciones preseleccionadas',
      },
      download: 'Descargar',
    },

    jobForm: {
      uploadImage: 'Agregar foto para chamba',
      infoText: 'Esta información no será vista por los candidatos.',
      save: 'GUARDAR',
      createJob: 'CREAR CHAMBA',
      jobImage: 'Foto de chamba',
      status: {
        label: 'Estatus de chamba',
        required: 'Seleccionar estatus de chamba.',
      },
      user: {
        label: 'Sucursal de chamba',
        required: 'Seleccionar sucursal.',
      },
      title: {
        label: 'Título de chamba',
        required: 'Escibe título de chamba.',
      },
      position: {
        label: 'Puesto',
        required: 'Seleccionar puesto.',
        placeholder: 'Seleccionar',
      },
      category: {
        label: 'Sector',
        required: 'Seleccionar sector.',
      },
      description: {
        label: 'Detalles de chamba',
        required: 'Escribe detalles de chamba.',
      },
      contactOption: {
        label: '¿Los candidatos deben contactarte directamente?',
        required: 'Escribe teléfono.',
      },
      autoShortlistEnabled: {
        label: 'Evaluación automatica',
      },
      autoShortlistThreshold: {
        label: 'Evaluación mínima',
        required: 'Escribe evaluación mínima.',
        minNumber: 'El mínima es {{count}}.',
        maxNumber: 'El máximo es {{count}}.',
      },
      contactPhone: {
        label: 'Teléfono',
        required: 'Escribe teléfono.',
      },
      workTimePreferences: {
        label: 'Tiempo',
        minLength: 'Obligatorio.',
        required: 'Por favor introduzca su horario de trabajo preferido.',
      },
      minExperienceDuration: {
        label: 'Experiencia mínima',
      },
      expectedSalaryMin: {
        label: 'Salario minimo deseado',
      },
      expectedSalaryValidate: 'Debe ser mayor a 0.',
      expectedSalaryMax: {
        label: 'Salario máxima deseado',
      },
      gender: {
        label: 'Genero',
        required: 'Seleccionar.',
      },
      militaryRequirements: {
        label: 'Estatus militar',
      },
      birthDateMin: {
        label: 'Edad mínima',
        minNumber: 'Edad mínima es {{age}}.',
      },
      birthDateMax: {
        label: 'Edad máxima',
        minNumber: 'Edad máxima es {{age}}.',
      },
      minEducationStatus: {
        label: 'Educación mínima',
      },
      minEnglishLevel: {
        label: 'Nivel de Inglés mínimo',
      },
      locationSearch: {
        label: 'Ubicación',
      },
      locationPosition: {
        label: 'Seleccionar ubicación',
        required: 'Seleccionar ubicación.',
      },
      peopleBoxProjectId: {
        label: 'Ingrese People Box Id',
        required: 'Por favor ingrese el número de people box ID',
      },
    },
  },
};
