// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { type Match, type RouterHistory } from 'react-router-dom';

import fcmFallback, { type FCMFallbackTicker } from '../../utils/fcmFallback';
import { isLoading } from '../../utils/apiState';
import { type Thread } from '../../types/chat';
import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from '../../redux/modules';
import {
  getDetailById,
  loadThunk,
  loadHiddenThunk,
  type InboxItemState,
} from '../../redux/modules/inbox';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';

import Loading from './Loading';
import NoData from './NoData';
import Threads from './threads';
import Conversation from './conversation';
import { blockUserByThread } from '../../api/user';
import Confirm from '../../components/elements/Confirm';

type Props = {
  match: Match,
  history: RouterHistory,
  fcmSupportStatus: boolean,

  branchId: number,
  activeThreadId?: number,
  isBranch: boolean,
  inbox: InboxItemState,
  loadThunk: (branchId: number, passBranchIdToRequest: boolean, page?: number, limit?: number) => void,
  loadHiddenThunk: (branchId: number, passBranchIdToRequest: boolean, page?: number, limit?: number) => void,
};

class Inbox extends PureComponent<Props> {
  props: Props;

  ticker: FCMFallbackTicker;

  confirmDialog: any;

  constructor(props: Props) {
    super(props);

    this.ticker = fcmFallback.ticker(this.refresh, 180000);
    this.ticker.observe(props.fcmSupportStatus);
    this.state = {
      limit: 10,
      page: 1
    }
  }

  componentWillMount() {
    this.props.loadThunk(this.props.branchId, !this.props.isBranch, this.state.page, this.state.limit);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.activeThreadId && this.props.activeThreadId) {
      this.props.loadThunk(this.props.branchId, !this.props.isBranch, this.state.page, this.state.limit);
    }

    this.ticker.observe(nextProps.fcmSupportStatus);
  }

  componentWillUnmount() {
    this.ticker.free();
  }

  refresh = () => {
    this.props.loadHiddenThunk(this.props.branchId, !this.props.isBranch, this.state.page, this.state.limit);
  };

  onThreadClick = (thread: Thread) => {
    this.props.history.push(`${this.props.match.url}/${thread.id}`);
  };

  handleBlockUser = (threadId: number) => () => {
    this.confirmDialog.show('Bu çalışanı bloklamak istediğinize emin misiniz?', {
      onConfirm: async () => {
        try {
          await blockUserByThread(threadId);

          this.refresh();
          this.props.history.replace(this.props.match.url);
        } catch (error) {
          alert(error.message || 'Bir hata oluştu.');
        }
      },
    });
  };

  nextPage = () => {
    this.setState({
      page: this.state.page + 1
    }, () => {
      this.props.loadHiddenThunk(this.props.branchId, !this.props.isBranch, this.state.page, this.state.limit, false);
    });
  };

  render() {
    const { inbox, branchId, activeThreadId } = this.props;

    if (isLoading(inbox)) {
      return <Loading />;
    }

    if (inbox.data.length === 0) {
      return <NoData />;
    }

    return (
      <div className="u-pad-ends">
        <Container>
          <Row>
            <Col md={6}>
              <Threads
                threads={inbox.data}
                activeThreadId={activeThreadId}
                onThreadClick={this.onThreadClick}
                next={() => {
                  this.nextPage();
                }}
              />
            </Col>

            <Col md={6}>
              {!!activeThreadId && (
                <Conversation
                  threadId={activeThreadId}
                  branchId={branchId}
                  onBlockUserClick={this.handleBlockUser(activeThreadId)}
                />
              )}
            </Col>
          </Row>
        </Container>
        <Confirm
          ref={ref => {
            this.confirmDialog = ref;
          }}
          confirmLabel="Devam et"
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, props: Props): Object => ({
  fcmSupportStatus: state.app.fcmSupport,
  currentUser: state.currentUser,
  inbox: getDetailById(state, { branchId: props.branchId }),
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ loadThunk, loadHiddenThunk }, dispatch);

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Inbox);
