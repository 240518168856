// @flow

import { initializeApp } from 'firebase/app';

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'saatte-is.firebaseapp.com',
  databaseURL: 'https://saatte-is.firebaseio.com',
  projectId: 'saatte-is',
  storageBucket: 'saatte-is.appspot.com',
  messagingSenderId: '472929644816',
  appId: '1:472929644816:web:2b8387554f83fda8167595',
  measurementId: 'G-5HW4F7Y48P',
});

export default app;
