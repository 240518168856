import { ContactOptionList } from '../../../../enums/contactOption';
import { ExperienceDurationMap } from '../../../../enums/experienceDuration';
import { JobStatusMap } from '../../../../enums/jobStatus';

// @flow
export default {
  constants: {
    defaultSelect: 'Lütfen seçiniz',
    benefitMap: {
      fare: 'Yol Masrafı',
      free_meal: 'Yemek',
      health_insurance: 'Tip/Prim',
    },
    workTimeMap: {
      full_time: 'Tam Zamanlı',
      part_time: 'Yarı Zamanlı',
      seasonal: 'Dönemsel',
    },
    militaryStatusMap: {
      '1': 'Yapılmadı',
      '2': 'Tecilli',
      '3': 'Yapıldı',
    },
    englishLevelMap: {
      '1': 'Yok',
      '2': 'Az',
      '3': 'Orta',
      '4': 'İyi',
    },
    gender: [
      {
        id: 'all',
        name: 'Herkes',
      },
      {
        id: 'male',
        name: 'Erkek',
      },
      {
        id: 'female',
        name: 'Kadın',
      },
    ],
    genderMap: {
      male: 'Erkek',
      female: 'Kadın',
    },
    educationMap: {
      elementary_school: 'İlkokul',
      middle_school: 'Ortaokul',
      high_school: 'Lise',
      university: 'Üniversite',
    },
    disabledStatusMap: {
      disabled: 'Engelli',
      not_disabled: 'Engelli Değil',
    },
    universityStatusMap: {
      graduated: 'Mezun',
      student: 'Devam Ediyor',
    },
    benefit: [
      { id: true, name: 'Var' },
      { id: false, name: 'Yok' },
    ],
    contact: [
      {
        id: ContactOptionList.PHONE,
        name: 'Evet, telefonla',
      },
      {
        id: ContactOptionList.TEXT,
        name: 'Evet, mesajla',
      },
      {
        id: ContactOptionList.NONE,
        name: 'Hayır',
      },
    ],
    autoShortlistEnabled: [
      {
        id: true,
        name: 'Aktif',
      },
      {
        id: false,
        name: 'Pasif',
      },
    ],
    experienceDurationList: [
      { key: ExperienceDurationMap.ALL, value: 'Tecrübesiz' },
      { key: ExperienceDurationMap.ONE_MONTH, value: '1 ay' },
      { key: ExperienceDurationMap.TWO_MONTHS, value: '2 ay' },
      { key: ExperienceDurationMap.THREE_MONTHS, value: '3 ay' },
      { key: ExperienceDurationMap.FOUR_MONTHS, value: '4 ay' },
      { key: ExperienceDurationMap.FIVE_MONTHS, value: '5 ay' },
      { key: ExperienceDurationMap.SIX_MONTHS, value: '6 ay' },
      { key: ExperienceDurationMap.SEVEN_MONTHS, value: '7 ay' },
      { key: ExperienceDurationMap.EIGHT_MONTHS, value: '8 ay' },
      { key: ExperienceDurationMap.NINE_MONTHS, value: '9 ay' },
      { key: ExperienceDurationMap.TEN_MONTHS, value: '10 ay' },
      { key: ExperienceDurationMap.ELEVEN_MONTHS, value: '11 ay' },
      { key: ExperienceDurationMap.ONE_YEAR, value: '1 yıl' },
      { key: ExperienceDurationMap.TWO_YEARS, value: '2 yıl' },
      { key: ExperienceDurationMap.THREE_YEARS, value: '3 yıl' },
      { key: ExperienceDurationMap.FOUR_YEARS, value: '4 yıl' },
      { key: ExperienceDurationMap.FIVE_YEARS, value: '5 yıl' },
      { key: ExperienceDurationMap.SIX_YEARS, value: '6 yıl' },
      { key: ExperienceDurationMap.SEVEN_YEARS, value: '7 yıl' },
      { key: ExperienceDurationMap.OVER_SEVEN_YEARS, value: '7 yıldan fazla' },
    ],
    jobStatusList: [
      { key: JobStatusMap.ACTIVE, value: 'Aktif' },
      { key: JobStatusMap.CLOSED, value: 'Pasif' },
    ],
    updateFeedback: 'Bildiriminiz güncellendi.',
  },
};
