// @flow

import { list as listRequest } from '../../api/category';
import { Status, type StatusType } from '../../utils/apiState';

import { type ReduxDispatch } from '../../types/redux';
import { type Category } from '../../types/category';

// Actions
const REQUEST = 'categories/REQUEST';
const SUCCESS = 'categories/SUCCESS';
const FAILED = 'categories/FAILED';

// Action Creator Types
type RequestAction = {
  type: typeof REQUEST,
};

type SuccessAction = {
  type: typeof SUCCESS,
  categories: Array<Category>,
};

type FailedAction = {
  type: typeof FAILED,
  error: any,
};

export type CategoriesActions = RequestAction | SuccessAction | FailedAction;

// Action Creators
export const request = (): RequestAction => ({
  type: REQUEST,
});

export const success = (categories: Array<Category>): SuccessAction => ({
  type: SUCCESS,
  categories,
});

export const failed = (error: any): FailedAction => ({
  type: FAILED,
  error,
});

// Thunks
export const loadThunk = (): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  dispatch(request());

  try {
    const categories = await listRequest();
    dispatch(success(categories));
  } catch (error) {
    dispatch(failed(error));
  }
};

// Reducer
export type CategoriesState = {
  +status: StatusType,
  +data: Array<Category>,
  +error: any,
};

const initialState = {
  status: Status.INIT,
  data: [],
  error: null,
};

export default function reducer(
  state: CategoriesState = initialState,
  action: CategoriesActions,
): CategoriesState {
  switch (action.type) {
    case REQUEST:
      return { ...state, status: Status.LOADING };
    case SUCCESS:
      return { ...state, status: Status.LOADED, data: action.categories };
    case FAILED:
      return { ...state, status: Status.FAILED, data: [], error: action.error };
    default:
      return state;
  }
}
