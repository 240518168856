// @flow

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import AuthService from '../../services/auth';
import { UserGrantMap } from '../../enums/userGrant';
import { JobStatusMap } from '../../enums/jobStatus';

import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';

import Box from '../../components/elements/Box';

import { type ReduxDispatch } from '../../types/redux';
import { type Job } from '../../types/job';
import useTranslator from '../../hooks/useTranslator';
import noImage from '../../img/empty-state/noImage.jpeg';

type Props = {
  job: Job,
  pusher: Function,
};

const ListItem = ({ job, pusher }: Props) => {
  const { t } = useTranslator();
  const isCurrentUserHR = AuthService.isGranted(UserGrantMap.HR_GRANT);

  const redirectToDetail = event => {
    event.stopPropagation();
    window.open(`/jobs/detail/${job.id}`, '_blank');
  };
  return (
    <Box
      className="c-box--clickable u-pad-sides-small u-pad-ends-small u-gap-bottom"
      onClick={() => {
        pusher(`/jobs/detail/${job.id}`);
      }}
    >
      <Row className="u-flex-align-middle@lg-up">
        <Col lg={2} md={3} className="u-gap-bottom@md-down">
          <img
            src={job.imageUrl.indexOf('job-image.jpg') === -1 ? job.imageUrl : noImage}
            className="u-full-width"
          />
        </Col>

        <Col lg={2} md={7} xs={9} className="u-gap-bottom@md-down">
          <span className="u-block u-font-size-md u-font-weight-medium job-title-size">
            <a href="#" onClick={redirectToDetail}>
              {job.title}{' '}
            </a>
          </span>
          <span className="u-block">{job.openedAt.format('L')}</span>
        </Col>

        <Col
          lg={isCurrentUserHR ? 2 : 1}
          md={2}
          xs={3}
          className="u-text-center u-gap-bottom@md-down"
        >
          {job.status === JobStatusMap.ACTIVE && (
            <span className="c-status c-status--active">{t('jobs.active')}</span>
          )}
          {job.status === JobStatusMap.CLOSED && (
            <span className="c-status c-status--inactive">{t('jobs.passive')}</span>
          )}
        </Col>

        <Col lg={isCurrentUserHR ? 1 : 2} xs={6} className="u-text-center u-gap-bottom@md-down">
          <span className="u-hidden@lg-up u-block u-font-size-sm@sm-down">
            {t('jobs.applicationCount')}
          </span>
          <span className="u-font-size-xl@md-up">{job.counts.applications}</span>
        </Col>
        <Col lg={isCurrentUserHR ? 1 : 2} xs={6} className="u-text-center u-gap-bottom@md-down">
          <span className="u-hidden@lg-up u-block u-font-size-sm@sm-down">
            {t('jobs.unreviewed')}
          </span>
          <span className="u-font-size-xl@md-up">{job.counts.unreviewed}</span>
        </Col>
        <Col lg={isCurrentUserHR ? 1 : 2} xs={6} className="u-text-center u-gap-bottom@md-down">
          <span className="u-hidden@lg-up u-block u-font-size-sm@sm-down">
            {t('jobs.shortListAdded')}
          </span>
          <span className="u-font-size-xl@md-up">{job.counts.shortLists}</span>
        </Col>
        <Col lg={1} xs={6} className="u-text-center u-gap-bottom@md-down">
          <span className="u-hidden@lg-up u-block u-font-size-sm@sm-down">
            {t('jobs.viewCount')}
          </span>
          <span className="u-font-size-xl@md-up">{job.counts.jobViews}</span>
        </Col>

        {isCurrentUserHR && (
          <Col lg={2} xs={12} className="u-text-center">
            <span className="c-status u-full-width">{job.user.companyName}</span>
          </Col>
        )}
      </Row>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ pusher: push }, dispatch);

// $FlowFixMe
export default connect(null, mapDispatchToProps)(ListItem);
