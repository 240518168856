// @flow

import { type Location, type NamedLocation, type GeoLocation } from '../types/location';

export const LocationMapper = {
  fromAPIResponse(response: string | null): Location {
    if (!response) {
      return { latitude: 0, longitude: 0 };
    }

    const [latitude, longitude] = response
      .split(',')
      .map((coor: string): number => parseFloat(coor.trim()));

    return { latitude, longitude };
  },

  toAPIRequest(location: Location): string {
    return `${location.latitude}, ${location.longitude}`;
  },
};

export const NamedLocationMapper = {
  fromAPIResponse(response: Object | null): NamedLocation {
    if (!response) {
      return { latitude: 0, longitude: 0, name: '' };
    }

    return {
      latitude: response.latitude,
      longitude: response.longitude,
      name: response.name || '',
    };
  },
};

export const GeoLocationMapper = {
  fromAPIResponse(response: Array<Object> | null): GeoLocation {
    if (!response) {
      return [];
    }

    return response;
  },
};
