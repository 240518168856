// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { type AuthenticatedUser } from '../../../../types/user';
import { type Application } from '../../../../types/application';
import { type Appointment } from '../../../../types/appointment';

import ProfileHeader from '../ProfileHeader';
import AppointmentDetail from './Detail';
import AppointmentCreate from './Create';

type Props = {
  application: Application,
  currentUser: AuthenticatedUser,
  workerRating: number,
  onCreate?: (appointment: Appointment) => void,
  onCancel?: () => void,
};

class AppointmentPage extends PureComponent<Props> {
  props: Props;

  render() {
    const { application, currentUser, workerRating, onCreate, onCancel } = this.props;

    return (
      <div>
        <ProfileHeader profile={application.user} workerRating={workerRating} />

        <hr className="u-hr-line" />

        {application.appointment && Object.keys(application.appointment).length ? (
          <AppointmentDetail appointment={application.appointment} onCancel={onCancel} />
        ) : (
          <AppointmentCreate
            currentUser={currentUser}
            application={application}
            onCreate={onCreate}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(AppointmentPage);
