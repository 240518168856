/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { type AuthenticatedUser } from '../types/user';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Button from '../../components/ui/Button';
import { themePalette } from '../../theme';
import Container from '../../components/layout/Container';
import { Basket } from '../../types/basket';
import Modal from '../../components/elements/Modal';
import Checkbox from '../../components/ui/Checkbox';
import { threeDSInit } from '../../api/payment';
import { PaymentProviderMap } from '../../enums/paymentProvider';
import useTranslator from '../../hooks/useTranslator';
import { currencyMapper } from '../../mappers/currency';

type Props = {
  currentUser: AuthenticatedUser,
  basket: Basket,
  t: Function,
  history: Object,
};

const Input = styled.input`
  width: 100%;
  height: 3em;
  padding: 10px;
  border: 2px solid ${props => (props.hasError ? 'red' : 'lightgrey')};
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 2%;

  &:focus {
    border-color: ${props => (props.hasError ? 'red' : themePalette.packageColors[0])};
    transition: border-color 0.3s ease-in-out;
  }
`;

const CardForm = ({ t, currentUser, basket, history }: Props) => {
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    cardNumberError: '',
    expiryDate: '',
    expiryDateError: '',
    cvc: '',
    cvcError: '',
    cardHolderName: '',
    cardHolderNameError: '',
  });
  const [isSalesAgreementChecked, setIsSalesAgreementChecked] = useState(false);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);

  const handleInputChange = (field, value) => {
    setCardDetails({ ...cardDetails, [field]: value, [`${field}Error`]: '' });
  };

  const handleInputBlur = (field, validator) => {
    if (validator()) {
      setCardDetails({ ...cardDetails, [`${field}Error`]: validator() });
    }
  };

  const validateCardHolderName = () => {
    return cardDetails.cardHolderName === '' ? t('payment.input.cardHolderName.required') : '';
  };

  const validateCardNumber = () => {
    if (cardDetails.cardNumber === '') {
      return t('payment.input.cardNumber.required');
    } else if (cardDetails.cardNumber.length < 19) {
      return t('payment.input.cardHolderName.enterValid');
    }
    return '';
  };

  const validateExpiryDate = () => {
    if (cardDetails.expiryDate === '') {
      return t('payment.input.expiryDate.required');
    } else if (cardDetails.expiryDate.length < 5) {
      return t('payment.input.expiryDate.enterValid');
    }
    return '';
  };

  const validateCvc = () => {
    if (cardDetails.cvc === '') {
      return t('payment.input.cvc.required');
    } else if (cardDetails.cvc.length < 3) {
      return t('payment.input.cvc.enterValid');
    }
    return '';
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const errors = [
      validateCardHolderName(),
      validateCardNumber(),
      validateExpiryDate(),
      validateCvc(),
    ];

    if (errors.some(error => error !== '')) {
      return;
    }

    const expiryDateArray = cardDetails.expiryDate.replace(/\s+/g, '').split('/');
    const [expiryMonth, expiryYear] = expiryDateArray;

    const initResponse = await threeDSInit({
      basketId: basket.id,
      cardHolderName: cardDetails.cardHolderName,
      cardNumber: cardDetails.cardNumber.replace(/\s+/g, ''),
      cvc: cardDetails.cvc,
      expireMonth: +expiryMonth,
      expireYear: +expiryYear,
      referrer: window.location.origin,
    });

    if (initResponse.ok) {
      if (initResponse.data.payload.provider === PaymentProviderMap.STRIPE) {
        window.location.replace('/packages/payment-result?status=success');
      } else {
        history.push('/payment/3ds', {
          response: initResponse.data.payload.result,
        });
      }
    } else {
      history.push(`/packages/payment-result?status=failure&errorKey=${initResponse.data.message}`);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Container>
          <Row style={{ marginBottom: '1em' }}>
            <Col>
              <Input
                type="text"
                id="cardHolderName"
                placeholder={t('payment.input.cardHolderName.placeholder')}
                value={cardDetails.cardHolderName}
                onChange={e => handleInputChange('cardHolderName', e.target.value)}
                onBlur={() => handleInputBlur('cardHolderName', validateCardHolderName)}
                hasError={!!cardDetails.cardHolderNameError}
              />
              {cardDetails.cardHolderNameError && <div style={{ color: 'red' }}>{cardDetails.cardHolderNameError}</div>}
            </Col>
          </Row>
          <Row style={{ marginBottom: '1em' }}>
            <Col>
              <Input
                type="text"
                id="cardNumber"
                placeholder={t('payment.input.cardNumber.placeholder')}
                maxLength={19}
                value={cardDetails.cardNumber}
                onChange={e => handleInputChange('cardNumber', e.target.value
                  .replace(/[^0-9]/g, '') // To allow only numbers
                  .replace(/(.{4})/g, '$1 ') // To put space between every 4 digits
                  .trim()
                )}
                onBlur={() => handleInputBlur('cardNumber', validateCardNumber)}
                hasError={!!cardDetails.cardNumberError}
              />
              {cardDetails.cardNumberError && <div style={{ color: 'red' }}>{cardDetails.cardNumberError}</div>}
            </Col>
          </Row>
          <Row style={{ marginBottom: '1em' }}>
            <Col md={6}>
              <Input
                type="text"
                id="expiryDate"
                placeholder={t('payment.input.expiryDate.placeholder')}
                maxLength={5}
                value={cardDetails.expiryDate}
                onChange={e => handleInputChange('expiryDate', e.target.value
                  .replace(/[^0-9]/g, '') // To allow only numbers
                  .replace(/^([2-9])$/g, '0$1') // To handle 3 > 03
                  .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2') // 13 > 01/3
                  .replace(/^0{1,}/g, '0') // To handle 00 > 0
                  .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2') // To handle 113 > 11/3
                )}
                onBlur={() => handleInputBlur('expiryDate', validateExpiryDate)}
                hasError={!!cardDetails.expiryDateError}
              />
              {cardDetails.expiryDateError && <div style={{ color: 'red' }}>{cardDetails.expiryDateError}</div>}
            </Col>
            <Col md={6}>
              <Input
                type="text"
                id="cvc"
                placeholder={t('payment.input.cvc.placeholder')}
                maxLength={4}
                value={cardDetails.cvc}
                onChange={e => handleInputChange('cvc', e.target.value.replace(/[^0-9]/g, '').trim())}
                onBlur={() => handleInputBlur('cvc', validateCvc)}
                hasError={!!cardDetails.cvcError}
              />
              {cardDetails.cvcError && <div style={{ color: 'red' }}>{cardDetails.cvcError}</div>}
            </Col>
          </Row>
          <Row style={{ marginBottom: '1em' }}>
            <Col>
              <Checkbox
                id="agreement-checkbox"
                name="agreement-checkbox"
                onChange={isChecked => setIsSalesAgreementChecked(isChecked)}
                required
              >
                <span>
                  <span
                    key={0}
                    role="button"
                    onClick={() => setIsAgreementModalOpen(true)}
                    style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                  >
                    {t('packages.packageListItem.salesAgreement')}
                  </span>
                  <span key={1}> {t('packages.packageListItem.readAndAgree')} </span>
                </span>
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type="submit"
                primary
                style={{
                  backgroundColor: themePalette.packageColors[0],
                  borderRadius: '10px',
                  width: '100%',
                }}
              >
                {`${t('payment.general.completePayment')} ${basket && basket.totalPrice ? basket.totalPrice : ''} ${currencyMapper.fromAPIResponse(basket && basket.currency ? basket.currency : '')}`}
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
      <Modal
        contentLabel="package-agreements-modal"
        isOpen={isAgreementModalOpen}
        onModalCloseRequested={() => setIsAgreementModalOpen(false)}
        ariaHideApp={false}
      >
        <iframe
          className="c-package-list-agreenent-iframe"
          src={`${process.env.REACT_APP_API_NEW_URL}/app-config/contents/service-agreement/${
            currentUser && currentUser.user && currentUser.user.country
              ? currentUser.user.country.country_code.toLowerCase()
              : 'en'
          }`}
          title={t('packages.packageListItem.salesAgreement')}
        />
      </Modal>
    </div>
  );
};

export default connect()(withTranslation()(CardForm));
