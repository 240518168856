// @flow
import React from 'react';

type Props = {
  /** extra className */
  className?: string,
};

export default function({ className, ...otherProps }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      {...otherProps}
      style={{
        verticalAlign: 'text-bottom',
      }}
    >
      <rect width="20" height="20" fill="none" />
      <g transform="translate(-960.558 -583.739)">
        <path
          d="M974.558,603.239h-8a5.756,5.756,0,0,1-5.75-5.75v-6a5.756,5.756,0,0,1,5.75-5.75h8a5.756,5.756,0,0,1,5.75,5.75v6A5.756,5.756,0,0,1,974.558,603.239Zm-8-16a4.255,4.255,0,0,0-4.25,4.25v6a4.254,4.254,0,0,0,4.25,4.25h8a4.255,4.255,0,0,0,4.25-4.25v-6a4.255,4.255,0,0,0-4.25-4.25Z"
          fill="#78849e"
        />
        <path
          d="M979,597.951h-3.6a3.462,3.462,0,0,1,0-6.924H979a1.313,1.313,0,0,1,1.312,1.312v4.3A1.312,1.312,0,0,1,979,597.951Zm-3.6-5.424a1.962,1.962,0,0,0,0,3.924h3.412v-3.924Z"
          fill="#78849e"
        />
        <path d="M969.442,591.591h-3.8a.75.75,0,0,1,0-1.5h3.8a.75.75,0,0,1,0,1.5Z" fill="#78849e" />
        <path
          d="M976.034,595.239H975.4a.75.75,0,0,1,0-1.5h.638a.75.75,0,0,1,0,1.5Z"
          fill="#78849e"
        />
      </g>
    </svg>
  );
}
