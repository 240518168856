/* eslint-disable react/no-did-update-set-state */
// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import swal from 'sweetalert';

import { type ReduxDispatch } from '../../types/redux';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Box from '../../components/elements/Box';
import Logo from '../../components/shared/Logo';
import Button from '../../components/ui/Button';
import FourDigitCodeInput from '../../components/ui/FourDigitCodeInput';

import { smsVerificationRequestWithoutAuth } from '../../api/user';
import { swalOkButtonTranslateKey } from '../../constants';

const CELL_COUNT = 4;

type Props = {
  location: any,
  push: Function,
  t: any,
  history: any,
};

type RouteState = any;

type State = {
  process: string | null,
  code: string,
  time: number,
  isActiveReSend: boolean,
};

class SMSVerify extends PureComponent<Props> {
  props: Props;

  routeState: RouteState;

  state: State;

  interval: any;

  constructor(props) {
    super(props);

    this.state = {
      process: null,
      code: '',
      time: 180,
      isActiveReSend: false,
    };

    this.routeState = this.props.location.state;
  }

  componentDidMount() {
    this.setState({ process: 'sendRequestCode', isActiveReSend: true });
    if (!this.routeState || Object.values(this.routeState).some(v => v === undefined)) {
      this.props.push('/register');
      return;
    }

    this.interval = setInterval(() => {
      this.setState(prevState => ({
        time: prevState.time - 1,
      }));
    }, 1000);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.time !== prevState.time) {
      if (this.state.time === 0) {
        this.setState({ isActiveReSend: true });
      } else if (this.state.time === 150) {
        this.setState({ isActiveReSend: true });
      } else if (this.state.time === 180) {
        this.setState({ isActiveReSend: false });
      }
    }

    if (this.state.process !== prevState.process) {
      switch (this.state.process) {
        case 'sendRequestCode':
          this.smsRequest(false);
          break;
        case 'codeSend':
          this.handleSubmit();
          break;
        case 'reSendRequestCode': {
          this.smsRequest(true);
          break;
        }
        case 'waitEnterCode': {
          this.setState({ time: 180 });
          break;
        }
        default:
          break;
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleSubmit = async (): Promise<*> => {
    if (this.state.code.length !== CELL_COUNT) {
      return;
    }

    this.props.history.push(this.routeState.pushBackTo, {
      ...this.routeState,
      phoneVerifyCode: this.state.code,
    });
  };

  handleCodeChange = (code: string) => {
    this.setState({ code });
  };

  smsRequest = async (isReSend: boolean) => {
    try {
      const smsResponse = await smsVerificationRequestWithoutAuth(
        this.routeState.phone,
        isReSend,
        this.routeState.countryCode,
      );
      switch (smsResponse) {
        case 'user.phone.verify.unique': {
          swal({
            text: this.props.t('common.apiValidations.user.phone.verify.unique'),
            icon: 'error',
            button: this.props.t(swalOkButtonTranslateKey),
            dangerMode: true,
          });

          this.setState({ process: null });
          return;
        }
        case 'user.phone.verify.daily_limit': {
          swal({
            text: this.props.t('common.apiValidations.user.phone.verify.dailyLimit'),
            icon: 'error',
            button: this.props.t(swalOkButtonTranslateKey),
            dangerMode: true,
          });

          this.setState({ process: null });
          return;
        }
        case 'user.phone.verified_code.send': {
          this.setState({ process: 'waitEnterCode' });
          return;
        }
        default:
      }
    } catch (error) {
      if (
        error.translate_key ===
        'error.not_found_http_exception.phone_verify.not_provided_criteria_by_user_while_sending_verification_code.e1030'
      ) {
        swal({
          text: this.props.t('common.apiValidations.user.phone.verify.notFoundHttpException'),
          icon: 'error',
          button: this.props.t(swalOkButtonTranslateKey),
          dangerMode: true,
        });
      } else {
        swal({
          text: this.props.t('common.errors.unexpected'),
          icon: 'error',
          button: this.props.t(swalOkButtonTranslateKey),
          dangerMode: true,
        });
      }

      this.setState({ process: null });
    }
  };

  reSendRequestCodeHandler = () => {
    if (this.state.isActiveReSend) {
      if (this.state.process !== 'reSendRequestCode') {
        this.setState({ process: 'reSendRequestCode' });
      }

      this.setState({
        code: undefined,
        time: 180,
      });

      return;
    }

    if (this.state.process) {
      return;
    }

    this.setState({
      process: 'sendRequestCode',
    });
  };

  renderTime = (timeMoment: string) => {
    if (
      (this.state.process === 'waitEnterCode' || this.state.process === 'codeSend') &&
      this.state.time > 0 &&
      this.state.time < 180
    ) {
      return <h5 className="u-text-center u-padding-top">{timeMoment}</h5>;
    }
    return null;
  };

  render() {
    const timeMoment = moment.utc(this.state.time * 1000).format('mm:ss');
    const outOfTimeRange = this.state.time < 0 || this.state.time > 180;

    return (
      <section className="u-pad-ends-large">
        <Container>
          <Row>
            <Col lg={6} className="col--offset-lg-3">
              <Box className="u-pad-ends-medium@md-up u-pad-ends@sm-down u-pad-sides-medium@md-up u-pad-sides@sm-down u-flex u-flex-align-center u-flex-dir-column">
                <Logo
                  className="c-box__center-logo"
                  alt={this.props.t('layout.header.title')}
                  style={{ width: 150, height: 150, objectFit: 'scale-down' }}
                />
                <div className="u-margin-top-large">
                  <h1 className="u-margin-bottom-large u-text-center">
                    {this.props.t('register.smsVerify.title')}
                  </h1>
                  <h5 className="u-margin-bottom-large u-text-center">
                    {this.props.t('register.smsVerify.infoText')}
                  </h5>
                </div>
                <FourDigitCodeInput
                  setValue={this.handleCodeChange}
                  disabled={this.state.process !== 'waitEnterCode'}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    marginTop: '5%',
                  }}
                >
                  <a
                    style={{ fontWeight: 'bold', fontSize: '1em' }}
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      this.reSendRequestCodeHandler();
                    }}
                    onKeyDown={event => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        event.preventDefault();
                        this.reSendRequestCodeHandler();
                      }
                    }}
                  >
                    {this.props.t('register.smsVerify.reSendCode')}
                  </a>
                </div>
                <div>
                  {this.state.process === 'waitEnterCode' || this.state.process === 'codeSend' ? (
                    <div className="u-margin-top-large u-flex u-flex-dir-column u-flex-full-center">
                      {this.renderTime(timeMoment)}
                      <Button
                        disabled={
                          (this.state.code || '').length !== CELL_COUNT ||
                          this.state.process === 'codeSend' ||
                          (this.state.isActiveReSend && outOfTimeRange)
                        }
                        loading={this.state.process === 'codeSend'}
                        onClick={() => {
                          if (this.state.process !== 'codeSend') {
                            this.setState({ process: 'codeSend' });
                          }
                        }}
                        className="u-half-width"
                      >
                        {this.props.t('register.smsVerify.confirm')}
                      </Button>
                    </div>
                  ) : null}
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ push }, dispatch);

// $FlowFixMe
export default connect(null, mapDispatchToProps)(withTranslation()(SMSVerify));
