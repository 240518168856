// @flow

import React from 'react';
import cx from 'classnames';

import Label from '../../ui/Label';
import Map from '../Map';

type Meta = {
  touched: boolean,
  error: string,
};

type Props = {
  label: string,
  meta: Meta,
  latitude: number,
  longitude: number,
  centerLatitude: number,
  centerLongitude: number,
  zoom: number,
  onMapClick: any => void,
  fullWidth?: boolean,
  language: string,
  onIdle: any => void,
};

const FormFieldMap = ({
  label,
  latitude,
  longitude,
  centerLatitude,
  centerLongitude,
  zoom,
  onMapClick,
  fullWidth,
  meta,
  language,
  onIdle,
}: Props) => {
  const classes = cx('c-form-group', {
    'has-error': meta.touched && meta.error,
  });

  return (
    <div className={classes}>
      <Label htmlFor="map" className="u-gap-bottom-xsmall">
        {label}
      </Label>

      <div className={fullWidth ? 'c-map' : ''}>
        <Map
          containerElement={<div style={{ height: '300px' }} />}
          mapElement={<div style={{ height: '300px' }} />}
          marker={
            latitude && longitude
              ? {
                  latitude: latitude || 38.9637,
                  longitude: longitude || 35.2433,
                }
              : null
          }
          center={{
            latitude: centerLatitude || 38.9637,
            longitude: centerLongitude || 35.2433,
          }}
          zoom={zoom}
          onClick={onMapClick}
          language={language}
          onIdle={onIdle}
        />
      </div>
      {meta.touched && meta.error && <span className="c-form-group__alert">{meta.error}</span>}
    </div>
  );
};

export default FormFieldMap;
