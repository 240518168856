// @flow

const DEFAULT_MESSAGE = 'Lütfen geçerli bir değer giriniz.';

const required = (message: string = DEFAULT_MESSAGE) => (value?: string) => {
  if (!value) {
    return undefined;
  }

  return /^-?[0-9]+$/.test(value || '') ? undefined : message;
};

export default required;
