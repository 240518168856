// @flow

import React from 'react';
import cx from 'classnames';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { JobStatusMap } from '../../../enums/jobStatus';
import { experienceToText } from '../../../utils/experience';
import { age } from '../../../utils/age';

import RemainingTime from '../../../containers/RemainingTime';

import Row from '../../../components/layout/Row';
import Col from '../../../components/layout/Col';
import CircleImage from '../../../components/elements/CircleImage';
import Badge from '../../../components/ui/Badge';
import ShortListToggle from '../../../containers/ShortListToggle';

import { type Application } from '../../../types/application';
import { type Job } from '../../../types/job';
import ApplicantMatchScore from '../../../components/application/ApplicantMatchScore';

type Props = {
  job: Job,
  isActive?: boolean,
  application: Application,
  onClick: () => void,
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
const ApplicationItem = ({ job, application, isActive, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={cx('c-box__clickable', { 'is-active': isActive })} onClick={onClick}>
      <div className="u-pad-sides-small u-pad-ends-small">
        <Row className="u-flex-align-middle">
          <Col md={8}>
            <div className="u-flex u-flex-align-middle">
              <CircleImage
                src={application.user.imageUrl}
                badge={
                  application.unreadWorkerMessageCount > 0
                    ? application.unreadWorkerMessageCount
                    : undefined
                }
              />
              <div className="u-gap-left">
                <h5 className="u-clear-gap u-gap-bottom-xsmall u-font-weight-bold u-color-dark">
                  {application.user.firstName} {application.user.lastName}{' '}
                  {application.user.birthDate && `(${age(application.user.birthDate.toDate())})`}{' '}
                  {!application.isSeen && (
                    <Badge type="success" round small>
                      {t('jobDetail.new')}
                    </Badge>
                  )}
                  {application.isShortListedByAnother && (
                    <Badge small round type="warn">
                      {t('jobDetail.inShortList')}
                    </Badge>
                  )}
                </h5>
                <div className="u-equalize-line-height">
                  {!application.isShortListed && (
                    <RemainingTime startDate={application.createdAt} />
                  )}
                  <div className="u-gap-bottom-xsmall">
                    <span className="u-font-size-sm">
                      {`${t('jobDetail.experience')}: ${experienceToText(
                        application.user.experiences,
                        t,
                      )}`}
                    </span>
                  </div>
                  {application.user.location && (
                    <div className="u-gap-bottom-xsmall">
                      <span className="u-font-size-sm">{`
                        ${t('jobDetail.address')}: ${application.user.location.name}${' '}
                        ${application.user.distance && `- ${application.user.distance}`}
                        `}</span>
                    </div>
                  )}
                  <div className="u-gap-bottom-xsmall">
                    <span className="u-font-size-sm">
                      {`${t('jobDetail.applicationDate')}: ${moment(application.createdAt).format(
                        'DD/MM/YYYY',
                      )}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            {application.matchScore && (
              <div className="u-text-center u-gap-bottom-xsmall">
                <ApplicantMatchScore percent={application.matchScore} />
              </div>
            )}
            {job.status === JobStatusMap.ACTIVE && (
              <ShortListToggle jobId={job.id} application={application} />
            )}
          </Col>
        </Row>
      </div>
      <hr className="u-hr-line" />
    </div>
  );
};

export default ApplicationItem;
