// @flow

import React from 'react';

import Icon from '../Icon';

type Props = {
  /** extra className */
  className?: string,

  /** if small */
  small?: boolean,

  /** Image Url */
  src?: string,

  /** if image is editable */
  editable?: boolean,

  /** if has badge */
  badge?: number,
};

/**
 * Circle Image Element
 */
const CircleImage = ({
  className = '',
  src = '',
  small = false,
  editable = false,
  badge,
  ...otherProps
}: Props) => {
  const classNames = ['c-circle-image'];

  if (small) {
    classNames.push('c-circle-image--small');
  }

  if (editable) {
    classNames.push('c-circle-image--editable');
  }

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    <span className={classNames.join(' ')} {...otherProps}>
      <span className="c-circle-image__imgContainer">
        <img className="c-circle-image__img" src={src} alt="" />
      </span>
      {editable && <Icon name="edit" className="c-circle-image__icon" />}
      {!!badge && <span className="c-circle-image__badge">{badge}</span>}
    </span>
  );
};

export default CircleImage;
