// @flow

export const Status = {
  INIT: 1,
  LOADING: 2,
  LOADED: 3,
  FAILED: 4,
};

export type StatusType = 1 | 2 | 3 | 4;

export function isInit(state: { +status: StatusType }): boolean {
  return Status.INIT === state.status;
}

export function isLoaded(state: { +status: StatusType }): boolean {
  return Status.LOADED === state.status;
}

export function isFailed(state: { +status: StatusType }): boolean {
  return Status.FAILED === state.status;
}

export function isLoading(state: { +status: StatusType }): boolean {
  return Status.LOADING === state.status;
}
