// @flow

import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import moment from 'moment-timezone';
import 'moment/locale/tr';
import 'moment/locale/es';
import 'moment-duration-format';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';

import { setAuthorizationHeader } from './utils/api';
import { setAuthorizationHeader as setNewAuthorizationHeader } from './utils/apiNew';
import auth from './services/auth';
import fcm from './services/fcm';
import Facebook from './services/analytics/providers/facebook';
import configureStore from './redux/configureStore';
import { sync } from './redux/modules/currentUser';
import { loadThunk as loadHolidays } from './redux/modules/holidays';

import App from './App';

import './index.css';
import './config/i18n';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: 1.0,
});

fcm.init();

moment.locale('tr');

const currentUser = auth.getCurrentUser() || {};
const initialState = { currentUser };

if (currentUser.accessToken) {
  setAuthorizationHeader(currentUser.accessToken);
  setNewAuthorizationHeader(currentUser.accessToken);
}

const history = createHistory();
const store = configureStore(initialState, history);

store.dispatch(loadHolidays());
if (currentUser.accessToken) {
  store.dispatch(sync());
}

const render = Component => {
  const root = document.getElementById('root');

  if (!root) {
    return;
  }

  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Component />
        </ConnectedRouter>
      </Provider>
    </BrowserRouter>,
    root,
  );
};

/* istanbul ignore if */
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

Facebook.initFacebookSdk().then(() => render(App));
