// @flow

import React from 'react';

import { type BranchesState } from '../../redux/modules/branches';

import Container from '../../components/layout/Container';
import Icon from '../../components/elements/Icon';
import Button from '../../components/ui/Button';

import NoData from './NoData';
import ListItem from './ListItem';

import { type Company } from '../../types/user';
import { themePalette } from '../../theme/index';
import useTranslator from '../../hooks/useTranslator';

type Props = {
  branches: BranchesState,
  onCreateClick: () => void,
  onEditClick: (Company) => void,
  onRemoveClick: (Company) => void,
};

const List = ({ branches, onCreateClick, onEditClick, onRemoveClick }: Props) => {
  if (branches.data.length === 0) {
    return <NoData onCreateClick={onCreateClick} />;
  }

  const { t } = useTranslator();

  return (
    <div className="u-pad-ends@md-up u-pad-ends-small@sm-down">
      <Container>
        <div className="u-hidden@md-up u-gap-bottom">
          <Button
            primary
            className="u-full-width"
            onClick={onCreateClick}
            style={{
              backgroundColor: themePalette.primary_color,
              boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
            }}
          >
            <Icon name="add" className="u-gap-right" />
            <span>{t('layout.navBar.addBranch')}</span>
          </Button>
        </div>

        {branches.data.map((branch) => (
          <ListItem
            key={branch.id}
            branch={branch}
            onEditClick={() => onEditClick(branch)}
            onRemoveClick={() => onRemoveClick(branch)}
          />
        ))}
      </Container>
    </div>
  );
};

export default List;
