// @flow

import apiNew from '../utils/apiNew';

import { CategoryMapper } from '../mappers/category';
import { type Category } from '../types/category';

// eslint-disable-next-line import/prefer-default-export
export const list = async (): Promise<Array<Category>> => {
  const response = await apiNew.get('/job/job-categories/datalist');

  if (!response.ok) {
    throw response.data;
  }

  return CategoryMapper.fromAPIResponseMultiple(response.data.payload);
};
