// @flow

const clearedStyles = {
  control: () => null,
  valueContainer: () => null,
  singleValue: (base: any) => ({
    ...base,
    color: null,
  }),
  placeholder: () => null,
  dropdownIndicator: () => null,
  clearIndicator: (base: any) => ({
    ...base,
    color: null,
    transition: null,
    padding: null,
    ':hover': {
      color: null,
    },
  }),
  loadingIndicator: (base: any) => ({
    ...base,
    color: null,
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    backgroundColor: null,
    transition: null,
    marginTop: null,
    marginBottom: null,
  }),
  option: (base: any) => ({
    ...base,
    color: null,
    padding: null,
    backgroundColor: null,
    fontSize: null,
    ':active': {
      backgroundColor: null,
    },
  }),
  menu: () => null,
  multiValueRemove: (base: any) => ({
    ...base,
    fontSize: null,
    color: null,
    ':hover': {
      backgroundColor: null,
      color: null,
    },
  }),
};

export default clearedStyles;
