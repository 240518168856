// @flow
export default {
  layout: {
    header: {
      title: 'Bonded',
      jobs: 'Jobs',
      branches: 'Branches',
      myProfile: 'My profile',
      candidatePool: 'Candidate pool',
      messages: 'Messages',
      logout: 'Log out',
      applications: 'Applications',
    },
    navbar: {
      title: 'All jobs',
      addJob: 'Create a jobs',
      update: 'Edit',
      excelReport: 'Export applications to excel',
      addBranch: 'Add Branch',
    },
  },
};
