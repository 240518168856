// @flow

import moment from 'moment-timezone';

import api from '../utils/api';

// eslint-disable-next-line import/prefer-default-export
export const list = async (): Promise<moment[]> => {
  const response = await api.get('/holidays');

  if (!response.ok) {
    throw response.data;
  }

  return (response.data.payload.dates || []).map(date => moment(date, 'YYYY.MM.DD'));
};
