// @flow
export default {
  payment: {
    error: {
      paymentErrorMessage: 'Ödeme tamamlanırken bir hata oluştu.',
      cardDeclined: 'Kart, işleme izin vermedi',
      expiredCard: 'Vadesi dolmuş kart',
      incorrectCvc: '	CVC2 bilgisi hatalı',
      incorrectNumber: 'Geçersiz kart numarası',
      invalidExpiryMonth: 'Son kullanma tarihi geçersiz',
      invalidExpiryYear: 'Son kullanma tarihi geçersiz',
      invalidNumber: 'Geçersiz kart numarası',
      insufficientFunds: 'Kart limiti yetersiz, yetersiz bakiye',
      invalidExpiryDate: 'Son kullanma tarihi geçersiz',
    },
    success: {
      paymentSuccessMessage: 'Ödemeniz başarıyla işlendi',
    },
    paymentResult: {
      success: 'Başarılı',
      failure: 'Başarısız',
    },
    stripeForm: {
      pay: 'Öde',
      payWithAmount: '{{amount}} ÖDE',
    },
    input: {
      cardHolderName: {
        required: "Kart Sahibi Adı gereklidir",
        enterValid: "Geçerli bir kart sahibi adı girin",
        placeholder: "Kart Sahibi Adı"
      },
      cardNumber: {
        required: "Kart Numarası gereklidir",
        enterValid: "Geçerli bir kart numarası girin",
        placeholder: "Kart Numarası"
      },
      expiryDate: {
        required: "Son Kullanma Tarihi gereklidir",
        enterValid: "Geçerli bir son kullanma tarihi girin",
        placeholder: "AA/YY"
      },
      cvc: {
        required: "CVC gereklidir",
        enterValid: "Geçerli bir CVC girin",
        placeholder: "CVC"
      }
    },
    general: {
      reachedPurchaseLimit: "Satın alma sınırına ulaşıldı",
      anErrorOccuredTryAgain: "Bir hata oluştu. Lütfen tekrar deneyin",
      noAddonForSelectedPackage: "Seçilen paket için eklenti yok",
      anErrorOccuredWhileLoadingAddons: "Eklentiler yüklenirken bir hata oluştu. Lütfen sayfayı yenileyin.",
      backToPreviousPage: "< Önceki Sayfaya Dön",
      yourCart: "Sepetiniz",
      additionalProducts: "Ek Ürünler",
      proceedToCheckout: "Ödeme işlemine devam et",
      pay: "ÖDE",
      cardDetails: "Kart Detayları",
      completePayment: "Ödemeyi Tamamla",
    }
  },
};
