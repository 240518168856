// @flow
export default {
  auth: {
    loginForm: {
      title: 'MEMBER LOGIN',
      email: { label: 'E-mail', required: 'Please Enter Your Company Name' },
      password: {
        label: 'Password',
        required: 'Please Enter Your Password.',
        minLength: 'Minimum 6 Characters.',
        maxLength: 'Maximum 15 Characters.',
      },
      register: 'Register',
      forgotPassword: 'Forgot My Password',
      login: 'LOGIN',
    },
    login: {
      error: {
        submissionError: {
          password: 'Please Check Your Username And Password.',
        },
      },
    },
  },
};
