// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import LinkButton from '../../components/ui/LinkButton';
import { type AuthenticatedUser } from '../../types/user';
import Header from '../../components/shared/Header';
import Icon from '../../components/elements/Icon';
import Navbar from './Navbar';
import useTranslator from '../../hooks/useTranslator';
import { themePalette } from '../../theme';
import success from '../../img/icons/status/success.svg';
import failure from '../../img/icons/status/failure.svg';
import Analytics from '../../services/analytics';
import { PaymentErrorMap } from '../../enums/paymentError';

type Props = {
  currentUser: AuthenticatedUser,
  location: Object,
};

// TODO: package_id will be added
const logPackagePurchased = async () => {
  // const result = await getPackagesList();

  // const purchasedPackage = result.find(pkg => pkg.id === packageId);

  // if (!purchasedPackage) {
  //   return;
  // }

  // Analytics.companyPurchasedPackage(packageId, purchasedPackage.currency, purchasedPackage.price);
  Analytics.companyPurchasedPackage();
};

const PaymentResult = ({ currentUser, location }: Props) => {
  const { t } = useTranslator();
  const { search } = location;
  const [errorMessage, setErrorMessage] = useState('');
  const query = new URLSearchParams(search);
  const isSuccess = query.get('status') === 'success';
  const errorKey = query.get('errorKey');

  useEffect(() => {
    if (!isSuccess) {
      if (Object.keys(PaymentErrorMap).includes(errorKey)) {
        setErrorMessage(`payment.error.${PaymentErrorMap[errorKey]}`);
        return;
      }

      setErrorMessage('payment.error.paymentErrorMessage');
    }
  }, [])

  return (
    <div id="successfulPaymentMessage">
      <Header currentUser={currentUser} />
      <Navbar currentUser={currentUser} />
      <div className="u-pad-ends-large u-text-center">
        <img
          src={isSuccess ? success : failure}
          width={270}
          height={150}
          alt={isSuccess ? t('payment.paymentResult.success') : t('payment.paymentResult.failure')}
        />
        <h3 className="u-color-gull-gray u-gap-bottom-medium">
          {isSuccess
            ? t('payment.success.paymentSuccessMessage')
            : t(errorMessage)}
        </h3>

        <LinkButton
          primary
          to={isSuccess ? '/jobs/create' : '/packages'}
          style={{
            backgroundColor: themePalette.primary_color,
            boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
          }}
        >
          {isSuccess ? <Icon name="add" className="u-gap-right-small" /> : null}
          <span>{isSuccess ? t('jobs.addJob') : t('common.glossary.tryAgain')}</span>
        </LinkButton>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(PaymentResult);
