// @flow

import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';

import CheckoutForm from './CheckoutForm';
import { type AuthenticatedUser } from '../../../../types/user';

type Props = {
  secret: string,
  currentUser: AuthenticatedUser,
  paymentAmountText: string | undefined,
};

const StripePayment = ({ secret, currentUser, paymentAmountText }: Props) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (currentUser && !stripePromise) {
      const { language } = currentUser.user;
      setStripePromise(
        loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
          locale: language ? language.languageCode : 'en',
        }),
      );
    }
  }, [currentUser]);

  if (!stripePromise) {
    return <div />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: secret,
      }}
    >
      <CheckoutForm paymentAmountText={paymentAmountText} />
    </Elements>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(StripePayment);
