// @flow

export const ExperienceDurationMap = {
  ALL: 0,
  ONE_MONTH: 1,
  TWO_MONTHS: 2,
  THREE_MONTHS: 3,
  FOUR_MONTHS: 4,
  FIVE_MONTHS: 5,
  SIX_MONTHS: 6,
  SEVEN_MONTHS: 7,
  EIGHT_MONTHS: 8,
  NINE_MONTHS: 9,
  TEN_MONTHS: 10,
  ELEVEN_MONTHS: 11,
  ONE_YEAR: 12,
  TWO_YEARS: 24,
  THREE_YEARS: 36,
  FOUR_YEARS: 48,
  FIVE_YEARS: 60,
  SIX_YEARS: 72,
  SEVEN_YEARS: 84,
  OVER_SEVEN_YEARS: 96,
};

export const ExperienceDurationList = [
  { key: ExperienceDurationMap.ALL, value: 'Tecrübesiz' },
  { key: ExperienceDurationMap.ONE_MONTH, value: '1 ay' },
  { key: ExperienceDurationMap.TWO_MONTHS, value: '2 ay' },
  { key: ExperienceDurationMap.THREE_MONTHS, value: '3 ay' },
  { key: ExperienceDurationMap.FOUR_MONTHS, value: '4 ay' },
  { key: ExperienceDurationMap.FIVE_MONTHS, value: '5 ay' },
  { key: ExperienceDurationMap.SIX_MONTHS, value: '6 ay' },
  { key: ExperienceDurationMap.SEVEN_MONTHS, value: '7 ay' },
  { key: ExperienceDurationMap.EIGHT_MONTHS, value: '8 ay' },
  { key: ExperienceDurationMap.NINE_MONTHS, value: '9 ay' },
  { key: ExperienceDurationMap.TEN_MONTHS, value: '10 ay' },
  { key: ExperienceDurationMap.ELEVEN_MONTHS, value: '11 ay' },
  { key: ExperienceDurationMap.ONE_YEAR, value: '1 yıl' },
  { key: ExperienceDurationMap.TWO_YEARS, value: '2 yıl' },
  { key: ExperienceDurationMap.THREE_YEARS, value: '3 yıl' },
  { key: ExperienceDurationMap.FOUR_YEARS, value: '4 yıl' },
  { key: ExperienceDurationMap.FIVE_YEARS, value: '5 yıl' },
  { key: ExperienceDurationMap.SIX_YEARS, value: '6 yıl' },
  { key: ExperienceDurationMap.SEVEN_YEARS, value: '7 yıl' },
  { key: ExperienceDurationMap.OVER_SEVEN_YEARS, value: '7 yıldan fazla' },
];

export const ExperienceDurationDefault = ExperienceDurationList[0];
