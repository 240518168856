// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';

import { withTranslation } from 'react-i18next';
import {
  loadThunk,
  createItemThunk,
  editItemThunk,
  deleteItemThunk,
  type BranchesState,
} from '../../redux/modules/branches';
import formErrorMapper from '../../utils/formErrorMapper';
import { isLoading, isFailed } from '../../utils/apiState';

import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from '../../redux/modules';
import { type AuthenticatedUser, type Company } from '../../types/user';

import Confirm from '../../components/elements/Confirm';
import Header from '../../components/shared/Header';
import List from './List';
import Navbar from './Navbar';
import Loading from './Loading';
import BranchModal from './BranchModal';

type Props = {
  branches: BranchesState,
  currentUser: AuthenticatedUser,
  loadThunk: () => void,
  createItemThunk: Object => void,
  editItemThunk: (id: number, data: Object) => void,
  deleteItemThunk: number => void,
  push: Function,
  t: Function,
};

type State = {
  isBranchModalOpen: boolean,
  currentBranch: Company | null,
};

class Branches extends PureComponent<Props, State> {
  props: Props;

  state: State;

  handleBranchModalSubmit: Company => void;

  openBranchModal: (Company | null) => void;

  closeBranchModal: () => void;

  handleRemove: Company => void;

  confirmDialog: any;

  constructor(props: Props) {
    super(props);

    this.state = { isBranchModalOpen: false, currentBranch: null };

    this.handleBranchModalSubmit = this.handleBranchModalSubmit.bind(this);
    this.openBranchModal = this.openBranchModal.bind(this);
    this.closeBranchModal = this.closeBranchModal.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentWillMount() {
    this.props.loadThunk();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (isFailed(nextProps.branches)) {
      this.props.push('/error');
    }
  }

  async handleBranchModalSubmit(values: Company) {
    try {
      if (this.state.currentBranch === null) {
        await this.props.createItemThunk(values);
      } else {
        await this.props.editItemThunk(
          this.state.currentBranch ? this.state.currentBranch.id : 0,
          values,
        );
      }

      this.closeBranchModal();
    } catch (err) {
      if (err.code && err.code === 400) {
        throw new SubmissionError(formErrorMapper(err.errors.children));
      }

      this.props.push('/error');
    }
  }

  openBranchModal(branch: Company | null) {
    this.setState({ isBranchModalOpen: true, currentBranch: branch });
  }

  closeBranchModal() {
    this.setState({ isBranchModalOpen: false, currentBranch: null });
  }

  handleRemove(branch: Company) {
    const { t } = this.props;

    this.confirmDialog.show(t('branches.dialogRemoveMessage'), {
      onConfirm: () => {
        this.props.deleteItemThunk(branch.id);
      },
    });
  }

  render() {
    const { branches, currentUser, t } = this.props;

    return (
      <div>
        <Header currentUser={currentUser} />
        <Navbar
          currentUser={currentUser}
          onButtonClick={() => {
            this.openBranchModal(null);
          }}
        />

        {isLoading(branches) ? (
          <Loading />
        ) : (
          <List
            branches={branches}
            onCreateClick={() => {
              this.openBranchModal(null);
            }}
            onEditClick={branch => {
              this.openBranchModal(branch);
            }}
            onRemoveClick={this.handleRemove}
          />
        )}

        <BranchModal
          isOpen={this.state.isBranchModalOpen}
          branch={this.state.currentBranch}
          onModalCloseRequested={this.closeBranchModal}
          handleSubmit={this.handleBranchModalSubmit}
        />

        <Confirm
          ref={ref => {
            this.confirmDialog = ref;
          }}
          confirmLabel={t('common.glossary.continue')}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  branches: state.branches,
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    { loadThunk, createItemThunk, editItemThunk, deleteItemThunk, push },
    dispatch,
  );

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Branches));
