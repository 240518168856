// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';
import { withTranslation } from 'react-i18next';

import {
  loadThunk,
  deleteItemThunk,
  createItemThunk,
  editItemThunk,
  type HRListState,
} from '../../redux/modules/hrList';
import { isLoading, isFailed } from '../../utils/apiState';
import formErrorMapper from '../../utils/formErrorMapper';
import { themePalette } from '../../theme/index';

import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from '../../redux/modules';
import { type HR } from '../../types/user';

import HRModal from './HRModal';
import Confirm from '../../components/elements/Confirm';
import Box from '../../components/elements/Box';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Icon from '../../components/elements/Icon';
import Button from '../../components/ui/Button';
import Loading from '../../components/elements/Loading';

import noHr from '../../img/empty-state/no-hr.svg';

type Props = {
  hrList: HRListState,
  loadThunk: () => void,
  deleteItemThunk: (number) => void,
  createItemThunk: (Object) => void,
  editItemThunk: (id: number, data: Object) => void,
  push: Function,
  t: Function,
};

type State = {
  isHRModalOpen: boolean,
  currentPerson: HR | null,
};

class HRList extends PureComponent<Props, State> {
  props: Props;

  state: State;

  handlePersonDelete: (number) => void;

  handleHRModalSubmit: (HR) => void;

  openHRModal: (HR | null) => void;

  closeHRModal: () => void;

  confirmDialog: any;

  constructor(props: Props) {
    super(props);

    this.state = { isHRModalOpen: false, currentPerson: null };

    this.handlePersonDelete = this.handlePersonDelete.bind(this);
    this.handleHRModalSubmit = this.handleHRModalSubmit.bind(this);
    this.openHRModal = this.openHRModal.bind(this);
    this.closeHRModal = this.closeHRModal.bind(this);
  }

  componentWillMount() {
    this.props.loadThunk();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (isFailed(nextProps.hrList)) {
      this.props.push('/error');
    }
  }

  handlePersonDelete(id: number, { t }: Props) {
    this.confirmDialog.show(t('profile.hrList.alerts.isSureRemoveThatHR'), {
      onConfirm: () => {
        this.props.deleteItemThunk(id);
      },
    });
  }

  async handleHRModalSubmit(values: HR) {
    try {
      if (this.state.currentPerson === null) {
        await this.props.createItemThunk(values);
      } else {
        await this.props.editItemThunk(
          this.state.currentPerson ? this.state.currentPerson.id : 0,
          values,
        );
      }

      this.closeHRModal();
    } catch (err) {
      if (err.code && err.code === 400) {
        throw new SubmissionError(formErrorMapper(err.errors.children));
      }

      this.props.push('/error');
    }
  }

  openHRModal(person: HR | null) {
    this.setState({ isHRModalOpen: true, currentPerson: person });
  }

  closeHRModal() {
    this.setState({ isHRModalOpen: false, currentPerson: null });
  }

  render() {
    const { hrList, t } = this.props;

    if (isLoading(hrList)) {
      return (
        <Box>
          <ul className="c-tab-list">
            <li className="c-tab-list__item">
              <a
                className="c-tab-list__link"
                style={{ color: themePalette.color, borderColor: themePalette.primary_color }}
              >
                {t('profile.hrList.detail.title')}
              </a>
            </li>
          </ul>

          <Loading large />
        </Box>
      );
    }

    return (
      <div>
        <Box>
          <ul className="c-tab-list">
            <li className="c-tab-list__item">
              <a
                className="c-tab-list__link"
                style={{ color: themePalette.color, borderColor: themePalette.primary_color }}
              >
                {t('profile.hrList.detail.title')}
              </a>
            </li>
          </ul>

          {hrList.data.length === 0 && (
            <div className="u-text-center u-pad-ends">
              <img
                src={noHr}
                width={270}
                height={150}
                alt={t('profile.hrList.detail.noHR.description')}
              />
              <h3 className="u-color-gull-gray u-gap-bottom-medium">
                {t('profile.hrList.detail.noHR.description')}
              </h3>

              <Button
                primary
                onClick={() => {
                  this.openHRModal(null);
                }}
                style={{
                  backgroundColor: themePalette.primary_color,
                  color: '#fff',
                  boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                }}
              >
                <Icon name="add" className="u-gap-right-small" />
                <span>{t('profile.hrList.subTitles.addUser')}</span>
              </Button>
            </div>
          )}

          {hrList.data.map((person) => (
            <div key={person.id}>
              <div className="u-pad-sides-small">
                <Row className="u-flex-align-middle">
                  <Col className="col--xs">
                    <Row className="u-flex-align-middle">
                      <Col sm={6}>
                        <span>
                          {person.firstName} {person.lastName}
                        </span>
                      </Col>
                      <Col sm={6}>
                        <span className="c-label">{person.email}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="u-free-width u-gap-top-small u-gap-bottom-small">
                    <Button
                      ghost
                      small
                      className="u-gap-right-small"
                      onClick={() => {
                        this.openHRModal(person);
                      }}
                    >
                      <Icon name="edit" />
                    </Button>
                    <Button
                      primary
                      small
                      onClick={() => {
                        this.handlePersonDelete(person.id);
                      }}
                      style={{
                        backgroundColor: themePalette.primary_color,
                        boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                      }}
                    >
                      <Icon name="close" />
                    </Button>
                  </Col>
                </Row>
              </div>
              <hr className="u-hr-line" />
            </div>
          ))}

          {hrList.data.length !== 0 && (
            <div className="u-pad-ends-small u-pad-sides-small u-text-right">
              <Button
                primary
                onClick={() => {
                  this.openHRModal(null);
                }}
                style={{
                  backgroundColor: themePalette.primary_color,
                  color: '#fff',
                  boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                }}
              >
                <Icon name="add" className="u-gap-right-small" />
                <span>{t('profile.hrList.subTitles.addUser')}</span>
              </Button>
            </div>
          )}

          <Confirm
            ref={(ref) => {
              this.confirmDialog = ref;
            }}
            confirmLabel={t('common.glossary.continue')}
          />

          <HRModal
            isOpen={this.state.isHRModalOpen}
            person={this.state.currentPerson}
            onModalCloseRequested={this.closeHRModal}
            handleSubmit={this.handleHRModalSubmit}
          />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  hrList: state.hrList,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    { loadThunk, deleteItemThunk, createItemThunk, editItemThunk, push },
    dispatch,
  );

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HRList));
