// @flow
export default {
  branches: {
    dialogRemoveMessage: 'Are You Sure You Want To Delete This Branch?',
    editBranch: 'EDIT BRANCH',
    addBranch: 'ADD NEW BRANCH',
    branchNotExist: 'There are no branches.',
    noBranch: 'No branches',
    companyName: {
      label: 'Branch Name',
      required: 'Please Enter Branch Name.',
    },
    firstName: {
      label: 'First Name',
      required: 'Please Enter Your First Name.',
    },
    lastName: {
      label: 'Last Name',
      required: 'Please Enter Your Last Name.',
    },
    email: {
      label: 'User Email',
      required: 'Please Enter the Email Of The User.',
      information: '* We Will Send An Email For the User To Create A Password.',
    },
    taxOffice: {
      label: 'Tax Office',
      placeholder: 'Please Select',
    },
    taxOfficeNo: {
      label: 'Tax ID No',
    },
    commercialTitle: {
      label: 'Commercial Title',
    },
    website: {
      label: 'Web Site',
    },
    companyAddress: {
      label: 'Detailed Address',
    },
    locationSearch: {
      label: 'Search Location',
    },
    locationPosition: {
      label: 'Pick A Location',
      required: 'Please Pick A Location.',
    },
    inbox: {
      totalMessages: 'Number of messages',
      totalUnreadMessages: 'Unread messages',
    },
  },
};
