// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { UserTypeMap } from '../../enums/userType';

import { type ReduxState } from '../../redux/modules';
import { type AuthenticatedUser } from '../../types/user';

import Detail from './Detail';
import Edit from './Edit';
import HRList from './HRList';

import Header from '../../components/shared/Header';
import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';

type Props = {
  currentUser: AuthenticatedUser,
};

const RootProfile = () => (
  <Row>
    <Col md={6}>
      <Route exact path="/profile" component={() => <Detail editable />} />
      <Route exact path="/profile/edit" component={Edit} />
      <Route render={() => <Redirect to="/profile" />} />
    </Col>
    <Col md={6}>
      <HRList />
    </Col>
  </Row>
);

const OtherProfile = ({ currentUser }: Props) => (
  <Row>
    <Col md={6} className="col--offset-md-3">
      <Route
        exact
        path="/profile"
        component={() => <Detail editable={currentUser.user.userType === UserTypeMap.COMPANY} />}
      />
      {currentUser.user.userType === UserTypeMap.COMPANY && (
        <Route exact path="/profile/edit" component={Edit} />
      )}
    </Col>
  </Row>
);

const Profile = ({ currentUser }: Props) => (
  <div>
    <Header currentUser={currentUser} />
    <section className="u-pad-ends-small">
      <Container>
        {currentUser.user.userType === UserTypeMap.ROOT && <RootProfile />}
        {currentUser.user.userType !== UserTypeMap.ROOT && (
          <OtherProfile currentUser={currentUser} />
        )}
      </Container>
    </section>
  </div>
);

const mapStateToProps = (state: ReduxState): Object => ({
  currentUser: state.currentUser,
});

// $FlowFixMe
export default connect(mapStateToProps)(Profile);
