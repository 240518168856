// @flow

import { push } from 'react-router-redux';
import moment from 'moment';
import {
  login as loginRequest,
  register as registerRequest,
  profileUpdate as profileUpdateRequest,
  me as syncRequest,
  resetPassword as resetPasswordRequest,
  forgetPassword as forgetPasswordRequest,
  resetPasswordValidToken as resetPasswordTokenIsValidRequest,
} from '../../api/auth';
import Auth from '../../services/auth';
import FCMTokenStorage from '../../services/fcm/tokenStorage';
import { AuthenticatedUserMapper } from '../../mappers/user';
import { setAuthorizationHeader, clearAuthorizationHeader } from '../../utils/api';
import {
  setAuthorizationHeader as setNewAuthorizationHeader,
  clearAuthorizationHeader as clearNewAuthorizationHeader,
} from '../../utils/apiNew';

import { type AuthenticatedUser } from '../../types/user';
import { type ReduxDispatch } from '../../types/redux';
import i18n from '../../config/i18n';
import Analytics from '../../services/analytics';

// Actions
export const LOGIN = 'currentUser/LOGIN';
export const LOGOUT = 'currentUser/LOGOUT';
export const UPDATE = 'currentUser/UPDATE';

// Action Creator Types
type LoginAction = {
  type: typeof LOGIN,
  user: AuthenticatedUser,
};

type LogoutAction = {
  type: typeof LOGOUT,
};

type UpdateAction = {
  type: typeof UPDATE,
  user: AuthenticatedUser,
};

export type CurrentUserActions = LoginAction | LogoutAction | UpdateAction;

// Thunks
export const login = (email: string, password: string): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  try {
    const user = await loginRequest({ email, password });
    const { language } = user.user;

    setAuthorizationHeader(user.accessToken);
    setNewAuthorizationHeader(user.accessToken);

    dispatch(({ type: LOGIN, user }: LoginAction));
    Auth.login(user);

    if (language) {
      i18n.changeLanguage(language.languageCode).then(() => {
        const langAndCountry = language.languageCode.split('-');
        const lang = langAndCountry[0];
        moment.locale(lang);
        document.querySelector('html').lang = lang;
      });
    }

    // this methods depends on Auth.isAuthenticated
    // so it needs to be called after Auth.login
    FCMTokenStorage.saveCachedToServer();

    Analytics.companyLoggedIn(user.user.country.id);
  } catch (error) {
    throw error;
  }
};

export const resetPassword = (
  resetPasswordCode: string,
  firstPassword: string,
  secondPassword: string,
  // eslint-disable-next-line consistent-return
): Function => async (): Promise<*> => {
  try {
    const resetPasswordResult = await resetPasswordRequest(resetPasswordCode, {
      firstPassword,
      secondPassword,
    });
    return resetPasswordResult;
  } catch (error) {
    // eslint-disable-next-line no-console
    throw error;
  }
};

export const sendEmail = (
  email: string,
  // eslint-disable-next-line consistent-return
): Function => async (): Promise<*> => {
  try {
    const sendEmailResult = await forgetPasswordRequest({ email });
    return sendEmailResult;
  } catch (error) {
    // eslint-disable-next-line no-console
    throw error;
  }
};

export const resetPasswordTokenIsValid = (
  resetPasswordCode: string,
  // eslint-disable-next-line consistent-return
): Function => {
  return async (): Promise<*> => {
    return resetPasswordTokenIsValidRequest(resetPasswordCode);
  };
};

export const logout = (): Function => (dispatch: ReduxDispatch) => {
  FCMTokenStorage.clearServer();
  clearAuthorizationHeader();
  clearNewAuthorizationHeader();
  dispatch(({ type: LOGOUT }: LogoutAction));
  Auth.logout();
};

export const profileUpdate = (data: Object, formViewData?: Object): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  const formData = AuthenticatedUserMapper.toAPIRequest(data, formViewData);

  const user = await profileUpdateRequest(formData);

  setAuthorizationHeader(user.accessToken);
  setNewAuthorizationHeader(user.accessToken);
  dispatch(({ type: UPDATE, user }: UpdateAction));
  Auth.login(user);
};

export const register = (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  companyName: string,
  phone: string,
  location: string,
  country: number,
  phoneVerifyCode: string,
): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  const user = await registerRequest({
    email,
    password,
    firstName,
    lastName,
    companyName,
    phone,
    location,
    country,
    phoneVerifyCode,
  });

  setAuthorizationHeader(user.accessToken);
  setNewAuthorizationHeader(user.accessToken);

  dispatch(({ type: LOGIN, user }: LoginAction));
  Auth.login(user);

  // this methods depends on Auth.isAuthenticated
  // so it needs to be called after Auth.login
  FCMTokenStorage.saveCachedToServer();
  Analytics.companyRegistered(user.user.country.id, user.user.id);
};
export const sync = (): Function => async (dispatch: ReduxDispatch) => {
  try {
    const user = await syncRequest();
    const { language } = user.user;

    setAuthorizationHeader(user.accessToken);
    setNewAuthorizationHeader(user.accessToken);

    dispatch(({ type: LOGIN, user }: LoginAction));
    Auth.login(user);

    if (language) {
      i18n.changeLanguage(language.languageCode).then(() => {
        const langAndCountry = language.languageCode.split('-');
        const lang = langAndCountry[0];
        moment.locale(lang);
        document.querySelector('html').lang = lang;
      });
    }
  } catch (err) {
    Auth.logout();
    clearAuthorizationHeader();
    clearNewAuthorizationHeader();
    dispatch(({ type: LOGOUT }: LogoutAction));
    dispatch(push('/login'));
  }
};

export const updateUserAfterJobCreditChange = () => sync();

// Reducer
export type CurrentUserState = AuthenticatedUser;

const initialState = AuthenticatedUserMapper.fromAPIResponse({});

export default function reducer(
  state: CurrentUserState = initialState,
  action: CurrentUserActions,
): CurrentUserState {
  switch (action.type) {
    case LOGIN:
      return action.user;
    case LOGOUT:
      return initialState;
    case UPDATE:
      return action.user;
    default:
      return state;
  }
}
