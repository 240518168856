// @flow

export const WorkTimeMap = {
  full_time: 'Tam Zamanlı',
  part_time: 'Yarı Zamanlı',
  seasonal: 'Dönemsel',
};

export const WorkTimeList = {
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
  SEASONAL: 'seasonal',
};

export const WorkTimeMapDefault = WorkTimeMap.full_time;
