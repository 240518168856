// @flow

import React, { PureComponent } from 'react';
import { Route, type Match, type RouterHistory } from 'react-router-dom';

import { withTranslation, Trans } from 'react-i18next';
import { age as ageUtil } from '../../utils/age';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Infobox from '../../components/ui/Infobox';
import Icon from '../../components/elements/Icon';

import Applications from './applications';
import Profile from './profile';
import NoData from './NoData';

import { GenderMap } from '../../enums/gender';
import { MilitaryStatusMap } from '../../enums/militaryStatus';
import { EducationMap } from '../../enums/educationStatus';
import { EnglishLevelMap } from '../../enums/englishLevel';
import { ExperienceDurationList } from '../../enums/experienceDuration';

import { type JobDetailItemState } from '../../redux/modules/jobDetail';
import { type Application } from '../../types/application';
import { type Appointment } from '../../types/appointment';
import { Status } from '../../utils/apiState';
import Loading from '../../components/elements/Loading';

const tagValues = {
  gender: value => `Cinsiyet: ${GenderMap[value]}`,
  military: value => `Askerlik: ${MilitaryStatusMap[value]}`,
  age: value => `Yaş: ${value[0]}-${value[1]}`,
  education: value => `Eğitim: ${EducationMap[value]}`,
  englishLevel: value => `Askerlik: ${EnglishLevelMap[value]}`,
  distance: value => `Uzaklık: ${value}KM`,
  experience: value => {
    const exp = ExperienceDurationList.find(obj => obj.key === value);
    return exp ? `Tecürebe: ${exp.value}` : `Tecürebe: ${value}`;
  },
};

type Props = {
  jobDetail: JobDetailItemState,
  match: Match,
  history: RouterHistory,
  onReachEnd?: activeTab => void,
  loadingMore: boolean,
  allJobs: JobsState,
};

type State = {
  filter: Object,
};

class Detail extends PureComponent<Props, State> {
  props: Props;

  state: State = {
    filter: {
      gender: [],
      military: [],
      age: [],
      englishLevel: [],
      education: [],
      distance: [],
      experience: [],
    },
  };

  openApplicationDetail = (application: Application) => {
    this.props.history.push(`${this.props.match.url}/${application.id}`);
  };

  openAppointmentDetail = (appointment: Appointment) => {
    this.props.history.push(`${this.props.match.url}/${appointment.applicationId}/appointment`);
  };

  handleFilterApply = (filter: Object) => {
    this.setState({ filter });
  };

  handleRemove = (field: string, value: any, index: number) => {
    this.setState(state => {
      const obj = {};

      if (value) {
        const values = [...state.filter[field]];
        values.splice(index, 1);
        obj[field] = [...values];
      } else {
        obj[field] = [];
      }

      return { filter: { ...state.filter, ...obj } };
    });
  };

  filterApplication = (application: Application): boolean => {
    const { filter } = this.state;
    let passed = true;

    if (filter.gender && filter.gender.length) {
      passed = passed && filter.gender.includes(application.user.gender);
    }

    if (filter.military && filter.military.length) {
      passed = passed && filter.military.includes(application.user.military);
    }

    if (filter.age && filter.age.length) {
      const birthday = application.user.birthDate;
      const age = birthday ? ageUtil(birthday.toDate()) : 0;
      passed = passed && filter.age[0][0] <= age && age <= filter.age[0][1];
    }

    if (filter.englishLevel && filter.englishLevel.length) {
      passed = passed && filter.englishLevel.includes(application.user.english);
    }

    if (filter.education && filter.education.length) {
      passed = passed && filter.education.includes(application.user.education);
    }

    if (filter.distance && filter.distance.length) {
      passed = passed && application.user.distanceInt <= filter.distance[0];
    }

    if (filter.experience && filter.experience.length) {
      passed = passed && application.user.experienceMonth >= filter.experience[0];
    }

    return !!passed;
  };

  findSelectedJob = (selectedApplicationId, jobDetail) => {
    const selectedApplication = jobDetail.data.allApplications.find(
      app => app.id === selectedApplicationId,
    );

    if (selectedApplication.job && this.props.allJobs.data) {
      const selectedJob = this.props.allJobs.data.find(
        job => job.id === selectedApplication.job.id,
      );
      return selectedJob;
    }

    return undefined;
  };

  findSelectedJobId = (selectedApplicationId, jobDetail) => {
    const selectedApplication = jobDetail.data.allApplications.find(
      app => app.id === selectedApplicationId,
    );

    return selectedApplication ? selectedApplication.job.id : undefined;
  };

  tagValueRender = (field: string, value: any) => tagValues[field](value);

  render() {
    const { jobDetail, onReachEnd = () => {}, loadingMore, allJobs } = this.props;

    if (jobDetail.data.allApplications.length === 0) {
      return <NoData />;
    }

    const { job } = jobDetail.data;
    const { rejectedApplicationCount } = jobDetail.data;
    const jobId = job.id;
    const branchId = job.user.id;

    if (job.id === 0 && allJobs.status !== Status.LOADED) {
      return <Loading />;
    }

    return (
      <div className="u-pad-ends-small">
        <Container>
          <Row>
            <Col md={12}>
              {!!rejectedApplicationCount && (
                <Infobox className="u-gap-bottom" type="warning">
                  <Icon name="info" className="c-infobox__icon" />
                  <Trans i18nKey="jobDetail.rejectedApplicationCount">
                    <strong>{{ rejectedApplicationCount }}</strong>
                    <strong></strong>
                  </Trans>
                </Infobox>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Route path={`${this.props.match.url}/:applicationId`}>
                {({ match }: { match: Match }) => {
                  if (match && job.id === 0) {
                    const selectedApplicationId = +match.params.applicationId;

                    const selectedJob = this.findSelectedJob(selectedApplicationId, jobDetail);

                    return (
                      <Applications
                        job={selectedJob}
                        allApplications={jobDetail.data.allApplications.filter(
                          this.filterApplication,
                        )}
                        shortListedApplications={jobDetail.data.shortListedApplications.filter(
                          this.filterApplication,
                        )}
                        onApplicationClick={this.openApplicationDetail}
                        onAppointmentClick={this.openAppointmentDetail}
                        activeApplicationId={match ? +match.params.applicationId : null}
                        onReachEnd={onReachEnd}
                        loadingMore={loadingMore}
                        match={this.props.match}
                      />
                    );
                  }

                  return (
                    <Applications
                      job={jobDetail.data.job}
                      allApplications={jobDetail.data.allApplications.filter(
                        this.filterApplication,
                      )}
                      shortListedApplications={jobDetail.data.shortListedApplications.filter(
                        this.filterApplication,
                      )}
                      onApplicationClick={this.openApplicationDetail}
                      onAppointmentClick={this.openAppointmentDetail}
                      activeApplicationId={match ? +match.params.applicationId : null}
                      onReachEnd={onReachEnd}
                      loadingMore={loadingMore}
                      match={this.props.match}
                    />
                  );
                }}
              </Route>
            </Col>

            <Col md={6}>
              <Route
                path={`${this.props.match.url}/:applicationId`}
                render={({ match }: { match: Match }) => {
                  if (match && job.id === 0) {
                    const selectedApplicationId = +match.params.applicationId;

                    const selectedJobId = this.findSelectedJobId(selectedApplicationId, jobDetail);

                    return (
                      <Profile
                        jobId={selectedJobId}
                        branchId={branchId}
                        match={match}
                        jobMatch={this.props.match}
                      />
                    );
                  }

                  return (
                    <Profile
                      jobId={jobId}
                      branchId={branchId}
                      match={match}
                      jobMatch={this.props.match}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(Detail);
