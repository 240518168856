// @flow

import swal from 'sweetalert';
import api from '../utils/api';

import { LocationMapper } from '../mappers/location';
import {
  ConversationMapper,
  MessageMapper,
  ThreadMapper,
  BranchesInboxItemMapper,
} from '../mappers/chat';
import {
  type Conversation,
  type Message,
  type Thread,
  type LocationMessagePayload,
  type TextMessagePayload,
  type BranchesInboxItem,
} from '../types/chat';

export const conversationDetail = async (
  threadId: number,
  branchId: number,
): Promise<Conversation> => {
  const response = await api.get(`/messages/thread/${threadId}?user_id=${branchId}`);

  if (!response.ok) {
    throw response.data;
  }

  const conversation = ConversationMapper.fromAPIResponse(response.data.payload);

  if (conversation.validation_errors.includes('improper_message')) {
    await swal({
      text:
        'Sohbet ettiğiniz kişinin sizi sistem dışında bir uygulamaya yönlendirdiği tespit edildi. Kendi güvenliğiniz için sistem üzerinden mesajlaşmaya devam etmenizi öneririz.',
      icon: 'info',
      button: 'Tamam',
      dangerMode: true,
    });
  }
  conversation.messages.reverse();

  return conversation;
};

export const deleteConversation = async (threadId: number): Promise<boolean> => {
  const response = await api.get(`/messages/thread/delete/${threadId}`);

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

export const findConversationDetailByApplicationId = async (
  applicationId: number,
): Promise<Conversation> => {
  const response = await api.put('/messages/check', {
    application_id: applicationId,
  });

  if (!response.ok) {
    throw response.data;
  }

  const conversation = ConversationMapper.fromAPIResponse(response.data.payload);
  conversation.messages.reverse();

  return conversation;
};

export async function sendMessage(
  branchId: number,
  recipientId: number,
  message: LocationMessagePayload | TextMessagePayload,
  messageStatus: ?number,
): Promise<{ message: Message, thread: Thread }> {
  const requestData = {
    user_id: branchId,
    recipient_id: recipientId,
    body: message.body,
    message_status: messageStatus,
  };

  if (message.type !== 'text') {
    (requestData: any).type = message.type;

    if (message.type === 'location') {
      requestData.body = LocationMapper.toAPIRequest(message.body);
    }
  }
  if (messageStatus) {
    requestData.message_status = messageStatus;
  }

  const response = await api.put('/messages/send', requestData);

  if (!response.ok) {
    throw response.data;
  }

  return {
    message: MessageMapper.fromAPIResponse(response.data.payload.message),
    thread: ThreadMapper.fromAPIResponse(response.data.payload.thread),
  };
}

export const branchesInbox = async (): Promise<BranchesInboxItem[]> => {
  const response = await api.get('/messages/all_inbox');

  if (!response.ok) {
    throw response.data;
  }

  return BranchesInboxItemMapper.fromAPIResponseMultiple(response.data.payload.all_inbox);
};

export const inbox = async (branchId?: number, page?: number, limit?: number): Promise<Thread[]> => {
  let request = `/messages/inbox?`;

  if (branchId) {
    request += `user_id=${branchId}&`;
  }

  if (page) {
    request += `page=${page}&`;
  }

  if (limit) {
    request += `limit=${limit}`;
  }

  const response = await api.get(request);

  if (!response.ok) {
    throw response.data;
  }

  return ThreadMapper.fromAPIResponseMultiple(response.data.payload.threads);
};
