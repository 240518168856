// @flow
export default {
  common: {
    errors: {
      errorOccured: 'There was an error.',
      anErrorOccurred: 'An error occurred! Please, try again later. Error code: {{errorCode}}',
      unexpected: 'There was an unexpected error.',
    },
    validations: {
      phone: 'Please enter your phone in an appropriate format.',
      email: 'Please enter a valid email.',
      integer: 'Please enter a valid entry.',
      maxLength: 'You have entered too many characters for this field.',
      maxNumber: 'This should be a smaller value.',
      minLength: 'Too few characters for this field.',
      minNumber: 'This should be a bigger value.',
      required: 'This field is required.',
    },
    glossary: {
      nonExistent: 'NA',
      month: 'Month',
      inexperienced: 'No experience',
      jobApplied: 'Applied to job',
      writeMessage: 'Write a message',
      yes: 'Yes',
      no: 'No',
      edit: 'Edit',
      delete: 'Delete',
      select: 'Please select',
      unknown: 'Unknown',
      save: 'Save',
      cancel: 'Cancel',
      continue: 'Continue',
      send: 'Send',
      editPhoto: 'Change photo',
      ok: 'OK',
      noResult: 'No Result',
      searchInChoices: 'Search',
      loading: 'Loading',
      detail: 'Detail',
      removeChoice: 'Remove Choice',
      backToHomePage: 'Back to Homepage',
      unAuthorizedView: 'You are not authorized to view this page.',
      tryAgain: 'Try Again',
    },
    confirms: {
      isSureToContinue: 'Are you sure you want to continue?',
    },
    alerts: {
      dashboardAccess:
        'One of our sales representatives will call you to help you with your web account',
      jobCreation: 'You can increase your job credits by purchasing a package.',
    },
    apiValidations: {
      user: {
        phone: {
          verify: {
            unique: 'The phone number which you entered is used by another user.',
            dailyLimit: 'You reached to daily trial limit. Please, try again after 24 hours.',
            invalidCode: 'The code which you entered is wrong.',
            notFoundHttpException:
              'Invalid phone number. Please enter a valid mobile phone number (Error Code: 1030)',
          },
        },
      },
    },
  },
};
