// @flow

import { create } from 'apisauce';

const apiNew = create({
  baseURL: process.env.REACT_APP_API_NEW_URL,
  headers: {
    'X-App-Name': '24SAATTEIS',
    'X-App-Request-Source': 'company_dashboard',
  },
});

export function setAuthorizationHeader(token: string) {
  apiNew.setHeader('Authorization', `Bearer ${token}`);
}

export function clearAuthorizationHeader() {
  delete apiNew.headers.Authorization;
}

export default apiNew;
