// @flow
import React, { useEffect } from 'react';

const IyzicoPayment = () => {
  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  return (
    <div className="u-full-width u-flex u-flex-align-center u-gap-top">
      <div id="iyzipay-checkout-form" className="responsive"></div>
    </div>
  );
};

export default IyzicoPayment;
