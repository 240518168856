// @flow

export const MilitaryStatusMap = {
  '1': 'Yapılmadı',
  '2': 'Tecilli',
  '3': 'Yapıldı',
};

export const MilitaryStatusList = {
  WAITING: '1',
  DELAYED: '2',
  DONE: '3',
};

export const MilitaryStatusDefault = MilitaryStatusMap['1'];
