// @flow
import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

export default function useTranslator() {
  const { t, i18n } = useTranslation();

  const translate = useCallback(key => (key !== undefined ? t(key) : undefined), [t]);

  return {
    t: translate,
    i18n,
    Trans,
  };
}
