// @flow

import api from '../utils/api';

const HEADER_COUNTRY = 'X-App-Country';

// eslint-disable-next-line import/prefer-default-export
export const blockUserByThread = async (threadID: number) => {
  const response = await api.put(`/user-block/by-thread/${threadID}`);
  if (!response.ok) {
    throw response.data;
  }

  return true;
};

export async function smsVerificationRequestWithoutAuth(
  phone: string,
  isReSend: boolean,
  countryCode: string,
): Promise<string> {
  const variables = {
    isVerify: isReSend,
    phone,
  };
  api.setHeader(HEADER_COUNTRY, countryCode);
  const response = await api.put<any>('/verify-phone-send-code', variables);

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload.message;
}

export async function updatePhoneNumber(phoneNumber: string, code: string) {
  const initialBaseUrlWithoutApiPostfix = api
    .getBaseURL()
    .slice(0, api.getBaseURL().lastIndexOf('/'));
  api.setBaseURL(`${initialBaseUrlWithoutApiPostfix}/api`);
  const response = await api.post('/update-phone-verify', {
    phone: phoneNumber,
    code,
  });
  api.setBaseURL(`${initialBaseUrlWithoutApiPostfix}/web_api`);

  if (!response.ok) {
    throw response.data;
  }

  return true;
}
