/* eslint-disable no-nested-ternary */
// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';

import { swalOkButtonTranslateKey } from '../../../constants';

import { type AuthenticatedUser } from '../../../types/user';
import Header from '../../../components/shared/Header';
import Navbar from '../Navbar';
import { PaymentProviderMap } from '../../../enums/paymentProvider';
import IyzicoPayment from './iyzico';
import useTranslator from '../../../hooks/useTranslator';
import StripePayment from './stripe';
import { fetchPaymentForm } from '../../../api/payment';
import Loading from '../Loading';

type Props = {
  currentUser: AuthenticatedUser,
  location: any,
};

const appendScriptToHead = (script: string) => {
  const scriptElement = document.createElement('script');
  scriptElement.type = 'text/javascript';
  scriptElement.id = 'iyzipay-checkout-form-script';
  // get rid of the script tags in string
  const scriptToAppend = script
    .replace('<script type="text/javascript">', '')
    .replace('</script>', '');

  scriptElement.innerHTML = scriptToAppend;

  document.head.appendChild(scriptElement);
};

const fetchContent = async (
  packageId: number,
  setLoading: Function,
  setSecret: Function,
  setProvider: Function,
) => {
  setLoading(true);
  try {
    const { result, provider } = await fetchPaymentForm(packageId);
    setProvider(provider);

    switch (provider) {
      case PaymentProviderMap.IYZICO:
        appendScriptToHead(result);
        break;
      case PaymentProviderMap.STRIPE:
        setSecret(result);
        break;
      default:
        break;
    }
  } catch (error) {
    console.error(error);
  }

  setLoading(false);
};

const Payment = ({ currentUser, location }: Props) => {
  if (!location || !location.state || !location.state.packageId) {
    return <Redirect to="/packages" />;
  }

  const { packageId, errorMessage } = location.state;
  const [loading, setLoading] = useState(false);
  const [secret, setSecret] = useState('');
  const [provider, setProvider] = useState('');
  const { t } = useTranslator();

  useEffect(() => {
    if (errorMessage) {
      swal({
        text: errorMessage,
        icon: 'error',
        button: t(swalOkButtonTranslateKey),
        dangerMode: true,
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    fetchContent(packageId, setLoading, setSecret, setProvider);
  }, [packageId]);

  return (
    <div>
      <Header currentUser={currentUser} />
      <Navbar currentUser={currentUser} />
      {loading ? (
        <Loading />
      ) : provider === PaymentProviderMap.IYZICO ? (
        <IyzicoPayment />
      ) : (
        <StripePayment secret={secret} paymentAmountText={location.state.paymentAmountText} />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(Payment);
