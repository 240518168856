// @flow
export default {
  resetPassword: {
    errors: {
      errorOccured: 'There was an error.',
    },
    alerts: {
      invalidToken: 'Invalid token',
    },
    form: {
      resetPassword: 'Reset my password',
      passwordIsNotEqual: 'Passwords must match.',
      invalidToken: 'Invalid Token',
      success: 'Your password has been reset.',
    },
  },
  forgetPassword: {
    title: 'Forgot my password',
    body: 'Please enter your email. A link will be sent with directions to reset your password',
    submit: 'Send',
    successSubmitText: 'A link is sent with directions to reset your password.',
    failSubmitText: 'Invalid email.',
  },
};
