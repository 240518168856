// @flow

import api from '../utils/api';

import { GenderMap } from '../enums/gender';
import { ExperienceDurationMap } from '../enums/experienceDuration';
import { MilitaryStatusMap } from '../enums/militaryStatus';
import { JobStatusMap } from '../enums/jobStatus';
import { EnglishLevelMap } from '../enums/englishLevel';
import { EducationMap } from '../enums/educationStatus';
import { JobMapper, JobPositionMapper } from '../mappers/job';
import { ApplicationMapper } from '../mappers/application';
import { type Job, type JobPosition } from '../types/job';
import { type JobDetail } from '../types/jobDetail';
import { type AllApplications, type ShortListedApplications } from '../types/applications';
import { type Pagination } from '../types/pagination';
import { PaginationMapper } from '../mappers/pagination';
import apiNew from '../utils/apiNew';
import moment from 'moment';
import { ApiResponse } from 'apisauce';

export type CVUser = {
  profilePictureUrl: string,
  fullname: string,
  geoLocation: string,
  email: string,
  phone: string,
  experienceMonth: number,
  gender: "male" | "female",
  localizedBirthDate: string,
  disabledStatus: string | "-",
  worktimePreferences: Array<string>,
  educationStatus: "elementarySchool" | "middleSchool" | "highSchool" | "university",
  university: {
    name: string,
    status: "graduated" | "student"
  } | null,
  about: string,
  expectedSalary: number,
  militaryStatus: "done" | "delayed" | "waiting",
  englishLevel: "zero" | "low" | "normal" | "high",
  experiences: [
    {
      duration: number,
      companyName: string,
      position: string
    }
  ]
}

export type JobData = {
  category: number,
  user: number,
  title: string,
  position: JobPosition,
  description: string,
  status: $Values<typeof JobStatusMap>,
  location: string,
  minExperienceDuration?: $Values<typeof ExperienceDurationMap>,
  expectedSalaryMin?: number,
  expectedSalaryMax?: number,
  gender?: $Values<typeof GenderMap>,
  minEducationStatus?: $Values<typeof EducationMap>,
  minEnglishLevel?: $Values<typeof EnglishLevelMap>,
  militaryRequirements: Array<$Values<typeof MilitaryStatusMap>>,
  birthDateMin?: number,
  birthDateMax?: number,
  imageFileBase64?: string,
  workTimePreferenceFullTime: boolean,
  workTimePreferencePartTime: boolean,
  fare: boolean,
  freeMeal: boolean,
  healthInsurance: boolean,
  peopleBoxProjectId?: number,
};

export const positions = async (query: string): Promise<Array<JobPosition>> => {
  const response = await apiNew.get(`/job/job-positions/datalist?q=${query}`);

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload.map(JobPositionMapper.fromAPIResponse);
};

export const list = async (
  page: number,
  branchId?: number,
  orderBy?: string,
  orderByDir?: string,
): Promise<{ jobs: Array<Job>, pagination: Pagination }> => {
  let url = '/jobs/list';

  if (page) {
    url = `${url}?page=${page}`;
  }

  if (branchId) {
    url = `${url}&filter=${branchId.toString()}`;
  }

  if (orderBy) {
    url = `${url}&order_by=${orderBy}`;
  }

  if (orderByDir) {
    url = `${url}&order_by_dir=${orderByDir}`;
  }

  const response = await api.get(url);

  if (!response.ok) {
    throw response.data;
  }

  return {
    jobs: JobMapper.fromAPIResponseMultiple(response.data.payload.jobs),
    pagination: PaginationMapper.fromAPIResponse(response.data.payload.pagination),
  };
};

export const create = async (data: JobData): Promise<Job> => {
  const response = await api.post('/jobs/create', data);

  if (!response.ok) {
    throw response.data;
  }

  return JobMapper.fromAPIResponse(response.data.payload.job);
};

export const edit = async (jobId: number, data: JobData): Promise<Job> => {
  const response = await api.post(`/jobs/edit/${jobId}`, data);

  if (!response.ok) {
    throw response.data;
  }

  return JobMapper.fromAPIResponse(response.data.payload.job);
};

export const detail = async (jobId: number): Promise<JobDetail> => {
  const response = await api.get(`/job/detail/${jobId}`);

  if (!response.ok) {
    throw response.data;
  }

  return {
    job: JobMapper.fromAPIResponse(response.data.payload.job),
    allApplications: ApplicationMapper.fromAPIResponseMultiple(
      response.data.payload.all_applications,
    ),
    rejectedApplicationCount: response.data.payload.rejected_application_count || 0,
    shortListedApplications: ApplicationMapper.fromAPIResponseMultiple(
      response.data.payload.short_list_applications,
    ),
  };
};

export const allApplications = async (jobId: number, page: number): Promise<AllApplications> => {
  let response: ApiResponse<any, any>;

  if (jobId === '0') {
    response = await api.get(`/job/company/all-applications?page=${page}`);
  } else {
    response = await api.get(`/job/detail/${jobId}/all-applications?page=${page}`);
  }

  if (!response.ok) {
    throw response.data;
  }

  return {
    paginator: response.data.payload.paginator,
    allApplications: ApplicationMapper.fromAPIResponseMultiple(
      jobId === '0' ? response.data.payload.applications : response.data.payload.all_applications,
    ),
  };
};

export const shortListedApplications = async (
  jobId: number,
  page: number,
): Promise<ShortListedApplications> => {
  let response: ApiResponse<any, any>;

  if (jobId === '0') {
    response = await api.get(`/job/company/all-applications?shortlistAdded=1?page=${page}`);
  } else {
    response = await api.get(`/job/detail/${jobId}/shortlist-applications?page=${page}`);
  }

  if (!response.ok) {
    throw response.data;
  }

  return {
    paginator: response.data.payload.paginator,
    shortListedApplications: ApplicationMapper.fromAPIResponseMultiple(
      jobId === '0'
        ? response.data.payload.applications
        : response.data.payload.short_list_applications,
    ),
  };
};

export const shortListToggle = async (jobId: number, workerId: number): Promise<boolean> => {
  const response = await api.get(`/job/detail/${jobId}/application/${workerId}/short-list`);

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

export const exportAsExcel = async (jobId: number, email: string): Promise<boolean> => {
  const response = await api.post(`/job/detail/${jobId}/export`, {
    email,
  });

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

export const getApplicationListForExcelDownload = async (shortlistAdded: 0 | 1, date: string | null, jobId: number | null) => {
  const SHORTLIST_ADDED = shortlistAdded === 1 ? "&shortlistAdded=1" : ""
  const JOB_ID = jobId ? `&jobId=${jobId}` : ""
  const CREATED_AT = date ? `&createdAt=${date}` : ""
  const response = await api.get(`/job/company/all-applications?excel=1${SHORTLIST_ADDED}${CREATED_AT}${JOB_ID}`)

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload
}

export const downlaodApplicationsAsExcel = async (data: { applications: Array<any>, language: string }): Promise<{ result: any, fileName: string }> => {
  const response = await apiNew.post(`/job/applications/export`, data, { responseType: "arraybuffer" })

  if (!response.ok) {

    throw response.data;

  }

  const fileName = `Applications_${moment().format("DD_MM_YYYY")}`

  return { result: response.data, fileName };
}

export const exportApplicationAsPdf = async (
  applicationId: number,
  email: string,
): Promise<boolean> => {
  const response = await api.post(`/job/detail/${applicationId}/export-pdf`, {
    email,
  });

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

export const downloadApplicationAsPDF = async (data: { userInformation: CVUser, languageCode: string }): Promise<{ result: any, fileName: string }> => {
  const response = await apiNew.post(`/export/pdf/cv`, data, { responseType: "arraybuffer" })

  if (!response.ok) {
    throw response.data;
  }

  const fileName = `${data.userInformation.fullname}_CV_${moment().format("DD_MM_YYYY")}`

  return { result: response.data, fileName };
};