// @flow

export const UserGrantMap = {
  BRANCH_GRANT: 10,
  COMPANY_GRANT: 40,
  HR_GRANT: 90,
  ROOT_GRANT: 100,
};

export const UserGrantDefault = UserGrantMap.BRANCH_GRANT;
