// @flow

export const GenderMap = {
  male: 'Erkek',
  female: 'Kadın',
};

export const GenderList = {
  FEMALE: 'female',
  MALE: 'male',
};

export const GenderMapDefault = GenderMap.male;
