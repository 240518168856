// @flow

import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from './index';

// Actions
const FCM_SUPPORT_CHANGED = 'app/FCM_SUPPORT_CHANGED';

// Action Creator Types
type FCMSupportChangeAction = {
  type: typeof FCM_SUPPORT_CHANGED,
  status: boolean,
};

export type AppActions = FCMSupportChangeAction;

// Action Creators
export const fcmSupportChange = (status: boolean): FCMSupportChangeAction => ({
  type: FCM_SUPPORT_CHANGED,
  status,
});

// Thunks
export const fcmSupportChangeThunk = (status: boolean): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  dispatch(fcmSupportChange(status));
};

// Reducer
export type AppState = {
  fcmSupport: boolean,
};

const initialState = {
  fcmSupport: true,
};

export default function reducer(state: AppState = initialState, action: AppActions): AppState {
  switch (action.type) {
    case FCM_SUPPORT_CHANGED:
      return { ...state, fcmSupport: action.status };
    default:
      return state;
  }
}

// Selectors
export const getFCMSupport = (state: ReduxState): boolean => state.app.fcmSupport;
