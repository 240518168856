// @flow

export const AppointmentStatus = {
  WAITING: 'waiting',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  COMPLETED: 'completed',
};

export const AppointmentStatusName = {
  OFFER_ACCEPTED: 'offer_accepted',
  OFFER_REJECTED: 'offer_rejected',
  OFFER_SENT: 'offer_sent',
  APPOINTMENT_POSITIVE: 'appointment_positive',
  APPOINTMENT_NEGATIVE: 'appointment_negative',
  APPOINTMENT_CANCELLED: 'appointment_cancelled',
};

export const AppointmentStatusDefault = AppointmentStatus.WAITING;
