// @flow

import React from 'react';

import Modal from '../Modal';
import Button from '../../ui/Button';
import { themePalette } from '../../../theme/index';

type Props = {
  isVisible: boolean,
  message: string,
  confirmLabel?: string,
  onConfirmClick: () => void,
  contentLabel?: string,
};

const Alert = ({ isVisible, message, confirmLabel, onConfirmClick, contentLabel }: Props) => (
  <Modal isOpen={isVisible} closeable={false} contentLabel={contentLabel}>
    <div className="u-text-center">
      <h3>{message}</h3>
      <div className="u-pad-top-small">
        <Button
          primary
          onClick={onConfirmClick}
          style={{
            backgroundColor: themePalette.primary_color,
            boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
          }}
        >
          {confirmLabel}
        </Button>
      </div>
    </div>
  </Modal>
);

export default Alert;
