// @flow

const MESSAGE_LIST = {
  'user.email.form_validation.unique': 'register.formError.emailIsNotUnique',
};

export const transformMessage = (message: string): string => MESSAGE_LIST[message] || message;

const formErrorMapper = (errors: Object, t?: Function): Object =>
  Object.keys(errors).reduce((prev, cur) => {
    if (errors[cur].errors) {
      return {
        ...prev,
        [cur]: t ? t(transformMessage(errors[cur].errors[0])) : errors[cur].errors[0],
      };
    }

    return prev;
  }, {});

export default formErrorMapper;
