// @flow
export default {
  packages: {
    packageListItem: {
      viewDetails: 'Detalles',
      salesAgreement: 'Acuerdo de Venta',
      readAndAgree: 'He leído y apruebo',
      buy: 'Comprar',
      creditCardDisclaimer:
        'No conservamos la información de su tarjeta de crédito. Esta es una compra única',
      exclusiveOfferForNewAccounts: 'Oferta exclusiva para nuevas cuentas',
      timeRemaining: 'Tiempo restante para esta oferta especial: ',
    },
    error: {
      agreementNotAccepted: 'Por Favor Acepte el Acuerdo de Venta para Continuar.',
      anErrorOccurred: 'Se ha producido un error. Vuelva a intentarlo más tarde.',
    },
    listPage: {
      disclaimer: 'Puede llamarnos al 55-7369-6617 para detalles del paquete y nuestros servicios.',
    },
  },
};
