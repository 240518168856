// @flow

import React from 'react';

import { type Application } from '../../../types/application';
import { type Appointment } from '../../../types/appointment';

import AppointmentItem from './AppointmentItem';

import noApplication from '../../../img/empty-state/no-application.svg';
import useTranslator from '../../../hooks/useTranslator';

type Props = {
  appointments: Array<Appointment>,
  onAppointmentClick: Appointment => void,
  activeApplicationId: number | null,
};

const AppointmentList = ({ appointments, onAppointmentClick, activeApplicationId }: Props) => {
  const { t } = useTranslator();

  if (appointments.length === 0) {
    return (
      <div className="u-pad-ends-large u-text-center">
        <img
          src={noApplication}
          width={270}
          height={156}
          alt={t('jobDeatail.noApplicationFound')}
        />
        <h3 className="u-color-gull-gray u-gap-bottom-medium">
          {t('jobDetail.noAppointmentData')}
        </h3>
      </div>
    );
  }

  return (
    <div>
      {appointments.map(appointment => (
        <AppointmentItem
          key={appointment.id}
          onClick={() => {
            onAppointmentClick(appointment);
          }}
          isActive={activeApplicationId === appointment.applicationId}
          appointment={appointment}
        />
      ))}
    </div>
  );
};

export default AppointmentList;
