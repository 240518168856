// @flow

import React, { PureComponent } from 'react';

import { JobCloseReasonList } from '../../enums/jobCloseReason';

import Modal from '../../components/elements/Modal';
import Button from '../../components/ui/Button';
import RadioGroup from '../../components/ui/RadioGroup';
import Radio from '../../components/ui/Radio';

type Props = {
  isOpen: boolean,
  onModalCloseRequested: () => void,
  onSubmit: (reason?: string) => void,
  t: Function,
};

type State = {
  reason?: string,
};

class CloseReasonModal extends PureComponent<Props, State> {
  props: Props;

  state: State;

  handleChange: string => void;

  constructor(props: Props) {
    super(props);

    this.state = { reason: undefined };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(reason: string) {
    this.setState({ reason });
  }

  render() {
    const { onSubmit, isOpen, onModalCloseRequested, t } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        closeable={false}
        onModalCloseRequested={onModalCloseRequested}
        contentLabel={t('jobDetail.jobClosedSubject')}
        title={t('jobDetail.jobClosedSubject')}
      >
        <div>
          <RadioGroup selectedValue={this.state.reason} onChange={this.handleChange}>
            {JobCloseReasonList.map(reason => (
              <Radio key={reason.key} value={reason.key} containerClassName="u-block u-gap-bottom">
                <span className="u-gap-left u-align-middle">{t(`jobDetail.${reason.key}`)}</span>
              </Radio>
            ))}
          </RadioGroup>

          <div className="u-gap-top u-text-center">
            <Button
              primary
              onClick={() => {
                onSubmit(this.state.reason);
              }}
            >
              {this.props.t('jobs.edit.title')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CloseReasonModal;
