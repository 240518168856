// @flow
export default {
  auth: {
    loginForm: {
      title: 'ÜYE GİRİŞİ YAP',
      email: { label: 'E-posta', required: 'Lütfen şirket ismini giriniz' },
      password: {
        label: 'Parola',
        required: 'Lütfen şifrenizi giriniz.',
        minLength: 'Lütfen en az 6 karakter giriniz.',
        maxLength: 'Lütfen en fazla 16 karakter giriniz.',
      },
      register: 'Kayıt ol',
      forgotPassword: 'Şifremi unuttum',
      login: 'GİRİŞ YAP',
    },
    login: {
      error: {
        submissionError: {
          password: 'Lütfen kullanıcı adınızı ve şifrenizi kontrol ediniz.',
        },
      },
    },
  },
};
