import React, { useState } from "react";
import Col from "../../components/layout/Col";
import Row from "../../components/layout/Row";
import useTranslator from "../../hooks/useTranslator";
import swal from "sweetalert";
import { deleteItem, updateItem } from "../../api/basket";
import { themePalette } from "../../theme";
import BaseLoading from '../../components/elements/Loading';
import Button from "../../components/ui/Button";
import Icon from "../../components/elements/Icon";
import { currencyMapper } from "../../mappers/currency";

type Props = {
    addons: AddOn[],
    basketId: number,
    setBasket: React.Dispatch<React.SetStateAction<undefined>>,
}

const BasketAddonList = ({ addons, basketId, setBasket }: Props) => {
    const [loadingIds, setLoadingIds] = useState({});
  const { t } = useTranslator();

  const handleIncreaseAddon = async (addonId, quantity) => {
    try {
      setLoadingIds(prev => ({ ...prev, [addonId]: true }));
      const selectedAddonInBasket = addons.find(addonInBasket => addonInBasket.id === addonId);

      if (selectedAddonInBasket && selectedAddonInBasket.product.purchaseLimit === quantity) {
        await swal({
          text: t('payment.general.reachedPurchaseLimit'),
          icon: 'warning',
        });
        setLoadingIds(prev => ({ ...prev, [addonId]: false }));
        return;
      }
      const basketResponse = await updateItem(basketId, addonId, quantity + 1);
      setBasket(basketResponse);
      setLoadingIds(prev => ({ ...prev, [addonId]: false }));
    } catch (error) {
      setLoadingIds(prev => ({ ...prev, [addonId]: false }));

      await swal({
        text: t('payment.general.anErrorOccuredTryAgain'),
        icon: 'error',
      });
    }
  };

  const handleDecreaseAddon = async (addonId, quantity) => {
    let basketResponse;
    try {
      setLoadingIds(prev => ({ ...prev, [addonId]: true }));
      if (quantity === 1) {
        basketResponse = await deleteItem(basketId, addonId);
      } else {
        basketResponse = await updateItem(basketId, addonId, quantity - 1);
      }
      setBasket(basketResponse);
      setLoadingIds(prev => ({ ...prev, [addonId]: false }));
    } catch (error) {
      setLoadingIds(prev => ({ ...prev, [addonId]: false }));

      await swal({
        text: t('payment.general.anErrorOccuredTryAgain'),
        icon: 'error',
      });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '19em', overflow: 'auto' }}>
      {addons.map((addon, index) => (
        <Row
          key={`basket-addon-${index}`}
          style={{
            display: 'flex',
            border: `2px solid ${themePalette.packageColors[0]}`,
            borderRadius: '10px',
            margin: '2%',
            padding: '3%',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          }}
        >
          <Col md={8}>
            <Row>
              <Col>
                <h5 style={{ margin: '1% 0' }}>{addon.product.title}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>
                  {addon.product.price} {currencyMapper.fromAPIResponse(addon.product.currency)}
                </span>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            {loadingIds[addon.id] ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '45px',
                  width: '45px',
                }}
              >
                <BaseLoading />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  gap: '0.5em',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  style={{ borderRadius: '10px', height: '45px', width: '45px' }}
                  type="button"
                  disabled={loadingIds[addon.id]}
                  onClick={() => handleDecreaseAddon(addon.id, addon.quantity)}
                >
                  <span style={{ fontWeight: 'lighter', fontSize: '35px' }}>-</span>
                </Button>

                <span>{addon.quantity}</span>

                <Button
                  style={{ borderRadius: '10px', height: '45px', width: '45px' }}
                  type="button"
                  disabled={loadingIds[addon.id]}
                  onClick={() => handleIncreaseAddon(addon.id, addon.quantity)}
                >
                  <Icon name="add" className="u-font-size-md" />
                </Button>
              </div>
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default BasketAddonList;
