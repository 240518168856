// @flow
export const bundleNames = {
  Bonded: { title: 'Bonded', icoName: '/faviconBonded.ico' },
  '24SaatteIs': { title: '24 Saatte İş', icoName: '/favicon.ico' },
};

export const swalOkButtonTranslateKey = 'common.glossary.ok';

export const currencyTurkishLira = "TRY";
export const currencyTurkishLiraSymbol = "₺";
