/* eslint-disable react/no-did-update-set-state */
// @flow

import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import debounce from 'lodash.debounce';
import { withTranslation } from 'react-i18next';
import Row from '../components/layout/Row';
import Col from '../components/layout/Col';

import FormField from '../components/elements/FormField';
import FormFieldMap from '../components/elements/FormFieldMap';
import GeoSuggestField from '../components/elements/GeoSuggestField';
import Button from '../components/ui/Button';
import LinkButton from '../components/ui/LinkButton';

import required from '../validators/required';
import email from '../validators/email';
import phone from '../validators/phone';

import { type ReduxState } from '../redux/modules';
import FormSearchableSelect from '../components/elements/FormSearchableSelect';
import maxLength, { maxLengthAllowsEmpty } from '../validators/maxLength';
import integer from '../validators/integer';
import { taxOffices } from '../api/common';
import { type AuthenticatedUser } from '../types/user';
import { themePalette } from '../theme/index';

type Props = {
  handleSubmit: Function,
  change: (field: string, value: any) => void,
  submitting: boolean,
  latitude: number,
  longitude: number,
  centerLatitude: number,
  centerLongitude: number,
  formViewData: any,
  zoom: number,
  t: Function,
  currentUser: AuthenticatedUser,
  shouldSubmitForm?: boolean,
};

type State = {
  googleMapsInitialized: Boolean,
  phoneVerifyNeeded: Boolean,
  phoneVerifyCode: string,
  phoneVerifyCompleted: Boolean,
  process: string | null,
  time: number,
  isActiveReSend: boolean,
  phoneNumber: string,
};

class ProfileEditForm extends PureComponent<Props> {
  props: Props;

  interval: any;

  state: State;

  constructor(props) {
    super(props);

    this.state = {
      googleMapsInitialized: false,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.shouldSubmitForm) {
      this.formRef.current.dispatchEvent(new Event('submit'));
    }
  }

  loadTaxOfficeOptions = debounce((value: string, cb: any) => {
    taxOffices(value).then(offices => {
      const options = offices.map(office => ({ value: office.id, label: office.name }));
      cb(options);
    });
  }, 200);

  render() {
    const {
      handleSubmit,
      change,
      submitting,
      latitude,
      longitude,
      centerLatitude,
      centerLongitude,
      formViewData,
      zoom,
      t,
      currentUser,
    } = this.props;

    const { language } = currentUser.user;
    const { googleMapsInitialized } = this.state;

    return (
      <form onSubmit={handleSubmit} ref={this.formRef}>
        <div className="u-pad-top-small u-pad-sides-small">
          <Row>
            <Col md={6}>
              <Field
                name="firstName"
                type="text"
                component={FormField}
                label={t('profile.edit.form.firstName.label')}
                validate={[required(t('profile.edit.form.firstName.required'))]}
                autoFocus
              />
            </Col>
            <Col md={6}>
              <Field
                name="lastName"
                type="text"
                component={FormField}
                label={t('profile.edit.form.lastName.label')}
                validate={[required(t('profile.edit.form.lastName.required'))]}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name="email"
                type="text"
                component={FormField}
                label={t('profile.edit.form.email.label')}
                validate={[
                  required(t('profile.edit.form.email.required')),
                  email(t('common.validations.email')),
                ]}
              />
            </Col>
            <Col md={6}>
              <Field
                name="phone"
                type="text"
                component={FormField}
                label={t('profile.edit.form.phone.label')}
                validate={[
                  required(t('profile.edit.form.phone.required')),
                  maxLength(10),
                  phone(t('common.validations.phone')),
                ]}
              />
            </Col>
            {formViewData && formViewData.taxOffice && formViewData.taxOffice.isActive ? (
              <Fragment>
                <Col md={6}>
                  <Field
                    name="taxOffice"
                    component={FormSearchableSelect}
                    label={t('profile.edit.form.taxOffice.label')}
                    validate={[]}
                    format={office =>
                      office ? { value: office.id, label: office.name } : undefined
                    }
                    parse={option =>
                      option ? { id: option.value, name: option.label } : undefined
                    }
                    props={{
                      placeholder: t('profile.edit.form.taxOffice.placeholder'),
                      loadOptions: this.loadTaxOfficeOptions,
                    }}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    name="taxOfficeNumber"
                    type="text"
                    component={FormField}
                    label={t('profile.edit.form.taxOfficeNumber.label')}
                    validate={[maxLengthAllowsEmpty(255), integer(t('common.validations.integer'))]}
                  />
                </Col>
              </Fragment>
            ) : null}
            <Col md={6}>
              <Field
                name="commercialTitle"
                type="text"
                component={FormField}
                label={t('profile.edit.form.commercialTitle.label')}
                validate={[maxLengthAllowsEmpty(255)]}
              />
            </Col>
            <Col md={6}>
              <Field
                name="website"
                type="text"
                component={FormField}
                label={t('profile.edit.form.website.label')}
                validate={[maxLengthAllowsEmpty(255)]}
              />
            </Col>
            {googleMapsInitialized ? (
              <Col md={12}>
                <Field
                  name="locationSearch"
                  label={t('profile.edit.form.locationSearch.label')}
                  component={GeoSuggestField}
                  props={{
                    onSuggestSelect: suggest => {
                      change(
                        'locationPosition',
                        `${suggest.location.lat}, ${suggest.location.lng}`,
                      );
                      change('centerLatitude', suggest.location.lat);
                      change('centerLongitude', suggest.location.lng);
                      change('zoom', 12);
                      change('latitude', suggest.location.lat);
                      change('longitude', suggest.location.lng);
                    },
                    onSuggestNoResults: () => {},
                  }}
                />
              </Col>
            ) : (
              <div className="c-form-group c-form-group--floating" style={{ width: '100%' }}>
                <div style={{ height: '30px', width: '100%' }}></div>
              </div>
            )}
            <Col md={12} className="u-gap-bottom">
              <Field
                name="locationPosition"
                label={t('profile.edit.form.locationPosition.label')}
                component={FormFieldMap}
                props={{
                  centerLatitude,
                  centerLongitude,
                  latitude,
                  longitude,
                  zoom,
                  onMapClick: event => {
                    change('locationPosition', `${event.latLng.lat()}, ${event.latLng.lng()}`);
                    change('latitude', event.latLng.lat());
                    change('longitude', event.latLng.lng());
                  },
                  language: language.languageCode,
                  onIdle: () =>
                    !googleMapsInitialized && this.setState({ googleMapsInitialized: true }),
                }}
                validate={[required(t('profile.edit.form.locationPosition.required'))]}
              />
            </Col>
          </Row>
        </div>
        <div className="u-pad-ends-small u-pad-sides-small u-text-center">
          <LinkButton to="/profile" className="u-gap-right" ghost>
            {t('profile.edit.form.cancel')}
          </LinkButton>

          <Button
            type="submit"
            primary
            disabled={submitting}
            loading={submitting}
            style={{
              backgroundColor: themePalette.primary_color,
              boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
            }}
          >
            {t('profile.edit.form.edit')}
          </Button>
        </div>
      </form>
    );
  }
}

const Form = reduxForm({
  form: 'profileEdit',
})(ProfileEditForm);

const selector = formValueSelector('profileEdit');

// $FlowFixMe
export default connect((state: ReduxState) => ({
  latitude: selector(state, 'latitude'),
  longitude: selector(state, 'longitude'),
  centerLatitude: selector(state, 'centerLatitude'),
  centerLongitude: selector(state, 'centerLongitude'),
  zoom: selector(state, 'zoom'),
}))(withTranslation()(Form));
