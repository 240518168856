// @flow
import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { type Appointment } from '../../../../types/appointment';

import { cancelAppointment } from '../../../../api/appointment';

import Button from '../../../../components/ui/Button';
import Map from '../../../../components/elements/Map';
import Confirm from '../../../../components/elements/Confirm';
import { themePalette } from '../../../../theme/index';

type Props = {
  appointment: Appointment,
  onCancel?: () => void,
  t: Function,
};

class AppointmentDetail extends PureComponent<Props> {
  props: Props;

  confirmDialog: any;

  handleCancel = () => {
    const { t } = this.props;
    this.confirmDialog.show(t('jobDetail.profile.isSureToRejectAppointment'), {
      onConfirm: this.cancel,
    });
  };

  cancel = async () => {
    const { onCancel, t } = this.props;
    try {
      await cancelAppointment(this.props.appointment.id);

      if (onCancel) {
        onCancel();
      }
    } catch (error) {
      alert(error.message || t('common.errors.errorOccured'));
    }
  };

  render() {
    const { appointment, t } = this.props;

    return (
      <div className="u-pad-ends-small u-pad-sides-small">
        <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
          {t('jobDetail.profile.date.label')}
        </h6>

        <div className="c-datetime">
          <div className="c-datetime__time">{appointment.appointmentAt.format('HH:mm')}</div>
          <div className="c-datetime__date">
            <div>{appointment.appointmentAt.format('L')}</div>
            <div className="u-font-size-sm">{appointment.appointmentAt.format('dddd')}</div>
          </div>
        </div>

        <hr className="u-hr-line" />

        <h6 className="u-gap-top u-color-primary" style={{ color: themePalette.color }}>
          {t('jobDetail.profile.location.label')}
        </h6>

        <div>
          <Map
            containerElement={<div style={{ height: '300px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            zoom={10}
            center={appointment.location}
            marker={appointment.location}
          />
        </div>

        <Confirm
          ref={ref => {
            this.confirmDialog = ref;
          }}
          confirmLabel={t('common.glossary.continue')}
        />

        <div className="u-gap-top">
          <Button
            block
            outline
            primary
            onClick={this.handleCancel}
            style={{
              backgroundColor: themePalette.primary_color,
              boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
              color: '#fff',
            }}
          >
            {t('jobDetail.profile.cancelAppointment')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AppointmentDetail);
