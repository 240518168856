// @flow
export default {
  payment: {
    error: {
      paymentErrorMessage: 'Hubo un error al completar el pago.',
      cardDeclined: 'Esta tarjeta ha sido rechazada',
      expiredCard:
        'La tarjeta ha caducado. Verifique la fecha de vencimiento o use una tarjeta diferente.',
      incorrectCvc:
        'El código de seguridad de la tarjeta es incorrecto. Verifique el código de seguridad de la tarjeta o use una tarjeta diferente.',
      incorrectNumber:
        'El número de tarjeta es incorrecto. Verifique el número de la tarjeta o use una tarjeta diferente.',
      invalidExpiryMonth:
        'La fecha de caducidad de la tarjeta es incorrecta. Verifique la fecha de vencimiento o use una tarjeta diferente.',
      invalidExpiryYear:
        'La fecha de caducidad de la tarjeta es incorrecta. Verifique la fecha de vencimiento o use una tarjeta diferente.',
      invalidNumber:
        'El número de tarjeta no es válido. Verifique los detalles de la tarjeta o use una tarjeta diferente.',
      insufficientFunds: 'Fondos insuficientes',
      invalidExpiryDate:
        'La fecha de caducidad de la tarjeta es incorrecta. Verifique la fecha de vencimiento o use una tarjeta diferente.',
    },
    success: {
      paymentSuccessMessage: 'Su pago ha sido procesado con éxito',
    },
    paymentResult: {
      success: 'Éxito',
      failure: 'Falla',
    },
    stripeForm: {
      pay: 'Pagar',
      payWithAmount: 'PAGA {{amount}}',
    },
    input: {
      cardHolderName: {
        required: "El nombre del titular de la tarjeta es obligatorio",
        enterValid: "Ingrese un nombre de titular de tarjeta válido",
        placeholder: "Nombre del Titular de la Tarjeta"
      },
      cardNumber: {
        required: "El número de la tarjeta es obligatorio",
        enterValid: "Ingrese un número de tarjeta válido",
        placeholder: "Número de la Tarjeta"
      },
      expiryDate: {
        required: "La fecha de vencimiento es obligatoria",
        enterValid: "Ingrese una fecha de vencimiento válida",
        placeholder: "MM/AA"
      },
      cvc: {
        required: "El CVC es obligatorio",
        enterValid: "Ingrese un CVC válido",
        placeholder: "CVC"
      }
    },
    general: {
      reachedPurchaseLimit: "Límite de compra alcanzado",
      anErrorOccuredTryAgain: "Ocurrió un error. Por favor, inténtelo de nuevo",
      noAddonForSelectedPackage: "No hay complementos para el paquete seleccionado",
      anErrorOccuredWhileLoadingAddons: "Ocurrió un error al cargar los complementos. Por favor, actualice la página.",
      backToPreviousPage: "< Volver a la página anterior",
      yourCart: "Tu carrito",
      additionalProducts: "Productos adicionales",
      proceedToCheckout: "Proceder al pago",
      pay: "PAGAR",
      cardDetails: "Detalles de la tarjeta",
      completePayment: "Pago Completo"
    }
  },
};
