// @flow
export default {
  register: {
    registerForm: {
      title: 'KAYIT OL',
      companyName: {
        label: 'Şirket İsmin',
        required: 'Lütfen şirket ismini giriniz.',
      },
      firstName: {
        label: 'İsim',
        required: 'Lütfen isminizi giriniz.',
      },
      lastName: {
        label: 'Soyad',
        required: 'Lütfen soyadınızı giriniz.',
      },
      email: {
        label: 'E-posta',
        required: 'Lütfen e-postanızı giriniz.',
      },
      phone: {
        label: 'Telefon Numarası',
        required: 'Lütfen telefon numarası giriniz.',
      },
      password: {
        label: 'Parola',
        required: 'Lütfen şifrenizi giriniz.',
        minLength: 'Lütfen en az 6 karakter giriniz.',
        maxLength: 'Lütfen en fazla 16 karakter giriniz.',
      },
      locationSearch: {
        label: 'Lokasyon Ara',
        required: 'Lütfen konumunuzu giriniz.',
      },
      userAgreements: {
        label:
          '<0>Kullanıcı Sözleşmesini</0> ve <1>Kişisel Veri İşleme Politikasını</1> kabul ediyorum.',
        minLength: 'Bu alan zorunludur.',
      },
      register: 'KAYIT OL',
      loginRedirectText: 'Zaten üyeliğiniz var ise ',
      underlinedText: '<0>giriş yapabilirsiniz</0>.',
    },
    formError: {
      emailIsNotUnique: 'Bu e-posta adresi başka bir kullanıcı tarafından kullanılıyor.',
    },
    smsVerify: {
      title: 'Telefon Doğrulama',
      infoText: 'Adayların size ulaşması için telefonunuzu onaylamamız gerekiyor.',
      sendCode: 'Kod Gönder',
      reSendCode: 'Yeni kod gönder',
      confirm: 'Doğrula',
    },
  },
};
