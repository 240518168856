// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import { withTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { sendEmail } from '../../redux/modules/currentUser';
import { type ReduxDispatch } from '../../types/redux';

import { swalOkButtonTranslateKey } from '../../constants';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Box from '../../components/elements/Box';
import Logo from '../../components/shared/Logo';
import ForgetPasswordForm from '../../forms/ForgetPasswordForm';

type Props = {
  sendEmail: (email: string) => Promise<*>,
  location: any,
  push: Function,
  t: Function,
};

class ForgetPassword extends PureComponent<Props> {
  props: Props;

  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (values: Object): Promise<*> => {
    try {
      const emailResult = await this.props.sendEmail(values.email);
      if (emailResult === true) {
        await swal({
          text: this.props.t('forgetPassword.successSubmitText'),
          icon: 'info',
          button: this.props.t(swalOkButtonTranslateKey),
          customClass: 'localColor',
        });
      }
    } catch (err) {
      if (err.errors.errors.includes('invalid.mail.address')) {
        await swal({
          text: this.props.t('forgetPassword.failSubmitText'),
          icon: 'info',
          button: this.props.t(swalOkButtonTranslateKey),
          customClass: 'localColor',
        });
      }
    }
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    this.props.push(from);
  };

  render() {
    return (
      <section className="u-pad-ends-large">
        <Container>
          <Row>
            <Col lg={6} className="col--offset-lg-3">
              <Box className="u-pad-ends-medium@md-up u-pad-ends@sm-down u-pad-sides-medium@md-up u-pad-sides@sm-down">
                <Logo
                  className="c-box__center-logo logo_size"
                  alt={this.props.t('layout.header.title')}
                  style={{ objectFit: 'scale-down' }}
                />
                <ForgetPasswordForm onSubmit={this.handleSubmit} />
              </Box>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ sendEmail, push }, dispatch);

// $FlowFixMe
export default connect(null, mapDispatchToProps)(withTranslation()(ForgetPassword));
