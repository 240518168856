// @flow

import moment from 'moment-timezone';

import { WorkerMapper } from './user';
import { ApplicationStatusDefault } from '../enums/applicationStatus';
import { type Application, type OtherApplication } from '../types/application';

import { AppointmentMapper } from './appointment';

export const OtherApplicationMapper = {
  fromAPIResponse(response: Object = {}) {
    return {
      applicationId: response.application_id || 0,
      jobId: response.job_id || 0,
      jobTitle: response.job_title || '',
      companyName: response.company_name || '',
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<OtherApplication> {
    if (!response) {
      return [];
    }

    return response.map(OtherApplicationMapper.fromAPIResponse);
  },
};

export const ApplicationMapper = {
  fromAPIResponse(response: Object): Application {
    return {
      id: response.id || 0,
      user: WorkerMapper.fromAPIResponse(response.user),
      otherApplications: OtherApplicationMapper.fromAPIResponseMultiple(
        response.other_applications || [],
      ),
      createdAt: moment(response.created),
      isShortListed: Boolean(response.short_list),
      shortListedAt: moment(response.short_list_added),
      expiresAt: moment(response.short_list_expire),
      status: response.status || ApplicationStatusDefault,
      isSeen: !!response.is_seen_by_job_owner,
      isShortListedByAnother: !!response.is_shortlisted_for_another_application_in_same_root,
      matchScore: response.match_score || undefined,
      appointment: response.appointment
        ? AppointmentMapper.fromAPIResponse(response.appointment)
        : undefined,
      isApplicantCameToAppointment: response.is_applicant_came_to_appointment,
      isAppointmentPositive: response.is_appointment_positive,
      isOfferSentToApplicant: response.is_offer_sent_to_applicant,
      isApplicantAcceptTheOffer: response.is_applicant_accept_the_offer,
      unreadWorkerMessageCount: response.worker_unread_messages_count,
      job: response.job
        ? { id: response.job.id, title: response.job.title, status: response.job.status }
        : undefined,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Application> {
    if (!response) {
      return [];
    }

    return response.map(ApplicationMapper.fromAPIResponse);
  },
};
