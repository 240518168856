// @flow

import React from 'react';

import HRForm from '../../forms/HRForm';

import Modal from '../../components/elements/Modal';

import { type HR } from '../../types/user';

import useTranslator from '../../hooks/useTranslator';

type Props = {
  isOpen: boolean,
  onModalCloseRequested: () => void,
  person: HR | null,
  handleSubmit: (HR) => void,
};

const HRModal = ({ person, handleSubmit, isOpen, onModalCloseRequested }: Props) => {
  const { t } = useTranslator();
  return (
    <Modal
      isOpen={isOpen}
      onModalCloseRequested={onModalCloseRequested}
      closeable
      contentLabel={
        person ? t('profile.hrList.subTitles.editUser') : t('profile.hrList.subTitles.addUser')
      }
      title={
        person ? t('profile.hrList.subTitles.editUser') : t('profile.hrList.subTitles.addUser')
      }
    >
      <HRForm onSubmit={handleSubmit} initialValues={person} isEdit={!!person} />
    </Modal>
  );
};

export default HRModal;
