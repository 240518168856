// @flow

import React, { PureComponent } from 'react';
import Notification from './Notification';
import { type Notification as NotificationType } from '../../../types/notification';

type Props = {};
type State = {
  notifications: NotificationType[],
};

class Container extends PureComponent<Props, State> {
  props: Props;

  state: State;

  containerRef: HTMLDivElement | null;

  constructor(props: Props) {
    super(props);

    this.state = { notifications: [] };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.notifications.length !== prevState.notifications.length) {
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    if (this.containerRef) {
      this.containerRef.scrollTop = this.containerRef.scrollHeight;
    }
  }

  add(notification: NotificationType) {
    this.setState((state: State) => ({
      ...state,
      notifications: state.notifications.concat([notification]),
    }));
  }

  remove(notification: NotificationType) {
    this.setState((state: State) => ({
      ...state,
      notifications: state.notifications.filter(n => n !== notification),
    }));
  }

  render() {
    return (
      <div
        ref={ref => {
          this.containerRef = ref;
        }}
        className="c-notification"
      >
        {/* eslint-disable react/no-array-index-key */}
        {this.state.notifications.map((notification, i) => (
          <Notification
            key={i}
            notification={notification}
            onRemove={() => {
              this.remove(notification);
            }}
          />
        ))}
        {/* eslint-enable react/no-array-index-key */}
      </div>
    );
  }
}

export default Container;
