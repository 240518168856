// @flow

import React, { PureComponent } from 'react';

import Label from '../../ui/Label';
import Checkbox from '../../ui/Checkbox';

type Meta = {
  touched: boolean,
  error: string,
};

type Props = {
  input: any,
  label: string,
  meta: Meta,
  options: Array<{ value: any, text: any }>,
};

type State = {
  value: Array<*>,
};

class FormFieldCheck extends PureComponent<Props, State> {
  props: Props;

  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      value: this.props.input.value || [],
    };
  }

  handleCheck = (key: string) => (val: boolean) => {
    if (val) {
      this.add(key);
    } else {
      this.remove(key);
    }
  };

  has = (key: string): boolean => this.state.value.includes(key);

  add = (key: string) => {
    if (this.has(key)) {
      return;
    }

    this.setState(
      state => {
        const newValue = [...state.value];
        newValue.push(key);

        return { value: newValue };
      },
      () => {
        this.props.input.onChange(this.state.value);
      },
    );
  };

  remove = (key: string) => {
    this.setState(
      state => {
        const newValue = [...state.value];
        const index = newValue.indexOf(key);
        if (index === -1) {
          return {};
        }

        newValue.splice(index, 1);
        return { value: newValue };
      },
      () => {
        this.props.input.onChange(this.state.value);
      },
    );
  };

  render() {
    const { label, meta, options, input } = this.props;

    return (
      <div className={`c-form-group u-gap-bottom ${meta.touched && meta.error ? 'has-error' : ''}`}>
        <Label htmlFor={input.name}>{label}</Label>
        <div>
          {options.map(item => (
            <Checkbox
              key={item.value}
              checked={this.has(item.value)}
              onChange={this.handleCheck(item.value)}
              containerClassName="u-gap-right"
            >
              {item.text}
            </Checkbox>
          ))}
        </div>
        {meta.touched && meta.error && <span className="c-form-group__alert">{meta.error}</span>}
      </div>
    );
  }
}

export default FormFieldCheck;
