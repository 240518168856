/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-has-content */
// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { type AuthenticatedUser } from '../../types/user';

import Loading from './Loading';
import Header from '../../components/shared/Header';
import { getBasket } from '../../api/basket';
import Container from '../../components/layout/Container';
import Col from '../../components/layout/Col';
import Row from '../../components/layout/Row';
import useTranslator from '../../hooks/useTranslator';
import { themePalette } from '../../theme';
import { Basket } from '../../types/basket';
import CardForm from './CardForm';
import ShieldStarIcon from '../../components/elements/Icon/shieldStarIcon';

type Props = {
  currentUser: AuthenticatedUser,
  history: Object,
};

type BasketDetailProps = {
  basket: Basket,
};

const BasketDetail = ({ basket }: BasketDetailProps) => {
  const extractFeaturesFromBasket = basket => {
    const features = [];

    basket.items.forEach(item => {
      if (item.product && item.product.features && item.product.features.length > 0) {
        item.product.features.forEach(feature => {
          features.push(feature);
        });
      }
    });

    return features;
  };

  const features = extractFeaturesFromBasket(basket);

  return (
    <Container style={{ maxHeight: '30em', overflow: 'auto' }}>
      <Row style={{ justifyContent: 'center' }}>
        {features.map((feature, index) => (
          <Col
            key={`basket-${index}`}
            md={5}
            className="mb-4"
            style={{
              margin: '0.5em',
              borderRadius: '5px',
              minWidth: '2em',
              minHeight: '6em',
              justifyContent: 'center',
              backgroundColor: '#EFEFEF',
            }}
          >
            <Row style={{
              display: 'flex',
              justifyContent: 'center',
              alignItem: 'center',
              marginTop: '1em',
            }}>
              <ShieldStarIcon style={{
                height: "30px",
                width: "30px",
              }} />
            </Row>
            <Row style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
              <p className="card-text" style={{ textAlign: 'center' }}>
                {feature.description}
              </p>
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const Card = ({ currentUser, history }: Props) => {
  if (!history || !history.location || !history.location.state || !history.location.state.basket) {
    return <Redirect to="/packages" />;
  }

  const { t } = useTranslator();
  const { basket: basketFromProp } = history.location.state;
  const [basket, setBasket] = useState();
  const [loading, setLoading] = useState(false);

  const fetchBasket = async (basketId: number) => {
    try {
      const basketResponse = await getBasket(basketId);
      if (!basketResponse) {
        return history.goBack();
      }
      setBasket(basketResponse);
    } catch (error) {
      return <Redirect to="/packages" />;
    }
  };

  const handlePreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    fetchBasket(basketFromProp.id);
  }, [basketFromProp]);

  return (
    <div>
      <Header currentUser={currentUser} />
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '2rem' }}>
            <Row>
              <Col>
                <h4
                  onClick={handlePreviousPage}
                  style={{ marginBottom: '1rem', cursor: 'pointer', fontWeight: 'bolder' }}
                >
                  {t('payment.general.backToPreviousPage')}
                </h4>
              </Col>
            </Row>
            <Row style={{ gap: '1em' }}>
              <Col md={5}>
                <Row>{basket && <BasketDetail basket={basket} />}</Row>
              </Col>
              <Col
                md={6}
                style={{
                  borderRadius: '10px',
                  border: `2px solid ${themePalette.packageColors[0]}`,
                  padding: '1em 2em',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  maxHeight: '30em',
                  overflow: 'auto',
                }}
              >
                <Row style={{ margin: '0 auto' }}>
                  <Col>
                    <h2>{t('payment.general.cardDetails')}</h2>
                  </Col>
                </Row>
                <CardForm basket={basket} currentUser={currentUser} history={history} />
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(Card);
