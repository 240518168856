// @flow
import swal from 'sweetalert';
import type { AuthenticatedUser } from '../types/user';

// eslint-disable-next-line import/prefer-default-export
export const dashboardAccessPopup = t => {
  swal({
    text: t('common.alerts.dashboardAccess'),
    icon: 'info',
    button: t('common.glossary.ok'),
    dangerMode: true,
  });
};

export const jobCreationPopup = t => {
  swal({
    text: t('common.alerts.jobCreation'),
    icon: 'info',
    button: t('common.glossary.ok'),
    dangerMode: true,
  });
};

// eslint-disable-next-line consistent-return
export const handlePageInfoPopup = (currentUser: AuthenticatedUser) => {
  if (currentUser.user.dashboardAccess === false) {
    return dashboardAccessPopup(currentUser.user.dashboardAccess);
  }
  if (currentUser.user.jobCreationBalance <= 0) {
    return jobCreationPopup(currentUser.user.jobCreationBalance);
  }
};
