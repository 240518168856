// @flow

import storage from './storage';
import { UserGrantMap } from '../enums/userGrant';
import { type AuthenticatedUser } from '../types/user';
import { UserTypeMap } from '../enums/userType';

const STORAGE_KEY = 'currentUser';

function getCurrentUser(): AuthenticatedUser | null {
  return storage.get(STORAGE_KEY);
}

function isAuthenticated(): boolean {
  return getCurrentUser() !== null;
}

function hasDashboardAccess(): boolean {
  const currentUser = getCurrentUser();
  return currentUser.user.dashboardAccess || currentUser.user.userType !== UserTypeMap.COMPANY;
}

function hasJobCreationBalance(): boolean {
  const currentUser = getCurrentUser();
  return (
    currentUser.user.jobCreationBalance > 0 || currentUser.user.userType !== UserTypeMap.COMPANY
  );
}

function isGranted(actionPriority: $Values<typeof UserGrantMap>): boolean {
  const currentUser = getCurrentUser();

  if (!currentUser) {
    return false;
  }

  return currentUser.user.grantLevel >= actionPriority;
}

function login(user: AuthenticatedUser) {
  storage.save(STORAGE_KEY, user);
}

function logout() {
  storage.remove(STORAGE_KEY);
}

export default {
  getCurrentUser,
  isAuthenticated,
  isGranted,
  login,
  logout,
  hasDashboardAccess,
  hasJobCreationBalance,
};
