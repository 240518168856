// @flow

import React, { useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';

import { type JobsState } from '../../redux/modules/jobs';

import AuthService from '../../services/auth';
import { UserGrantMap } from '../../enums/userGrant';
import { UserTypeMap } from '../../enums/userType';
import { themePalette } from '../../theme/index';
import { AuthenticatedUser } from '../../types/user';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';

import Icon from '../../components/elements/Icon';
import WalletIcon from '../../components/elements/Icon/walletIcon';
import LinkButton from '../../components/ui/LinkButton';

import NoData from './NoData';
import ListItem from './ListItem';
import useTranslator from '../../hooks/useTranslator';
import Loading from './Loading';

type Props = {
  jobs: JobsState,
  loadingMore: boolean,
  onReachEnd: () => void,
  currentUser: AuthenticatedUser,
  location: any,
  getJobListWithSorting: (orderBy?: number, orderByDir?: number) => void,
  sortedState: string,
  setSortedState: React.Dispatch<React.SetStateAction<string>>,
  sortedStateDirection: string,
  setSortedStateDirection: React.Dispatch<React.SetStateAction<string>>,
};

const List = ({
  jobs,
  loadingMore,
  onReachEnd,
  currentUser,
  location,
  getJobListWithSorting,
  sortedState,
  setSortedState,
  sortedStateDirection,
  setSortedStateDirection,
}: Props) => {
  const { t } = useTranslator();
  const isCurrentUserHR = AuthService.isGranted(UserGrantMap.HR_GRANT);

  const isInitialRender = useRef(true);

  if (
    !jobs.data.length &&
    !currentUser.user.jobCreationBalance &&
    currentUser.user.userType === UserTypeMap.COMPANY
  ) {
    return (
      <Redirect
        to={{
          pathname: '/packages',
          state: { from: location },
        }}
      />
    );
  }

  const onSortedStatedChange = sortedStateInput => {
    if (sortedState === sortedStateInput) {
      if (sortedStateDirection === 'DESC') {
        setSortedState(null);
        setSortedStateDirection(null);
        return;
      }

      const newDirection = sortedStateDirection === 'ASC' ? 'DESC' : 'ASC';
      setSortedStateDirection(newDirection);
    } else {
      setSortedState(sortedStateInput);
      setSortedStateDirection('ASC');
    }
  };

  const getIconName = fieldName => {
    if (sortedState === fieldName) {
      if (sortedStateDirection === 'ASC') {
        return 'angle-up';
      }
      return 'angle-down';
    }
    return 'filter';
  };

  if (jobs.data.length === 0) {
    return <NoData />;
  }

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    getJobListWithSorting(sortedState, sortedStateDirection);
  }, [sortedState, sortedStateDirection]);

  return (
    <div className="u-pad-ends@md-up u-pad-ends-small@sm-down">
      <Container>
        <div className="u-hidden@md-up u-gap-bottom">
          {currentUser.user.userType === UserTypeMap.COMPANY && (
            <LinkButton
              to="/packages"
              className="u-gap-right"
              style={{
                backgroundColor: 'transparent',
                color: '#000',
              }}
            >
              <WalletIcon />
              <span className="u-gap-left-small">{currentUser.user.jobCreationBalance || 0}</span>
            </LinkButton>
          )}
          <LinkButton
            primary
            className="u-full-width"
            to="/jobs/create"
            style={{
              backgroundColor: themePalette.primary_color,
              boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
            }}
          >
            <Icon name="add" className="u-gap-right" />
            <span>{t('jobs.addJob')}</span>
          </LinkButton>
        </div>
        <div
          className="u-gap-bottom-small u-pad-sides-small u-font-size-sm u-hidden@md-down"
          style={{ marginRight: '1rem' }}
        >
          <Row className="u-text-center u-gap-bottom@md-down">
            <Col lg={2}>
              <span />
            </Col>
            <Col lg={2} className="u-text-left" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              {t('jobs.jobListTitle')}
              <Icon
                size="small"
                name={getIconName('jobTitle')}
                style={{ color: sortedState === 'jobTitle' ? 'red' : 'black' }}
                className="c-form-group__icon"
                onClick={() => onSortedStatedChange('jobTitle')}
              />
              {'  '}&nbsp;
              {t('jobs.jobListDate')}{' '}
              <Icon
                size="small"
                name={getIconName('openedDate')}
                style={{ color: sortedState === 'openedDate' ? 'red' : 'black' }}
                className="c-form-group__icon"
                onClick={() => onSortedStatedChange('openedDate')}
              />
            </Col>
            <Col lg={isCurrentUserHR ? 2 : 1}>
              {t('jobs.status')}{' '}
              <Icon
                size="small"
                name={getIconName('status')}
                style={{ color: sortedState === 'status' ? 'red' : 'black' }}
                className="c-form-group__icon"
                onClick={() => onSortedStatedChange('status')}
              />
            </Col>
            <Col lg={isCurrentUserHR ? 1 : 2} className="u-text-center">
              {t('jobs.jobListCount')}
            </Col>
            <Col lg={isCurrentUserHR ? 1 : 2} className="u-text-center">
              {t('jobs.unreviewed')}
            </Col>
            <Col lg={isCurrentUserHR ? 1 : 2} className="u-text-center">
              {t('jobs.shortList')}
            </Col>
            <Col lg={1} className="u-text-center">
              {t('jobs.view')}
            </Col>
            {isCurrentUserHR && (
              <Col lg={2} className="u-text-center">
                {t('jobs.branchName')}{' '}
                <Icon
                  size="small"
                  name={getIconName('branchName')}
                  style={{ color: sortedState === 'branchName' ? 'red' : 'black' }}
                  className="c-form-group__icon"
                  onClick={() => onSortedStatedChange('branchName')}
                />
              </Col>
            )}
          </Row>
        </div>
        <div
          className="c-box__scroll"
          onScroll={e => {
            const element = e.target;

            if (element.scrollHeight - element.scrollTop <= element.clientHeight + 5) {
              onReachEnd();
            }
          }}
        >
          {jobs.data.map(job => (
            <ListItem key={job.id} job={job} />
          ))}

          {loadingMore ? <Loading /> : null}
        </div>
      </Container>
    </div>
  );
};

export default List;
