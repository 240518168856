// @flow

import React from 'react';

import Container from '../components/layout/Container';
import Row from '../components/layout/Row';
import Col from '../components/layout/Col';
import Box from '../components/elements/Box';
import Logo from '../components/shared/Logo';
import LinkButton from '../components/ui/LinkButton';
import useTranslator from '../hooks/useTranslator';
import { themePalette } from '../theme';

const UnexpectedError = () => {
  const { t } = useTranslator();

  return (
    <section className="u-pad-ends-large">
      <Container>
        <Row>
          <Col lg={6} className="col--offset-lg-3">
            <Box className="u-pad-ends-medium@md-up u-pad-ends@sm-down u-pad-sides-medium@md-up u-pad-sides@sm-down u-text-center">
              <Logo
                className="c-box__center-logo"
                alt={t('layout.header.title')}
                style={{ width: 150, height: 150, objectFit: 'scale-down' }}
              />
              <h1 className="u-font-weight-bold u-text-center u-gap-top-large">
                {t('common.errors.unexpected')}
              </h1>
              <LinkButton
                primary
                to="/"
                style={{
                  backgroundColor: themePalette.primary_color,
                  boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                }}
              >
                {t('common.glossary.backToHomePage')}
              </LinkButton>
            </Box>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UnexpectedError;
