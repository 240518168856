// @flow

import React, { type Node } from 'react';

import { useTranslation } from 'react-i18next';
import CircleImage from '../../../components/elements/CircleImage';
import Badge from '../../../components/ui/Badge';
import WorkerRating from '../../../components/application/WorkerRating';
import { experienceToText } from '../../../utils/experience';

import { type Worker } from '../../../types/user';

type Props = {
  profile: Worker,
  children?: Node,
  workerRating: number,
};

const ProfileHeader = ({ profile, workerRating, children }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="u-flex u-pad-ends-small u-pad-sides-small">
        <CircleImage src={profile.imageUrl} />
        <div className="u-gap-sides-small u-flex-grow-full">
          <h2 className="u-clear-gap-top u-gap-bottom-xsmall u-font-weight-bold">
            {profile.firstName} {profile.lastName}
          </h2>
          <WorkerRating workerRatingScore={workerRating} />
          <p className="u-clear-gap u-color-dark">{profile.email}</p>
          <p className="u-clear-gap u-color-dark">{profile.phone}</p>
          <p>
            {profile.location && (
              <Badge
                className="u-inline-block u-bg-gray u-color-dark u-gap-right-small u-uppercase"
                type="info"
                small
              >
                {profile.location.name}
              </Badge>
            )}

            {profile.workTimePreference && (
              <Badge
                className="u-inline-block u-bg-gray u-color-dark u-gap-right-small u-uppercase"
                type="info"
                small
              >
                {profile.workTimePreference
                  ? t('constants.workTimeMap')[profile.workTimePreference]
                  : t('jobDetail.profile.unknown')}
              </Badge>
            )}

            <Badge
              className="u-inline-block u-bg-gray u-color-dark u-gap-right-small u-uppercase"
              type="info"
              small
            >
              {experienceToText(profile.experiences, t)}
            </Badge>
          </p>
        </div>
        <div className="u-gap-left-small u-text-right">{children}</div>
      </div>
    </div>
  );
};

export default ProfileHeader;
