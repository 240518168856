// @flow

import moment from 'moment';
import {
  AddOn,
  CompanySubscription,
  Package,
  PackageFeature,
  Product,
  ProductCommonType,
  ProductType,
  Subscription,
} from '../types/product';

type ProductFeaturePayload = {
  description: string,
  order: number,
  preview: number,
  icon?: any,
};

type CreditTypeName = 'jobCreationAmount' | 'threadCreationAmount';

export type ProductPayload = {
  id: number,
  createdBy: string | null,
  updatedBy: string | null,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  title: string,
  type: 'package' | 'addon' | 'product',
  features: Array<ProductFeaturePayload>,
  price: number,
  originalPrice: number | null,
  isPopular: boolean,
  credit: number,
  creditType: { id: number, name: CreditTypeName } | null,
  creditTypeId: number,
  isDashboardAccess: boolean,
  order: number,
  currency: string,
  isForNewcomers: boolean,
  isForReturnees: boolean,
  testGroup: any | null,
  countryId: number,
  isStandalone: boolean,
  isJobRelated: boolean,
  relatedPackages: any | null,
  purchaseLimit: number,
  isSpecial: boolean,
  imageUrl?: string,
  products: {
    credit: number,
    creditType: { id: number, name: 'jobCreationAmount' | 'threadCreationAmount' },
    creditTypeId: number,
    id: number,
    title: string,
    type: 'product',
  }[],
  rulePrice: any,
  rulePriceTTL: string,
};

const calculateCreationAmount = (response: ProductPayload, amountType: CreditTypeName): number => {
  try {
    if (response.creditType) {
      return amountType === response.creditType.name ? response.credit : 0;
    }
    return response.products
      .filter(p => p.creditType.name === amountType)
      .map(p => p.credit)
      .reduce((f, s) => f + s, 0);
  } catch (error) {
    console.error('ProductMapper - calculateCreationAmount - ', error);
    return 0;
  }
};

const FeatureMapper = {
  fromApiResponse(response: ProductFeaturePayload): PackageFeature {
    return {
      order: response.order,
      preview: response.preview === 1,
      description: response.description,
      icon: response.icon,
    };
  },
  fromApiResponseMultiple(response: Array<ProductFeaturePayload>): Array<PackageFeature> {
    return response ? response.map(FeatureMapper.fromApiResponse) : [];
  },
};

export const ProductMapper = {
  fromApiResponse(response: ProductPayload): ProductType {
    const COMMON_OBJECT: ProductCommonType = {
      id: response.id,
      title: response.title,
      features: FeatureMapper.fromApiResponseMultiple(response.features),
      price: response.price,
      originalPrice: response.originalPrice,
      isPopular: response.isPopular,
      credit: response.credit,
      creditTypeId: response.creditTypeId,
      isDashboardAccess: response.isDashboardAccess,
      order: response.order,
      currency: response.currency,
      isForNewcomers: response.isForNewcomers,
      isForReturnees: response.isForReturnees,
      testGroup: response.testGroup,
      countryId: response.countryId,
      isStandalone: response.isStandalone,
      isJobRelated: response.isJobRelated,
      relatedPackages: response.relatedPackages,
      purchaseLimit: response.purchaseLimit,
      isSpecial: response.isSpecial,
      jobCreationAmount: calculateCreationAmount(response, 'jobCreationAmount'),
      threadCreationAmount: calculateCreationAmount(response, 'threadCreationAmount'),
    };

    if (response.type === 'package') {
      return {
        ...COMMON_OBJECT,
        type: 'package',
        rulePrice: response.rulePrice,
        rulePriceTTL: response.rulePriceTTL ? moment(response.rulePriceTTL) : null,
      };
    }

    if (response.type === 'addon') {
      return {
        ...COMMON_OBJECT,
        type: 'addon',
        imageUrl: response.imageUrl,
      };
    }

    if (response.type === 'product') {
      return {
        ...COMMON_OBJECT,
        type: 'product',
      };
    }

    return COMMON_OBJECT;
  },
  fromApiResponseMultiple(response: Array<ProductPayload>): Array<ProductType> {
    return response.map(ProductMapper.fromApiResponse);
  },
};

export const SubscriptionMapper = {
  fromApiResponse(response: any): Subscription {
    return {
      id: response.id,
      type: 'subscription_package',
      interval: response.interval,
      isPurchasable: response.isPurchasable,
      isUpgradeable: response.isUpgradeable,
      currency: response.currency,
      period: response.period,
      price: response.price,
      product: ProductMapper.fromApiResponse(response.product),
    };
  },
  fromApiResponseMultiple(response: Array<any>): Array<Subscription> {
    return response.map(SubscriptionMapper.fromApiResponse);
  },
};

export const CompanySubscriptionMapper = {
  fromApiResponse(response: any): CompanySubscription {
    return {
      id: response.id,
      planId: response.planId,
      startsAt: moment(response.startsAt),
      nextAttemptAt: moment(response.nextAttemptAt),
      endsAt: moment(response.endsAt),
      status: response.status,
      plan: SubscriptionMapper.fromApiResponse(response.plan),
    };
  },
  fromApiResponseMultiple(response: Array<any>): Array<CompanySubscription> {
    return response.map(CompanySubscriptionMapper.fromApiResponse);
  },
};
