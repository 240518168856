// @flow

import api from '../utils/api';

import { AuthenticatedUserMapper } from '../mappers/user';
import { type AuthenticatedUser } from '../types/user';
import { type Token } from '../types/fcm';

export type LoginData = {
  email: string,
  password: string,
};

export type ResetPasswordData = {
  user_update_password: {
    firstPassword: string,
    secondPassword: string,
  },
};
export type ForgetPasswordData = {
  email: string,
};

export type RegisterData = {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  companyName: string,
  phone: string,
  location: string,
  country: number,
  phoneVerifyCode: string,
};

export type ProfileUpdateData = {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  location: string,
  taxOffice: number,
  taxOfficeNumber: string,
  commercialTitle: string,
  website: string,
  imageFileBase64?: string,
};

const HEADER_COUNTRY = 'X-App-Country';

// eslint-disable-next-line import/prefer-default-export
export const login = async (data: LoginData): Promise<AuthenticatedUser> => {
  const response = await api.post('/user/login', data);
  if (!response.ok) {
    throw response.data;
  }

  return AuthenticatedUserMapper.fromAPIResponse(response.data.payload);
};

export const profileUpdate = async (data: ProfileUpdateData): Promise<AuthenticatedUser> => {
  const response = await api.post('/user/profile-update', data);

  if (!response.ok) {
    throw response.data;
  }

  return AuthenticatedUserMapper.fromAPIResponse(response.data.payload);
};

export const me = async (): Promise<AuthenticatedUser> => {
  const response = await api.get('/user/me');

  if (!response.ok) {
    throw response.data;
  }

  api.setHeader(HEADER_COUNTRY, response.data.payload.user.country.country_code);

  return AuthenticatedUserMapper.fromAPIResponse(response.data.payload);
};

export const updateFCMToken = async (token: Token): Promise<boolean> => {
  const response = await api.post('/user/update-fcm-token', {
    fcm_token: token,
  });

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

// eslint-disable-next-line import/prefer-default-export
export const register = async (data: RegisterData): Promise<AuthenticatedUser> => {
  const response = await api.put('/user/company/register', data);
  if (!response.ok) {
    throw response.data;
  }

  if (response.data.payload.message === 'user.phone.verify.unique') {
    throw new Error('common.apiValidations.user.phone.verify.unique');
  }

  if (response.data.payload.message === 'user.phone.verified_code.error') {
    throw new Error('common.apiValidations.user.phone.verify.invalidCode');
  }

  return AuthenticatedUserMapper.fromAPIResponse(response.data.payload);
};

export const resetPasswordValidToken = async (resetPasswordCode: string) => {
  const response = await api.get(`user/reset-password-valid-token/${resetPasswordCode}`);
  return response.ok;
};

export const resetPassword = async (resetPasswordCode: string, data: ResetPasswordData) => {
  const response = await api.post(`user/reset-password/${resetPasswordCode}`, data);

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

export const forgetPassword = async (data: ForgetPasswordData) => {
  const response = await api.post(`user/v2/forget-password`, data);

  if (!response.ok) {
    throw response.data;
  }

  return true;
};
