// @flow
export default {
  inbox: {
    isSureToDeleteConversation: 'Are You Sure You Want To Delete This Conversation?',
    blockUserQuestion: 'Are You Sure You Want To Block This User?',
    deleteConversation: 'Delete Conversation',
    blockUser: 'Block User',
    experience: 'Work Experience',
    noMessage: 'No messages',
    messagesNotFound: 'You have no messages.',
    appointmentConfirmed:
      'The interview is set for {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}}.',
    appointmentNotConfirmed:
      'Interview {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} not confirmed.',
    appointmentCanceledCompany:
      'The interview {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} is cancelled.',
    appointmentCanceledOutOf24Hours:
      'The interview {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} was canceled because there was no response to the invitation within 24 hours.',
    appointmentUpdated: 'Interview {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} updated.',
    statuses: {
      main: 'Appointment {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}}',
      approved: 'Appointment {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} approved.',
      rejected: 'Appointment {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} rejected.',
      canceled: 'Appointment {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} canceled.',
      expired: 'Appointment {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} expired.',
      waiting: 'Appointment {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} waiting for confirm.',
    },
  },
};
