// @flow

import React from 'react';

import noMessage from '../../img/empty-state/no-application.svg';
import useTranslator from '../../hooks/useTranslator';

const NoData = () => {
  const { t } = useTranslator();

  return (
    <div className="u-pad-ends-large u-text-center">
      <img src={noMessage} width={270} height={150} alt={t('inbox.noMessage')} />

      <h3 className="u-color-gull-gray u-gap-bottom-medium">{t('inbox.messagesNotFound')}</h3>
    </div>
  );
};

export default NoData;
