// @flow

import React from 'react';

type Props = {
  /** option value */
  value: string,

  children: string,
};

/**
 * Option component for Select component
 */
const Option = ({ value, children, ...otherProps }: Props) => (
  <option value={value} {...otherProps}>
    {children}
  </option>
);

export default Option;
