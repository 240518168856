// @flow

import React from 'react';

type Props = {
  /** textarea text */
  value: string,

  /** rows */
  rows?: number,

  /** cols */
  cols?: number,

  /** extra className */
  className?: string,

  /** if input is disabled */
  disabled?: boolean,

  /** onChange event handler */
  onChange: (value: string) => void,
};

/**
 * Controlled textarea component
 */
const Textarea = ({
  value,
  className = '',
  disabled = false,
  rows,
  cols,
  onChange,
  ...otherProps
}: Props) => {
  const classNames = ['c-form-control c-form-control--textarea'];

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    <textarea
      className={classNames.join(' ')}
      value={value}
      rows={rows}
      cols={cols}
      disabled={disabled}
      onChange={(event: SyntheticInputEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value);
      }}
      {...otherProps}
    />
  );
};

export default Textarea;
