// @flow

import apiNew from '../utils/apiNew';
import { CompanySubscriptionMapper, ProductMapper, SubscriptionMapper } from '../mappers/product';
import { type CompanySubscription, type ProductType, type Subscription } from '../types/product';

// eslint-disable-next-line import/prefer-default-export
export const getProductDetail = async (id: number): Promise<ProductType> => {
  const response = await apiNew.get<any>(`/product/${id}`);

  if (!response.ok || !response) {
    throw response.data;
  }

  return ProductMapper.fromApiResponse(response.data.payload);
};

export const getProducts = async (type: "all" | "package" | "addon", relatedPackageId?: number): Promise<Array<ProductType>> => {
  const queryParams = new URLSearchParams();
    if (type !== "all") {
      queryParams.append("type", type);
    }
    if (type === "addon" && relatedPackageId) {
      queryParams.append("relatedPackageId", String(relatedPackageId));
    }

    const response = await apiNew.get(`/product?${queryParams.toString()}`);

  if (!response.ok || !response) {
    throw response.data;
  }

  return ProductMapper.fromApiResponseMultiple(response.data.payload);
};

export const getSubscriptions = async (): Promise<Array<ProductType>> => {
  const response = await apiNew.get<any>("/product/subscription/plan");

  if (!response.ok || !response) {
    throw response.data;
  }

  return SubscriptionMapper.fromApiResponseMultiple(response.data.payload);
};

export const getMySubscription = async (): Promise<CompanySubscription | null> => {
  const response = await apiNew.get<any>("/product/subscription");

  if (!response.ok || !response) {
    throw response.data;
  }

  const subscriptions = CompanySubscriptionMapper.fromApiResponseMultiple(response.data.payload);
  const lastSubscriptionIndex = subscriptions.length - 1;
  return subscriptions.length > 0 && subscriptions[lastSubscriptionIndex].status === "active" ? subscriptions[lastSubscriptionIndex] : null;
};

export const cancelMySubscription = async (id: number) => {
  const response = await apiNew.delete<any>(`/product/subscription/${id}/cancel`);

  if (!response.ok || !response) {
    throw response.data;
  }

  return true;
};
