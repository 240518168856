// @flow

import api from '../utils/api';
import apiNew from '../utils/apiNew';
import { AppointmentMapper, AppointmentNewMapper } from '../mappers/appointment';

import { type Location } from '../types/location';
import { type Appointment } from '../types/appointment';

export const getAppointmentList = async (
  jobId: string | number,
  startedDate: string,
): Promise<Array<Appointment>> => {
  const response = await apiNew.get(
    `/appointment/job/${jobId}?startFrom=${startedDate}&withFeedback=true&withStatusLog=true`,
  );

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentNewMapper.fromAPIResponseMultiple(response.data.payload);
};

export const getAllAppointmentByApplicationIds = async (
  applicationIds: number[],
  startedFrom?: string,
): Promise<Array<Appointment>> => {
  const response = await apiNew.post(`/appointment/application`, {
    applicationIds,
    startedFrom,
    withFeedback: true,
    withStatusLog: true,
  });

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentNewMapper.fromAPIResponseMultiple(response.data.payload);
};

export const appointmentDetail = async (appointmentId: number): Promise<Appointment> => {
  const response = await api.get(`/appointments/${appointmentId}`);

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentMapper.fromAPIResponse(response.data.payload.appointment);
};

export const cancelAppointment = async (appointmentId: number) => {
  const response = await api.delete(`/appointments/${appointmentId}`);

  if (!response.ok) {
    throw response.data;
  }

  return true;
};

type CreateAppointmentData = {
  appointmentAt: moment,
  location: Location,
  address?: string,
};

export const createAppointmentForApplication = async (
  applicationId: number,
  data: CreateAppointmentData,
): Promise<Appointment> => {
  const response = await api.post(`/appointments/create-for-application/${applicationId}`, {
    appointmentAt: data.appointmentAt,
    lat: data.location.latitude,
    lng: data.location.longitude,
    address: data.address,
  });

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentMapper.fromAPIResponse(response.data.payload.appointment);
};

export const appointmentDetailByApplication = async (
  applicationId: number,
): Promise<Appointment> => {
  const response = await api.get(`/appointments/find-by-application/${applicationId}`);

  if (!response.ok) {
    throw response.data;
  }

  return AppointmentMapper.fromAPIResponse(response.data.payload.appointment);
};
