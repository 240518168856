// @flow

import React, { type Node } from 'react';

type Props = {
  /** extra className */
  className?: string,

  /** container children */
  children: Node,

  /** class-prefix `col--xs-` Extra small devices Phones (<544px) */
  xs?: number,

  /** class-prefix `col--sm-` Small devices (≥544px) */
  sm?: number,

  /** class-prefix `col--md-` Small devices Tablets (≥768px) */
  md?: number,

  /** class-prefix `col--lg-` Medium devices Desktops (≥992px) */
  lg?: number,

  /** class-prefix `col--xl-` Large devices Desktops (≥1200px) */
  xl?: number,
};

/**
 * Col Element
 */
const Col = ({ className = '', children, xs, sm, md, lg, xl, ...otherProps }: Props) => {
  const classNames = ['col'];

  if (xl) {
    classNames.push(`col--xl-${xl.toString()}`);
  }

  if (lg) {
    classNames.push(`col--lg-${lg.toString()}`);
  }

  if (md) {
    classNames.push(`col--md-${md.toString()}`);
  }

  if (sm) {
    classNames.push(`col--sm-${sm.toString()}`);
  }

  if (xs) {
    classNames.push(`col--xs-${xs.toString()}`);
  }

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} {...otherProps}>
      {children}
    </div>
  );
};

export default Col;
