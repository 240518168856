// @flow

export const MessageStatusMap = {
  SUCCESS: 1,
  WAITING: 2,
};
export const ErrorTypeMessageStatusMap = {
  improper_message: MessageStatusMap.SUCCESS,
  sleep_mode: MessageStatusMap.WAITING,
};

export const MessageStatusDefault = MessageStatusMap.SUCCESS;
