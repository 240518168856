// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { withRouter, type Match, type RouterHistory } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import FileSaver from "file-saver"
import {
  loadThunk,
  fetchAllApplicationsThunk,
  fetchShortListedApplicationsThunk,
  getDetailById,
  type JobDetailItemState,
} from '../../redux/modules/jobDetail';
import { loadThunk as loadAllJobsThunk, type JobsState } from '../../redux/modules/jobs';
import { downlaodApplicationsAsExcel, exportAsExcel, getApplicationListForExcelDownload } from '../../api/job';
import { isLoading, isFailed } from '../../utils/apiState';

import { swalOkButtonTranslateKey } from '../../constants';

import { type ReduxState } from '../../redux/modules';
import { type ReduxDispatch } from '../../types/redux';
import { type AuthenticatedUser } from '../../types/user';

import Header from '../../components/shared/Header';
import Alert from '../../components/elements/Alert';
import Navbar from './Navbar';
import Loading from './Loading';
import Detail from './Detail';
import ExcelModal from './ExcelModal';
import ExcelDownloadModal from './ExcelDownloadModal';

type Props = {
  match: Match,
  history: RouterHistory,
  currentUser: AuthenticatedUser,
  jobDetail: JobDetailItemState,
  jobId: number,
  allJobs: JobsState,
  loadThunk: number => void,
  loadAllJobsThunk: () => void,
  fetchAllApplicationsThunk: (jobId: number, page: number) => void,
  fetchShortListedApplicationsThunk: (jobId: number, page: number) => void,
  push: Function,
  t: Function,
};

type State = {
  allApplicationLoading: boolean,
  shortlistedApplicationsLoading: boolean,
  showExcelAlert: boolean,
  showExcelModal: boolean,
  showExcelDownloadModal: boolean,
  allApplicationsPage: numnber,
  shortlistedApplicationsPage: numnber,
  loadingMore: boolean,
};

class JobDetail extends PureComponent<Props, State> {
  props: Props;

  state: State;

  handleJobChange: string => void;

  handleExcelClick: () => void;

  handleExcelAlertClose: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      allApplicationLoading: true,
      shortlistedApplicationsLoading: true,
      showExcelAlert: false,
      showExcelModal: false,
      showExcelDownloadModal: false,
      allApplicationsPage: 1,
      shortlistedApplicationsPage: 1,
      loadingMore: false,
    };

    this.handleJobChange = this.handleJobChange.bind(this);
    this.handleExcelClick = this.handleExcelClick.bind(this);
    this.handleExcelAlertClose = this.handleExcelAlertClose.bind(this);
  }

  componentWillMount() {
    this.props.loadThunk(this.props.jobId);
    this.props.loadAllJobsThunk();
    this.loadJobDetailAllApplicantions();
    this.loadJobDetailShortListedApplicantions();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (isFailed(nextProps.jobDetail) && nextProps.jobId !== '0') {
      if (typeof nextProps.jobDetail.error === 'object' && nextProps.jobDetail.error.code === 404) {
        this.props.push('/jobs');
        return;
      }

      this.props.push('/error');
    } else if (nextProps.jobId !== this.props.jobId) {
      this.setState(
        {
          allApplicationLoading: true,
          shortlistedApplicationsLoading: true,
          allApplicationsPage: 1,
          shortlistedApplicationsPage: 1,
        },
        () => {
          this.props.loadThunk(nextProps.jobId);
          this.props.loadAllJobsThunk();
          this.loadJobDetailAllApplicantions();
          this.loadJobDetailShortListedApplicantions();
        },
      );
    }
  }

  loadJobDetailAllApplicantions = async () => {
    const pageCount =
      this.props.jobDetail.data.allApplicationsPaginator &&
      this.props.jobDetail.data.allApplicationsPaginator.page_count
        ? this.props.jobDetail.data.allApplicationsPaginator.page_count
        : 1;

    if (!this.state.loadingMore && pageCount + 1 > this.state.allApplicationsPage) {
      this.setState({
        loadingMore: true,
      });

      await this.props.fetchAllApplicationsThunk(this.props.jobId, this.state.allApplicationsPage);

      this.setState(state => ({
        allApplicationsPage: state.allApplicationsPage + 1,
        allApplicationLoading: false,
        loadingMore: false,
      }));
    }
  };

  loadJobDetailShortListedApplicantions = async () => {
    const pageCount =
      this.props.jobDetail.data.shortListedApplicationsPaginator &&
      this.props.jobDetail.data.shortListedApplicationsPaginator.page_count
        ? this.props.jobDetail.data.shortListedApplicationsPaginator.page_count
        : 1;

    if (!this.state.loadingMore && pageCount + 1 > this.state.shortlistedApplicationsPage) {
      this.setState({
        loadingMore: true,
      });

      await this.props.fetchShortListedApplicationsThunk(
        this.props.jobId,
        this.state.shortlistedApplicationsPage,
      );

      this.setState(state => ({
        shortlistedApplicationsPage: state.shortlistedApplicationsPage + 1,
        shortlistedApplicationsLoading: false,
        loadingMore: false,
      }));
    }
  };

  handleJobChange(jobId: string) {
    if (parseInt(jobId, 10) === this.props.jobDetail.data.job.id) {
      return;
    }
    this.props.push(`/jobs/detail/${jobId}`);
  }

  async handleExcelClick() {
    this.setState({ showExcelModal: true });
  }

  handleExcelModalSubmit = (values: Object) => {
    this.sendExcel(values.email);
  };

  sendExcel = (email: string) => {
    const jobId = this.props.jobDetail.data.job.id;

    try {
      exportAsExcel(jobId, email);
      this.setState({ showExcelAlert: true, showExcelModal: false });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert('Bir hata meydana geldi.');
    }
  };

  handleExcelModalClose = () => {
    this.setState({ showExcelModal: false });
  };

  handleExcelAlertClose() {
    this.setState({ showExcelAlert: false });
  }

  handleExcelDownloadClick = () => {
    this.setState({ showExcelDownloadModal: true });
  }

  handleExcelDownloadModalSubmit = (values: Object) => {

    const DATE = (values.date && values.date.length) ? `${values.year}-${values.month}-${values.day}` : null
    const SHORTLIST_ADDED = values.onlyShortlisted ? 1 : 0
    this.downloadExcel(SHORTLIST_ADDED, DATE);
  };

  downloadExcel = async (shortlistadded, date) => {
    const jobId = this.props.jobDetail.data.job.id;
    try {
      const { applications } = await getApplicationListForExcelDownload(shortlistadded, date, jobId)
      const { result, fileName } = await downlaodApplicationsAsExcel({ applications, language: this.props.currentUser.user.language.languageCode })

      const blob = new Blob([result], { type: "application/xlsx" })

      FileSaver.saveAs(blob, `${fileName}.xlsx`)

      this.setState({ showExcelDownloadModal: false });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert('Bir hata meydana geldi.');
    }
  };

  handleExcelDownloadModalClose = () => {
    this.setState({ showExcelDownloadModal: false });
  };


  render() {
    const { allApplicationLoading, shortlistedApplicationsLoading } = this.state;
    const { currentUser, match, history, jobDetail, allJobs, jobId, t } = this.props;

    return (
      <div>
        <Header currentUser={currentUser} />

        <Navbar
          currentUser={currentUser}
          allJobs={allJobs}
          jobId={jobId.toString()}
          onJobChange={this.handleJobChange}
          onExcelClick={this.handleExcelClick}
          onExcelDownloadClick={this.handleExcelDownloadClick}
        />

        <Alert
          isVisible={this.state.showExcelAlert}
          onConfirmClick={this.handleExcelAlertClose}
          message={t('jobs.detail.sendExcelInfoText')}
          confirmLabel={t(swalOkButtonTranslateKey)}
          contentLabel={t('common.glossary.warning')}
        />

        <ExcelModal
          isOpen={this.state.showExcelModal}
          onSubmit={this.handleExcelModalSubmit}
          onClose={this.handleExcelModalClose}
        />

        <ExcelDownloadModal
          isOpen={this.state.showExcelDownloadModal}
          onSubmit={this.handleExcelDownloadModalSubmit}
          onClose={this.handleExcelDownloadModalClose}
        />

        {isLoading(jobDetail) || allApplicationLoading || shortlistedApplicationsLoading ? (
          <Loading />
        ) : (
          <Detail
            jobDetail={jobDetail}
            match={match}
            history={history}
            loadingMore={this.state.loadingMore}
            onReachEnd={activeTab => {
              if (activeTab === 'all') {
                this.loadJobDetailAllApplicantions();
              } else {
                this.loadJobDetailShortListedApplicantions();
              }
            }}
            allJobs={allJobs}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, { match }: { match: Object }): Object => ({
  currentUser: state.currentUser,
  jobDetail: getDetailById(state, { jobId: match.params.jobId }),
  jobId: match.params.jobId,
  allJobs: state.jobs,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    {
      loadThunk,
      fetchAllApplicationsThunk,
      fetchShortListedApplicationsThunk,
      loadAllJobsThunk,
      push,
    },
    dispatch,
  );

// $FlowFixMe
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobDetail)),
);
