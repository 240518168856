/* eslint-disable jsx-a11y/iframe-has-title */
// @flow
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Box from '../../components/elements/Box';

type Props = {
  history: Object,
};

const Iyzico3DSecure = ({ history }: Props) => {
  if (!history || !history.location || !history.location.state || !history.location.state.response) {
    return <Redirect to="/packages" />;
  }

  const iframeRef = useRef(null);
  const response = history.location.state.response;

  useEffect(() => {
    const iframeWindow = iframeRef.current && iframeRef.current.contentWindow;
    const doc =
      iframeRef.current &&
      (iframeRef.current.contentDocument || iframeRef.current.contentWindow.document);

    if (iframeWindow && doc) {
      doc.open();
      doc.write(response);
      doc.close();
    }
  }, [response]);

  const handlePayment = event => {
    const paymentResponse = JSON.parse(event.data);

    if (paymentResponse.status === false) {
      history.push(`/packages/payment-result?status=failure&errorKey=${paymentResponse.errorMessage}`);
      window.removeEventListener('message', handlePayment);
    } else if (paymentResponse.status) {
      window.location.replace('/packages/payment-result?status=success');
      window.removeEventListener('message', handlePayment);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handlePayment);
  }, []);

  return (
    <Box style={{ display: 'flex', flexGrow: 1, height: '100vh', overflow: 'hidden' }}>
      <iframe
        name="Iyzico3DSecure"
        ref={iframeRef}
        id="Iyzico3DSecure"
        style={{ width: '100%', height: '100%', border: 'none' }}
      ></iframe>
    </Box>
  );
};

export default connect()(Iyzico3DSecure);
