// @flow

import React, { type Node } from 'react';

type Props = {
  /** checkbox text */
  children?: Node,

  /** container className */
  containerClassName?: string,

  /** checkbox className */
  className?: string,

  /** if radio is disabled */
  disabled?: boolean,

  /** radio value */
  value: any,
};

/**
 * Controlled radio component
 */
const Radio = ({
  containerClassName = '',
  className = '',
  disabled = false,
  children,
  value,
  ...otherProps
}: Props) => {
  const containerClassNames = ['c-radio'];
  const classNames = ['c-radio__inp'];

  if (containerClassName.length > 0) {
    containerClassNames.push(containerClassName);
  }

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    /* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
    <label className={containerClassNames.join(' ')}>
      <input
        type="radio"
        disabled={disabled}
        value={value}
        className={classNames.join(' ')}
        {...otherProps}
      />
      <span className="c-radio__mask u-gap-right-small" />
      {children}
    </label>
  );
};

export default Radio;
