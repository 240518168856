// @flow

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import FormField from '../components/elements/FormField';
import required from '../validators/required';
import useTranslator from '../hooks/useTranslator';
import { themePalette } from '../theme/index';
import email from '../validators/email';
import ForgetPassword from '../routes/forgetPassword';
import Row from '../components/layout/Row';
import Col from '../components/layout/Col';
import Button from '../components/ui/Button';

type Props = {
  handleSubmit: Function,
  submitting: boolean,
  invalid: boolean,
};

const ForgetPasswordForm = ({ handleSubmit, submitting, invalid }: Props) => {
  const { t } = useTranslator();

  return (
    <form onSubmit={handleSubmit}>
      <h1 className='u-font-weight-bold u-text-center u-gap-top-large'>
        {t('forgetPassword.title')}
      </h1>
      <p className='u-font-weight-bold u-text-center u-gap-top-large'>{t('forgetPassword.body')}</p>
      <div className='u-gap-bottom-large'>
        <Field
          name="email"
          type="email"
          component={FormField}
          label={t('auth.loginForm.email.label')}
          validate={[
            required(t('register.registerForm.email.required')),
            email(t('common.validations.email')),
          ]}
        />
      </div>
      <div className='u-text-center'>
        <div className='c-navbar__body'>
          <Row className='u-flex-grow-full u-flex-align-middle'>
            <Col md={12}>
              <Button
                type='submit'
                primary
                disabled={invalid || submitting}
                loading={submitting}
                style={{
                  backgroundColor: themePalette.primary_color,
                  boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                }}
              >
                {t('forgetPassword.submit')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'forgetPassword',
})(ForgetPasswordForm);
