// @flow
export default {
  common: {
    errors: {
      errorOccured: 'Bir hata meydana geldi.',
      anErrorOccurred:
        'Bir hata oluştu! Lütfen daha sonra tekrar deneyin. Hata kodu: {{errorCode}}',
      unexpected: 'Beklenmedik bir hata oluştu',
    },
    validations: {
      phone: 'Lütfen telefon numaranızı 5XXXXXXXXX şeklinde giriniz.',
      email: 'Lütfen geçerli bir e-posta adresi giriniz.',
      integer: 'Lütfen geçerli bir değer giriniz.',
      maxLength: 'Bu alan için fazla karakter girdiniz.',
      maxNumber: 'Bu değer daha küçük olmalıdır.',
      minLength: 'Bu alan için az karakter girdiniz.',
      minNumber: 'Bu değer daha büyük olmalıdır.',
      required: 'Bu alan zorunludur',
    },
    glossary: {
      nonExistent: 'Yok',
      month: 'Ay',
      inexperienced: 'Tecrübesiz',
      jobApplied: 'İlana Başvuruldu',
      writeMessage: 'Mesaj yaz',
      yes: 'Evet',
      no: 'Hayır',
      edit: 'Düzenle',
      delete: 'Sil',
      select: 'Lütfen seçiniz',
      unknown: 'Bilinmiyor',
      save: 'Kaydet',
      cancel: 'Vazgeç',
      continue: 'Devam et',
      send: 'Gönder',
      editPhoto: 'FOTOĞRAFI DÜZENLE',
      ok: 'Tamam',
      warning: 'Uyarı',
      noResult: 'Sonuç Bulunamadı',
      searchInChoices: 'Seçenekler için arama yapın',
      loading: 'Yükleniyor',
      detail: 'Detay',
      removeChoice: 'Seçimi Temizle',
      backToHomePage: 'Anasayfaya Dön',
      unAuthorizedView: 'Bu sayfayı görüntülemek için yetkiniz bulunmuyor.',
      tryAgain: 'Yeniden Dene',
    },
    confirms: {
      isSureToContinue: 'Devam etmek istediğinize emin misiniz?',
    },
    alerts: {
      dashboardAccess:
        'Web platformumuzu kullanmanıza yardımcı olması için müşteri temsilcilerimizi 0850 840 5748 numaralı telefondan arayabilirsiniz.',
      jobCreation: 'Yeni paket satın alarak ilan kredilerinizi çoğaltabilirsiniz',
    },
    apiValidations: {
      user: {
        phone: {
          verify: {
            unique: 'Girdiğin telefon numarası başka bir kullanıcı tarafından kullanılıyor.',
            dailyLimit: 'Günlük deneme limitine ulaştın. Lütfen 24 saat sonra tekrar dene.',
            invalidCode: 'Girdiğin kod hatalı',
            notFoundHttpException:
              'Geçersiz telefon numarası. Lütfen geçerli bir cep telefonu numarası giriniz (Hata kodu: 1030)',
          },
        },
      },
    },
  },
};
