// @flow

import React from 'react';
import { type Simple } from '../../../types/notification';

type Props = Simple;

const SimpleNotification = (props: Props): any => (
  <div role="presentation" onClick={props.onClick} className="c-notification__message">
    <button type="button" className="c-notification__close-btn" onClick={props.onClose} />

    <div className="c-notification__message-container">
      <div className="u-flex u-flex-align-middle">
        <div className="u-flex-grow-full">
          <h5 className="u-clear-gap-top u-gap-bottom-xsmall u-font-weight-bold">{props.title}</h5>

          <span className="u-color-gull-gray">{props.body}</span>
        </div>
      </div>
    </div>
  </div>
);

export default SimpleNotification;
