// @flow

import React from 'react';

import Label from '../../ui/Label';
import Select from '../../ui/Select';
import Option from '../../ui/Option';
import useTranslator from '../../../hooks/useTranslator';

type Meta = {
  touched: boolean,
  error: string,
};

type Props = {
  input: any,
  label: string,
  meta: Meta,
  options: Array<Object>,
  disabled?: boolean,
  noDefaultOption?: boolean,
  float?: boolean,
};

/**
 * Form Field Select
 */
const FormFieldSelect = (props: Props) => {
  const { t } = useTranslator();
  return (
    <div
      className={`c-form-group u-gap-bottom ${props.meta.touched && props.meta.error ? 'has-error' : ''
        } ${props.float ? 'c-form-group--floating' : ''}`}
    >
      <Label htmlFor={props.input.name}>{props.label}</Label>
      <Select
        id={props.input.name}
        className="u-full-width"
        disabled={props.disabled}
        {...props.input}
      >
        {props.noDefaultOption ? null : <option value="">{t('constants.defaultSelect')}</option>}

        {props.options.map(option => (
          <Option key={option.key ? option.key : option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Select>
      {props.meta.touched && props.meta.error && (
        <span className="c-form-group__alert">{props.meta.error}</span>
      )}
    </div>
  );
};

export default FormFieldSelect;
