// @flow

export const JobStatusMap = {
  ACTIVE: 'active',
  CLOSED: 'closed',
};

export const JobStatusList = [
  { key: JobStatusMap.ACTIVE, value: 'Aktif' },
  { key: JobStatusMap.CLOSED, value: 'Pasif' },
];

export const JobStatusDefault = JobStatusMap.ACTIVE;
