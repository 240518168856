// @flow

async function request(): Promise<boolean> {
  try {
    await Notification.requestPermission();

    return true;
  } catch (err) {
    return false;
  }
}

export default { request };
