// @flow
export default {
  auth: {
    loginForm: {
      title: 'INICIO DE SESION',
      email: { label: 'E-mail', required: 'Escribe nombre de compañia' },
      password: {
        label: 'Contraseña',
        required: 'Escribe contraseña.',
        minLength: 'Mínimo 6 caracteres.',
        maxLength: 'Máximo 16 caracteres.',
      },
      register: 'REGISTRATE',
      forgotPassword: 'Olividé mi contraseña',
      login: 'INICIO DE SESION',
    },
    login: {
      error: {
        submissionError: {
          password: 'Usuario y contraseña inválidos.',
        },
      },
    },
  },
};
