// @flow

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import FormField from '../components/elements/FormField';
import Button from '../components/ui/Button';
import required from '../validators/required';
import minLength from '../validators/minLength';
import maxLength from '../validators/maxLength';
import Col from '../components/layout/Col';
import Row from '../components/layout/Row';
import useTranslator from '../hooks/useTranslator';
import { themePalette } from '../theme/index';

type Props = {
  handleSubmit: Function,
  submitting: boolean,
  invalid: boolean,
};

const ResetPasswordForm = ({ handleSubmit, submitting, invalid }: Props) => {
  const { t } = useTranslator();

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="u-font-weight-bold u-text-center u-gap-top-large">
        {t('resetPassword.form.resetPassword')}
      </h1>
      <div className="u-gap-bottom-large">
        <Field
          name="firstPassword"
          type="password"
          component={FormField}
          label={t('auth.loginForm.password.label')}
          validate={[
            required(t('auth.loginForm.password.required')),
            minLength(6, t('auth.loginForm.password.minLength')),
            maxLength(16, t('auth.loginForm.password.maxLength')),
          ]}
        />
      </div>
      <div className="u-gap-bottom-large">
        <Field
          name="secondPassword"
          type="password"
          component={FormField}
          label={t('auth.loginForm.password.label')}
          validate={[
            required(t('auth.loginForm.password.required')),
            minLength(6, t('auth.loginForm.password.minLength')),
            maxLength(16, t('auth.loginForm.password.maxLength')),
          ]}
        />
      </div>
      <div className="u-text-center">
        <div className="c-navbar__body">
          <Row className="u-flex-grow-full u-flex-align-middle">
            <Col md={12}>
              <Button
                type="submit"
                primary
                disabled={invalid || submitting}
                loading={submitting}
                style={{
                  backgroundColor: themePalette.primary_color,
                  boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                }}
              >
                {t('resetPassword.form.resetPassword')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'resetPassword',
})(ResetPasswordForm);
