// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import debounce from 'lodash.debounce';

import { withTranslation } from 'react-i18next';
import { UserGrantMap } from '../enums/userGrant';
import AuthService from '../services/auth';

import { type AuthenticatedUser } from '../types/user';

import * as jobAPI from '../api/job';

import { ContactOptionList } from '../enums/contactOption';
import { UserTypeMap } from '../enums/userType';

import FormField from '../components/elements/FormField';
import FormFieldSelect from '../components/elements/FormFieldSelect';
import FormSearchableSelect from '../components/elements/FormSearchableSelect';
import FormFieldCheck from '../components/elements/FormFieldCheck';
import FormFieldTextarea from '../components/elements/FormFieldTextarea';
import FormFieldMap from '../components/elements/FormFieldMap';
import GeoSuggestField from '../components/elements/GeoSuggestField';

import required from '../validators/required';
import integer from '../validators/integer';
import minNumber from '../validators/minNumber';
import maxNumber from '../validators/maxNumber';
import phone from '../validators/phone';
import minLength from '../validators/minLength';

import UploadDialog from '../components/abstract/UploadDialog';
import ImageCropModal from '../components/elements/ImageCropModal';
import Row from '../components/layout/Row';
import Col from '../components/layout/Col';
import Button from '../components/ui/Button';

import { type CategoriesState } from '../redux/modules/categories';
import { type BranchesState } from '../redux/modules/branches';
import { type ReduxState } from '../redux/modules';
import { themePalette } from '../theme/index';
import salaryExpectation from '../validators/salaryExpectation';

type Props = {
  handleSubmit: Function,
  change: (field: string, value: any) => void,
  submitting: boolean,
  categories: CategoriesState,
  branches: BranchesState,
  cover: string,
  latitude: number,
  longitude: number,
  centerLatitude: number,
  centerLongitude: number,
  zoom: number,
  isEdit: boolean,
  formViewData: any,
  contactOption: string,
  autoShortlistEnabled?: boolean,
  currentUser: AuthenticatedUser,
  t: Function,
};

type State = {
  isCoverModelOpened: boolean,
  cropModalImage: File | '',
  googleMapsInitialized: Boolean,
};

class JobForm extends PureComponent<Props, State> {
  props: Props;

  state: State = {
    isCoverModelOpened: false,
    cropModalImage: '',
    googleMapsInitialized: false,
  };

  handleCoverClick = (file: File) => {
    this.setState({ cropModalImage: file }, () => {
      this.openImageCropModal();
    });
  };

  openImageCropModal = () => {
    this.setState({ isCoverModelOpened: true });
  };

  closeImageCropModal = () => {
    this.setState({ isCoverModelOpened: false });
  };

  handleCroppedImage = (image: HTMLCanvasElement) => {
    this.props.change('cover', image.toDataURL('image/jpeg'));
    this.closeImageCropModal();
  };

  loadPositionOptions = debounce((value: string, cb: any) => {
    jobAPI.positions(value).then(positions => {
      const options = positions.map(position => ({ value: position.id, label: position.name }));
      cb(options);
    });
  }, 200);

  render() {
    const {
      handleSubmit,
      change,
      submitting,
      cover,
      latitude,
      longitude,
      centerLatitude,
      centerLongitude,
      zoom,
      categories,
      branches,
      contactOption,
      isEdit,
      formViewData,
      autoShortlistEnabled,
      currentUser,
      t,
    } = this.props;

    const { googleMapsInitialized } = this.state;

    const canAutoShortlist = currentUser.user.userType !== UserTypeMap.COMPANY;
    const { language } = currentUser.user;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="col--offset-md-3 u-gap-bottom">
              <div className="u-gap-bottom">
                <UploadDialog accept=".jpg,.png" onFileReady={this.handleCoverClick}>
                  {!cover && (
                    <span className="c-image-upload__content">
                      <svg
                        width="58"
                        height="46"
                        viewBox="0 0 58 46"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="#7C90A5">
                          <path d="M57 0H1C.448 0 0 .447 0 1v44c0 .553.448 1 1 1h56c.552 0 1-.447 1-1V1c0-.553-.448-1-1-1zm-1 44H2V2h54v42z" />
                          <path d="M16 22.138c3.07 0 5.57-2.498 5.57-5.568 0-3.072-2.5-5.57-5.57-5.57-3.07 0-5.57 2.498-5.57 5.57 0 3.07 2.5 5.568 5.57 5.568zM16 13c1.968 0 3.57 1.602 3.57 3.57 0 1.966-1.602 3.568-3.57 3.568-1.968 0-3.57-1.6-3.57-3.568 0-1.967 1.602-3.57 3.57-3.57zM7 40c.234 0 .47-.082.66-.25l16.313-14.36 10.302 10.3c.39.39 1.023.39 1.414 0 .39-.39.39-1.023 0-1.414L30.88 29.47l9.18-10.055 11.262 10.323c.407.373 1.04.345 1.413-.062s.346-1.04-.062-1.413l-12-11c-.196-.18-.457-.268-.72-.262-.265.013-.515.13-.694.326l-9.793 10.727-4.743-4.743c-.374-.373-.972-.392-1.368-.044L6.34 38.25c-.416.364-.456.996-.09 1.41.197.226.473.34.75.34z" />
                        </g>
                      </svg>
                      <span className="u-gap-top">{t('jobs.jobForm.uploadImage')}</span>
                    </span>
                  )}
                  {cover && (
                    <img
                      className="c-image-upload__content-img"
                      src={cover}
                      alt={t('jobs.jobForm.jobImage')}
                    />
                  )}
                </UploadDialog>
              </div>
              {isEdit && (
                <Field
                  name="status"
                  component={FormFieldSelect}
                  label={t('jobs.jobForm.status.label')}
                  validate={[required(t('jobs.jobForm.status.required'))]}
                  props={{
                    options: t('constants.jobStatusList').map(status => ({
                      id: status.key,
                      name: status.value,
                    })),
                  }}
                />
              )}
              {AuthService.isGranted(UserGrantMap.HR_GRANT) && (
                <Field
                  name="user"
                  component={FormFieldSelect}
                  label={t('jobs.jobForm.user.label')}
                  validate={[required(t('jobs.jobForm.user.required'))]}
                  props={{
                    options: branches.data.map(branch => ({
                      id: branch.id,
                      name: branch.companyName,
                    })),
                  }}
                />
              )}
              <Field
                name="title"
                type="text"
                component={FormField}
                label={t('jobs.jobForm.title.label')}
                validate={[required(t('jobs.jobForm.title.required'))]}
              />
              <Field
                name="position"
                component={FormSearchableSelect}
                label={t('jobs.jobForm.position.label')}
                validate={[required(t('jobs.jobForm.position.required'))]}
                props={{
                  placeholder: t('jobs.jobForm.position.placeholder'),
                  loadOptions: this.loadPositionOptions,
                }}
              />
              <Field
                name="category"
                component={FormFieldSelect}
                label={t('jobs.jobForm.category.label')}
                validate={[required(t('jobs.jobForm.category.required'))]}
                props={{
                  options: categories.data,
                }}
              />
              <Field
                name="fare"
                component={FormFieldSelect}
                label={t('constants.benefitMap.fare')}
                normalize={(value: string) => String(value) === 'true'}
                props={{
                  options: t('constants.benefit'),
                }}
              />
              <Field
                name="freeMeal"
                component={FormFieldSelect}
                label={t('constants.benefitMap.free_meal')}
                normalize={(value: string) => String(value) === 'true'}
                props={{
                  options: t('constants.benefit'),
                }}
              />
              <Field
                name="healthInsurance"
                component={FormFieldSelect}
                label={t('constants.benefitMap.health_insurance')}
                normalize={(value: string) => String(value) === 'true'}
                props={{
                  options: t('constants.benefit'),
                }}
              />
              <Field
                name="description"
                component={FormFieldTextarea}
                label={t('jobs.jobForm.description.label')}
                validate={[required(t('jobs.jobForm.description.required'))]}
                props={{
                  rows: 5,
                  verticalResize: true,
                }}
              />
              <Field
                name="contactOption"
                component={FormFieldSelect}
                label={t('jobs.jobForm.contactOption.label')}
                validate={[required(t('jobs.jobForm.contactOption.required'))]}
                props={{
                  options: t('constants.contact'),
                }}
              />

              {canAutoShortlist && (
                <Field
                  name="autoShortlistEnabled"
                  component={FormFieldSelect}
                  label={t('jobs.jobForm.autoShortlistEnabled.label')}
                  normalize={(value: string) => String(value) === 'true'}
                  props={{
                    options: t('constants.autoShortlistEnabled'),
                  }}
                />
              )}
              {canAutoShortlist && !!autoShortlistEnabled && (
                <Field
                  name="autoShortlistThreshold"
                  type="number"
                  component={FormField}
                  label={t('jobs.jobForm.autoShortlistThreshold.label')}
                  validate={[
                    integer(t('common.validations.integer')),
                    required(t('jobs.jobForm.autoShortlistThreshold.required')),
                    minNumber(
                      50,
                      t('jobs.jobForm.autoShortlistThreshold.minNumber', { count: 50 }),
                    ),
                    maxNumber(
                      100,
                      t('jobs.jobForm.autoShortlistThreshold.maxNumber', { count: 100 }),
                    ),
                  ]}
                />
              )}

              {contactOption === ContactOptionList.PHONE && (
                <Field
                  name="contactPhone"
                  type="text"
                  component={FormField}
                  label={t('jobs.jobForm.contactPhone.label')}
                  validate={[
                    required(t('jobs.jobForm.contactPhone.required')),
                    phone(t('common.validations.phone')),
                  ]}
                />
              )}
              <Field
                name="workTimePreferences"
                component={FormFieldCheck}
                label={t('jobs.jobForm.workTimePreferences.label')}
                validate={[minLength(1, t('jobs.jobForm.workTimePreferences.required'))]}
                props={{
                  options: Object.entries(t('constants.workTimeMap')).map(([key, value]) => ({
                    value: key,
                    text: value,
                  })),
                }}
              />
              <Field
                name="minExperienceDuration"
                component={FormFieldSelect}
                label={t('jobs.jobForm.minExperienceDuration.label')}
                props={{
                  options: t('constants.experienceDurationList').map(item => ({
                    id: item.key,
                    name: item.value,
                  })),
                }}
              />
              <Row>
                <Col md={6}>
                  <Field
                    className="u-clear-gap-bottom"
                    name="expectedSalaryMin"
                    type="number"
                    component={FormField}
                    label={t('jobs.jobForm.expectedSalaryMin.label')}
                    validate={[salaryExpectation(t('jobs.jobForm.expectedSalaryValidate'))]}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    className="u-clear-gap-bottom"
                    name="expectedSalaryMax"
                    type="number"
                    component={FormField}
                    label={t('jobs.jobForm.expectedSalaryMax.label')}
                    validate={[salaryExpectation(t('jobs.jobForm.expectedSalaryValidate'))]}
                  />
                </Col>
              </Row>
              <p className="u-gap-bottom">
                <small>{t('jobs.jobForm.infoText')}</small>
              </p>
              <Field
                name="gender"
                component={FormFieldSelect}
                label={t('jobs.jobForm.gender.label')}
                validate={[required(t('jobs.jobForm.gender.required'))]}
                props={{
                  options: t('constants.gender'),
                }}
              />
              {formViewData &&
                formViewData.militaryRequirements &&
                formViewData.militaryRequirements.isActive ? (
                <Field
                  name="militaryRequirements"
                  component={FormFieldCheck}
                  label={t('jobs.jobForm.militaryRequirements.label')}
                  props={{
                    options: Object.entries(t('constants.militaryStatusMap')).map(
                      ([key, value]) => ({
                        value: key,
                        text: value,
                      }),
                    ),
                  }}
                />
              ) : null}
              <Row>
                <Col md={6}>
                  <Field
                    name="birthDateMin"
                    type="text"
                    component={FormField}
                    label={t('jobs.jobForm.birthDateMin.label')}
                    validate={[
                      integer(t('common.validations.integer')),
                      minNumber(18, t('jobs.jobForm.birthDateMin.minNumber', { age: 18 })),
                    ]}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    name="birthDateMax"
                    type="text"
                    component={FormField}
                    label={t('jobs.jobForm.birthDateMax.label')}
                    validate={[
                      integer(t('common.validations.integer')),
                      minNumber(18, t('jobs.jobForm.birthDateMax.minNumber', { age: 18 })),
                    ]}
                  />
                </Col>
              </Row>
              <Field
                name="minEducationStatus"
                component={FormFieldSelect}
                label={t('jobs.jobForm.minEducationStatus.label')}
                props={{
                  options: Object.entries(t('constants.educationMap')).map(([key, value]) => ({
                    id: key,
                    name: value,
                  })),
                }}
              />
              {formViewData &&
                formViewData.minEnglishLevel &&
                formViewData.minEnglishLevel.isActive ? (
                <Field
                  name="minEnglishLevel"
                  component={FormFieldSelect}
                  label={t('jobs.jobForm.minEnglishLevel.label')}
                  props={{
                    options: Object.entries(t('constants.englishLevelMap')).map(([key, value]) => ({
                      id: key,
                      name: value,
                    })),
                  }}
                />
              ) : null}
              {googleMapsInitialized ? (
                <Field
                  name="locationSearch"
                  label={t('jobs.jobForm.locationSearch.label')}
                  component={GeoSuggestField}
                  props={{
                    onSuggestSelect: suggest => {
                      change(
                        'locationPosition',
                        `${suggest.location.lat}, ${suggest.location.lng}`,
                      );
                      change('centerLatitude', suggest.location.lat);
                      change('centerLongitude', suggest.location.lng);
                      change('zoom', 12);
                      change('latitude', suggest.location.lat);
                      change('longitude', suggest.location.lng);
                    },
                    onSuggestNoResults: () => { },
                  }}
                />
              ) : (
                <div className="c-form-group c-form-group--floating">
                  <div style={{ height: '30px' }}></div>
                </div>
              )}
              {currentUser.user.peopleBoxCredential &&
                currentUser.user.peopleBoxCredential.status && (
                  <Field
                    name="peopleBoxProjectId"
                    type="text"
                    component={FormField}
                    label={t('jobs.jobForm.peopleBoxProjectId.label')}
                    validate={[
                      integer(t('common.validations.integer')),
                      minLength(1, t('jobs.jobForm.peopleBoxProjectId.required')),
                    ]}
                  />
                )}
            </Col>
          </Row>
          <div className="u-gap-bottom">
            <Field
              name="locationPosition"
              label={t('jobs.jobForm.locationPosition.label')}
              component={FormFieldMap}
              props={{
                centerLatitude,
                centerLongitude,
                latitude,
                longitude,
                zoom,
                onMapClick: event => {
                  change('locationPosition', `${event.latLng.lat()}, ${event.latLng.lng()}`);
                  change('latitude', event.latLng.lat());
                  change('longitude', event.latLng.lng());
                },
                language: language.languageCode,
                onIdle: () =>
                  !googleMapsInitialized && this.setState({ googleMapsInitialized: true }),
              }}
              validate={[required(t('jobs.jobForm.locationPosition.required'))]}
            />
          </div>
          <div className="u-text-center">
            <Button
              type="submit"
              primary
              disabled={submitting}
              loading={submitting}
              style={{
                backgroundColor: themePalette.primary_color,
                boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
              }}
            >
              {isEdit ? t('jobs.jobForm.save') : t('jobs.jobForm.createJob')}
            </Button>
          </div>
        </form>

        <ImageCropModal
          isOpen={this.state.isCoverModelOpened}
          source={this.state.cropModalImage}
          onModalCloseRequested={this.closeImageCropModal}
          width={688}
          height={352}
          border={40}
          borderRadius={0}
          onImageReceive={this.handleCroppedImage}
        />
      </div>
    );
  }
}

const Form = reduxForm({
  form: 'jobCreate',
})(JobForm);

const selector = formValueSelector('jobCreate');

// $FlowFixMe
export default connect((state: ReduxState) => ({
  cover: selector(state, 'cover'),
  latitude: selector(state, 'latitude'),
  longitude: selector(state, 'longitude'),
  centerLatitude: selector(state, 'centerLatitude'),
  centerLongitude: selector(state, 'centerLongitude'),
  zoom: selector(state, 'zoom'),
  contactOption: selector(state, 'contactOption'),
  autoShortlistEnabled: selector(state, 'autoShortlistEnabled'),
}))(withTranslation()(Form));
