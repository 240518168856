// @flow
export default {
  branches: {
    dialogRemoveMessage: '¿Estás seguro de borrar esta sucursal?',
    editBranch: 'EDITAR SUCURSAL',
    addBranch: 'AGERGAR SUCURSAL',
    branchNotExist: 'No tienes sucursales.',
    noBranch: 'No tienes sucursales',
    companyName: {
      label: 'Nombre de sucursal',
      required: 'Escribe nombre de sucursal.',
    },
    firstName: {
      label: 'Nombre(s)',
      required: 'Escribe nombre(s).',
    },
    lastName: {
      label: 'Apellido',
      required: 'Escribe apellido.',
    },
    email: {
      label: 'E-mail del responsable',
      required: 'Escribe e-mail del responsable.',
      information: 'Enviaremos un link para crear una cuanta.',
    },
    taxOffice: {
      label: 'Dirección fiscal ',
      placeholder: 'Seleccionar',
    },
    taxOfficeNo: {
      label: 'RFC',
    },
    commercialTitle: {
      label: 'Nombre commercial',
    },
    website: {
      label: 'Web site',
    },
    companyAddress: {
      label: 'Dirección completar',
    },
    locationSearch: {
      label: 'Buscar ubicacción',
    },
    locationPosition: {
      label: 'Ubicación',
      required: 'Seleccionar ubicacción.',
    },
    inbox: {
      totalMessages: 'Número de mensajes',
      totalUnreadMessages: 'Mensajes no leídos',
    },
  },
};
