// @flow
import React from 'react';
import { UserTypeMap } from '../../enums/userType';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import LinkButton from '../../components/ui/LinkButton';
import Icon from '../../components/elements/Icon';
import WalletIcon from '../../components/elements/Icon/walletIcon';

import { type AuthenticatedUser } from '../../types/user';
import useTranslator from '../../hooks/useTranslator';
import { themePalette } from '../../theme/index';

type Props = {
  currentUser: AuthenticatedUser,
};

const Navbar = ({ currentUser }: Props) => {
  const { t } = useTranslator();

  return (
    <div className="c-navbar c-navbar--white">
      <Container>
        <div className="c-navbar__body">
          <Row className="u-flex-grow-full u-flex-align-middle">
            <Col md={6}>
              <h1>{currentUser.user.companyName}</h1>
            </Col>
            <Col md={6} className="u-hidden@sm-down u-text-right">
              {currentUser.user.userType === UserTypeMap.COMPANY && (
                <LinkButton
                  to="/packages"
                  className="u-gap-right"
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                  }}
                >
                  <WalletIcon />
                  <span className="u-gap-left-small">
                    {currentUser.user.jobCreationBalance || 0}
                  </span>
                </LinkButton>
              )}

              <LinkButton
                primar
                to="/jobs/create"
                style={{
                  backgroundColor: themePalette.primary_color,
                  color: '#fff',
                  boxShadow: 'none',
                }}
              >
                <Icon name="add" className="u-gap-right" />
                <span>{t('layout.navbar.addJob')}</span>
              </LinkButton>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
