// @flow

import React, { PureComponent } from 'react';
import cx from 'classnames';

import Label from '../../ui/Label';
import Textarea from '../../ui/Textarea';

type Meta = {
  touched: boolean,
  error: string,
};

type Props = {
  input: any,
  label: string,
  meta: Meta,
  disabled?: boolean,
  cols?: number,
  rows?: number,
  verticalResize?: boolean,
};

type State = {
  isActive: boolean,
};

/**
 * Form Field Textarea
 */
class FormFieldTextarea extends PureComponent<Props, State> {
  props: Props;

  state: State;

  onFocus: () => void;

  onBlur: () => void;

  constructor(props: Props) {
    super(props);

    this.state = { isActive: !!this.props.input.value };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onFocus() {
    this.setState({ isActive: true });
  }

  onBlur() {
    this.setState({ isActive: false });
  }

  render() {
    const classes = cx('c-form-group', 'c-form-group--floating', {
      'has-error': this.props.meta.touched && this.props.meta.error,
      'is-active': this.state.isActive,
    });

    const { onFocus, onBlur, ...otherInputProps } = this.props.input;

    return (
      <div className={classes}>
        <Label htmlFor={this.props.input.name}>{this.props.label}</Label>
        <Textarea
          id={this.props.input.name}
          disabled={this.props.disabled}
          cols={this.props.cols}
          rows={this.props.rows}
          onFocus={(event: SyntheticInputEvent<HTMLTextAreaElement>) => {
            this.onFocus();
            onFocus(event);
          }}
          onBlur={(event: SyntheticInputEvent<HTMLTextAreaElement>) => {
            if (!this.props.input.value) {
              this.onBlur();
            }

            onBlur(event);
          }}
          style={this.props.verticalResize ? { resize: 'vertical' } : undefined}
          {...otherInputProps}
        />
        {this.props.meta.touched && this.props.meta.error && (
          <span className="c-form-group__alert">{this.props.meta.error}</span>
        )}
      </div>
    );
  }
}

export default FormFieldTextarea;
