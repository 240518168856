/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
// @flow
import React, { FC } from 'react';

import emptyStar from '../../../img/icons/stars/star-outline.png';
import halfStar from '../../../img/icons/stars/star-half-filled.png';
import filledStar from '../../../img/icons/stars/star-filled.png';

const COMPONENT_CSS_CLASS = 'c-worker-rating-stars';

type Props = {
  rating: number,
  maxStars: number,
};

const WorkerRatingStars: FC<Props> = ({ rating, maxStars }) => {
  const getStarImage = (index: number) => {
    if (index + 1 <= Math.round(rating) && index + 1 - rating <= 0.25) {
      return filledStar;
    }
    return rating - index >= 0.5 ? halfStar : emptyStar;
  };

  return (
    <div className={COMPONENT_CSS_CLASS}>
      {[...new Array(maxStars)].map((_, i) => (
        <img key={i} src={getStarImage(i)} className={`${COMPONENT_CSS_CLASS}-onestar`} />
      ))}
    </div>
  );
};

export default WorkerRatingStars;
