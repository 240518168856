// @flow

import React, {
  useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';

import CircleImage from '../../components/elements/CircleImage';
import Box from '../../components/elements/Box';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Icon from '../../components/elements/Icon';
import LinkButton from '../../components/ui/LinkButton';
import StaticMap from '../../components/elements/StaticMap';

import { type ReduxState } from '../../redux/modules';
import { type AuthenticatedUser } from '../../types/user';
import useTranslator from '../../hooks/useTranslator';
import { themePalette } from '../../theme';
import Loading from '../../components/elements/Loading';
import formInputList from '../../api/formInputList';
import Alert from '../../components/elements/Alert';

type Props = {
  currentUser: AuthenticatedUser,
  editable: boolean,
};

const Detail = ({ currentUser, editable }: Props) => {
  const { t } = useTranslator();

  const [loading, setLoading] = useState(true);
  const [formViewData, setFormViewData] = useState(null);
  const [handleAlert, setHandleAlert] = useState({
    isActive: false,
    message: "",
    data: null
  });

  useEffect(() => {
    formInputList("CompanyProfileForm")
      .then((res) => {
        setFormViewData(res);
      })
      .catch((e) => {
        setHandleAlert({
          message: "common.errors.anErrorOccurred",
          data: { errorCode: 1014 },
          isActive: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <Loading />

  return (
    <Box>
      <div className="u-pad-ends-small u-pad-sides-small">
        <div className="u-flex u-flex-align-middle">
          <CircleImage className="u-gap-right" src={currentUser.user.imageUrl} />
          <h1 className="u-font-weight-bold u-flex-grow-full h3@md-down">
            {currentUser.user.companyName || t('profile.detail.unknown')}
          </h1>
          {editable && (
            <LinkButton ghost small to="/profile/edit" className="u-gap-left-small">
              <Icon name="edit" />
            </LinkButton>
          )}
        </div>
      </div>
      <hr className="u-hr-line" />
      <div className="u-pad-top-small u-pad-sides-small">
        <Row>
          <Col md={6} className="u-gap-bottom">
            <span className="c-label u-color-primary" style={{ color: themePalette.color }}>
              {t('profile.detail.name')}
            </span>
            <p>{currentUser.user.firstName || '-'}</p>
          </Col>
          <Col md={6} className="u-gap-bottom">
            <span className="c-label u-color-primary" style={{ color: themePalette.color }}>
              {t('profile.detail.lastName')}
            </span>
            <p>{currentUser.user.lastName || '-'}</p>
          </Col>
        </Row>
      </div>
      <hr className="u-hr-line" />
      <div className="u-pad-top-small u-pad-sides-small">
        <Row>
          <Col md={6} className="u-gap-bottom">
            <span className="c-label u-color-primary" style={{ color: themePalette.color }}>
              {t('profile.detail.email')}
            </span>
            <p>{currentUser.user.email || '-'}</p>
          </Col>
          <Col md={6} className="u-gap-bottom">
            <span className="c-label u-color-primary" style={{ color: themePalette.color }}>
              {t('profile.detail.phone')}
            </span>
            <p>{currentUser.user.phone || '-'}</p>
          </Col>
        </Row>
      </div>

      {formViewData && formViewData.taxOffice && formViewData.taxOffice.isActive ? <div className="u-pad-top-small u-pad-sides-small">
        <Row>
          <Col md={6} className="u-gap-bottom">
            <span className="c-label u-color-primary" style={{ color: themePalette.color }}>
              {t('profile.detail.taxOffice')}
            </span>
            <p>{currentUser.user.taxOffice ? (currentUser.user.taxOffice: any).name : '-'}</p>
          </Col>
          <Col md={6} className="u-gap-bottom">
            <span className="c-label u-color-primary" style={{ color: themePalette.color }}>
              {t('profile.detail.taxOfficeNumber')}
            </span>
            <p>{(currentUser.user: any).taxOfficeNumber || '-'}</p>
          </Col>
        </Row>
      </div> : null}

      <div className="u-pad-top-small u-pad-sides-small">
        <Row>
          <Col md={6} className="u-gap-bottom">
            <span className="c-label u-color-primary" style={{ color: themePalette.color }}>
              {t('profile.detail.commercialTitle')}
            </span>
            <p>{(currentUser.user: any).commercialTitle || '-'}</p>
          </Col>
          <Col md={6} className="u-gap-bottom">
            <span className="c-label u-color-primary" style={{ color: themePalette.color }}>
              {t('profile.detail.website')}
            </span>
            <p>{(currentUser.user: any).website || '-'}</p>
          </Col>
        </Row>
      </div>

      <div className="u-pad-top-small u-pad-sides-small">
        <Row>
          <Col className="u-gap-bottom">
            <span className="c-label u-color-primary" style={{ color: themePalette.color }}>
              {t('profile.detail.companyAddress')}
            </span>
            <p className="u-gap-bottom-xsmall">{currentUser.user.location.name}</p>
            <StaticMap
              className="u-img-responsive"
              alt="Lokasyon"
              latitude={currentUser.user.location.latitude}
              longitude={currentUser.user.location.longitude}
              markers={[
                {
                  latitude: currentUser.user.location.latitude,
                  longitude: currentUser.user.location.longitude,
                },
              ]}
              width={490}
              height={175}
            />
          </Col>
        </Row>
      </div>

      {currentUser.user.location.longitude !== 0 && <hr className="u-hr-line" />}
      {currentUser.user.location.longitude !== 0 && (
        <div className="u-pad-top-small u-pad-sides-small">
          <Row>
            <Col className="u-gap-bottom">
              <span className="c-label u-color-primary">{t('profile.detail.location')}</span>
              <p className="u-gap-bottom-xsmall">{currentUser.user.location.name}</p>
              <StaticMap
                className="u-img-responsive"
                alt="Lokasyon"
                latitude={currentUser.user.location.latitude}
                longitude={currentUser.user.location.longitude}
                markers={[
                  {
                    latitude: currentUser.user.location.latitude,
                    longitude: currentUser.user.location.longitude,
                  },
                ]}
                width={490}
                height={175}
              />
            </Col>
          </Row>
        </div>
      )}
      <Alert
        isVisible={handleAlert.isActive}
        onConfirmClick={() => setHandleAlert({
          isActive: false
        })}
        message={t(handleAlert.message, handleAlert.data)}
      />
    </Box>
  );
};

const mapStateToProps = (state: ReduxState): Object => ({
  currentUser: state.currentUser,
});

// $FlowFixMe
export default connect(mapStateToProps)(Detail);
