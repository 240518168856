// @flow

import React from 'react';

import { type Application } from '../../../types/application';
import { type Job } from '../../../types/job';

import ApplicationItem from './ApplicationItem';

import noApplication from '../../../img/empty-state/no-application.svg';
import useTranslator from '../../../hooks/useTranslator';
import Loading from '../../jobs/Loading';

type Props = {
  job: Job,
  applications: Array<Application>,
  onApplicationClick: Application => void,
  activeApplicationId: number | null,
  loadingMore: boolean,
};

const ShortListedApplications = ({
  job,
  applications,
  onApplicationClick,
  activeApplicationId,
  loadingMore
}: Props) => {
  if (applications.length === 0) {
    const { t } = useTranslator();

    return (
      <div className="u-pad-ends-large u-text-center">
        <img
          src={noApplication}
          width={270}
          height={156}
          alt={t('jobDetail.profile.noApplicationFound')}
        />
        <h3 className="u-color-gull-gray u-gap-bottom-medium">
          {t('jobDetail.profile.noShortlistedWorker')}
        </h3>
      </div>
    );
  }

  return (
    <div>
      {applications.map(application => (
        <ApplicationItem
          key={application.id}
          onClick={() => {
            onApplicationClick(application);
          }}
          isActive={activeApplicationId === application.id}
          application={application}
          job={application.job ? application.job : job}
        />
      ))}
      {loadingMore ? <Loading /> : null}
    </div>
  );
};

export default ShortListedApplications;
