// @flow

import React from 'react';
import cx from 'classnames';

import CircleImage from '../../../components/elements/CircleImage';

import { type Thread } from '../../../types/chat';

type Props = {
  thread: Thread,
  onClick: () => void,
  isActive?: boolean,
};

const Item = ({ thread, onClick, isActive }: Props) => (
  <div
    role="presentation"
    className={cx('c-box__clickable', { 'is-active': isActive })}
    onClick={onClick}
  >
    <div className="u-pad-sides-small u-pad-ends-small">
      <div className="u-flex u-flex-align-middle">
        <CircleImage
          badge={thread.nbUnreadMessage}
          small
          src={thread.user.imageUrl}
          className="u-min-width-zero"
        />

        <div className="u-gap-left u-min-width-zero">
          <h5 className="u-clear-gap">
            {thread.user.firstName} {thread.user.lastName}
          </h5>
          <span
            className={cx('u-font-size-sm u-color-gull-gray u-text-truncate u-block', {
              'u-font-weight-bold': thread.nbUnreadMessage > 0,
            })}
          >
            {thread.lastMessage}
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default Item;
