// @flow

import React from 'react';
import type { Node } from 'react';

type Props = {
  /** extra className */
  className?: string,

  /** label value */
  children: Node,

  /** label html element */
  htmlFor: string,
};

/**
 * Label component
 */
const Label = ({ className = '', htmlFor, children, ...otherProps }: Props) => {
  const classNames = ['c-label'];

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label htmlFor={htmlFor} className={classNames.join(' ')} {...otherProps}>
      {children}
    </label>
  );
};

export default Label;
