// @flow
import { setUserId, logEvent, getAnalytics } from 'firebase/analytics';
import app from '../../fcm/app';
import { AuthenticatedUser } from '../../../types/user';

const Events = {
  WEB_SIGNUP: 'WEB_SIGNUP',
  WEB_LOGIN: 'WEB_LOGIN',
  WEB_PURCHASE: 'WEB_PURCHASE',
  WEB_JOB_CREATED: 'WEB_JOB_CREATED',
};

const logEventInternal = (event, params, user: AuthenticatedUser) => {
  const firebaseAnalytics = getAnalytics(app);
  if (user) {
    setUserId(firebaseAnalytics, `${user.user.id}`);
  }

  logEvent(firebaseAnalytics, event, params);
};

export default {
  Events,
  logEvent: logEventInternal,
};
