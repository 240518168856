// @flow

import { ApiResponse } from 'apisauce';
import api from '../utils/api';
import { PackageMapper } from '../mappers/package';

import { type Package } from '../types/package';
import apiNew from '../utils/apiNew';
import { ThreeDSInitRequest } from '../types/payment';

// eslint-disable-next-line import/prefer-default-export
export const getPackagesList = async (): Promise<Array<Package>> => {
  const response = await api.get(`/payment/packages`);

  if (!response.ok) {
    throw response.data;
  }

  return PackageMapper.fromAPIResponseMultiple(response.data.payload.packages);
};

export const fetchPaymentForm = async (
  packageId: number,
): Promise<{ result: string, provider: string }> => {
  const response = await apiNew.post('/payment/checkout', {
    packageId,
  });

  if (!response.ok) {
    throw response.data;
  }

  return response.data.payload;
};

export const threeDSInit = async (request: ThreeDSInitRequest): Promise<ApiResponse> => {
  const response = await apiNew.post('/payment/init', request);

  return response;
};
