// @flow
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { withTranslation } from 'react-i18next';
import required from '../../validators/required';
import emailValidator from '../../validators/email';

import FormField from '../../components/elements/FormField';
import Modal from '../../components/elements/Modal';
import Button from '../../components/ui/Button';
import { themePalette } from '../../theme/index';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  handleSubmit: Function,
  submitting: boolean,
  t: Function,
};

class ExcelModal extends React.PureComponent<Props> {
  props: Props;

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, submitting, handleSubmit, t } = this.props;

    return (
      <Modal contentLabel="excelExport" isOpen={isOpen} onModalCloseRequested={this.handleClose}>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="u-gap-bottom">
              <h1 className="u-text-center">{t('jobs.excelModal.title')}</h1>
              <Field
                name="email"
                type="email"
                component={FormField}
                label={t('jobs.excelForm.email.label')}
                validate={[required(t('jobs.excelForm.email.required')), emailValidator()]}
              />
            </div>
            <div className="u-text-center">
              <Button
                type="submit"
                primary
                loading={submitting}
                style={{
                  backgroundColor: themePalette.primary_color,
                  boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                }}
              >
                {t('jobs.excelForm.send')}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

export default reduxForm({ form: 'excelExport' })(withTranslation()(ExcelModal));
