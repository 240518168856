// @flow

import React from 'react';

import { type Message } from '../../../types/chat';

import TextBubble from './TextBubble';
import ApplicationBubble from './ApplicationBubble';
import LocationBubble from './LocationBubble';
import AppointmentBubble from './AppointmentBubble';
import AppointmentStatusBubble from './AppointmentStatusBubble';

type Props = {
  alignToRight?: boolean,
  message: Message,
};

const Bubble = (props: Props) => {
  switch (props.message.type) {
    case 'appointment':
      return <AppointmentBubble {...props} />;

    case 'appointment_status':
      return <AppointmentStatusBubble {...props} />;

    case 'application':
      return <ApplicationBubble {...props} />;

    case 'location':
      return <LocationBubble {...props} />;

    case 'text':
    default:
      return <TextBubble {...props} />;
  }
};

export default Bubble;
