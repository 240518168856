// @flow

import React from 'react';

type Props = {
  value: string,
  title: string,
  onSelect: string => void,
  className: string | undefined,
};

const DropdownItem = ({ value, title, onSelect, className }: Props) => (
  <li className={`${className || 'c-dropdown__menu'}-item`}>
    <a
      href=""
      className={`${className || 'c-dropdown__menu'}-link`}
      onClick={(event: Event) => {
        event.preventDefault();
        onSelect(value);
      }}
    >
      {title}
    </a>
  </li>
);

export default DropdownItem;
