// @flow

import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import BranchForm from '../../forms/BranchForm';

import Modal from '../../components/elements/Modal';

import { type Company } from '../../types/user';

type Props = {
  isOpen: boolean,
  onModalCloseRequested: () => void,
  branch: Company | null,
  handleSubmit: Object => void,
};

class BranchModal extends PureComponent<Props> {
  props: Props;

  initialValues: Object;

  render() {
    const { branch, handleSubmit, isOpen, onModalCloseRequested, t } = this.props;

    const initialValues = {
      companyName: branch ? branch.companyName : '',
      firstName: branch ? branch.firstName : '',
      lastName: branch ? branch.lastName : '',
      email: branch ? branch.email : '',
      taxOffice: branch ? branch.taxOffice : undefined,
      taxOfficeNumber: branch ? branch.taxOfficeNumber : '',
      website: branch ? branch.website : '',
      commercialTitle: branch ? branch.commercialTitle : '',
      companyAddress: branch ? branch.companyAddress : '',
      locationSearch: '',
      locationPosition: branch ? branch.location.latitude : '',
      latitude: branch ? branch.location.latitude : 0,
      longitude: branch ? branch.location.longitude : 0,
      centerLatitude: branch ? branch.location.latitude : 0,
      centerLongitude: branch ? branch.location.longitude : 0,
      zoom: branch ? 12 : 5,
    };

    return (
      <Modal
        isOpen={isOpen}
        onModalCloseRequested={onModalCloseRequested}
        closeable
        contentLabel={branch ? t('branches.editBranch') : t('branches.addBranch')}
        title={branch ? t('branches.editBranch') : t('branches.addBranch')}
      >
        <BranchForm onSubmit={handleSubmit} initialValues={initialValues} isEdit={!!branch} />
      </Modal>
    );
  }
}

export default withTranslation()(BranchModal);
