// @flow

import moment from 'moment-timezone';

import { UserTypeMap, UserTypeDefault } from '../enums/userType';
import { UserGrantMap } from '../enums/userGrant';

import { LocationMapper, NamedLocationMapper, GeoLocationMapper } from './location';
import { ExperienceMapper } from './experience';
import { stringify as geoLocationStringifier } from '../utils/location';

import { type ProfileUpdateData } from '../api/auth';
import { type HRData } from '../api/hr';
import { type BranchData } from '../api/branch';

import {
  type Worker,
  type Company,
  type HR,
  type Root,
  type User,
  type AuthenticatedUser,
} from '../types/user';
import { LanguageMapper } from './language';

export const WorkerMapper = {
  fromAPIResponse(response: Object = {}): Worker {
    const geoLocation = GeoLocationMapper.fromAPIResponse(response.geo_location);

    return {
      id: response.id || 0,
      userType: UserTypeMap.WORKER,
      firstName: response.first_name || '',
      lastName: response.last_name || '',
      email: response.email || '',
      phone: response.phone || '',
      identityNumber: response.identity_number || '',
      drivingLicenses:
        response.driving_licenses && Array.isArray(response.driving_licenses)
          ? response.driving_licenses
          : [],
      certificates: response.certificates || '',
      imageUrl: response.image_url || '',
      about: response.about || '',
      disabledStatus: response.disabled_status,
      distance: response.distance || '',
      distanceInt: Number((response.distance || '').replace(' KM', '')),
      location: NamedLocationMapper.fromAPIResponse({
        ...LocationMapper.fromAPIResponse(response.location),
        name: geoLocationStringifier(geoLocation),
      }),
      geoLocation,
      education: response.education_status || '',
      universityStatus: response.university_status || '',
      university: response.university || null,
      experiences: ExperienceMapper.fromAPIResponseMultiple(response.experiences),
      experienceMonth: response.experience_month || 0,
      workCategoryPreferences: response.work_category_preferences || [],
      workTimePreference: response.work_time_preference,
      workTimePreferences: response.user_attributes
        ? response.user_attributes.work_time_preferences.map(wTP => ({
          key: wTP.translate_key,
          value: wTP.name,
        }))
        : undefined,
      expectedSalary: response.expected_salary,
      gender: response.gender ? response.gender : null,
      birthDate: response.birth_date ? moment(response.birth_date) : null,
      english: response.english_level || null,
      military: response.military_status || null,
      comment: response.comment || '',
      dashboardAccess: response.dashboard_access || false,
      ratingScore: response.rating_score || null,
      language: LanguageMapper.fromAPIResponse(response.language),
      country: response.country || null,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Worker> {
    if (!response) {
      return [];
    }

    return response.map(WorkerMapper.fromAPIResponse);
  },
};

export const CompanyMapper = {
  fromAPIResponse(response: Object = {}): Company {
    const geoLocation = GeoLocationMapper.fromAPIResponse(response.geo_location);

    return {
      id: response.id || 0,
      userType: response.user_type || 'branch',
      grantLevel:
        response.user_type === UserTypeMap.COMPANY
          ? UserGrantMap.COMPANY_GRANT
          : UserGrantMap.BRANCH_GRANT,
      companyName: response.company_name || '',
      firstName: response.first_name || '',
      lastName: response.last_name || '',
      email: response.email || '',
      imageUrl: response.image_url || '',
      about: response.about || '',
      phone: response.phone || '',
      location: NamedLocationMapper.fromAPIResponse({
        ...LocationMapper.fromAPIResponse(response.location),
        name: geoLocationStringifier(geoLocation),
      }),
      geoLocation,
      counts: {
        jobs: response.nb_jobs || 0,
        shortLists: response.nb_short_list || 0,
        applications: response.nb_applications || 0,
      },
      taxOffice: response.tax_office,
      taxOfficeNumber: response.tax_office_number || '',
      website: response.website || '',
      commercialTitle: response.commercial_title || '',
      companyAddress: response.company_address || '',
      dashboardAccess: response.dashboard_access || false,
      jobCreationBalance: response.job_creation_balance || 0,
      language: LanguageMapper.fromAPIResponse(response.language),
      country: response.country || null,
      activeJobCount: response.active_job_count || 0,
      peopleBoxCredential: response.user_attributes
        ? response.user_attributes.people_box_credential
        : undefined,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Company> {
    if (!response) {
      return [];
    }

    return response.map(CompanyMapper.fromAPIResponse);
  },

  toRegisterApiRequest(request: Object): BranchData {
    return {
      companyName: request.companyName,
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
      password: request.password,
      phone: request.phone,
      location: `${request.latitude}, ${request.longitude}`,
    };
  },
  toAPIRequest(request: Object): BranchData {
    return {
      companyName: request.companyName,
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
      location: `${request.latitude}, ${request.longitude}`,
      taxOffice: request.taxOffice ? request.taxOffice.id : undefined,
      taxOfficeNumber: request.taxOfficeNumber,
      website: request.website,
      commercialTitle: request.commercialTitle,
      companyAddress: request.companyAddress,
    };
  },
};

export const HRMapper = {
  fromAPIResponse(response: Object = {}): HR {
    const geoLocation = GeoLocationMapper.fromAPIResponse(response.geo_location);

    return {
      id: response.id || 0,
      userType: UserTypeMap.HR,
      grantLevel: UserGrantMap.HR_GRANT,
      companyName: response.company_name || '',
      firstName: response.first_name || '',
      lastName: response.last_name || '',
      email: response.email || '',
      imageUrl: response.image_url || '',
      about: response.about || '',
      phone: response.phone || '',
      dashboardAccess: response.dashboard_access || false,
      location: NamedLocationMapper.fromAPIResponse({
        ...LocationMapper.fromAPIResponse(response.location),
        name: geoLocationStringifier(geoLocation),
      }),
      geoLocation,
      language: LanguageMapper.fromAPIResponse(response.language),
      country: response.country || null,
      peopleBoxCredential: response.user_attributes
        ? response.user_attributes.people_box_credential
        : undefined,
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<HR> {
    if (!response) {
      return [];
    }

    return response.map(HRMapper.fromAPIResponse);
  },

  toAPIRequest(request: Object): HRData {
    return {
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
    };
  },
};

export const RootMapper = {
  fromAPIResponse(response: Object = {}): Root {
    const geoLocation = GeoLocationMapper.fromAPIResponse(response.geo_location);

    return {
      id: response.id || 0,
      userType: UserTypeMap.ROOT,
      grantLevel: UserGrantMap.ROOT_GRANT,
      companyName: response.company_name || '',
      firstName: response.first_name || '',
      lastName: response.last_name || '',
      email: response.email || '',
      imageUrl: response.image_url || '',
      about: response.about || '',
      phone: response.phone || '',
      dashboardAccess: response.dashboard_access || false,
      location: NamedLocationMapper.fromAPIResponse({
        ...LocationMapper.fromAPIResponse(response.location),
        name: geoLocationStringifier(geoLocation),
      }),
      geoLocation,
      language: LanguageMapper.fromAPIResponse(response.language),
      country: response.country || null,
      peopleBoxCredential: response.user_attributes
        ? response.user_attributes.people_box_credential
        : undefined,
    };
  },
};

export const UserMapper = {
  fromAPIResponse(response: Object): User {
    const userType = response ? response.user_type : UserTypeDefault;

    switch (userType) {
      case 'worker':
        return WorkerMapper.fromAPIResponse(response);
      case 'branch':
      case 'company':
        return CompanyMapper.fromAPIResponse(response);
      case 'hr_account':
        return HRMapper.fromAPIResponse(response);
      case 'root':
        return RootMapper.fromAPIResponse(response);
      default:
        throw new Error('unknown_user_type');
    }
  },
};

export const AuthenticatedUserMapper = {
  fromAPIResponse(response: Object): AuthenticatedUser {
    const userType = response.user ? response.user.user_type : UserTypeDefault;

    switch (userType) {
      case 'worker':
        throw new Error('worker_login');
      case 'branch':
        return {
          user: CompanyMapper.fromAPIResponse(response.user || {}),
          accessToken: response.access_token || '',
        };
      case 'company':
        return {
          user: CompanyMapper.fromAPIResponse(response.user || {}),
          accessToken: response.access_token || '',
        };
      case 'hr_account':
        return {
          user: HRMapper.fromAPIResponse(response.user || {}),
          accessToken: response.access_token || '',
        };
      case 'root':
        return {
          user: RootMapper.fromAPIResponse(response.user || {}),
          accessToken: response.access_token || '',
        };
      default:
        throw new Error('unknown_user_type');
    }
  },

  toAPIRequest(request: Object, formViewData?: Object): ProfileUpdateData {
    let returnValue = {
      firstName: request.firstName,
      lastName: request.lastName,
      email: request.email,
      phone: request.phone,
      commercialTitle: request.commercialTitle,
      website: request.website,
      location: `${request.latitude}, ${request.longitude}`,
      imageFileBase64: request.imageFile || undefined,
    };

    if (formViewData && formViewData.taxOffice && formViewData.taxOffice.isActive) {
      returnValue = {
        ...returnValue,
        taxOffice: request.taxOffice ? request.taxOffice.id : null,
        taxOfficeNumber: request.taxOfficeNumber,
      };
    }

    return returnValue;
  },
};
