// @flow

import React, { type Node } from 'react';

type Props = {
  /** callback function */
  callback: Function,

  /** extra styles */
  extraStyles?: Object,

  /** link value */
  children: Node,
};

/**
 * CallbackLink component
 */
const CallbackLink = ({ callback, extraStyles, children, ...otherProps }: Props) => {
  return (
    <button
      type="button"
      onClick={callback}
      style={{
        all: 'unset',
        textTransform: 'none',
        textDecoration: 'underline',
        ...extraStyles,
      }}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default CallbackLink;
