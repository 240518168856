// @flow

import React from 'react';
import { type FieldProps } from 'redux-form';

import Radio from '../../../../components/ui/Radio';
import RadioGroup from '../../../../components/ui/RadioGroup';
import Button from '../../../../components/ui/Button';
import useTranslator from '../../../../hooks/useTranslator';

const SelectField = (field: FieldProps) => {
  const { t } = useTranslator();

  return (
    <div className="u-flex">
      <div className="u-full-width">
        <div className="u-flex u-full-width">
          <RadioGroup
            selectedValue={field.input.value}
            onChange={field.input.onChange}
            disabled={field.disabled}
          >
            <Radio containerClassName="u-gap-right-large" value>
              {t('common.glossary.yes')}
            </Radio>
            <Radio containerClassName="u-gap-right-large" value={false}>
              {t('common.glossary.no')}
            </Radio>
          </RadioGroup>
        </div>
      </div>
      <div className="u-free-width u-text-nowrap u-pad-sides-small u-text-right u-border-left">
        <Button
          className="c-button c-button-link u-font-size-sm u-color-gull-gray"
          onClick={() => {
            field.input.onChange(null);
          }}
        >
          {t('common.glossary.removeChoice')}
        </Button>
      </div>
    </div>
  );
};

export default SelectField;
