// @flow

import React from 'react';

import Icon from '../../components/elements/Icon';
import LinkButton from '../../components/ui/LinkButton';
import useTranslator from '../../hooks/useTranslator';

import noJob from '../../img/empty-state/no-job.svg';
import { themePalette } from '../../theme/index';

const NoData = () => {
  const { t } = useTranslator();

  return (
    <div className="u-pad-ends-large u-text-center">
      <img src={noJob} width={270} height={150} alt={t('jobs.noBranchFound')} />
      <h3 className="u-color-gull-gray u-gap-bottom-medium">{t('jobs.noData')}</h3>

      <LinkButton
        primary
        to="/jobs/create"
        style={{
          backgroundColor: themePalette.primary_color,
          boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
        }}
      >
        <Icon name="add" className="u-gap-right-small" />
        <span>{t('jobs.addJob')}</span>
      </LinkButton>
    </div>
  );
};

export default NoData;
