// @flow

import React from 'react';
import cx from 'classnames';

type Props = {
  containerHeight?: number,
  small?: boolean,
  large?: boolean,
};

const Loading = ({ containerHeight, small, large }: Props) => {
  const style = Object.assign({}, containerHeight ? { height: containerHeight } : {});
  const classes = cx('c-loading', {
    'c-loading--small': small,
    'c-loading--large': large,
  });

  return <div className={classes} style={style} />;
};

export default Loading;
