/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-has-content */
// @flow

import React, { useEffect, useRef, useState } from 'react';
import swal from 'sweetalert';

import { swalOkButtonTranslateKey } from '../../constants';

import Modal from '../../components/elements/Modal';

import { themePalette } from '../../theme';

import { type Package } from '../../types/package';
import { type AuthenticatedUser } from '../../types/user';
import useTranslator from '../../hooks/useTranslator';
import CallbackLink from '../../components/ui/CallbackLink';
import Checkbox from '../../components/ui/Checkbox';
import Button from '../../components/ui/Button';
import analytics from '../../services/analytics';
import { getProducts } from '../../api/product';
import { addItem, createBasket } from '../../api/basket';
import Alert from '../../components/elements/Alert';
import { currencyMapper } from '../../mappers/currency';

type Props = {
  paymentPackage: Package,
  packageIndex: number,
  packageCount: number,
  minHeightOfPackageNameDiv: number,
  handleHeightOfPackageNameDiv: Function,
  isLargeScreen: boolean,
  isPaymentEnabled: boolean,
  history: Object,
  currentUser: AuthenticatedUser,
};

const PackageListItem = ({
  paymentPackage,
  packageIndex,
  packageCount,
  minHeightOfPackageNameDiv,
  handleHeightOfPackageNameDiv,
  isLargeScreen,
  isPaymentEnabled,
  history,
  currentUser,
}: Props) => {
  const { t } = useTranslator();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [priceRuleRemainTime, setPriceRuleRemainTime] = useState(
    paymentPackage.rulePriceTTL
      ? paymentPackage.rulePriceTTL.getTime() - new Date().getTime()
      : undefined,
  );

  const handleDetailsModalClose = () => {
    setIsDetailsModalOpen(false);
  };

  const continueAsPackage = async (paymentPackage: Package) => {
    try {
      analytics.purchaseListedPackageButtonPressed(
        `${paymentPackage.id}`,
        `${paymentPackage.price}`,
      );
      const basket = await createBasket();
      const response = await addItem(basket.id, { productId: paymentPackage.id });
      history.push('/payment/basket', {
        basket: response,
      });
    } catch (error) {
      swal({
        text: t('packages.error.anErrorOccurred'),
        icon: 'info',
        button: t(swalOkButtonTranslateKey),
        dangerMode: true,
      });
    }
  };

  const handleBuyButtonClick = async () => {
    continueAsPackage(paymentPackage);
  };

  const packageNameDivRef = useRef(null);

  const descriptionListMapper = (feature, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <li key={`package[${paymentPackage.id}]-feature[${index}]`}>{feature.description}</li>
  );

  const getFormattedTime = milliseconds => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);

    const days = Math.floor(totalHours / 24);
    const seconds = totalSeconds % 60;
    const minutes = totalMinutes % 60;
    const hours = totalHours % 24;

    let message = t('packages.packageListItem.timeRemaining');

    if (days > 0) {
      message += `${days}:`;
    }
    if (hours > 0) {
      message += `${hours}:`;
    }
    if (minutes > 0) {
      message += `${minutes}:`;
    }
    message += `${seconds}`;

    return message;
  };

  useEffect(() => {
    if (priceRuleRemainTime) {
      if (priceRuleRemainTime <= 1000) {
        window.location.reload();
        return;
      }

      setTimeout(() => {
        setPriceRuleRemainTime(priceRuleRemainTime - 1000);
      }, 1000);
    }
  }, [priceRuleRemainTime]);

  useEffect(() => {
    if (packageNameDivRef.current) {
      handleHeightOfPackageNameDiv(packageNameDivRef.current.clientHeight, packageIndex);
    }
  }, [packageNameDivRef.current]);

  const currentUserCountryCodeIsDefined =
    currentUser &&
    currentUser.user &&
    currentUser.user.country &&
    currentUser.user.country.country_code;

  return (
    <div
      style={{
        width: isLargeScreen ? `${(100 / packageCount).toFixed(2)}%` : '100%',
      }}
      className="u-pad-sides-xsmall u-pad-ends-small u-flex"
    >
      <div
        className="c-package-list-item c-package-list-item__smooth u-overflow-hidden u-flex u-flex-dir-column u-flex-align-spaced u-flex-grow-full"
        style={{
          border: paymentPackage.isPopular
            ? `2px solid ${
                themePalette.packageColors[packageIndex % themePalette.packageColors.length]
              }`
            : undefined,
        }}
      >
        <div>
          <div
            className="u-flex u-flex-align-start@lg-up u-pad-sides-small u-pad-ends-xsmall u-gap-bottom u-flex-grow-full u-flex-dir-column"
            style={{
              backgroundColor:
                themePalette.packageColors[packageIndex % themePalette.packageColors.length],
              minHeight: minHeightOfPackageNameDiv,
            }}
            ref={packageNameDivRef}
          >
            <h1 className="u-font-weight-bold h3@md-down c-package-list-item__package_name_text">
              {paymentPackage.name}
            </h1>
            <CallbackLink
              callback={() => setIsDetailsModalOpen(true)}
              extraStyles={{
                color: '#FFF',
                cursor: 'pointer',
                width: 'fit-content',
              }}
            >
              {t('packages.packageListItem.viewDetails')}
            </CallbackLink>
          </div>

          <div className="u-pad-sides-xsmall u-flex-grow-full">
            <ul>
              {paymentPackage.features
                .filter(feature => feature.preview)
                .map(descriptionListMapper)}
            </ul>
          </div>
        </div>

        <div className="u-pad-ends-small"></div>

        {paymentPackage.rulePriceTTL && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h3
              className="u-font-weight-bold"
              style={{
                color: '#116500',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {t('packages.packageListItem.exclusiveOfferForNewAccounts')}
            </h3>
            <h3
              className="u-font-weight-bold"
              style={{
                color: '#116500',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              {getFormattedTime(priceRuleRemainTime)}
            </h3>
          </div>
        )}

        {isPaymentEnabled && (
          <div
            className="u-flex u-flex-dir-column"
            style={{
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            {paymentPackage.rulePriceTTL ? (
              <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', display: 'flex' }}>
                <h3 className="u-font-weight-bold">{`${paymentPackage.price} ${currencyMapper.fromAPIResponse(paymentPackage.currency)}`}</h3>
                <h3
                  style={{
                    textDecoration: 'line-through',
                    fontSize: '90%',
                    color: '#FF1937',
                  }}
                >{`${paymentPackage.originalPrice} ${currencyMapper.fromAPIResponse(paymentPackage.currency)}`}</h3>
              </div>
            ) : (
              <div>
                <h3 className="u-font-weight-bold">
                  {`${paymentPackage.price} ${currencyMapper.fromAPIResponse(paymentPackage.currency)}`}
                </h3>
              </div>
            )}

            <Button
              style={{
                backgroundColor:
                  themePalette.packageColors[packageIndex % themePalette.packageColors.length],
                borderRadius: '15px',
                marginBottom: '1em',
              }}
              className="u-three-quarter-width"
              onClick={handleBuyButtonClick}
            >
              <span
                style={{
                  color: '#FFF',
                }}
              >
                {t('packages.packageListItem.buy')}
              </span>
            </Button>
          </div>
        )}
        <Modal
          contentLabel={`package-details-modal-${paymentPackage.id}`}
          size="small"
          isOpen={isDetailsModalOpen}
          onModalCloseRequested={handleDetailsModalClose}
          ariaHideApp={false}
        >
          <div className="u-pad-sides-xsmall u-flex-grow-full">
            <ul>
              {paymentPackage.features
                .filter(feature => !feature.preview)
                .map(descriptionListMapper)}
            </ul>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PackageListItem;
