/* eslint-disable no-return-assign */
// @flow
import React, { useState, useEffect, useRef } from 'react';

const DIGIT_ARRAY = Array.from(Array(4).keys());

type Props = {
  setValue: Function,
  disableInitialFocus?: boolean,
  disabled?: boolean,
};

const FourDigitCodeInput = ({ setValue, disableInitialFocus, disabled }: Props) => {
  const [focusedDigit, setFocusedDigit] = useState(0);
  const [code, setCode] = useState('');
  const itemsRef = useRef<Array<HTMLInputElement | null>>([]);

  const handleDigitChange = (digit: number, newValue: string) => {
    if (newValue === '') {
      if (digit !== code.length - 1) {
        return;
      }

      const codeToSet = code.slice(0, -1);
      setCode(codeToSet);
      setValue(codeToSet);
      return;
    }

    const regex = /^[0-9\b]+$/;

    if (regex.test(newValue)) {
      const newCode = code.split('');
      newCode[digit] = newValue;
      const codeToSet = newCode.join('');
      setCode(codeToSet);
      setValue(codeToSet);
      setFocusedDigit(digit + 1);
    }
  };

  const handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !e.target.value.length) {
      setFocusedDigit(focusedDigit - 1);
    }
  };

  useEffect(() => {
    if (disableInitialFocus && focusedDigit === 0) {
      return;
    }

    if (itemsRef.current[focusedDigit]) {
      itemsRef.current[focusedDigit].focus();
    }
  }, [focusedDigit]);

  return (
    <div className="u-flex u-flex-justify-center u-flex-align-center u-flex-wrap u-margin-top">
      {DIGIT_ARRAY.map(digit => (
        <div
          key={digit}
          style={{
            border: '1px solid #757575',
            borderRadius: '0.5rem',
            height: '4rem',
            width: '3rem',
            margin: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <input
            key={`input${digit}`}
            maxLength={1}
            type="text"
            className="c-form-control"
            disabled={code.length >= digit + 2 || disabled}
            ref={(element: HTMLInputElement) => (itemsRef.current[digit] = element)}
            value={code.length > digit ? '*' : ''}
            onChange={e => handleDigitChange(digit, e.target.value)}
            style={{
              paddingLeft: '0.3rem',
              fontSize: '2rem',
              height: '100%',
              width: '50%',
              textAlign: 'center',
            }}
            onKeyDown={handleKeyDown}
          />
        </div>
      ))}
    </div>
  );
};

export default FourDigitCodeInput;
