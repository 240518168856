// @flow

import React from 'react';
import useTranslator from '../../hooks/useTranslator';

import noApplication from '../../img/empty-state/no-application.svg';

const NoData = () => {
  const { t } = useTranslator();

  return (
    <div className="u-pad-ends-large u-text-center">
      <img src={noApplication} width={270} height={156} alt={t('jobDeatail.noApplicationFound')} />
      <h3 className="u-color-gull-gray u-gap-bottom-medium">
        {t('jobs.detail.noApplicationData')}
      </h3>
    </div>
  );
};

export default NoData;
