// @flow

import { list as listRequest } from '../../api/holiday';
import { Status, type StatusType } from '../../utils/apiState';

import { type ReduxDispatch } from '../../types/redux';

// Actions
const REQUEST = 'holidays/REQUEST';
const SUCCESS = 'holidays/SUCCESS';
const FAILED = 'holidays/FAILED';

// Action Creator Types
type RequestAction = {
  type: typeof REQUEST,
};

type SuccessAction = {
  type: typeof SUCCESS,
  holidays: Array<string>,
};

type FailedAction = {
  type: typeof FAILED,
  error: any,
};

export type HolidaysActions = RequestAction | SuccessAction | FailedAction;

// Action Creators
export const request = (): RequestAction => ({
  type: REQUEST,
});

export const success = (holidays: Array<string>): SuccessAction => ({
  type: SUCCESS,
  holidays,
});

export const failed = (error: any): FailedAction => ({
  type: FAILED,
  error,
});

// Thunks
export const loadThunk = (): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  dispatch(request());

  try {
    const holidays = await listRequest();
    dispatch(success(holidays));
  } catch (error) {
    dispatch(failed(error));
  }
};

// Reducer
export type HolidaysState = {
  +status: StatusType,
  +data: Array<string>,
  +error: any,
};

const initialState = {
  status: Status.INIT,
  data: [],
  error: null,
};

export default function reducer(
  state: HolidaysState = initialState,
  action: HolidaysActions,
): HolidaysState {
  switch (action.type) {
    case REQUEST:
      return { ...state, status: Status.LOADING };
    case SUCCESS:
      return { ...state, status: Status.LOADED, data: action.holidays };
    case FAILED:
      return { ...state, status: Status.FAILED, data: [], error: action.error };
    default:
      return state;
  }
}
