// @flow

import {
  list as listRequest,
  remove as deleteRequest,
  create as createRequest,
  edit as editRequest,
} from '../../api/hr';
import { Status, type StatusType } from '../../utils/apiState';
import { HRMapper } from '../../mappers/user';

import { type ReduxDispatch } from '../../types/redux';
import { type HR } from '../../types/user';

// Actions
const REQUEST = 'hrList/REQUEST';
const SUCCESS = 'hrList/SUCCESS';
const FAILED = 'hrList/FAILED';
const DELETE_ITEM = 'hrList/DELETE_ITEM';
const CREATE_ITEM = 'hrList/CREATE_ITEM';
const EDIT_ITEM = 'hrList/EDIT_ITEM';

// Action Creator Types
type RequestAction = {
  type: typeof REQUEST,
};

type SuccessAction = {
  type: typeof SUCCESS,
  hrList: Array<HR>,
};

type FailedAction = {
  type: typeof FAILED,
  error: any,
};

type DeleteItemAction = {
  type: typeof DELETE_ITEM,
  id: number,
};

type CreateItemAction = {
  type: typeof CREATE_ITEM,
  item: HR,
};

type EditItemAction = {
  type: typeof EDIT_ITEM,
  item: HR,
};

export type HRListActions =
  | RequestAction
  | SuccessAction
  | FailedAction
  | DeleteItemAction
  | CreateItemAction
  | EditItemAction;

// Action Creators
export const request = (): RequestAction => ({
  type: REQUEST,
});

export const success = (hrList: Array<HR>): SuccessAction => ({
  type: SUCCESS,
  hrList,
});

export const failed = (error: any): FailedAction => ({
  type: FAILED,
  error,
});

export const deleteItem = (id: number): DeleteItemAction => ({
  type: DELETE_ITEM,
  id,
});

export const createItem = (item: HR): CreateItemAction => ({
  type: CREATE_ITEM,
  item,
});

export const editItem = (item: HR): EditItemAction => ({
  type: EDIT_ITEM,
  item,
});

// Thunks
export const loadThunk = (): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  dispatch(request());

  try {
    const hrList = await listRequest();
    dispatch(success(hrList));
  } catch (error) {
    dispatch(failed(error));
  }
};

export const deleteItemThunk = (id: number): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  try {
    dispatch(deleteItem(id));
    await deleteRequest(id);
  } catch (err) {
    loadThunk();
  }
};

export const createItemThunk = (data: HR): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  const formData = HRMapper.toAPIRequest(data);

  const item = await createRequest(formData);

  dispatch(createItem(item));
};

export const editItemThunk = (id: number, data: HR): Function => async (
  dispatch: ReduxDispatch,
): Promise<*> => {
  const formData = HRMapper.toAPIRequest(data);

  const item = await editRequest(id, formData);

  dispatch(editItem(item));
};

// Reducer
export type HRListState = {
  +status: StatusType,
  +data: Array<HR>,
  +error: any,
};

const initialState = {
  status: Status.INIT,
  data: [],
  error: null,
};

export default function reducer(
  state: HRListState = initialState,
  action: HRListActions,
): HRListState {
  switch (action.type) {
    case REQUEST:
      return { ...state, status: Status.LOADING };
    case SUCCESS:
      return { ...state, status: Status.LOADED, data: action.hrList };
    case FAILED:
      return { ...state, status: Status.FAILED, data: [], error: action.error };
    case DELETE_ITEM: {
      const { id } = action;
      return { ...state, data: state.data.filter(item => item.id !== id) };
    }
    case CREATE_ITEM:
      return { ...state, data: [...state.data, action.item] };
    case EDIT_ITEM: {
      const actionItem = action.item;
      const newData = state.data.map((item: HR) => {
        if (item.id === actionItem.id) {
          return actionItem;
        }

        return item;
      });

      return { ...state, data: newData };
    }
    default:
      return state;
  }
}
