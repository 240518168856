// @flow

import { type NewMessageReceivedNotification } from '../../../types/fcm';

// eslint-disable-next-line
let _onNewMessage;

// inline cache for preventing duplicat messages
// eslint-disable-next-line
let _messageCache = {};

export default function(notification: NewMessageReceivedNotification) {
  const { payload } = notification;

  if (typeof payload.message === 'string') {
    payload.message = JSON.parse(payload.message);
  }

  if (_messageCache[payload.message.id]) {
    return;
  }
  _messageCache[payload.message.id] = true;

  if (_onNewMessage) {
    _onNewMessage(notification);
  }
}

export function onNewMessage(fn: NewMessageReceivedNotification => void) {
  _onNewMessage = fn;
}
