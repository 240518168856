// @flow
export default {
  register: {
    registerForm: {
      title: 'SIGN UP',
      companyName: {
        label: 'Company name',
        required: 'Please enter your company name.',
      },
      firstName: {
        label: 'First name',
        required: 'Please enter your first name.',
      },
      lastName: {
        label: 'Last name',
        required: 'Please enter your last name.',
      },
      email: {
        label: 'E-mail',
        required: 'Please enter your last name.',
      },
      phone: {
        label: 'Phone',
        required: 'Please enter your phone number.',
      },
      password: {
        label: 'Password',
        required: 'Please enter a password.',
        minLength: 'Min 6 characters.',
        maxLength: 'Max 16 characters.',
      },
      locationSearch: {
        label: 'Search location',
        required: 'Please enter location.',
      },
      userAgreements: {
        label: 'I read and accept the <0>user agreement</0> and <1>privacy agreement</1>.',
        minLength: 'This field is required.',
      },
      register: 'SIGN UP',
      loginRedirectText: 'If you are already a member, you can ',
      underlinedText: '<0>login</0>',
      countrySelect: {
        label: 'Select country',
        required: 'Select country.',
      },
    },
    formError: {
      emailIsNotUnique:
        'This email is used by another user. If you already have an account, please use the Sign in button.',
    },
    smsVerify: {
      title: 'Phone Verification',
      infoText: 'We need to confirm your phone number so that jobseekers can reach you.',
      sendCode: 'Send SMS Code',
      reSendCode: 'Send A New Code',
      confirm: 'Confirm',
    },
  },
};
