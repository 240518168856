// @flow

export const EducationMap = {
  elementary_school: 'İlkokul',
  middle_school: 'Ortaokul',
  high_school: 'Lise',
  university: 'Üniversite',
};

export const EducationMapDefault = EducationMap.elementary_school;
