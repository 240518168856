// @flow

function get(key: string, defaultValue: any = null): any {
  const data = localStorage.getItem(key);

  if (!data) {
    return defaultValue;
  }

  return JSON.parse(data);
}

function save(key: string, data: any) {
  localStorage.setItem(key, JSON.stringify(data));
}

function remove(key: string) {
  localStorage.removeItem(key);
}

export default {
  get,
  save,
  remove,
};
