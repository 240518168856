// @flow

import React from 'react';
import cx from 'classnames';

import { useTranslation } from 'react-i18next';
import { type Message } from '../../../types/chat';

type Props = {
  alignToRight?: boolean,
  message: Message,
};

const ApplicationBubble = ({ message, alignToRight }: Props) => {
  const { t } = useTranslation();
  if (message.type !== 'application') {
    return null;
  }

  return (
    <span
      className={cx('c-chat__bubble c-chat__bubble--application', {
        'c-chat__bubble--left': alignToRight,
      })}
    >
      <span className="c-chat__bubble--application-title">{t('common.glossary.jobApplied')}</span>
      <span className="c-chat__bubble--application-subtitle">{message.body.jobTitle}</span>
    </span>
  );
};

export default ApplicationBubble;
