// @flow

import React, { type Element } from 'react';

import Radio from '../Radio';

type Props = {
  /** className */
  className?: string,

  /** selected group value */
  selectedValue?: string,

  /** options */
  children: Iterable<Element<typeof Radio>>,

  /** onChange handler */
  onChange: (selectedOption: string) => void,

  /** disabled */
  disabled: boolean | undefined,
};

const RadioGroup = ({
  className = '',
  selectedValue,
  children,
  onChange,
  disabled = false,
}: Props) => {
  const options = React.Children.map(children, (option: any) =>
    React.cloneElement(option, {
      checked: option.props.value === selectedValue,
      onChange: () => onChange(option.props.value),
      disabled,
    }),
  );

  return <div className={`c-radio-group ${className}`}>{options}</div>;
};

export default RadioGroup;
