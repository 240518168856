// @flow

import { type Package } from '../types/package';

// eslint-disable-next-line import/prefer-default-export
export const PackageMapper = {
  fromAPIResponse(response: Object): Package {
    return {
      id: response.id,
      name: response.name,
      price: response.price,
      originalPrice: response.original_price,
      isPopular: response.is_popular,
      features: response.features,
      currency: response.currency,
      threadCreationAmount: response.thread_creation_amount,
      jobCreationAmount: response.job_creation_amount,
      rulePriceTTL: response.rule_price_ttl && new Date(response.rule_price_ttl),
    };
  },
  fromAPIResponseMultiple(response: Array<Object>): Array<Package> {
    return response.map(PackageMapper.fromAPIResponse);
  },
};
