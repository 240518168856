// @flow

import React from 'react';
import { Route, Redirect, type ContextRouter } from 'react-router-dom';

import Auth from '../../services/auth';
import { UserGrantMap } from '../../enums/userGrant';
import { dashboardAccessPopup, jobCreationPopup } from '../../utils/pagePermissions';
import useTranslator from '../../hooks/useTranslator';

type Props = {
  component: any,
  level: $Values<typeof UserGrantMap>,
};

function redirectToPackages(location: string) {
  return (
    <Redirect
      to={{
        pathname: '/packages',
        state: { from: location },
      }}
    />
  );
}

const ProtectedRoute = ({ component: Component, level, ...otherProps }: Props): any => {
  const { t } = useTranslator();
  return (
    <Route
      {...otherProps}
      render={(props: ContextRouter) => {
        if (!Auth.isAuthenticated()) {
          return (
            <Redirect
              to={{
                pathname: '/register',
                state: { from: props.location },
              }}
            />
          );
        }

        const allowedRoutes = [
          '/',
          '/login',
          '/register',
          '/jobs',
          '/profile',
          '/logout',
          '/profile/edit',
          '/packages',
          '/packages/payment-result',
          '/packages/payment',
        ];

        // if (!allowedRoutes.includes(props.location.pathname) && !Auth.hasDashboardAccess()) {
        //   dashboardAccessPopup(t);
        //   return redirectToPackages(props.location);
        // }

        if (props.location.pathname === '/jobs/create' && !Auth.hasJobCreationBalance()) {
          jobCreationPopup(t);
          return redirectToPackages(props.location);
        }

        if (!Auth.isGranted(level)) {
          return <Redirect to="/unauthorized" />;
        }

        return <Component {...props} {...otherProps} />;
      }}
    />
  );
};

export default ProtectedRoute;
