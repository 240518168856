// @flow

import React, { type Node } from 'react';

type Props = {
  /** badge value */
  children?: Node,

  /** extra className */
  className?: string,

  /** badge type */
  type?: 'default' | 'success' | 'danger' | 'info' | 'warn',

  /** small badge */
  small?: boolean,

  /** round badge */
  round?: boolean,
};

/**
 * Badge component
 */
const Badge = ({
  children,
  className = '',
  type = 'default',
  small = false,
  round = false,
}: Props) => {
  const classNames = ['c-badge'];

  if (className.length > 0) {
    classNames.push(className);
  }

  if (small) {
    classNames.push('c-badge--small');
  }

  if (round) {
    classNames.push('c-badge--round');
  }

  classNames.push(`c-badge--${type}`);

  return <span className={classNames.join(' ')}>{children}</span>;
};

export default Badge;
