// @flow
export default {
  inbox: {
    isSureToDeleteConversation: 'Bu mesajlaşmayı silmek istediğinize emin misiniz?',
    blockUserQuestion: 'Bu çalışanı bloklamak istediğinize emin misiniz?',
    deleteConversation: 'Mesajlaşmayı Sil',
    blockUser: 'Çalışanı Blokla',
    experience: 'İş Tecrübesi',
    noMessage: 'Mesajlaşma bulunamadı',
    messagesNotFound: 'Hiç mesajlaşmanız bulunmamaktadır.',
    appointmentConfirmed:
      'Randevu {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} aralığına oluşturuldu.',
    appointmentNotConfirmed:
      'Randevu {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} onaylanmadı.',
    appointmentCanceledCompany:
      'Randevu {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} şirket tarafından iptal edildi.',
    appointmentCanceledOutOf24Hours:
      '24 Saat içerisinde randevu davetine dönüş yapılmadığından randevu iptal edildi.',
    appointmentUpdated: 'Randevu {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} güncellendi.',
    statuses: {
      main: 'Randevu {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}}',
      approved: 'Randevu {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} onaylandı.',
      rejected: 'Randevu {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} onaylanmadı.',
      canceled: 'Randevu {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} iptal edildi.',
      expired: 'Randevu {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} süresi doldu',
      waiting: 'Randevu {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} onay bekliyor.',
    },
  },
};
