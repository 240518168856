// @flow
export default {
  jobs: {
    noData: 'No jobs yet.',
    addJob: 'CREATE A JOB',
    jobListTitle: 'Job title',
    jobListCount: 'Application',
    unreviewed: 'Unreviewed',
    shortList: 'Shortlisted',
    branchName: 'Branch name',
    active: 'ACTIVE',
    passive: 'INACTIVE',
    applicationCount: 'Application count',
    noBranchFound: 'No branches yet',
    view: 'View',
    jobListDate: 'Job Date',
    status: 'Status',
    create: {
      title: 'CREATE A JOB',
    },

    edit: {
      title: 'EDIT JOB',
    },

    detail: {
      noApplicationData: 'No application yet.',
      sendExcelInfoText: 'Excel will be sent to the specified email',
    },

    excelModal: {
      title: 'Export to excel',
    },
    excelForm: {
      email: {
        label: 'E-mail',
        required: 'Please enter your email.',
      },
      send: 'Send',
    },

    excelDownloadModal: {
      title: 'Excel download service',
    },
    excelDownloadForm: {
      date: {
        label: 'Applications after a certain date',
      },
      day: {
        label: 'Day',
        required: 'Please select a day',
      },
      month: {
        label: 'Month',
        required: 'Please select a month',
      },
      day: {
        label: 'Year',
        required: 'Please select a year',
      },
      onlyShortlisted: {
        label: 'Only shortlisted applications',
      },
      download: 'Download',
    },

    jobForm: {
      uploadImage: 'Upload a picture for your job',
      infoText: 'This information will not be displayed to candidates.',
      save: 'SAVE',
      createJob: 'CREATE A JOB',
      jobImage: 'Image Of Job',
      status: {
        label: 'Job status',
        required: 'Please select a job status.',
      },
      user: {
        label: 'Branch',
        required: 'Please select a branch.',
      },
      title: {
        label: 'Job title',
        required: 'Please enter job title.',
      },
      position: {
        label: 'Position',
        required: 'Please enter position.',
        placeholder: 'Please select',
      },
      category: {
        label: 'Category',
        required: 'Please select a category.',
      },
      description: {
        label: 'Job detail',
        required: 'Please enter job details.',
      },
      contactOption: {
        label: 'Should the shortlisted candidates contact you directly?',
        required: 'Please enter contact information.',
      },
      autoShortlistEnabled: {
        label: 'Auto-shortlist?',
      },
      autoShortlistThreshold: {
        label: 'Minimum match score',
        required: 'Please enter a minimum match score to shortlist.',
        minNumber: 'Minimum value can be {{count}}.',
        maxNumber: 'Maximum value can be {{count}}.',
      },
      contactPhone: {
        label: 'Phone',
        required: 'Please enter your phone number.',
      },
      workTimePreferences: {
        label: 'Work time preference',
        minLength: 'This field is required.',
        required: 'Please enter your work time preference.',
      },
      minExperienceDuration: {
        label: 'Minimum experience',
      },
      expectedSalaryValidate: 'Must be greater than zero.',
      expectedSalaryMin: {
        label: 'Minimum salary',
      },
      expectedSalaryMax: {
        label: 'Maksimum salary',
      },
      gender: {
        label: 'Gender',
        required: 'Please select gender filter.',
      },
      militaryRequirements: {
        label: 'Military requirement',
      },
      birthDateMin: {
        label: 'Minimum age',
        minNumber: 'Minimum age can be {{age}}.',
      },
      birthDateMax: {
        label: 'Maksimum age',
        minNumber: 'Maximum age can be {{age}}.',
      },
      minEducationStatus: {
        label: 'Minimum education level',
      },
      minEnglishLevel: {
        label: 'Minimum English level',
      },
      locationSearch: {
        label: 'Search location',
      },
      locationPosition: {
        label: 'Select location',
        required: 'Please select location.',
      },
      peopleBoxProjectId: {
        label: 'Enter People Box Id',
        required: 'Please enter people box ID.',
      },
    },
  },
};
