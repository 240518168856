// @flow

import newMessageReceived from './newMessageReceived';
import { type Notification } from '../../../types/fcm';

export default function(notification: Notification) {
  switch (notification.type) {
    case 'new_message_recevied':
      newMessageReceived(notification);
      break;
    default:
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line
        console.error('Unexpected notification type!', notification);
      }
  }
}
