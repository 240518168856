// @flow

export type FCMFallbackTicker = { observe(boolean): void, free(): void };

function ticker(fn: Function, ms: number): FCMFallbackTicker {
  // eslint-disable-next-line no-underscore-dangle
  let _interval;

  return {
    observe(hasFCMSupport: boolean) {
      if (!hasFCMSupport) {
        if (!_interval) {
          _interval = setInterval(fn, ms);
        }
        return;
      }

      if (_interval) {
        clearInterval(_interval);
        _interval = null;
      }
    },

    free() {
      if (_interval) {
        clearInterval(_interval);
        _interval = null;
      }
    },
  };
}

export default {
  ticker,
};
