// @flow
import translationEnUS from './enUs/translations';
import translationEsMX from './esMx/translations';
import translationTR from './trTr/translations';

const resources: { [language: string]: any } = {
  tr: {
    name: 'Turkish',
    translation: translationTR,
  },
  trTR: {
    name: 'Turkish - Turkey',
    translation: translationTR,
  },
  en: {
    name: 'English',
    translation: translationEnUS,
  },
  enUS: {
    name: 'English American',
    translation: translationEnUS,
  },
  es: {
    name: 'Spanish',
    translation: translationEsMX,
  },
  esMX: {
    name: 'Spanish - Mexico',
    translation: translationEsMX,
  },
};

export default resources;
