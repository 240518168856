// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { login } from '../../redux/modules/currentUser';
import { type ReduxDispatch } from '../../types/redux';
import {
  loadThunk as loadCategoriesThunk
} from '../../redux/modules/categories';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Box from '../../components/elements/Box';
import LoginForm from '../../forms/LoginForm';
import Logo from '../../components/shared/Logo';

type Props = {
  login: (email: string, password: string) => Promise<*>,
  loadCategoriesThunk: () => void,
  location: any,
  push: Function,
  t: Function,
};

class Login extends PureComponent<Props> {
  props: Props;

  handleSubmit = async (values: Object): Promise<*> => {
    try {
      await this.props.login(values.email, values.password);
      this.props.loadCategoriesThunk();

      /* $FlowIssue */
      const { from } = this.props.location.state || { from: { pathname: '/' } };

      this.props.push(from);
    } catch (err) {
      if (err.message === 'exception.user.invalid') {
        throw new SubmissionError({
          password: this.props.t('auth.login.error.submissionError.password'),
        });
      }

      this.props.push('/error');
    }
  };

  render() {
    return (
      <section className="u-pad-ends-large">
        <Container>
          <Row>
            <Col lg={6} className="col--offset-lg-3">
              <Box className="u-pad-ends-medium@md-up u-pad-ends@sm-down u-pad-sides-medium@md-up u-pad-sides@sm-down">
                <Logo
                  className="c-box__center-logo"
                  alt={this.props.t('layout.header.title')}
                  style={{ width: 150, height: 150, objectFit: 'scale-down' }}
                />
                <LoginForm onSubmit={this.handleSubmit} />
              </Box>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ login, push, loadCategoriesThunk }, dispatch);

// $FlowFixMe
export default connect(null, mapDispatchToProps)(withTranslation()(Login));
