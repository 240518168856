// @flow

import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { workerComment } from '../api/application';

import WorkerCommentForm from '../forms/WorkerCommentForm';
import { type Worker } from '../types/user';
import { themePalette } from '../theme/index';

type Props = {
  worker: Worker,
  t: Function,
};

class WorkerComment extends PureComponent<Props> {
  props: Props;

  handleSubmit: Object => Promise<*>;

  constructor(props: Props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(values: Object) {
    const workerCommentUpdate = await workerComment(this.props.worker.id, values);
    if (workerCommentUpdate.status === true) {
      await swal({
        text: this.props.t('jobDetail.profile.commentUpdated'),
        icon: 'info',
        button: this.props.t('jobDetail.profile.saveComment'),
        customClass: 'localColor',
      });
    }
  }

  render() {
    const initialValues = { comment: this.props.worker.comment };
    const { t } = this.props;

    return (
      <div className="u-pad-ends-small u-pad-sides-small">
        <h6 className="u-clear-gap-top u-color-primary" style={{ color: themePalette.color }}>
          {t('jobDetail.profile.comments')}
        </h6>
        <WorkerCommentForm onSubmit={this.handleSubmit} initialValues={initialValues} />
      </div>
    );
  }
}

export default withTranslation()(WorkerComment);
