// @flow

import React, { type Node } from 'react';

type Props = {
  /** extra className */
  className?: string,

  /** Row children */
  children: Node,

  /** No Gutter */
  noGutter?: boolean,
};

/**
 * Grid Row Element
 */
const Row = ({ className = '', children, noGutter = false, ...otherProps }: Props) => {
  const classNames = ['row'];

  if (noGutter) {
    classNames.push('row--no-gutter');
  }

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} {...otherProps}>
      {children}
    </div>
  );
};

export default Row;
