// @flow

import { type Category } from '../types/category';

// eslint-disable-next-line import/prefer-default-export
export const CategoryMapper = {
  fromAPIResponse(response: Object): Category {
    return {
      id: response.id || 0,
      name: response.name || '',
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Category> {
    if (!response) {
      return [];
    }

    return response.map(CategoryMapper.fromAPIResponse);
  },
};
