// @flow

import { type Notification } from '../../types/fcm';

/**
 * Parses remote fcm notification.
 * Returns null on invalid notification.
 */
export default function(notif: Object): Notification | null {
  if (!notif || !notif.type) {
    return null;
  }

  try {
    return ({
      type: notif.type,
      payload: typeof notif.payload === 'string' ? JSON.parse(notif.payload) : notif.payload,
    }: any);
  } catch (e) {
    return null;
  }
}
