// @flow

import React, { type Node } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  /** link path */
  to: string,

  /** extra className */
  className?: string,

  /** button value */
  children: Node,

  /** if button is primary */
  primary?: boolean,

  /** if button is ghost */
  ghost?: boolean,

  /** if button is small size */
  small?: boolean,

  /** if button is disabled */
  disabled?: boolean,

  /** if button state is loading */
  loading?: boolean,
};

/**
 * Button component
 */
const Button = ({
  to,
  className = '',
  style = {},
  children,
  primary = false,
  ghost = false,
  small = false,
  disabled = false,
  loading = false,
  ...otherProps
}: Props) => {
  const classNames = ['c-button'];

  if (primary) {
    classNames.push('c-button--primary');
  }

  if (ghost) {
    classNames.push('c-button--ghost');
  }

  if (small) {
    classNames.push('c-button--small');
  }

  if (loading) {
    classNames.push('c-button--loading');
  }

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    <Link
      to={to}
      className={classNames.join(' ')}
      disabled={disabled}
      style={style}
      {...otherProps}
    >
      <span className="c-button__inner">{children}</span>
    </Link>
  );
};

export default Button;
