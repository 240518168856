// @flow

import React from 'react';

import './index.css';

type Props = {
  /** extra className */
  className?: string,
  size?: 'medium' | 'large',
  /** close, check, clock, map-marker, th-large, search, edit, add, store, angle-up, angle-right, angle-left, angle-down, message */
  // eslint-disable-next-line
  name:
    | 'close'
    | 'check'
    | 'clock'
    | 'map-marker'
    | 'th-large'
    | 'search'
    | 'edit'
    | 'add'
    | 'store'
    | 'angle-up'
    | 'angle-right'
    | 'angle-left'
    | 'angle-down'
    | 'message'
    | 'info'
    | 'appointment'
    | 'recruitment'
    | 'filter'
    | 'profile'
    | 'rating'
    | 'conversation',
  onClick: void,
};

/**
 * Icon Element
 */
const Icon = ({ className = '', name, size, onClick, ...otherProps }: Props) => {
  const classNames = [];

  if (size) {
    classNames.push(`icon--${size}`);
  }

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <i
      role={onClick ? 'button' : 'none'}
      className={`icon icon-${name} ${classNames.join(' ')}`}
      onClick={onClick}
      {...otherProps}
    />
  );
};

export default Icon;
