// @flow
export default {
  errors: {
    errorBoundary: {
      title: 'Oops! Bir Hata Oluştu.',
      explanation: 'Lütfen yeniden yüklemeyi veya ana sayfaya dönmeyi deneyin.',
      notFound: 'Aradığınız Sayfa Bulunamadı',
      backToHome: 'Ana Sayfaya Dön',
    },
  },
};
