import api from '../utils/api';

const formInputList = async (name) => {
    return new Promise(async (resolve, reject) => {
        const response = await api.post('/form-input-list', {
            "formName": name
        });

        if (!response.ok) {
            reject(response.data);
            throw response.data;
        }

        resolve(response.data.payload);
    });
};
export default formInputList;