// @flow

export const UserTypeMap = {
  WORKER: 'worker',
  BRANCH: 'branch',
  COMPANY: 'company',
  HR: 'hr_account',
  ROOT: 'root',
};

export const UserTypeDefault = UserTypeMap.BRANCH;
