// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import FormFieldTextarea from '../components/elements/FormFieldTextarea';
import Button from '../components/ui/Button';
import { themePalette } from '../theme/index';

type Props = {
  handleSubmit: Function,
  submitting: boolean,
};

const WorkerCommentForm = ({ handleSubmit, submitting }: Props) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <div className="u-gap-bottom">
        <Field
          name="comment"
          type="comment"
          component={FormFieldTextarea}
          label={t('jobDetail.profile.comment.label')}
          props={{
            rows: 8,
          }}
        />
      </div>
      <div className="u-text-right">
        <Button
          type="submit"
          primary
          disabled={submitting}
          loading={submitting}
          style={{
            backgroundColor: themePalette.primary_color,
            boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
          }}
        >
          {t('jobDetail.profile.saveComment')}
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'worker_comment',
})(WorkerCommentForm);
