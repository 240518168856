// @flow

import React from 'react';

type Props = {
  className?: string,
  alt?: string,
  latitude: number,
  longitude: number,
  zoom?: number,
  width: number,
  height: number,
  mapType?: 'roadmap' | 'satellite' | 'hybrid' | 'terrain',
  markers?: Array<{ latitude: number, longitude: number }>,
};

const StaticMap = ({
  className,
  alt = 'Lokasyon',
  latitude,
  longitude,
  zoom = 12,
  width,
  height,
  mapType = 'roadmap',
  markers = [],
}: Props) => {
  const src = [
    'https://maps.googleapis.com/maps/api/staticmap?',
    `center=${latitude},${longitude}&`,
    `zoom=${zoom}&`,
    `size=${width}x${height}&`,
    `maptype=${mapType}&`,
    `markers=${markers.map(marker => `${marker.latitude},${marker.longitude}`).join('|')}&`,
    `key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''}`,
  ].join('');

  return <img src={src} alt={alt} className={className} />;
};

export default StaticMap;
