// @flow
export default {
  profile: {
    detail: {
      unknown: 'Desconocido',
      name: 'NOMBRE(S)',
      lastName: 'APELLIDO',
      email: 'E-MAIL',
      phone: 'TELEFONO',
      taxOffice: 'UBICACION FISCAL',
      taxOfficeNumber: 'RFC',
      commercialTitle: 'TITILO COMERCIAL',
      website: 'WEB SITE',
      companyAddress: 'UBICACION',
      location: 'UBICACION',
    },

    edit: {
      error: {
        submissionError: {
          phone: 'Este número ha sido usado por otro usuario.',
        },
      },

      form: {
        edit: 'Editar',
        cancel: 'Cancelar',
        firstName: {
          label: 'Nombre(s)',
          required: 'Escribe nombre(s).',
        },
        lastName: {
          label: 'Apellido',
          required: 'Escribe apellido.',
        },
        email: {
          label: 'E-mail',
          required: 'Escribe e-mail.',
        },
        phone: {
          label: 'Teléfono',
          required: 'Escribe teléfono.',
        },
        taxOffice: {
          label: 'Ubicación fiscal',
          placeholder: 'Escribe Ubicación fiscal',
        },
        taxOfficeNumber: {
          label: 'RFC',
        },
        commercialTitle: {
          label: 'Titilo commercial',
        },
        website: {
          label: 'Web site',
        },
        locationSearch: {
          label: 'Ubicación',
        },
        locationPosition: {
          label: 'Seleccionar Ubicación',
          required: 'Seleccionar Ubicación.',
        },
      },
    },
    hrList: {
      detail: {
        title: 'Usuarios',
        noHR: {
          description: 'No hay usuario.',
        },
      },
      alerts: {
        isSureRemoveThatHR: '¿Estás seguro que quieres borrar este usuario ?',
      },
      subTitles: {
        addUser: 'Agregar usuario',
        editUser: 'Editar usuario',
      },
      hrModal: {},
      hrForm: {
        informations: {
          aboutEMail:
            'Enviaremos un link para crear contraseña.',
        },
      },
    },
  },
};
