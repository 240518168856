// @flow

import { type Experience } from '../types/experience';

// eslint-disable-next-line import/prefer-default-export
export const experienceToText = (experiences: Array<Experience>, t) => {
  // TODO: t passed to function as an argument to cover errors thrown because of Class Components render method
  if (experiences.length === 0) {
    return t('common.glossary.nonExistent');
  }

  const totalMonth = experiences.reduce((total, exp) => total + exp.duration.key, 0);

  if (totalMonth) {
    return `${totalMonth} ${t('common.glossary.month').toLowerCase()}`;
  }

  return t('common.glossary.inexperienced');
};
