// @flow
import React from 'react';

type Props = {
  /** extra className */
  className?: string,
};

export default function ({ className, ...otherProps }: Props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...otherProps}>
      <path
        d="M1 13V14C1 14.7956 1.31607 15.5587 1.87868 16.1213C2.44129 16.6839 3.20435 17 4 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V13M13 9L9 13M9 13L5 9M9 13V1"
        stroke={otherProps.stroke || "black"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

  );
}
