// @flow

import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { compose, withProps } from 'recompose';

type Props = {
  marker?: {
    latitude: number,
    longitude: number,
  },
  ref?: any,
  zoom: number,
  center: {
    latitude: number,
    longitude: number,
  },
  onClick?: any => void,
  onIdle?: any => void,
};

const Map = compose(
  withProps(props => {
    return {
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
        process.env.REACT_APP_GOOGLE_MAP_API_KEY
      }&libraries=places&language=${props.language || 'en-US'}`,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: props.containerElement,
      mapElement: props.mapElement,
    };
  }),
  withScriptjs,
  withGoogleMap,
)((props: Props) => (
  <GoogleMap
    ref={props.ref}
    options={{
      streetViewControl: false,
    }}
    zoom={props.zoom}
    center={{ lat: props.center.latitude, lng: props.center.longitude }}
    onClick={props.onClick}
    onIdle={props.onIdle}
  >
    {props.marker && (
      <Marker position={{ lat: props.marker.latitude, lng: props.marker.longitude }} />
    )}
  </GoogleMap>
));

export default Map;
