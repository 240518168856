// @flow

import React, { PureComponent, type Node } from 'react';

type Props = {
  accept: string,
  onFileReady: (file: File) => void,
  children: Node,
};

class UploadDialog extends PureComponent<Props> {
  props: Props;

  ref: any;

  handleFileChange: (SyntheticInputEvent<HTMLInputElement>) => void;

  constructor(props: Props) {
    super(props);

    this.handleFileChange = this.handleFileChange.bind(this);
  }

  handleFileChange(event: SyntheticInputEvent<HTMLInputElement>) {
    this.props.onFileReady(event.target.files[0]);
  }

  render() {
    return (
      <span>
        <input
          type="file"
          style={{ display: 'none' }}
          accept={this.props.accept}
          ref={ref => {
            this.ref = ref;
          }}
          onChange={this.handleFileChange}
          onClick={(event: any) => {
            // eslint-disable-next-line
            event.target.value = null;
          }}
        />
        <a
          href=""
          onClick={event => {
            event.preventDefault();
            this.ref.click();
          }}
        >
          {this.props.children}
        </a>
      </span>
    );
  }
}

export default UploadDialog;
