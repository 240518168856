import React from 'react';
import WorkerRatingStars from './WorkerRatingStars';

const COMPONENT_CSS_CLASS = 'c-worker-rating';

type Props = {
  workerRatingScore: number,
};

const MAX_STARS = 5;
let ratingTextRegex = /(\d+\.\d)(\d+)/;

const WorkerRating = ({ workerRatingScore }: Props) => {
  return (
    <div className={COMPONENT_CSS_CLASS}>
      {`${
        workerRatingScore === 0 ? '-' : workerRatingScore.toString().replace(ratingTextRegex, '$1')
      }/5`}
      <WorkerRatingStars rating={workerRatingScore} maxStars={MAX_STARS} />
    </div>
  );
};

export default WorkerRating;
