// @flow

import React, { type Node } from 'react';

type Props = {
  /** checkbox text */
  children?: Node,

  /** container className */
  containerClassName?: string,

  /** checkbox className */
  className?: string,

  /** if checkbox is disabled */
  disabled?: boolean,

  /** checkbox is checked */
  checked: boolean,

  /** onChange event handler */
  onChange: (isChecked: boolean) => void,

  /** innerRef incase it is needed */
  innerRef?: any,
};

/**
 * Controlled checkbox component
 */
const Checkbox = ({
  containerClassName = '',
  className = '',
  disabled = false,
  children,
  checked,
  onChange,
  innerRef,
  ...otherProps
}: Props) => {
  const containerClassNames = ['c-checkbox'];
  const classNames = ['c-checkbox__inp'];

  if (containerClassName.length > 0) {
    containerClassNames.push(containerClassName);
  }

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    /* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
    <label className={containerClassNames.join(' ')}>
      <input
        type="checkbox"
        disabled={disabled}
        className={classNames.join(' ')}
        checked={checked}
        onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
          onChange(event.target.checked);
        }}
        ref={innerRef}
        {...otherProps}
      />
      <span className="c-checkbox__mask u-gap-right-small" />
      {children}
    </label>
  );
};

export default Checkbox;
