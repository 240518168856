/* eslint-disable no-underscore-dangle */
// @flow
import { Basket, BasketItem } from '../types/basket';

export type BasketItemPayload = {
  product: {
    id: number,
    type: 'package' | 'addon' | 'product',
    title: string,
    price: number,
    currency: string,
  },
  quantity: number,
  totalPrice: number,
  _id: string,
};

export type BasketPayload = {
  status: 'basket_active',
  _id: string,
  items: BasketItemPayload[],
  totalPrice: number,
  userId: number,
  currency: string,
};

export const BasketMapper = {
  fromApiResponseBasketItem(response: BasketItemPayload): BasketItem {
    return {
      id: response._id,
      quantity: response.quantity,
      // @ts-ignore
      product: response.product,
      totalPrice: response.totalPrice,
    };
  },

  fromApiResponse(response: BasketPayload): Basket {
    return {
      id: response._id,
      userId: response.userId,
      status: response.status,
      items: response.items.map(BasketMapper.fromApiResponseBasketItem),
      totalPrice: response.totalPrice,
      currency: response.currency,
    };
  },
};
