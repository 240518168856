// @flow
export default {
  layout: {
    header: {
      title: '24 Saatte İş',
      jobs: 'İlanlar',
      branches: 'Şubeler',
      myProfile: 'Profilim',
      candidatePool: 'Aday Havuzu',
      messages: 'Mesajlar',
      logout: 'Çıkış Yap',
      applications: 'Başvurular',
    },
    navbar: {
      title: 'Tüm İlanlar',
      addJob: 'İlan Oluştur',
      update: 'Düzenle',
      excelReport: "Başvuruları Excel'e aktar",
      addBranch: 'Şube Ekle',
    },
  },
};
