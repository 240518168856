// @flow

import React from 'react';
import { Redirect } from 'react-router-dom';

import Auth from '../../services/auth';

type Props = {
  component: any,
};

const AnonymousRoute = ({ component: Component, ...otherProps }: Props): any => {
  if (Auth.isAuthenticated()) {
    return <Redirect to="/" />;
  }

  return <Component {...otherProps} />;
};

export default AnonymousRoute;
