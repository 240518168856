// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';
import { withTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { resetPassword, resetPasswordTokenIsValid } from '../../redux/modules/currentUser';
import { type ReduxDispatch } from '../../types/redux';

import Container from '../../components/layout/Container';
import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';
import Box from '../../components/elements/Box';
import Logo from '../../components/shared/Logo';
import ResetPasswordForm from '../../forms/ResetPasswordForm';

type Props = {
  resetPassword: (
    resetPasswordCode: string,
    firstPassword: string,
    secondPassword: string,
  ) => Promise<*>,
  resetPasswordTokenIsValid: (resetPasswordCode: string) => Promise<*>,
  location: any,
  push: Function,
  t: Function,
};

class ResetPassword extends PureComponent<Props> {
  props: Props;

  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidToken();
  }

  async isValidToken(): * {
    const isValid = await this.props.resetPasswordTokenIsValid(
      this.props.computedMatch.params.resetPasswordCode,
    );
    if (!isValid) {
      await swal({
        text: this.props.t('resetPassword.form.invalidToken'),
        icon: 'info',
        button: this.props.t('common.glossary.ok'),
        className: 'localColor',
      });
      this.props.push('/');
    }
  }

  handleSubmit = async (values: Object): Promise<*> => {
    try {
      const resetPasswordResult = await this.props.resetPassword(
        this.props.computedMatch.params.resetPasswordCode,
        values.firstPassword,
        values.secondPassword,
      );
      if (resetPasswordResult === true) {
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        this.props.push(from);
        await swal({
          text: this.props.t('resetPassword.form.success'),
          icon: 'info',
          button: this.props.t('common.glossary.ok'),
          dangerMode: true,
          customClass: 'localColor',
        });
      }
    } catch (err) {
      if (err.errors.errors.includes('password.is_not.equal')) {
        throw new SubmissionError({
          firstPassword: this.props.t('resetPassword.form.passwordIsNotEqual'),
          secondPassword: this.props.t('resetPassword.form.passwordIsNotEqual'),
        });
      }
    }
  };

  render() {
    return (
      <section className="u-pad-ends-large">
        <Container>
          <Row>
            <Col lg={6} className="col--offset-lg-3">
              <Box className="u-pad-ends-medium@md-up u-pad-ends@sm-down u-pad-sides-medium@md-up u-pad-sides@sm-down">
                <Logo
                  className="c-box__center-logo logo_size"
                  alt={this.props.t('layout.header.title')}
                  style={{ objectFit: 'scale-down' }}
                />
                <ResetPasswordForm onSubmit={this.handleSubmit} />
              </Box>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ resetPassword, resetPasswordTokenIsValid, push }, dispatch);

// $FlowFixMe
export default connect(null, mapDispatchToProps)(withTranslation()(ResetPassword));
