// @flow

import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import { withTranslation } from 'react-i18next';
import fcmFallback, { type FCMFallbackTicker } from '../../../utils/fcmFallback';
import { isLoading, isFailed, isLoaded } from '../../../utils/apiState';
import { type TextMessagePayload, type LocationMessagePayload } from '../../../types/chat';
import { type CurrentUserState } from '../../../redux/modules/currentUser';
import { type ReduxDispatch } from '../../../types/redux';
import { type ReduxState } from '../../../redux/modules';
import {
  getDetailByThreadId,
  sendMessageThunk,
  loadHiddenThunk,
  loadThunk,
  type ConversationDetailItemState,
} from '../../../redux/modules/conversationDetail';

import { deleteConversation } from '../../../api/chat';

import Confirm from '../../../components/elements/Confirm';
import Button from '../../../components/ui/Button';
import Loading from '../Loading';
import Conversation from '../../../components/chat/Conversation';
import CircleImage from '../../../components/elements/CircleImage';
import { experienceToText } from '../../../utils/experience';
import { themePalette } from '../../../theme/index';

type Props = {
  currentUser: CurrentUserState,
  threadId: number,
  branchId: number,
  fcmSupportStatus: boolean,
  conversationDetail: ConversationDetailItemState,
  loadThunk: (number, number) => void,
  loadHiddenThunk: (number, number) => void,
  push: any,
  sendMessageThunk: (
    threadId: number,
    branchId: number,
    recipientId: number,
    message: TextMessagePayload | LocationMessagePayload,
  ) => void,
  onBlockUserClick?: () => void,
  t: Function,
};

class ConversationRoot extends PureComponent<Props> {
  props: Props;

  ticker: FCMFallbackTicker;

  confirmDialog: any;

  constructor(props: Props) {
    super(props);

    this.ticker = fcmFallback.ticker(this.refresh, 5e3);
    this.ticker.observe(props.fcmSupportStatus);
    this.chatScrollToBottomRef = createRef();
  }

  componentWillMount() {
    this.props.loadThunk(this.props.threadId, this.props.branchId);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.threadId !== nextProps.threadId) {
      this.props.loadThunk(nextProps.threadId, nextProps.branchId);
    }
    if (
      this.props.conversationDetail !== nextProps.conversationDetail &&
      isLoaded(nextProps.conversationDetail)
    ) {
      this.chatScrollToBottomRef.current();
    }
    this.ticker.observe(nextProps.fcmSupportStatus);
  }

  componentWillUnmount() {
    this.ticker.free();
  }

  refresh = () => {
    this.props.loadHiddenThunk(this.props.threadId, this.props.branchId);
  };

  onNewMessage = (message: TextMessagePayload | LocationMessagePayload) => {
    const { thread } = this.props.conversationDetail.data;

    this.props.sendMessageThunk(thread.id, this.props.branchId, thread.user.id, message);
  };

  handleThreadDelete = async () => {
    const { t } = this.props;

    this.confirmDialog.show(t('inbox.isSureToDeleteConversation'), {
      onConfirm: async () => {
        await deleteConversation(this.props.threadId);

        if (
          this.props.currentUser.user.userType === 'root' ||
          this.props.currentUser.user.userType === 'hr_account'
        ) {
          this.props.push(`/inbox/${this.props.branchId}`);
          return;
        }

        this.props.push('/inbox');
      },
    });
  };

  render() {
    const { onBlockUserClick, conversationDetail, t } = this.props;

    if (isFailed(conversationDetail)) {
      return null;
    }

    if (isLoading(conversationDetail)) {
      return <Loading />;
    }

    return (
      <div>
        <div className="u-bg-white u-pad-ends-small u-pad-sides-small">
          <div className="u-flex u-flex-align-middle">
            <CircleImage src={conversationDetail.data.thread.user.imageUrl} />
            <div className="u-gap-left u-flex-grow-full">
              <h2 className="u-clear-gap-top u-gap-bottom-xsmall u-font-weight-bold">
                {conversationDetail.data.thread.user.firstName}{' '}
                {conversationDetail.data.thread.user.lastName}
              </h2>
              <span className="u-color-gull-gray">
                {`
                ${t('inbox.experience')}: ${experienceToText(
                  conversationDetail.data.thread.user.experiences,
                  t,
                )}`}
              </span>
            </div>
            <div className="u-flex u-flex-dir-column">
              {conversationDetail.data.messages.length > 0 && (
                <Button
                  type="button"
                  primary
                  outline
                  small
                  onClick={this.handleThreadDelete}
                  style={{
                    color: themePalette.color,
                    boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                  }}
                >
                  {t('inbox.deleteConversation')}
                </Button>
              )}
              {onBlockUserClick && (
                <Button
                  type="button"
                  danger
                  small
                  className="u-gap-top-xsmall"
                  onClick={onBlockUserClick}
                  style={{
                    backgroundColor: themePalette.primary_color,
                    boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                  }}
                >
                  {t('inbox.blockUser')}
                </Button>
              )}
            </div>
          </div>
        </div>
        <hr className="u-hr-line" />
        <Conversation
          scrollToBottomRef={this.chatScrollToBottomRef}
          thread={conversationDetail.data.thread}
          messages={conversationDetail.data.messages}
          onNewMessage={this.onNewMessage}
        />
        <Confirm
          ref={ref => {
            this.confirmDialog = ref;
          }}
          confirmLabel={t('common.glossary.continue')}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState, props: Props): Object => ({
  fcmSupportStatus: state.app.fcmSupport,
  conversationDetail: getDetailByThreadId(state, { threadId: props.threadId }),
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ loadThunk, loadHiddenThunk, sendMessageThunk, push }, dispatch);

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConversationRoot));
