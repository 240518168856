// @flow

import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { type Thread } from '../../../types/chat';

import Box from '../../../components/elements/Box';
import Item from './Item';

type Props = {
  threads: Thread[],
  onThreadClick: Thread => void,
  activeThreadId?: number,
  next?: () => void,
};
class Threads extends PureComponent<Props> {
  containerRef: HTMLDivElement | null;

  render() {
    const { threads, onThreadClick, activeThreadId, next } = this.props;

    return (
      <Box>
        <div
          ref={ref => {
            this.containerRef = ref;
          }}
          style={{
            maxHeight: "720px",
            overflowY: "scroll"
          }}
          onScroll={(event) => {
            const container = event.target;

            if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
              if (next) {
                next();
              }
            }
            return false;
          }}
        >
          {threads.map(thread => (
            <Item
              key={thread.id}
              thread={thread}
              onClick={() => {
                onThreadClick(thread);
              }}
              isActive={!!activeThreadId && activeThreadId === thread.id}
            />
          ))}
        </div>
      </Box>
    );
  }
}
export default withTranslation()(Threads);
