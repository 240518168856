// @flow
export default {
  jobDetail: {
    allApplications: 'TÜM BAŞVURULAR',
    shortList: 'KISA LİSTE',
    appointment: 'RANDEVU',
    searchPlaceholder: 'Ad Soyada Göre Ara',
    applyFilter: 'Filtrele',
    removeFromShortlist: 'Kısa Listeden Çıkar',
    addToShortList: 'Kısa Listeye Ekle',
    remainingTime: 'Kalan Süre',
    experience: 'İş Tecrübesi',
    shortListExitQuestion: 'Bu kullanıcıyı kısa listeden çıkarmak istediğinize emin misiniz?',
    jobClosedSubject: 'İLAN KAPATMA NEDENİ',
    found_via_app: 'Aradığım çalışanı 24 Saatte İşten buldum',
    found_via_other: 'Aradığım çalışanı başka bir kanalla buldum',
    not_need_anymore: 'Çalışana artık ihtiyacım yok',
    other: 'Diğer',
    address: 'Adres',
    new: 'YENİ',
    inShortList: 'KISA LİSTEDE',
    noAppointmentData: 'Randevu verilmiş çalışan bulunmuyor.',
    rejectedApplicationCount:
      '<0>{{rejectedApplicationCount}}</0> <1>başvuru</1>, kısa listeye eklenmediği için silinmiştir.',
    applicationDate: 'Başvuru Tarihi',
    profile: {
      profile: 'PROFİL',
      message: 'MESAJ',
      appointment: 'RANDEVU',
      feedback: 'GERİ BİLDİRİM',
      sendAsPDF: 'PDF Olarak Gönder',
      personalInformation: 'KİŞİSEL BİLGİLER',
      gender: 'Cinsiyet',
      birthDate: 'Doğum Tarihi',
      unknown: 'Bilinmiyor',
      militaryStatus: 'Askerlik Durumu',
      identityNumber: 'TC Kimlik No',
      disabledStatus: 'Engel Durumu',
      certificate: 'Sertifika',
      drivingLicenses: 'Ehliyet',
      about: 'HAKKINDA',
      noAboutArticle: 'Hakkında yazısı bulunmuyor.',
      educationInformation: 'EĞİTİM BİLGİLERİ',
      educationStatus: 'Öğrenim Durumu',
      university: 'Üniversite',
      englishStatus: 'İngilizce Seviyesi',
      experience: 'İŞ DENEYİMİ',
      noExperience: 'İş tecrübesi bulunmuyor.',
      workPreferences: 'ÇALIŞMA TERCİHLERİ',
      workCategoryPreferences: 'Sektör Tercihi',
      workTimePreferences: 'Süre Tercihi',
      expectedSalary: 'Maaş Beklentisi',
      otherApplications: 'DİĞER BAŞVURULAR',
      noOtherApplications: 'Şirketinizin başka bir ilanına başvurusu bulunmuyor.',
      noApplicationFound: 'Başvuru bulunmuyor',
      noShortlistedWorker: 'Kısa listeye alınan çalışan bulunmuyor.',
      comments: 'YORUMLAR',
      comment: {
        label: 'Çalışan Hakkında Yorumlar',
      },
      commentUpdated: 'Adayın yorumu başarıyla düzenlendi',
      saveComment: 'Yorumu Kaydet',
      shareApplicantCV: "Başvuran CV'sini paylaş",
      email: {
        label: 'E-posta',
        required: 'Lütfen e-postanızı giriniz.',
      },
      send: 'Gönder',
      isSureToDeleteConversation: 'Bu mesajlaşmayı silmek istediğinize emin misiniz?',
      isSureToRejectAppointment: 'Bu randevuyu iptal etmek istediğinize emin misiniz?',
      blockUserQuestion: 'Bu çalışanı bloklamak istediğinize emin misiniz?',
      deleteConversation: 'Mesajlaşmayı Sil',
      blockUser: 'Çalışanı Blokla',
      appointmentDateError: 'Geçmiş bir zaman için randevu oluşturamazsınız.',
      date: {
        label: 'RANDEVU ZAMANI',
        required: 'Lütfen tarih seçiniz.',
        placeholder: 'Randevu Tarihi',
      },
      time: {
        required: 'Lütfen saat seçiniz.',
        placeholder: 'Randevu Saati',
      },
      address: {
        label: 'RANDEVU AÇIK ADRESİ',
        placeholder: 'Açık Adres',
      },
      location: {
        label: 'RANDEVU YERİ',
        placeholder: 'Açık Adres',
        required: 'Lütfen saat seçiniz.',
      },
      createAppointment: 'Randevu Oluştur',
      cancelAppointment: 'Randevuyu İptal Et',
      isApplicantCameToAppointment: {
        label: 'RANDEVUYA GELDİ Mİ?',
      },
      isAppointmentPositive: {
        label: 'GÖRÜŞME OLUMLU GEÇTİ Mİ?',
      },
      isOfferSentToApplicant: {
        label: 'TEKLİF VERİLDİ Mİ?',
      },
      isApplicantAcceptTheOffer: {
        label: 'TEKLİFİ KABUL ETTİ Mİ?',
      },
      feedbackCommentLabel: 'YORUMLAR',
      clearSelections: 'Seçimleri Temizle',
      saveReview: 'Değerlendirmeyi Kaydet',
    },

    filter: {
      gender: 'CİNSİYET',
      militaryStatus: 'ASKERLİK DURUMU',
      notDone: 'Yapılmadı',
      delayed: 'Tecilli',
      done: 'Yaptı',
      age: 'YAŞ',
      experience: 'İŞ TECRÜBESİ',
      educationStatus: 'EĞİTİM DURUMU',
      englishLevel: 'İNGİLİZCE SEVİYESİ',
      distance: 'UZAKLIK',
      byApplicantCameToAppointment: 'RANDEVUYA GELME DURUMUNA GÖRE',
      came: 'Geldi',
      didNotCome: 'Gelmedi',
      accordingToInterview: 'GÖRÜŞME DURUMUNA GÖRE',
      positive: 'Olumlu',
      negative: 'Olumsuz',
      accordingToBid: 'TEKLİF VERİLME DURUMUNA GÖRE',
      offerSubmitted: 'Teklif Verildi',
      noOffer: 'Teklif Verilmedi',
      accordingToAcceptTheOffer: 'TEKLİFİ KABUL ETME DURUMUNA GÖRE',
      accepted: 'Kabul etti',
      didNotAccept: 'Kabul etmedi',
      clearFilter: 'Filtreyi Temizle',
      applyFilter: 'FİLTRELEME YAP',
    },

    feedbackForm: {
      noAppointment: 'Bu aday için oluşturulmuş bir randevu bulunmuyor!',
    },
  },
};
