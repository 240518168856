// @flow

import React, { type Node } from 'react';

type Props = {
  /** button or submit */
  type?: 'button' | 'submit',

  /** extra className */
  className?: string,

  /** button value */
  children: Node,

  /** if button is primary */
  primary?: boolean,

  /** if button is success */
  success?: boolean,

  /** if button is danger */
  danger?: boolean,

  /** if button is warning */
  warning?: boolean,

  /** if button is info */
  info?: boolean,

  /** if button is ghost */
  ghost?: boolean,

  /** if button is outline */
  outline?: boolean,

  /** if button is outline */
  borderless?: boolean,

  /** if button is link */
  link?: boolean,

  /** if button has small size */
  small?: boolean,

  /** if button is block */
  block?: boolean,

  /** if button is sharp */
  sharp?: boolean,

  /** if button is disabled */
  disabled?: boolean,

  /** if button state is loading */
  loading?: boolean,
};

/**
 * Button component
 */
const Button = ({
  type = 'button',
  className = '',
  style = {},
  children,
  primary = false,
  success = false,
  danger = false,
  warning = false,
  info = false,
  ghost = false,
  outline = false,
  borderless = false,
  block = false,
  sharp = false,
  link = false,
  small = false,
  disabled = false,
  loading = false,
  ...otherProps
}: Props) => {
  const classNames = ['c-button'];

  if (primary) {
    classNames.push('c-button--primary');
  }

  if (success) {
    classNames.push('c-button--success');
  }

  if (danger) {
    classNames.push('c-button--danger');
  }

  if (warning) {
    classNames.push('c-button--warning');
  }

  if (info) {
    classNames.push('c-button--info');
  }

  if (ghost) {
    classNames.push('c-button--ghost');
  }

  if (outline) {
    classNames.push('c-button--outline');
  }

  if (borderless) {
    classNames.push('c-button--borderless');
  }

  if (block) {
    classNames.push('c-button--block');
  }

  if (sharp) {
    classNames.push('c-button--sharp');
  }

  if (link) {
    classNames.push('c-button--link');
  }

  if (small) {
    classNames.push('c-button--small');
  }

  if (loading) {
    classNames.push('c-button--loading');
  }

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      className={classNames.join(' ')}
      disabled={disabled}
      style={style}
      {...otherProps}
    >
      <span className="c-button__inner">{children}</span>
    </button>
  );
};

export default Button;
