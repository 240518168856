// @flow

import { ExperienceDurationList, ExperienceDurationDefault } from '../enums/experienceDuration';
import { type Experience, type ExperienceDuration } from '../types/experience';

export const ExperienceDurationMapper = {
  fromAPIResponse(response: number): ExperienceDuration {
    if (!response) {
      return ExperienceDurationDefault;
    }

    const experienceDuration = ExperienceDurationList.find(
      (duration: ExperienceDuration) => duration.key === response,
    );

    return experienceDuration || ExperienceDurationDefault;
  },
};

export const ExperienceMapper = {
  fromAPIResponse(response: Object): Experience {
    if (!response) {
      return {
        id: 0,
        companyName: '',
        position: '',
        duration: ExperienceDurationDefault,
      };
    }

    return {
      id: response.id,
      companyName: response.company_name || '',
      position: response.position || '',
      duration: ExperienceDurationMapper.fromAPIResponse(response.duration),
    };
  },

  fromAPIResponseMultiple(response: Array<Object> | null): Array<Experience> {
    if (!response) {
      return [];
    }

    return response.map(ExperienceMapper.fromAPIResponse);
  },
};
