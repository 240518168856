// @flow
export default {
  inbox: {
    isSureToDeleteConversation: '¿Estás seguro de borrar este mensaje?',
    blockUserQuestion: '¿Estás seguro de bloquear a este usario?',
    deleteConversation: 'Borrar mensaje',
    blockUser: 'Bloquear usario',
    experience: 'Experiencia laboral',
    noMessage: 'No tienes mensajes',
    messagesNotFound: 'No tienes mensajes.',
    appointmentConfirmed: 'Entrevista sera {{dateStartAt}}, entre {{hourStartAt}} - {{hourEndAt}}',
    appointmentNotConfirmed:
      'Entrevista {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} rechazada.',
    appointmentCanceledCompany:
      'La cita {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} fue cancelada por la empresa.',
    appointmentCanceledOutOf24Hours:
      'La cita {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} fue cancelada porque no hubo respuesta a la invitación a la cita dentro de las 24 horas.',
    appointmentUpdated: 'Cita {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} actualizada.',
    statuses: {
      main: 'Entrevista {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}}',
      approved: 'Entrevista {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} aceptada.',
      rejected: 'Entrevista {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} rechazada.',
      canceled: 'Entrevista {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} cancelada.',
      expired: 'Entrevista {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} expirada.',
      waiting: 'Entrevista {{dateStartAt}}, {{hourStartAt}} - {{hourEndAt}} esperando.',
    },
  },
};
