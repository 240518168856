// @flow
/**
 * Analytics service is used as a bridge.
 * It dispatchs domain events to 3rd party analytics services
 * such as Firebase Analytics, Facebook.
 *
 */
import Facebook from './providers/facebook';
import FirebaseAnalytics from './providers/firebaseAnalytics';

async function companyRegistered(country: number, id: number) {
  Facebook.logSignUp(country, id);
  FirebaseAnalytics.logEvent(FirebaseAnalytics.Events.WEB_SIGNUP, { country, id });
}

async function companyLoggedIn(country: number) {
  Facebook.logLogin(country);
  FirebaseAnalytics.logEvent(FirebaseAnalytics.Events.WEB_LOGIN, { country });
}

// TODO: package_id will be added
/**
 *
 * @param {*} packageId
 * @param {*} currency
 * @param {*} price
 */
async function companyPurchasedPackage() {
  // Facebook.logPurchase(packageId, currency, price);
  Facebook.logPurchase();
  FirebaseAnalytics.logEvent(FirebaseAnalytics.Events.WEB_PURCHASE, {});
}

async function companyCreatedJob(country: number, jobId: number, jobTitle: string) {
  Facebook.logJobCreated({ country, jobId, jobTitle });
  FirebaseAnalytics.logEvent(FirebaseAnalytics.Events.WEB_JOB_CREATED, {
    country,
    job_id: jobId,
    job_title: jobTitle,
  });
}

async function purchaseListedPackageButtonPressed(packageId: string, price: string) {
  Facebook.logPurchasePackagePress(packageId, price);
}

export default {
  companyRegistered,
  companyLoggedIn,
  companyPurchasedPackage,
  companyCreatedJob,
  purchaseListedPackageButtonPressed,
};
