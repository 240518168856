// @flow

import React, { type Node } from 'react';

type Props = {
  /** extra className */
  className?: string,

  /** container children */
  children: Node,
};

/**
 * Container Element
 */
const Container = ({ className = '', children, ...otherProps }: Props) => {
  const classNames = ['container'];

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} {...otherProps}>
      {children}
    </div>
  );
};

export default Container;
