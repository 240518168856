// @flow

import React from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';

import { swalOkButtonTranslateKey } from '../../../../constants';

import Button from '../../../../components/ui/Button';

import { PaymentErrorMap } from '../../../../enums/paymentError';

type Props = {
  paymentAmountText: string | undefined,
};

const CheckoutForm = ({ paymentAmountText }: Props) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_API_NEW_URL}/payment/checkout/stripe/finalize`,
      },
    });

    if (result.error) {
      if (Object.keys(PaymentErrorMap).includes(result.error.code)) {
        swal({
          text: t(`payment.error.${PaymentErrorMap[result.error.code]}`),
          icon: 'error',
          button: t(swalOkButtonTranslateKey),
          dangerMode: true,
        });
        return;
      }

      swal({
        text: t('payment.error.paymentErrorMessage'),
        icon: 'error',
        button: t(swalOkButtonTranslateKey),
        dangerMode: true,
      });
    }
  };

  return (
    <div className="u-full-width u-flex u-flex-align-center u-gap-top">
      <form onSubmit={handleSubmit} className="u-flex u-flex-dir-column u-flex-align-center">
        <PaymentElement />
        <Button
          className="u-gap-top"
          type="submit"
          style={{
            backgroundColor: '#45b1e8',
            color: '#fff',
          }}
          disabled={!stripe}
        >
          {paymentAmountText
            ? t('payment.stripeForm.payWithAmount', { amount: paymentAmountText })
            : t('payment.stripeForm.pay')}
        </Button>
      </form>
    </div>
  );
};

export default CheckoutForm;
