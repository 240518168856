// @flow
export default {
  packages: {
    packageListItem: {
      viewDetails: 'Detayları İncele',
      salesAgreement: 'Mesafeli Satış Sözleşmesi',
      readAndAgree: 'Okudum ve onaylıyorum.',
      buy: 'Satın Al',
      creditCardDisclaimer:
        'Kredi kartı bilgileriniz saklanmaz, kartınızdan güvenli şekilde tek bir kez çekim yapılır.',
      exclusiveOfferForNewAccounts: 'Yeni Hesaplar için Özel Teklif',
      timeRemaining: 'Bu özel teklif için kalan süre: ',
    },
    error: {
      agreementNotAccepted: 'Mesafeli satış sözleşmesini onaylamanız gerekmektedir.',
      anErrorOccurred: 'Bir hata meydana geldi. Daha sonra tekrar deneyiniz.',
    },
    listPage: {
      disclaimer:
        'Paket detayları ve hizmetlerimiz hakkında detaylı bilgi almak için 0850 840 57 48 numaralı telefonu arayabilirsiniz.',
    },
  },
};
