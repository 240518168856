/* eslint-disable no-shadow */
// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import debounce from 'lodash.debounce';

import { withTranslation } from 'react-i18next';
import { i18n } from 'i18next';
import * as commonAPI from '../api/common';

import Row from '../components/layout/Row';
import Col from '../components/layout/Col';

import FormField from '../components/elements/FormField';
import FormFieldTextarea from '../components/elements/FormFieldTextarea';
import FormFieldMap from '../components/elements/FormFieldMap';
import GeoSuggestField from '../components/elements/GeoSuggestField';
import Button from '../components/ui/Button';

import integer from '../validators/integer';
import { maxLengthAllowsEmpty } from '../validators/maxLength';
import required from '../validators/required';
import email from '../validators/email';

import { type ReduxState } from '../redux/modules';
import FormSearchableSelect from '../components/elements/FormSearchableSelect';
import { themePalette } from '../theme/index';

type Props = {
  handleSubmit: Function,
  change: (field: string, value: any) => void,
  submitting: boolean,
  latitude: number,
  longitude: number,
  centerLatitude: number,
  centerLongitude: number,
  zoom: number,
  isEdit: boolean,
  t: Function,
  i18n: i18n,
};

type State = {
  googleMapsInitialized: Boolean,
};

class BranchForm extends PureComponent<Props> {
  props: Props;

  state: State = {
    googleMapsInitialized: false,
  };

  loadTaxOfficeOptions = debounce((value: string, cb: any) => {
    commonAPI.taxOffices(value).then((offices) => {
      const options = offices.map((office) => ({ value: office.id, label: office.name }));
      cb(options);
    });
  }, 200);

  render() {
    const {
      handleSubmit,
      change,
      submitting,
      latitude,
      longitude,
      centerLatitude,
      centerLongitude,
      zoom,
      isEdit,
      t,
      i18n,
    } = this.props;

    const { googleMapsInitialized } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <div className="u-pad-top-small">
          <Row>
            <Col md={12}>
              <Field
                name="companyName"
                type="text"
                component={FormField}
                label={t('branches.companyName.label')}
                validate={[required(t('branches.companyName.required'))]}
              />
            </Col>
            <Col md={6}>
              <Field
                name="firstName"
                type="text"
                component={FormField}
                label={t('branches.firstName.label')}
                validate={[required(t('branches.firstName.required'))]}
              />
            </Col>
            <Col md={6}>
              <Field
                name="lastName"
                type="text"
                component={FormField}
                label={t('branches.lastName.label')}
                validate={[required(t('branches.lastName.required'))]}
              />
            </Col>
            <Col md={12}>
              <Field
                name="email"
                type="text"
                component={FormField}
                label={t('branches.email.label')}
                validate={[
                  required(t('branches.email.required')),
                  email(t('common.validations.email')),
                ]}
                props={{
                  helpText: isEdit ? undefined : t('branches.email.information'),
                }}
              />
            </Col>
            <Col md={6}>
              <Field
                name="taxOffice"
                component={FormSearchableSelect}
                label={t('branches.taxOffice.label')}
                validate={[]}
                format={(office) => (office ? { value: office.id, label: office.name } : undefined)}
                parse={(option) => (option ? { id: option.value, name: option.label } : undefined)}
                props={{
                  placeholder: t('branches.taxOffice.placeholder'),
                  loadOptions: this.loadTaxOfficeOptions,
                }}
              />
            </Col>
            <Col md={6}>
              <Field
                name="taxOfficeNumber"
                type="text"
                component={FormField}
                label={t('branches.taxOfficeNo.label')}
                validate={[maxLengthAllowsEmpty(255), integer(t('common.validations.integer'))]}
              />
            </Col>
            <Col md={6}>
              <Field
                name="commercialTitle"
                type="text"
                component={FormField}
                label={t('branches.commercialTitle.label')}
                validate={[maxLengthAllowsEmpty(255)]}
              />
            </Col>
            <Col md={6}>
              <Field
                name="website"
                type="text"
                component={FormField}
                label={t('branches.website.label')}
                validate={[maxLengthAllowsEmpty(255)]}
              />
            </Col>
            <Col md={12}>
              <Field
                name="companyAddress"
                component={FormFieldTextarea}
                label={t('branches.companyAddress.label')}
                validate={[maxLengthAllowsEmpty(1000)]}
              />
            </Col>
            {googleMapsInitialized ? (
              <Col md={12}>
                <Field
                  name="locationSearch"
                  label={t('branches.locationSearch.label')}
                  component={GeoSuggestField}
                  props={{
                    onSuggestSelect: (suggest) => {
                      change(
                        'locationPosition',
                        `${suggest.location.lat}, ${suggest.location.lng}`,
                      );
                      change('centerLatitude', suggest.location.lat);
                      change('centerLongitude', suggest.location.lng);
                      change('zoom', 12);
                      change('latitude', suggest.location.lat);
                      change('longitude', suggest.location.lng);
                    },
                    onSuggestNoResults: () => {},
                  }}
                />
              </Col>
            ) : (
              <div className="c-form-group c-form-group--floating" style={{ width: '100%' }}>
                <div style={{ height: '30px' }}></div>
              </div>
            )}

            <Col md={12}>
              <Field
                name="locationPosition"
                label={t('branches.locationPosition.label')}
                component={FormFieldMap}
                props={{
                  centerLatitude,
                  centerLongitude,
                  latitude,
                  longitude,
                  zoom,
                  onMapClick: (event) => {
                    change('locationPosition', `${event.latLng.lat()}, ${event.latLng.lng()}`);
                    change('latitude', event.latLng.lat());
                    change('longitude', event.latLng.lng());
                  },
                  fullWidth: true,
                  language: i18n.language,
                  onIdle: () =>
                    !googleMapsInitialized && this.setState({ googleMapsInitialized: true }),
                }}
                validate={[required(t('branches.locationPosition.required'))]}
              />
            </Col>
          </Row>
        </div>
        <div className="u-pad-top-small u-pad-sides-small u-text-center">
          <Button
            type="submit"
            primary
            disabled={submitting}
            loading={submitting}
            style={{
              backgroundColor: themePalette.primary_color,
              boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
            }}
          >
            {isEdit ? t('branches.editBranch') : t('branches.addBranch')}
          </Button>
        </div>
      </form>
    );
  }
}

const Form = reduxForm({
  form: 'branch',
})(BranchForm);

const selector = formValueSelector('branch');

// $FlowFixMe
export default connect((state: ReduxState) => ({
  latitude: selector(state, 'latitude'),
  longitude: selector(state, 'longitude'),
  centerLatitude: selector(state, 'centerLatitude'),
  centerLongitude: selector(state, 'centerLongitude'),
  zoom: selector(state, 'zoom'),
}))(withTranslation()(Form));
