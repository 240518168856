// @flow

import api from '../utils/api';

import { HRMapper } from '../mappers/user';
import { type HR } from '../types/user';

export type HRData = {
  firstName: string,
  lastName: string,
  email: string,
};

export const list = async (): Promise<Array<HR>> => {
  const response = await api.get('/hr-accounts/list');

  if (!response.ok) {
    throw response.data;
  }

  return HRMapper.fromAPIResponseMultiple(response.data.payload.hr_accounts);
};

export const create = async (data: HRData): Promise<HR> => {
  const response = await api.put('/hr-accounts/create', data);

  if (!response.ok) {
    throw response.data;
  }

  return HRMapper.fromAPIResponse(response.data.payload.hr_account);
};

export const edit = async (id: number, data: HRData): Promise<HR> => {
  const response = await api.post(`/hr-accounts/edit/${id}`, data);

  if (!response.ok) {
    throw response.data;
  }

  return HRMapper.fromAPIResponse(response.data.payload.hr_account);
};

export const remove = async (id: number): Promise<boolean> => {
  const response = await api.delete(`/hr-accounts/delete/${id}`);

  if (!response.ok) {
    throw response.data;
  }

  return true;
};
