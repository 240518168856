// @flow

import React from 'react';

import Container from '../../components/layout/Container';
import Button from '../../components/ui/Button';
import Icon from '../../components/elements/Icon';

import { type AuthenticatedUser } from '../../types/user';
import useTranslator from '../../hooks/useTranslator';
import { themePalette } from '../../theme/index';

type Props = {
  currentUser: AuthenticatedUser,
  onButtonClick: () => void,
};

const Navbar = ({ currentUser, onButtonClick }: Props) => {
  const { t } = useTranslator();

  return (
    <div className="c-navbar c-navbar--white">
      <Container>
        <div className="c-navbar__body">
          <div>
            <h1 className="h2@md-down u-clear-gap">{currentUser.user.companyName}</h1>
          </div>
          <div className="c-navbar__seperator" />
          <div className="u-hidden@sm-down">
            <Button
              primary
              onClick={onButtonClick}
              style={{
                backgroundColor: themePalette.primary_color,
                boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
              }}
            >
              <Icon name="add" className="u-gap-right" />
              <span>{t('layout.navbar.addBranch')}</span>
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
