// @flow
import { Pagination } from '../types/pagination';

export const PaginationMapper = {
  fromAPIResponse(response: Object): Pagination {
    return {
      totalPage: response.total_page,
      currentPage: response.current_page,
      nextPage: !!response.next_page,
    };
  },
};
