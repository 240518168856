// @flow

import React from 'react';
import { Field, reduxForm } from 'redux-form';

import FormField from '../components/elements/FormField';
import Button from '../components/ui/Button';

import required from '../validators/required';
import email from '../validators/email';
import useTranslator from '../hooks/useTranslator';
import { themePalette } from '../theme/index';

type Props = {
  handleSubmit: Function,
  submitting: boolean,
  isEdit: boolean,
};

const HRForm = ({ handleSubmit, submitting, isEdit }: Props) => {
  const { t } = useTranslator();
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="firstName"
          type="text"
          component={FormField}
          label={t('profile.edit.form.firstName.label')}
          validate={[required(t('profile.edit.form.firstName.required'))]}
        />

        <Field
          name="lastName"
          type="text"
          component={FormField}
          label={t('profile.edit.form.lastName.label')}
          validate={[required(t('profile.edit.form.lastName.required'))]}
        />

        <Field
          name="email"
          type="email"
          component={FormField}
          label={t('profile.edit.form.email.label')}
          validate={[
            required(t('profile.edit.form.email.required')),
            email(t('common.validations.email')),
          ]}
        />
        {!isEdit && (
          <span className="c-label">
            {'* ' + t('profile.hrList.hrForm.informations.aboutEMail')}
          </span>
        )}
      </div>
      <div className="u-text-center u-pad-top-small">
        <Button
          type="submit"
          primary
          disabled={submitting}
          loading={submitting}
          style={{
            backgroundColor: themePalette.primary_color,
            color: '#fff',
            boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
          }}
        >
          {isEdit ? t('profile.hrList.subTitles.editUser') : t('profile.hrList.subTitles.addUser')}
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'hr',
})(HRForm);
