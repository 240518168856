// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import Row from '../../components/layout/Row';
import Col from '../../components/layout/Col';

import Box from '../../components/elements/Box';
import LinkButton from '../../components/ui/LinkButton';
import CircleImage from '../../components/elements/CircleImage';
import Icon from '../../components/elements/Icon';

import { type BranchesInboxItem } from '../../types/chat';
import useTranslator from '../../hooks/useTranslator';

type Props = {
  item: BranchesInboxItem,
};

const ListItem = ({ item }: Props) => {
  const { t } = useTranslator();
  return (
    <Box className="u-pad-sides-small u-pad-ends-small u-gap-bottom">
      <Row className="u-flex-align-middle">
        <Col lg={5} md={12} className="u-flex u-flex-align-middle u-gap-bottom@md-down">
          <CircleImage small src={item.imageUrl} />

          <div className="u-flex-grow-full u-gap-left">
            <Link to={`/inbox/${item.id}`} className="u-block u-font-size-md u-font-weight-medium">
              {item.companyName}
            </Link>
          </div>
        </Col>

        <Col lg={2} xs={6} className="u-text-center u-gap-bottom@md-down">
          <span className="u-hidden@lg-up u-block u-font-size-sm@sm-down">
            {t('branches.inbox.totalMessages')}
          </span>
          <Link to={`/inbox/${item.id}`} className="u-font-size-xl@md-up">
            {item.nbTotal}
          </Link>
        </Col>

        <Col lg={2} xs={6} className="u-text-center u-gap-bottom@md-down">
          <span className="u-hidden@lg-up u-block u-font-size-sm@sm-down">
            {t('branches.inbox.totalUnreadMessages')}
          </span>
          <Link to={`/inbox/${item.id}`} className="u-font-size-xl@md-up">
            {item.nbInbox}
          </Link>
        </Col>

        <Col lg={3} xs={12} className="u-flex">
          <LinkButton to={`/inbox/${item.id}`} ghost small className="u-gap-right u-flex-grow-full">
            <Icon name="edit" className="u-gap-right-xsmall" /> {t('common.glossary.detail')}
          </LinkButton>
        </Col>
      </Row>
    </Box>
  );
};

export default ListItem;
