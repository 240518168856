// @flow

import React, { PureComponent } from 'react';
import { type Notification as NotificationType } from '../../../types/notification';
import SimpleNotification from './SimpleNotification';
import MessageNotification from './MessageNotification';

type Props = {
  notification: NotificationType,
  onRemove: () => void,
};

class Notification extends PureComponent<Props> {
  props: Props;

  dismissTimer: any;

  componentDidMount() {
    this.scheduleDismissTimer();
  }

  componentWillUnmout() {
    this.clearDismissTimer();
  }

  scheduleDismissTimer() {
    if (this.props.notification.dismissAfter) {
      this.dismissTimer = setTimeout(this.onClose, this.props.notification.dismissAfter);
    }
  }

  clearDismissTimer() {
    if (this.dismissTimer) {
      clearTimeout(this.dismissTimer);
    }
  }

  onClose = () => {
    this.props.onRemove();

    if (typeof this.props.notification.onClose === 'function') {
      this.props.notification.onClose();
    }
  };

  onClick = () => {
    this.props.onRemove();

    if (typeof this.props.notification.onClick === 'function') {
      this.props.notification.onClick();
    }
  };

  render() {
    const { notification } = this.props;

    switch (notification.type) {
      case 'message':
        return (
          <MessageNotification {...notification} onClose={this.onClose} onClick={this.onClick} />
        );
      case 'simple':
        return (
          <SimpleNotification {...notification} onClose={this.onClose} onClick={this.onClick} />
        );
      case 'custom':
        return (
          <notification.element
            payload={notification.payload}
            onClose={this.onClose}
            onClick={this.onClick}
          />
        );
      default:
        throw new Error(`Unexpeted notification type: ${notification.type}`);
    }
  }
}

export default Notification;
