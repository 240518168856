// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { type AuthenticatedUser } from '../../types/user';

import { getPackagesList } from '../../api/payment';

import List from './List';
import Loading from './Loading';
import Header from '../../components/shared/Header';
import Navbar from './Navbar';

type Props = {
  currentUser: AuthenticatedUser,
  history: Object,
};

const getPackages = async (setPackages, setLoading) => {
  try {
    setLoading(true);
    const result = await getPackagesList();

    const packagesWithOrderedFeatures = result.map(pkg => ({
      ...pkg,
      features: pkg.features.sort((a, b) => a.order - b.order),
    }));

    setPackages(packagesWithOrderedFeatures);
  } catch (error) {
    setPackages([]);
  }

  setLoading(false);
};

const Packages = ({ currentUser, history }: Props) => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPackages(setPackages, setLoading);
  }, []);

  return (
    <div>
      <Header currentUser={currentUser} />
      <Navbar currentUser={currentUser} />
      {loading ? (
        <Loading />
      ) : (
        <List packages={packages} isPaymentEnabled history={history} currentUser={currentUser} />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(Packages);
