// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, type Match } from 'react-router-dom';

import { type ReduxState } from '../../redux/modules';
import { type CurrentUserState } from '../../redux/modules/currentUser';

import Inbox from './Inbox';
import Navbar from './Navbar';
import { UserTypeMap } from '../../enums/userType';

import Header from '../../components/shared/Header';

type Props = {
  match: Match,
  currentUser: CurrentUserState,
};

class InboxRoot extends Component<Props> {
  props: Props;

  renderForBranch() {
    const { match } = this.props;

    return (
      <Route path={`${match.url}/:threadId`}>
        {({ match: threadMatch, history }) => (
          <Inbox
            match={match}
            history={history}
            branchId={this.props.currentUser.user.id}
            activeThreadId={threadMatch && +threadMatch.params.threadId}
            isBranch
          />
        )}
      </Route>
    );
  }

  renderForRoot() {
    return (
      <Route
        path={`${this.props.match.url}/:branchId`}
        render={({ match }) => (
          <Route path={`${match.url}/:threadId`}>
            {({ match: threadMatch, history }) => (
              <Inbox
                match={match}
                history={history}
                branchId={+match.params.branchId}
                activeThreadId={threadMatch && +threadMatch.params.threadId}
              />
            )}
          </Route>
        )}
      />
    );
  }

  render() {
    const { currentUser } = this.props;

    const isBranch =
      currentUser.user.userType === UserTypeMap.BRANCH ||
      currentUser.user.userType === UserTypeMap.COMPANY;

    return (
      <div>
        <Header currentUser={currentUser} />

        <Navbar />

        {isBranch ? this.renderForBranch() : this.renderForRoot()}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  currentUser: state.currentUser,
});

// $FlowFixMe
export default withRouter(connect(mapStateToProps)(InboxRoot));
