// @flow

import React from 'react';
import CircleImage from '../../elements/CircleImage';
import { type Message } from '../../../types/notification';

type Props = Message;

const MessageNotification = (props: Props): any => (
  <div
    role="presentation"
    onClick={props.onClick}
    className="c-notification__message u-cursor-pointer"
  >
    <button
      type="button"
      className="c-notification__close-btn"
      onClick={event => {
        event.stopPropagation();

        if (props.onClose) {
          props.onClose();
        }
      }}
    />

    <div className="c-notification__message-container">
      <div className="u-flex u-flex-align-middle">
        <CircleImage small src={props.avatar} />

        <div className="u-gap-left u-flex-grow-full">
          <h5 className="u-clear-gap-top u-gap-bottom-xsmall u-font-weight-bold">{props.title}</h5>

          <span className="u-color-gull-gray">{props.body}</span>
        </div>
      </div>
    </div>
  </div>
);

export default MessageNotification;
