// @flow

import React from 'react';
import cx from 'classnames';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { type Message } from '../../../types/chat';
// eslint-disable-next-line import/named
import { AppointmentStatus } from '../../../enums/appointmentStatus';
// eslint-disable-next-line import/named
import { getDate, getHour } from '../../../utils/appointment';

type Props = {
  alignToRight?: boolean,
  message: Message,
};

const AppointmentBubble = ({ message, alignToRight }: Props) => {
  const { t } = useTranslation();
  if (message.type !== 'appointment') {
    return null;
  }

  let text;

  switch (message.messagePayload.status) {
    case AppointmentStatus.ACCEPTED:
    case AppointmentStatus.COMPLETED:
      text = t('inbox.statuses.approved', {
        dateStartAt: getDate(message.messagePayload.startAt),
        hourStartAt: getHour(message.messagePayload.startAt),
        hourEndAt: getHour(message.messagePayload.endAt),
      });
      break;
    case AppointmentStatus.REJECTED:
      text = t('inbox.statuses.rejected', {
        dateStartAt: getDate(message.messagePayload.startAt),
        hourStartAt: getHour(message.messagePayload.startAt),
        hourEndAt: getHour(message.messagePayload.endAt),
      });
      break;
    case AppointmentStatus.CANCELLED:
      text = t('inbox.statuses.canceled', {
        dateStartAt: getDate(message.messagePayload.startAt),
        hourStartAt: getHour(message.messagePayload.startAt),
        hourEndAt: getHour(message.messagePayload.endAt),
      });
      break;
    case AppointmentStatus.EXPIRED:
      text = t('inbox.statuses.expired', {
        dateStartAt: getDate(message.messagePayload.startAt),
        hourStartAt: getHour(message.messagePayload.startAt),
        hourEndAt: getHour(message.messagePayload.endAt),
      });
      break;
    case AppointmentStatus.WAITING:
      text = t('inbox.statuses.waiting', {
        dateStartAt: getDate(message.messagePayload.startAt),
        hourStartAt: getHour(message.messagePayload.startAt),
        hourEndAt: getHour(message.messagePayload.endAt),
      });
      break;
    default:
      text = t('inbox.statuses.main');
  }

  return (
    <span
      className={cx('c-chat__bubble', {
        'c-chat__bubble--left': alignToRight,
      })}
    >
      {text}
      <time className="c-chat__time">{moment(message.date).fromNow()}</time>
    </span>
  );
};

export default AppointmentBubble;
