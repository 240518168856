// @flow
export default {
  resetPassword: {
    errors: {
      errorOccured: 'Bir hata meydana geldi.',
    },
    alerts: {
      invalidToken: 'Gecersiz Token',
    },
    form: {
      resetPassword: 'Şifremi Sıfırla',
      passwordIsNotEqual: '2 sifre birbirinin aynısı olmalıdır.',
      invalidToken: 'Gecersiz Token',
      success: 'Sifreniz başarılı bir şekilde degiştirilmistir.',
    },
  },
  forgetPassword: {
    title: 'Şifremi Unuttum',
    body: 'E-posta adresini yazın, size şifre sıfırlama linki gönderilecek.',
    submit: 'Gonder',
    successSubmitText: 'Sifre sıfırlama linki e-posta adresinize gönderildi.',
    failSubmitText: 'Gecersiz veya yanlıs email adresi girdiniz.',
  },
};
