// @flow

import { create } from 'apisauce';

const api = create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'X-App-Name': '24SAATTEIS',
    'X-App-Request-Source': 'company_dashboard',
  },
});

export function setAuthorizationHeader(token: string) {
  api.setHeader('Authorization', `Bearer ${token}`);
}

export function clearAuthorizationHeader() {
  delete api.headers.Authorization;
}

export default api;
