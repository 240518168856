// @flow

import React from 'react';
import { Field, reduxForm } from 'redux-form';

import FormField from '../components/elements/FormField';
import Button from '../components/ui/Button';

import required from '../validators/required';
import minLength from '../validators/minLength';
import maxLength from '../validators/maxLength';
import email from '../validators/email';

import Col from '../components/layout/Col';

import Row from '../components/layout/Row';
import useTranslator from '../hooks/useTranslator';
import { themePalette } from '../theme/index';

type Props = {
  handleSubmit: Function,
  submitting: boolean,
  invalid: boolean,
};

const LoginForm = ({ handleSubmit, submitting, invalid }: Props) => {
  const { t } = useTranslator();

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="u-font-weight-bold u-text-center u-gap-top-large">
        {t('auth.loginForm.title')}
      </h1>
      <div className="u-gap-bottom-large">
        <Field
          name="email"
          type="email"
          component={FormField}
          label={t('auth.loginForm.email.label')}
          validate={[
            required(t('auth.loginForm.email.label')),
            email(t('common.validations.email')),
          ]}
        />
        <Field
          name="password"
          type="password"
          component={FormField}
          label={t('auth.loginForm.password.label')}
          validate={[
            required(t('auth.loginForm.password.required')),
            minLength(6, t('auth.loginForm.password.minLength')),
            maxLength(16, t('auth.loginForm.password.maxLength')),
          ]}
        />
        <div className="u-text-center">
          <div className="c-navbar__body">
            <Row className="u-flex-grow-full u-flex-align-middle">
              <Col md={6}>
                <div className="u-text-left u-font-size-sm">
                  <a href="/register">{t('auth.loginForm.register')}</a>
                </div>
              </Col>
              <Col md={6}>
                <div className="u-text-right u-font-size-sm">
                  <a href="/forget-password">{t('auth.loginForm.forgotPassword')}</a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="u-text-center">
        <div className="c-navbar__body">
          <Row className="u-flex-grow-full u-flex-align-middle">
            <Col md={12}>
              <Button
                id="loginButton"
                type="submit"
                primary
                disabled={invalid || submitting}
                loading={submitting}
                style={{
                  backgroundColor: themePalette.primary_color,
                  boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                }}
              >
                {t('auth.loginForm.login')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'login',
})(LoginForm);
