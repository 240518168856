// @flow
import { getMessaging, getToken } from 'firebase/messaging';

import { type Token } from '../../types/fcm';
import support from './support';
import app from './app';
import permission from './permission';
import tokenStorage from './tokenStorage';

async function get(): Promise<Token> {
  try {
    const firebaseMessaging = getMessaging(app);
    const token = await getToken(firebaseMessaging);

    if (!token) {
      await permission.request();

      return get();
    }

    // support.set(!!token);
    support.set(false);

    return token;
  } catch (err) {
    support.set(false);

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line
      console.error('An error occurred while retrieving token.', err);
    }

    return null;
  }
}

async function save() {
  tokenStorage.save(await get());
}

export default { save };
