// @flow

import React from 'react';
import cx from 'classnames';

import { type Message } from '../../../types/chat';
import StaticMap from '../../elements/StaticMap';

type Props = {
  alignToRight?: boolean,
  message: Message,
};

const LocationBubble = ({ message, alignToRight }: Props) => {
  if (message.type !== 'location') {
    return null;
  }

  return (
    <span
      className={cx('c-chat__bubble c-chat__bubble--location', {
        'c-chat__bubble--left': alignToRight,
      })}
    >
      <StaticMap
        alt="Lokasyon"
        latitude={message.body.latitude}
        longitude={message.body.longitude}
        markers={[
          {
            latitude: message.body.latitude,
            longitude: message.body.longitude,
          },
        ]}
        width={500}
        height={250}
        zoom={14}
      />
    </span>
  );
};

export default LocationBubble;
