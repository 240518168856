import { ContactOptionList } from '../../../../enums/contactOption';
import { ExperienceDurationMap } from '../../../../enums/experienceDuration';
import { JobStatusMap } from '../../../../enums/jobStatus';

// @flow
export default {
  constants: {
    defaultSelect: 'Seleccionar',
    benefitMap: {
      fare: 'Propina',
      free_meal: 'Alimentos',
      health_insurance: 'Seguro',
    },
    workTimeMap: {
      full_time: 'Tiempo completo',
      part_time: 'Medio tiempo',
      seasonal: 'Temporal',
    },
    militaryStatusMap: {
      '1': 'Sin cartilla',
      '2': 'Luego',
      '3': 'Con cartilla',
    },
    englishLevelMap: {
      '1': 'No',
      '2': 'Básico',
      '3': 'Intermedio',
      '4': 'Avanzado',
    },
    gender: [
      {
        id: 'all',
        name: 'Todos',
      },
      {
        id: 'male',
        name: 'Hombre',
      },
      {
        id: 'female',
        name: 'Mujer',
      },
    ],
    genderMap: {
      male: 'Hombre',
      female: 'Mujer',
    },
    educationMap: {
      elementary_school: 'Primaria',
      middle_school: 'Secundaria',
      high_school: 'Bachillerato',
      university: 'Licenciatura',
    },
    disabledStatusMap: {
      disabled: 'Con capacidades diferentes',
      not_disabled: 'Sin capacidades diferentes',
    },
    universityStatusMap: {
      graduated: 'Titulado',
      student: 'Estudiente',
    },
    jobStatusList: [
      { key: JobStatusMap.ACTIVE, value: 'Activo' },
      { key: JobStatusMap.CLOSED, value: 'Inactivo' },
    ],
    benefit: [
      { id: true, name: 'Sí' },
      { id: false, name: 'No' },
    ],
    contact: [
      {
        id: ContactOptionList.PHONE,
        name: 'Sí, por teléfono',
      },
      {
        id: ContactOptionList.TEXT,
        name: 'Si, por mensaje',
      },
      {
        id: ContactOptionList.NONE,
        name: 'No',
      },
    ],
    autoShortlistEnabled: [
      {
        id: true,
        name: 'Activo',
      },
      {
        id: false,
        name: 'Inactivo',
      },
    ],
    experienceDurationList: [
      { key: ExperienceDurationMap.ALL, value: 'Sin experiencia' },
      { key: ExperienceDurationMap.ONE_MONTH, value: '1 mes' },
      { key: ExperienceDurationMap.TWO_MONTHS, value: '2 meses' },
      { key: ExperienceDurationMap.THREE_MONTHS, value: '3 meses' },
      { key: ExperienceDurationMap.FOUR_MONTHS, value: '4 meses' },
      { key: ExperienceDurationMap.FIVE_MONTHS, value: '5 meses' },
      { key: ExperienceDurationMap.SIX_MONTHS, value: '6 meses' },
      { key: ExperienceDurationMap.SEVEN_MONTHS, value: '7 meses' },
      { key: ExperienceDurationMap.EIGHT_MONTHS, value: '8 meses' },
      { key: ExperienceDurationMap.NINE_MONTHS, value: '9 meses' },
      { key: ExperienceDurationMap.TEN_MONTHS, value: '10 meses' },
      { key: ExperienceDurationMap.ELEVEN_MONTHS, value: '11 meses' },
      { key: ExperienceDurationMap.ONE_YEAR, value: '1 año' },
      { key: ExperienceDurationMap.TWO_YEARS, value: '2 años' },
      { key: ExperienceDurationMap.THREE_YEARS, value: '3 años' },
      { key: ExperienceDurationMap.FOUR_YEARS, value: '4 años' },
      { key: ExperienceDurationMap.FIVE_YEARS, value: '5 años' },
      { key: ExperienceDurationMap.SIX_YEARS, value: '6 años' },
      { key: ExperienceDurationMap.SEVEN_YEARS, value: '7 años' },
      { key: ExperienceDurationMap.OVER_SEVEN_YEARS, value: 'Más de 7 años' },
    ],
    updateFeedback: 'Su comentario ha sido actualizada.',
  },
};
