// @flow

const DEFAULT_MESSAGE = 'Bu alan için fazla karakter girdiniz.';

export const maxLengthAllowsEmpty = (length: number, message: string = DEFAULT_MESSAGE) => (
  value?: string = '',
) => (value && value.length > length ? message : undefined);

const maxLength = (length: number, message: string = DEFAULT_MESSAGE) => (value?: string = '') =>
  value && value.length <= length ? undefined : message;

export default maxLength;
