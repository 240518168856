// @flow
import React, { type Node } from 'react';

type InfoboxType = 'info' | 'success' | 'warning' | 'alert';

type Props = {
  children?: Node,
  className?: string,
  type?: InfoboxType,
};

const defaultClassName = 'c-infobox';

function typeClassName(type: InfoboxType): string {
  switch (type) {
    case 'info':
      return 'c-infobox--info';
    case 'success':
      return 'c-infobox--success';
    case 'warning':
      return 'c-infobox--warning';
    case 'alert':
      return 'c-infobox--alert';
    default:
      return '';
  }
}

const Infobox = (props: Props) => {
  const classNames = [defaultClassName];

  if (props.className) {
    classNames.push(props.className);
  }

  if (props.type) {
    classNames.push(typeClassName(props.type));
  }

  return <div className={classNames.join(' ')}>{props.children}</div>;
};

export default Infobox;
