// @flow

import { branchesInbox as branchesInboxRequest } from '../../api/chat';
import { Status, type StatusType } from '../../utils/apiState';

import { type ReduxDispatch } from '../../types/redux';
import { type BranchesInboxItem } from '../../types/chat';

// Actions
const REQUEST = 'branchesInbox/REQUEST';
const SUCCESS = 'branchesInbox/SUCCESS';
const FAILED = 'branchesInbox/FAILED';

// Action Creator Types
type RequestAction = {
  type: typeof REQUEST,
};

type SuccessAction = {
  type: typeof SUCCESS,
  branchesInbox: Array<BranchesInboxItem>,
};

type FailedAction = {
  type: typeof FAILED,
  error: any,
};

export type BranchesInboxActions = RequestAction | SuccessAction | FailedAction;

// Action Creators
export const request = (): RequestAction => ({
  type: REQUEST,
});

export const success = (branchesInbox: Array<BranchesInboxItem>): SuccessAction => ({
  type: SUCCESS,
  branchesInbox,
});

export const failed = (error: any): FailedAction => ({
  type: FAILED,
  error,
});

// Thunks
export const loadThunk = (): Function => async (dispatch: ReduxDispatch): Promise<*> => {
  dispatch(request());

  try {
    const branchesInbox = await branchesInboxRequest();

    dispatch(success(branchesInbox));
  } catch (error) {
    dispatch(failed(error));
  }
};

// Reducer
export type BranchesInboxState = {
  +status: StatusType,
  +data: Array<BranchesInboxItem>,
  +error: any,
};

const initialState = {
  status: Status.INIT,
  data: [],
  error: null,
};

export default function reducer(
  state: BranchesInboxState = initialState,
  action: BranchesInboxActions,
): BranchesInboxState {
  switch (action.type) {
    case REQUEST:
      return { ...state, status: Status.LOADING };
    case SUCCESS:
      return { ...state, status: Status.LOADED, data: action.branchesInbox };
    case FAILED:
      return { ...state, status: Status.FAILED, data: [], error: action.error };
    default:
      return state;
  }
}
