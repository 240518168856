// @flow

export const ContactOptionMap = {
  via_phone: 'Telefon',
  via_message: 'Mesaj',
  no_contact: 'Hayır',
};

export const ContactOptionList = {
  PHONE: 'via_phone',
  TEXT: 'via_message',
  NONE: 'no_contact',
};

export const ContactOptionMapDefault = ContactOptionMap.via_phone;
