// @flow

import React from 'react';

import BaseLoading from '../../components/elements/Loading';

const Loading = () => (
  <div className="u-gap-top u-gap-bottom">
    <BaseLoading large />
  </div>
);

export default Loading;
