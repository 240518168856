// @flow
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Bubble from './Bubble';
import Button from '../../ui/Button';
import Box from '../../elements/Box';
import {
  type Thread,
  type Message,
  type TextMessagePayload,
  type LocationMessagePayload,
} from '../../../types/chat';
import { themePalette } from '../../../theme/index';

type Props = {
  thread: Thread,
  messages: Message[],
  scrollToBottomRef: React.Ref,
  onNewMessage: (TextMessagePayload | LocationMessagePayload) => void,
  t: Function,
};

class Conversation extends PureComponent<Props> {
  props: Props;

  containerRef: HTMLDivElement | null;

  inputRef: HTMLInputElement | null;

  constructor(props) {
    super(props);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    if (this.props.scrollToBottomRef) {
      this.props.scrollToBottomRef.current = this.scrollToBottom;
    }
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.messages.length !== this.props.messages.length) {
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    if (this.containerRef) {
      this.containerRef.scrollTop = this.containerRef.scrollHeight;
    }
  }

  sendTextMessage(text: string) {
    this.props.onNewMessage({
      body: text,
      type: 'text',
    });

    setTimeout(() => {
      this.scrollToBottom();
    });
  }

  onSubmit = (event: any) => {
    event.preventDefault();

    if (!this.inputRef) {
      return;
    }

    const value = this.inputRef.value.trim();
    if (!value) {
      (this.inputRef: any).focus();
      return;
    }

    (this.inputRef: any).value = '';
    this.sendTextMessage(value);
  };

  render() {
    const { thread, messages, t } = this.props;

    return (
      <Box>
        <div
          ref={ref => {
            this.containerRef = ref;
          }}
          className="c-chat"
        >
          {messages.map(message => (
            <Bubble
              key={message.id}
              message={message}
              alignToRight={thread.user.id !== message.sender.id}
            />
          ))}
        </div>

        <form
          onSubmit={this.onSubmit}
          className="c-chat__form u-pad-ends-small u-pad-sides-small u-flex u-flex-align-bottom"
        >
          <div className="c-form-group u-flex-grow-full">
            <input
              ref={ref => {
                this.inputRef = ref;
              }}
              type="text"
              className="c-form-control"
              placeholder={`${t('common.glossary.writeMessage')}...`}
            />
          </div>

          <Button
            type="submit"
            small
            primary
            className="u-gap-left"
            style={{
              backgroundColor: themePalette.primary_color,
              boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
            }}
          >
            {t('common.glossary.send')}
          </Button>
        </form>
      </Box>
    );
  }
}

export default withTranslation()(Conversation);
