// @flow

import React from 'react';

type Props = {
  /** input type */
  type: string,

  /** extra className */
  className?: string,

  /** if input is disabled */
  disabled?: boolean,

  /** input value */
  value: string,

  /** onChange event handler */
  onChange: (value: string) => void,
};

/**
 * Controlled input component
 */
const Input = ({
  type,
  className = '',
  disabled = false,
  value,
  onChange,
  ...otherProps
}: Props) => {
  const classNames = ['c-form-control'];

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    <input
      type={type}
      className={classNames.join(' ')}
      disabled={disabled}
      value={value}
      onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      }}
      {...otherProps}
    />
  );
};

export default Input;
