// @flow
import React from 'react';
import { Field, formValueSelector, reduxForm, initialize } from 'redux-form';

import { withTranslation } from 'react-i18next';
import required from '../../validators/required';
import emailValidator from '../../validators/email';

import FormField from '../../components/elements/FormField';
import Modal from '../../components/elements/Modal';
import Button from '../../components/ui/Button';
import { themePalette } from '../../theme/index';
import FormFieldCheck from '../../components/elements/FormFieldCheck';
import { connect } from 'react-redux';
import FormFieldSelect from '../../components/elements/FormFieldSelect';
import moment from 'moment';

const TODAY = moment()
const ONE_MONTH_AGO = moment().subtract(1, 'months')

type Props = {
    isOpen: boolean,
    onClose: () => void,
    handleSubmit: Function,
    submitting: boolean,
    t: Function,
    initialize: Function
};

class ExcelDownloadModal extends React.PureComponent<Props> {
    props: Props;

    componentDidMount() {
        this.props.initialize({
            day: `${ONE_MONTH_AGO.date()}`,
            month: `${ONE_MONTH_AGO.month() + 1}`,
            year: `${ONE_MONTH_AGO.year()}`
        })
    }

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        const { isOpen, submitting, handleSubmit, t, date, onlyShortlisted, day, month, year } = this.props;

        const DATE = moment(`${year || TODAY.year()}-${month || (TODAY.month() + 1)}-${day || TODAY.date()}`)


        return (
            <Modal contentLabel="excelDonwload" isOpen={isOpen} onModalCloseRequested={this.handleClose}>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="u-gap-bottom">
                            <h1 className="u-text-center">{t('jobs.excelDownloadModal.title')}</h1>

                            <Field
                                name="date"
                                component={FormFieldCheck}
                                props={{
                                    options: [{
                                        value: "date",
                                        text: t('jobs.excelDownloadForm.date.label')
                                    }],
                                }}
                            />
                            <div style={{ display: "flex", }}>

                                <Field
                                    name="day"
                                    disabled={date ? !date.length : true}
                                    component={FormFieldSelect}
                                    label={t('jobs.excelDownloadForm.day.label')}
                                    validate={date && date.length ? [required(t('jobs.excelDownloadForm.day.required'))] : undefined}
                                    props={{
                                        options: Array.from({ length: DATE.daysInMonth() }, (v, i) => ({ id: i + 1, name: i + 1 })),
                                    }}
                                />
                                <div style={{ width: "16px" }} />
                                <Field
                                    name="month"
                                    disabled={date ? !date.length : true}
                                    component={FormFieldSelect}
                                    label={t('jobs.excelDownloadForm.month.label')}
                                    validate={date && date.length ? [required(t('jobs.excelDownloadForm.month.required'))] : undefined}
                                    props={{
                                        options: moment.months().map((v, i) => ({ id: i + 1, name: v }))
                                    }}
                                />
                                <div style={{ width: "16px" }} />
                                <Field
                                    name="year"
                                    disabled={date ? !date.length : true}
                                    component={FormFieldSelect}
                                    label={t('jobs.excelDownloadForm.year.label')}
                                    validate={date && date.length ? [required(t('jobs.excelDownloadForm.year.required'))] : undefined}
                                    props={{
                                        options: Array.from({ length: TODAY.diff("2010-01-01", "years") }, (v, i) => ({ id: 2010 + i + 1, name: 2010 + i + 1 })),
                                    }}
                                />
                            </div>
                            <Field
                                name="onlyShortlisted"
                                component={FormFieldCheck}
                                props={{
                                    options: [{
                                        value: "onlyShortlisted",
                                        text: t('jobs.excelDownloadForm.onlyShortlisted.label')
                                    }],

                                }}
                            />
                        </div>
                        <div className="u-text-center">
                            <Button
                                type="submit"
                                primary
                                loading={submitting}
                                style={{
                                    backgroundColor: themePalette.primary_color,
                                    boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                                }}
                            >
                                {t('jobs.excelDownloadForm.download')}
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }
}


const ExcelDownloadForm = reduxForm({
    form: 'excelDonwload',
})(ExcelDownloadModal);

// export default reduxForm({ form: 'excelDonwload' })(withTranslation()(ExcelDownloadModal));


const selector = formValueSelector('excelDonwload');

export default connect((state: ReduxState) => ({
    date: selector(state, 'date'),
    day: selector(state, 'day'),
    month: selector(state, 'month'),
    year: selector(state, 'year'),
    onlyShortlisted: selector(state, 'onlyShortlisted'),
    initialize
}))(withTranslation()(ExcelDownloadForm));