// @flow

import support from './support';
import token from './token';
import messageReceiver from './messageReceiver';
import { onNewMessage } from './handler/newMessageReceived';

async function init() {
  // saves current fcm token and listens further tokens.
  try {
    await token.save();
    messageReceiver.init();
  } catch (error) {
    console.log('error: ', error);
  }
}

export default {
  init,
  onNewMessage,
  onSupportStatusChange: support.onChange,
};
