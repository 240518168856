// @flow

import React, { PureComponent } from 'react';
import AsyncSelect from 'react-select/async';

import { withTranslation } from 'react-i18next';
import Label from '../../ui/Label';

import clearedStyles from './clearedStyles';
import Icon from '../Icon';

type Meta = {
  touched: boolean,
  error: string,
};

type Props<Options> = {
  input: any,
  label: string,
  meta: Meta,
  placeholder?: string,
  noOptionsMessage?: string,
  defaultOptions?: Options,
  loadOptions?: (
    inputValue: string,
  ) => Promise<Options> | ((inputValue: string, cb: (options: Options) => void) => void),
  t: Function,
};

class FormSearchableSelect<O> extends PureComponent<Props<O>> {
  DropDownIcon = () => <Icon name="angle-down" className="c-react-select__dropdown-indicator" />;

  noOptionsMessageDefault = ({ inputValue }: any) =>
    inputValue
      ? this.props.t('common.glossary.noResult')
      : this.props.t('common.glossary.searchInChoices');

  render() {
    const {
      input,
      meta,
      label,
      placeholder,
      loadOptions,
      noOptionsMessage,
      t,
      defaultOptions,
    } = this.props;

    return (
      <div className={`c-form-group u-gap-bottom ${meta.touched && meta.error ? 'has-error' : ''}`}>
        <Label htmlFor={input.name}>{label}</Label>
        <div>
          <AsyncSelect
            className="c-react-select"
            classNamePrefix="c-react-select"
            styles={clearedStyles}
            loadOptions={loadOptions}
            placeholder={placeholder || t('constants.defaultSelect')}
            noOptionsMessage={noOptionsMessage || this.noOptionsMessageDefault}
            loadingMessage={() => `${t('common.glossary.loading')}...`}
            components={{
              DropdownIndicator: this.DropDownIcon,
            }}
            defaultOptions={defaultOptions}
            onChange={input.onChange}
            value={input.value}
            onBlur={() => input.onBlur()}
            onFocus={input.onFocus}
          />
        </div>
        {meta.touched && meta.error && <span className="c-form-group__alert">{meta.error}</span>}
      </div>
    );
  }
}

export default withTranslation()(FormSearchableSelect);
