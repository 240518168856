// @flow
export default {
  layout: {
    header: {
      title: 'Empleo 24',
      jobs: 'Chambas',
      branches: 'Sucursales',
      myProfile: 'Mi perfil',
      candidatePool: 'Candidatos cerca',
      messages: 'Mensajes',
      logout: 'Cerrar sesion',
      applications: 'Solicitudes',
    },
    navbar: {
      title: 'Todas las chambas',
      addJob: 'Crear chamba',
      update: 'Editar',
      excelReport: 'Exportar solicitudes a Excel',
      addBranch: 'Agregar sucursales',
    },
  },
};
