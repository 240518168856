// @flow

export const JobCloseReasonMap = {
  FOUND_VIA_APP: 'found_via_app',
  FOUND_VIA_OTHER: 'found_via_other',
  NOT_NEED_ANYMORE: 'not_need_anymore',
  OTHER: 'other',
};

export const JobCloseReasonList = [
  {
    key: JobCloseReasonMap.FOUND_VIA_APP,
    value: 'Aradığım çalışanı 24 Saatte İşten buldum',
  },
  {
    key: JobCloseReasonMap.FOUND_VIA_OTHER,
    value: 'Aradığım çalışanı başka bir kanalla buldum',
  },
  {
    key: JobCloseReasonMap.NOT_NEED_ANYMORE,
    value: 'Çalışana artık ihtiyacım yok',
  },
  { key: JobCloseReasonMap.OTHER, value: 'Diğer' },
];

export const JobCloseReasonDefault = JobCloseReasonMap.OTHER;
