// @flow

import React, { PureComponent } from 'react';
import cx from 'classnames';

import { withTranslation } from 'react-i18next';
import Icon from '../Icon';
import DropdownItem from '../DropdownItem';

type Item = {
  value: string,
  title: string,
  activeJobCount: number,
};

type Props = {
  value: string,
  options: Array<Item>,
  onChange: string => void,
  t: Function,
  className?: string,
};

type State = {
  isOpened: boolean,
};

class Dropdown extends PureComponent<Props, State> {
  props: Props;

  state: State;

  toggleDropdown: () => void;

  handleOptionChange: string => void;

  handleClickOutside: Event => void;

  ref: any;

  constructor(props: Props) {
    super(props);

    this.state = { isOpened: false };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  toggleDropdown(event: Event) {
    event.preventDefault();
    this.setState(state => ({ isOpened: !state.isOpened }));
  }

  handleOptionChange(value: string) {
    this.setState(state => ({ isOpened: !state.isOpened }));
    this.props.onChange(value);
  }

  handleClickOutside(event: Event) {
    if (!this.ref || !this.ref.contains(event.target)) {
      this.setState({ isOpened: false });
    }
  }

  render() {
    const { options, value, t, className } = this.props;
    const { isOpened } = this.state;

    const active = options.find(option => option.value === value);

    return (
      <div
        className={cx('c-dropdown', { 'is-active': isOpened })}
        ref={ref => {
          this.ref = ref;
        }}
      >
        <a href="" className="c-dropdown__toggle h1@lg-up h2sm-down" onClick={this.toggleDropdown}>
          <span>{active ? active.title : t('common.glossary.select')}</span>
          <Icon name="angle-down" className="u-gap-left u-font-size-xs" />
        </a>
        <ul className={`c-dropdown__menu ${className}`}>
          {options.map(option => (
            <DropdownItem
              key={option.value}
              value={option.value}
              title={option.title}
              onSelect={this.handleOptionChange}
              className={className}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default withTranslation()(Dropdown);
