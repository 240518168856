// @flow

import React from 'react';

import { useTranslation } from 'react-i18next';
import Container from '../components/layout/Container';
import Row from '../components/layout/Row';
import Col from '../components/layout/Col';
import Box from '../components/elements/Box';
import Logo from '../components/shared/Logo';
import LinkButton from '../components/ui/LinkButton';

const UnexpectedError = () => {
  const { t } = useTranslation();
  return (
    <section className="u-pad-ends-large">
      <Container>
        <Row>
          <Col lg={6} className="col--offset-lg-3">
            <Box className="u-pad-ends-medium@md-up u-pad-ends@sm-down u-pad-sides-medium@md-up u-pad-sides@sm-down u-text-center">
              <Logo className="c-box__center-logo" alt={t('layout.header.title')} />
              <h1 className="u-pad-top u-pad-bottom-xsmall">
                {t('errors.errorBoundary.notFound')}
              </h1>
              <LinkButton to="/" primary>
                {t('errors.errorBoundary.backToHome')}
              </LinkButton>
            </Box>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UnexpectedError;
