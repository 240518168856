// @flow

import React from 'react';

import logoPrimary from './logo-primary-new.svg';

type Props = {
  /** extra className */
  className?: string,

  /** alt text */
  alt: string,

  /** image by header choice */
  isHeader?: boolean,

  /** image custom style by theme of current app */
  style: {},
};

/**
 * Logo Element
 */
const Logo = ({ className = '', alt, isHeader, style, ...otherProps }: Props) => {
  const classNames = [];

  if (className.length > 0) {
    classNames.push(className);
  }

  return (
    <img
      className={classNames.join(' ')}
      src={logoPrimary}
      alt={alt}
      style={style}
      {...otherProps}
    />
  );
};

export default Logo;
