/* eslint-disable no-alert */
// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withTranslation } from 'react-i18next';
import { blockUserByThread } from '../../../../api/user';

import fcmFallback, { type FCMFallbackTicker } from '../../../../utils/fcmFallback';
import {
  sendMessageThunk,
  loadByApplicationIdThunk,
  remove as removeConversation,
  type ConversationDetailState,
} from '../../../../redux/modules/conversationDetail';
import { deleteConversation } from '../../../../api/chat';
import { type ReduxState } from '../../../../redux/modules';
import { type ReduxDispatch } from '../../../../types/redux';
import {
  type Conversation as ConversationType,
  type TextMessagePayload,
  type LocationMessagePayload,
} from '../../../../types/chat';
import { type Application } from '../../../../types/application';
import { fetchThunk as fetchJobDetailThunk } from '../../../../redux/modules/jobDetail';

import Conversation from '../../../../components/chat/Conversation';
import Confirm from '../../../../components/elements/Confirm';
import Button from '../../../../components/ui/Button';
import ProfileHeader from '../ProfileHeader';

import Loading from '../../Loading';
import { themePalette } from '../../../../theme/index';

type Props = {
  branchId: number,
  dispatch: ReduxDispatch,
  fcmSupportStatus: boolean,
  conversations: ConversationDetailState,
  application: Application,
  loadByApplicationIdThunk: number => Promise<ConversationType>,
  sendMessageThunk: (
    threadId: number,
    branchId: number,
    recipientId: number,
    message: TextMessagePayload | LocationMessagePayload,
  ) => void,
  fetchJobDetailThunk: number => void,
  jobId: number,
  workerRating: number,
  t: Function,
};

type State = {
  loading: boolean,
  conversation?: ConversationType,
};

class ConversationDetail extends PureComponent<Props, State> {
  props: Props;

  state: State;

  ticker: FCMFallbackTicker;

  confirmDialog: any;

  constructor(props: Props) {
    super(props);

    this.state = { loading: false };

    this.ticker = fcmFallback.ticker(this.findConversationHidden, 5e3);
    this.ticker.observe(props.fcmSupportStatus);
  }

  componentWillMount() {
    if (this.props.application.id !== 0) {
      this.findConversation(this.props.application.id);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.application.id !== nextProps.application.id && nextProps.application.id !== 0) {
      this.findConversation(nextProps.application.id);
    }
    this.ticker.observe(nextProps.fcmSupportStatus);
  }

  componentWillUnmount() {
    this.ticker.free();
  }

  findConversation(applicationId: number) {
    this.setState({ loading: true }, async () => {
      try {
        const conversation = await this.props.loadByApplicationIdThunk(applicationId);

        this.props.fetchJobDetailThunk(this.props.jobId);
        this.setState({ loading: false, conversation });
      } catch (error) {
        this.setState({ loading: false });
      }
    });
  }

  findConversationHidden = async () => {
    try {
      const conversation = await this.props.loadByApplicationIdThunk(this.props.application.id);

      this.setState({ conversation });
    } catch (error) {
      // ...
    }
  };

  onNewMessage = (message: TextMessagePayload | LocationMessagePayload) => {
    const { conversation } = this.state;

    if (conversation) {
      this.props.sendMessageThunk(
        conversation.thread.id,
        this.props.branchId,
        conversation.thread.user.id,
        message,
      );
    }
  };

  handleThreadRemove = () => {
    const { t } = this.props;

    this.confirmDialog.show(t('jobDetail.profile.isSureToDeleteConversation'), {
      onConfirm: async () => {
        if (!this.state.conversation) {
          return;
        }

        await deleteConversation(this.state.conversation.thread.id);
      },
    });
  };

  handleBlockUser = () => {
    const { t } = this.props;
    const { conversation } = this.state;
    if (!conversation) return;

    const threadId = conversation.thread.id;

    this.confirmDialog.show(t('jobDetail.profile.blockUserQuestion'), {
      onConfirm: async () => {
        try {
          await blockUserByThread(threadId);

          this.props.dispatch(removeConversation(threadId));
          this.setState({ conversation: undefined });
        } catch (error) {
          alert(error.message || t('common.errors.errorOccurred'));
        }
      },
    });
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    if (!this.state.conversation) {
      return null;
    }

    const conversation = this.props.conversations[this.state.conversation.thread.id].data;
    const { workerRating, t } = this.props;

    return (
      <div>
        <ProfileHeader profile={this.props.application.user} workerRating={workerRating}>
          <div className="u-gap-bottom-small">
            {!!conversation.messages.length && (
              <div className="u-gap-bottom-small">
                <Button
                  type="button"
                  block
                  outline
                  small
                  onClick={this.handleThreadRemove}
                  style={{
                    color: themePalette.color,
                    boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
                  }}
                >
                  {t('jobDetail.profile.deleteConversation')}
                </Button>
              </div>
            )}
            <Button
              type="button"
              danger
              small
              className="u-gap-top-xsmall"
              onClick={this.handleBlockUser}
              style={{
                backgroundColor: themePalette.primary_color,
                boxShadow: 'inset 0 0 0 0.07143em rgba(0, 0, 0, 0.33)',
              }}
            >
              {t('jobDetail.profile.blockUser')}
            </Button>
          </div>
        </ProfileHeader>

        <hr className="u-hr-line" />

        <Confirm
          ref={ref => {
            this.confirmDialog = ref;
          }}
          confirmLabel={t('common.glossary.continue')}
        />

        <Conversation
          thread={conversation.thread}
          messages={conversation.messages}
          onNewMessage={this.onNewMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  fcmSupportStatus: state.app.fcmSupport,
  conversations: state.conversationDetail,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators(
    { loadByApplicationIdThunk, fetchJobDetailThunk, sendMessageThunk, dispatch },
    dispatch,
  );

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConversationDetail));
