// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';
import { withTranslation } from 'react-i18next';
import swal from 'sweetalert';

import { profileUpdate } from '../../redux/modules/currentUser';
import formErrorMapper from '../../utils/formErrorMapper';

import UploadDialog from '../../components/abstract/UploadDialog';
import CircleImage from '../../components/elements/CircleImage';
import Box from '../../components/elements/Box';
import ProfileEditForm from '../../forms/ProfileEditForm';
import ImageCropModal from '../../components/elements/ImageCropModal';

import { type ReduxDispatch } from '../../types/redux';
import { type ReduxState } from '../../redux/modules';
import { type AuthenticatedUser } from '../../types/user';
import Alert from '../../components/elements/Alert';
import Loading from '../../components/elements/Loading';
import formInputList from '../../api/formInputList';
import { updatePhoneNumber } from '../../api/user';
import { swalOkButtonTranslateKey } from '../../constants';

type Props = {
  currentUser: AuthenticatedUser,
  profileUpdate: (data: Object, formViewData?: Object) => Promise<*>,
  push: Function,
  t: Function,
  location: any,
  history: any,
};

type State = {
  isAvatarModelOpened: boolean,
  cropModalImage: File | '',
  avatar: string | null,
  formViewData: any,
  alertMessage: string,
  showAlert: boolean,
  alertData: any,
  loading: boolean,
  shouldSubmitForm: boolean,
  routeState: any,
};

class Edit extends PureComponent<Props, State> {
  props: Props;

  state: State;

  initialValues: Object;

  handleAvatarClick: File => void;

  openImageCropModal: () => void;

  closeImageCropModal: () => void;

  handleCroppedImage: HTMLCanvasElement => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      isAvatarModelOpened: false,
      cropModalImage: '',
      avatar: null,
      formViewData: null,
      alertMessage: '',
      showAlert: false,
      alertData: null,
      loading: true,
      shouldSubmitForm: false,
      routeState: this.props.location.state,
    };

    this.handleAvatarClick = this.handleAvatarClick.bind(this);
    this.openImageCropModal = this.openImageCropModal.bind(this);
    this.closeImageCropModal = this.closeImageCropModal.bind(this);
    this.handleCroppedImage = this.handleCroppedImage.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);

    this.initialValues = {
      firstName: this.props.currentUser.user.firstName,
      lastName: this.props.currentUser.user.lastName,
      email: this.props.currentUser.user.email,
      phone: this.props.currentUser.user.phone,
      taxOffice: this.props.currentUser.user.taxOffice,
      taxOfficeNumber: this.props.currentUser.user.taxOfficeNumber,
      commercialTitle: this.props.currentUser.user.commercialTitle,
      website: this.props.currentUser.user.website,
      locationSearch: '',
      locationPosition: this.props.currentUser.user.location.latitude || '',
      latitude: this.props.currentUser.user.location.latitude,
      longitude: this.props.currentUser.user.location.longitude,
      centerLatitude: this.props.currentUser.user.location.latitude,
      centerLongitude: this.props.currentUser.user.location.longitude,
      zoom: 12,
      ...this.props.location.state,
    };
  }

  componentWillMount() {
    formInputList('CompanyProfileForm')
      .then(res => {
        this.setState({
          formViewData: res,
        });
      })
      .catch(e => {
        this.setState({
          alertMessage: 'common.errors.anErrorOccurred',
          alertData: { errorCode: 1014 },
          showAlert: true,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    if (this.state.routeState && this.state.routeState.phoneVerifyCode) {
      this.setState({
        shouldSubmitForm: true,
      });
    }

    window.onbeforeunload = () => {
      this.props.history.replace();
      return true;
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleAlertClose = () => {
    this.setState({
      showAlert: false,
    });
  };

  handleSubmitInternal = async (values: any, code?: string) => {
    try {
      if (code) {
        await updatePhoneNumber(values.phone, code);
      }

      await this.props.profileUpdate(
        {
          ...values,
          imageFile: this.state.avatar,
        },
        this.state.formViewData,
      );
      this.props.push('/profile');
    } catch (err) {
      this.setState({
        routeState: null,
      });

      if (err && err.payload && err.payload.message) {
        swal({
          text: this.props.t(`phoneVerify.${err.payload.message}`),
          icon: 'error',
          button: this.props.t(swalOkButtonTranslateKey),
          dangerMode: true,
        });
        return;
      }
      if (err.code && err.code === 400) {
        throw new SubmissionError(formErrorMapper(err.errors.children, this.props.t));
      }
      this.props.push('/error');
    }
  };

  handleSubmit = async (values: Object): Promise<*> => {
    if (values.phone && values.phone !== this.props.currentUser.user.phone) {
      if (
        !this.state.routeState ||
        (this.state.routeState && !this.state.routeState.phoneVerifyCode)
      ) {
        this.props.push('/sms-verify', {
          pushBackTo: '/profile/edit',
          ...values,
          countryCode: this.props.currentUser.user.country.country_code,
        });

        return;
      }

      await this.handleSubmitInternal(values, this.state.routeState.phoneVerifyCode);
      return;
    }

    await this.handleSubmitInternal(values);
  };

  handleAvatarClick(file: File) {
    this.setState({ cropModalImage: file }, () => {
      this.openImageCropModal();
    });
  }

  openImageCropModal() {
    this.setState({ isAvatarModelOpened: true });
  }

  closeImageCropModal() {
    this.setState({ isAvatarModelOpened: false });
  }

  handleCroppedImage(image: HTMLCanvasElement) {
    this.setState({ avatar: image.toDataURL('image/jpeg') });
    this.closeImageCropModal();
  }

  render() {
    const { currentUser, t } = this.props;

    if (this.state.loading) return <Loading />;

    return (
      <div>
        <Box>
          <div className="u-pad-ends-small u-pad-sides-small">
            <div className="u-flex u-flex-align-middle">
              <UploadDialog accept=".jpg,.png" onFileReady={this.handleAvatarClick}>
                <CircleImage
                  editable
                  className="u-gap-right"
                  src={this.state.avatar || currentUser.user.imageUrl}
                />
              </UploadDialog>

              <h1 className="u-font-weight-bold u-flex-grow-full h3@md-down">
                {currentUser.user.companyName}
              </h1>
            </div>
          </div>
          <hr className="u-hr-line" />
          <ProfileEditForm
            formViewData={this.state.formViewData}
            onSubmit={this.handleSubmit}
            initialValues={this.initialValues}
            currentUser={currentUser}
            shouldSubmitForm={this.state.shouldSubmitForm}
          />
        </Box>

        <ImageCropModal
          isOpen={this.state.isAvatarModelOpened}
          source={this.state.cropModalImage}
          onModalCloseRequested={this.closeImageCropModal}
          width={320}
          height={320}
          border={40}
          borderRadius={160}
          onImageReceive={this.handleCroppedImage}
        />
        <Alert
          isVisible={this.state.showAlert}
          onConfirmClick={this.handleAlertClose}
          message={t(this.state.alertMessage, this.state.alertData)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState): Object => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): Object =>
  bindActionCreators({ profileUpdate, push }, dispatch);

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Edit));
