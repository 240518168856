// @flow

import React from 'react';

import Container from '../../components/layout/Container';
import useTranslator from '../../hooks/useTranslator';

const Navbar = () => {
  const { t } = useTranslator();

  return (
    <div className="c-navbar c-navbar--white">
      <Container>
        <div className="c-navbar__body">
          <div>
            <h1 className="h2@md-down u-clear-gap">{t('layout.header.messages')}</h1>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
