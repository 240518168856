// @flow

import React, { PureComponent } from 'react';
import cx from 'classnames';

import moment from 'moment';
import { type Message } from '../../../types/chat';

type Props = {
  alignToRight?: boolean,
  message: Message,
};

class TextBubble extends PureComponent<Props> {
  props: Props;

  reload: any;

  componentDidMount() {
    this.reload = setInterval(() => {
      this.forceUpdate();
    }, 10e3);
  }

  componentWillUnmount() {
    if (this.reload) {
      clearInterval(this.reload);
    }
  }

  render() {
    const { message, alignToRight } = this.props;

    return (
      <span
        className={cx('c-chat__bubble', {
          'c-chat__bubble--left': alignToRight,
        })}
      >
        {message.body.text}

        <time className="c-chat__time">{moment(message.date).fromNow()}</time>
      </span>
    );
  }
}

export default TextBubble;
